import coinbaseValidator from './exchanges/coinbase';
import binanceTradesValidator from './exchanges/binance';
import coinbaseProTradesValidator from './exchanges/coinbase_pro';
import digitax from './exchanges/digitax';
import manualEntry from './manualEntry';

// returns tradeDataProps
// data includes exchange and files
export default (data) => {
  switch (data.exchange) {
    case 'coinbase':
      return coinbaseValidator(data);
    case 'coinbase_pro':
      return coinbaseProTradesValidator(data);
    case 'binance':
      return binanceTradesValidator(data);
    case 'digitax':
      return digitax(data);
    case 'manual_entry':
      return manualEntry(data);
    default:
      return {
        isValid: true,
        comment: 'We dont recognize this exchange',
        tradeData: data
      };
  }
};
