import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PnlTransactionsTable from 'modules/components/PnlTransactionsTable';
import pnlHeaders from 'modules/components/PnlTransactionsTable/headers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Divider from '@material-ui/core/Divider';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  marginTwo: {
    margin: theme.spacing(2)
  },
  marginTop: {
    paddingTop: theme.spacing(2)
  },
  marginOne: {
    margin: theme.spacing(0.5)
  },
  paper: {
    margin: theme.spacing(2),
    width: '95%'
  },
  displayFlex: {
    width: 200,
    display: 'flex'
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  button: {
    [theme.breakpoints.up('md')]: {
      minWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '31%'
    },
  },
  divColor: {
    backgroundColor: 'red'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  arrow: {
    width: '100px',
    height: 'auto'
  },
  marginRight: {
    marginRight: theme.spacing(2)
  }
});

// used to force a re-render
let count = 0;
class GainLossDetailTab extends React.Component {
  state = {
    selectedCurrency: null
  }

  handleCurrencyClick = (currency) => (event) => {
    const { selectedCurrency } = this.state;
    if (currency === selectedCurrency) {
      this.setState({ selectedCurrency: null });
    } else {
      this.setState({ selectedCurrency: currency });
    }
  }

  renderNoSelectedCurrency = () => {
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <div className={classes.hideOnMobile}>
          <div className={classes.alignCenter}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.marginTwo)}>
              {"Select a currency on the left to see your transactions"}
            </Typography>
          </div>
          <div className={classes.alignCenter}>
            <KeyboardBackspaceIcon className={classes.arrow} />
          </div>
        </div>
        <div className={clsx(classes.hideOnDesktop, classes.alignCenter)}>
          <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.marginTwo)}>
            {"Select a currency below to see your transactions"}
          </Typography>
        </div>
      </div>
    );
  }

  render () {
    const { classes, pnlTransactions, summedGainLoss } = this.props;
    const { selectedCurrency } = this.state;

    if ((Array.isArray(pnlTransactions) && pnlTransactions.length === 0) || !Array.isArray(pnlTransactions)) {
      return (
        <div className={classes.paper}>
          <div className={classes.alignTogether}>
            <ErrorOutlineIcon />
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.marginTwo)}>
              {"You have no transactions for this tax year."}
            </Typography>
          </div>
        </div>
      );
    }

    const transactionCounts = {};
    pnlTransactions.forEach(trans => {
      if (!(trans.sellCCY in transactionCounts)) {
        transactionCounts[trans.sellCCY] = 1;
      } else {
        transactionCounts[trans.sellCCY]++;
      }
    });

    let currencies = Object.keys(transactionCounts).map(
      ccy => ({ ccy, count: transactionCounts[ccy] })
    );

    currencies = _.sortBy(currencies, 'count').reverse();

    let selectedCurrencyTransactions = [];
    if (selectedCurrency) {
      selectedCurrencyTransactions = pnlTransactions.filter(
        ({ sellCCY }) => sellCCY === selectedCurrency
      );
    }

    let gainLoss = {};
    pnlTransactions.forEach(({ sellCCY, pnl }) => {
      if (!(sellCCY in gainLoss)) {
        gainLoss[sellCCY] = 0;
      }
      gainLoss[sellCCY] += parseFloat(pnl);
    });

    Object.keys(gainLoss).forEach(ccy => {
      if (gainLoss[ccy] < 0) {
        gainLoss[ccy] = '(' + String(_.round(gainLoss[ccy], 2).toLocaleString()).replace('-','') + ')'
      } else {
        gainLoss[ccy] = _.round(gainLoss[ccy], 2).toLocaleString();
      }
    });

    count += 1;
    return (
      <React.Fragment>
        <div className={clsx(classes.marginTwo, classes.alignCenter)}>
          <Typography variant="subtitle1" className={classes.textAlignCenter}>
            {`Your total gain (loss) is $${summedGainLoss}.`}
          </Typography>
        </div>
        <Divider className={classes.marginTwo} />
        <div className={classes.hideOnMobile}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <List>
                {
                  currencies.map(({ ccy }) => (
                    <ListItem key={ccy}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={this.handleCurrencyClick(ccy)}
                        color={selectedCurrency === ccy ? "primary" : "default"}
                      >
                        <div>
                          <Typography variant="subtitle1">
                            {ccy}
                          </Typography>
                          <Typography variant="subtitle2" className={classes.removeTextTransform}>
                            {`${transactionCounts[ccy]} Transactions.`}
                          </Typography>
                          <Typography variant="subtitle2" className={classes.removeTextTransform}>
                            {`Gain (Loss)  $${gainLoss[ccy]}`}
                          </Typography>
                        </div>
                      </Button>
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item xs={9}>
              {
                selectedCurrencyTransactions.length === 0 ?
                  this.renderNoSelectedCurrency() :
                  <PnlTransactionsTable
                    title={`Transactions for ${selectedCurrency}`}
                    key={count}
                    pnlTransactions={selectedCurrencyTransactions}
                    displayColumns={pnlHeaders}
                  />
              }
            </Grid>
          </Grid>
        </div>
        <div className={classes.hideOnDesktop}>
          {this.renderNoSelectedCurrency()}
          <div className={classes.marginTop}>
            {
              currencies.map(({ ccy }) => (
                <Button
                  variant="contained"
                  className={clsx(classes.button, classes.marginOne)}
                  onClick={this.handleCurrencyClick(ccy)}
                  color={selectedCurrency === ccy ? "primary" : "default"}
                  key={ccy}
                >
                  <div>
                    <Typography variant="subtitle1">
                      {ccy}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.removeTextTransform}>
                      {`${transactionCounts[ccy]} Transactions.`}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.removeTextTransform}>
                      {`Gain (Loss)  $${gainLoss[ccy]}`}
                    </Typography>
                  </div>
                </Button>
              ))
            }
          </div>
          {
            selectedCurrencyTransactions.length === 0 ?
              null : 
              <PnlTransactionsTable 
                title={`Transactions for ${selectedCurrency}`}
                key={count}
                pnlTransactions={selectedCurrencyTransactions}
                displayColumns={pnlHeaders}
              />
          }
        </div>
      </React.Fragment>
    );
  }
}

GainLossDetailTab.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    pnlTransactions: PropTypes.array
  })
};

// function mapStateToProps(state) {
//   return {
//     exchangeAndTaxData: {
//       pnlTransactions: state.exchangeAndTaxData.pnlTransactions
//     }
//   };
// }

export default compose(
  withStyles(styles),
  connect(null)
)(GainLossDetailTab);