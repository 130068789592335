import React from 'react';
import MissingTransactionHistory from './pages/missing-transaction-history';
import WalletMovementsUnmatched from './pages/wallet-movements-unmatched';
import NotFound from '../notfound';

class HelpTopLevel extends React.Component {
  render() {
    const { url } = this.props.match;

    let ComponentToRender;
    if (url === '/help/missing-transaction-history') {
      ComponentToRender = MissingTransactionHistory;
    } else if (url === '/help/wallet-movements-unmatched') {
      ComponentToRender = WalletMovementsUnmatched;
    } else {
      ComponentToRender = NotFound;
    }

    return (
      <div>
        <ComponentToRender />;
      </div>
    );
  }
}


export default HelpTopLevel;