import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import GetStarted from 'modules/components/GetStarted';
import Watermark from 'modules/components/Watermark';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  btnFree: {
    textTransform: 'none'
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10)
    }
  },
  marginDivider: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  invisible: {
    visibility: 'hidden'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  alignLeft: {
    display: 'flex',
    alignItems: 'left'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '31%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  backgroundGradient: {
    background: 'linear-gradient(45deg, #2CBD72 30%, #FFFFFF 90%)'
  },
  textDecoration: {
    textDecoration: 'underline'
  },
  marginSubHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  mobilePadding: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  subHeader: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'center'
  },
});

class ContactUs extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Card variant="outlined" className={classes.mobilePadding}>
        <div className={clsx(classes.flex, classes.margin, classes.textAlignCenter)}>
          <Typography variant="h1">
            {"Contact Digitax"}
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.marginSubHeader)}>
          <Typography variant="subtitle1">
            <Box component="span" fontWeight="fontWeightLight">
              {"We're always ready to help."}
            </Box>
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.marginSubHeader, classes.textAlignCenter)}>
          <Typography variant="body">
            {"Have a question?  Email us at "}
            <Box component="span" fontWeight="fontWeightBold">
              {"support@digitax.io"}
            </Box>
            {".  Our staff will get back to you as quickly as we can."}
          </Typography>
        </div>
        <div className={clsx(classes.flex, classes.marginSubHeader, classes.textAlignCenter)}>
          <Typography variant="body">
            {"We're located in Easton, Pennsylvania.  Get in touch!"}
          </Typography>
        </div>
        <GetStarted />
        <Watermark />
      </Card>
    );
  }
}


export default withStyles(styles)(ContactUs);