// This component wraps child components with our application with Redux and Styles

import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';

// Wraps the application with our styles
import withRoot from './modules/withRoot';

// the provider receives all child components via the component's children property
const Wrapped = ({ children, initialState }) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(reduxThunk))
  );

  // wraps everything with store
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

// wraps Wrapped with our Root theme
export default withRoot(Wrapped)
// export default Wrapped;
