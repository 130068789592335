import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
// import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Dot from 'modules/components/Dot';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ReactComponent as GateioSvg } from 'modules/statics/exchangeIcons/gateio.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
// import gateio from 'modules/statics/exchangeIcons/gateio.svg';

const styles = theme => ({
  root: {
    // width: '84vw',
    // height: '100vh'
  },
  cardMargin: {
    marginTop: theme.spacing(1)
  },
  container: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(12)
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1)
    }
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  paddingTop: {
    paddingTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  paddingBottom: {
    // marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  authorContainer: {
    marginTop: theme.spacing(0.5)
  },
  marginRightIcon: {
    marginRight: theme.spacing(2)
  },
  infoIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  marginRightWrittenBy: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  // imgSize: {
  //   width: '100%',
  //   height: 'auto'
  // },
  italics: {
    // textDecoration: 'emphasis'
  },
  alignTogether: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  headerDivider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  marginTwoLine: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2.6)
  },
  marginOneLine: {
    marginRight: theme.spacing(1),
    // marginBottom: theme.spacing(1.4)
  },
  info: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: '6em',
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      height: '7em',
      backgroundColor: theme.palette.primary.main
    },
  },
  marginInfoIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  list: {
    listStylePosition: 'outside'
  },
  dot: {
    paddingTop: '0.1em',
    width: '0.5em',
    height: 'auto'
  },
  arrow: {
    marginBottom: '0.8em',
    // width: '1em',
    // height: 'auto'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  imgSize: {
    [theme.breakpoints.up('md')]: {
      width: '10%',
      objectFit: 'contain',
      height: '8em',
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      width: '25%',
      objectFit: 'contain',
      height: '8em',
      marginRight: theme.spacing(2)
    },
  },
  mobileFontSize: {
    fontSize: '30px'
  },
  infoSmall: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: '4em',
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      height: '7em',
      backgroundColor: theme.palette.primary.main
    }
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
});
// { "Integrate Digitax to your transactions with Binance's API or upload the downloadable CSVs from Binance's portal to Digitax." }


class HelpContent extends React.Component {
  handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  render() {
    const { classes, exchange } = this.props;
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/exchanges">
            {"Exchange Integrations"}
          </Link>
          <Typography color="textPrimary">{exchange + ' Detail'}</Typography>
        </Breadcrumbs>
        <Divider className={classes.cardMargin} />
        <div className={classes.container}>
          <div className={clsx(classes.marginTop, classes.alignTogether)}>
            <SvgIcon className={classes.imgSize}>
              <GateioSvg />
            </SvgIcon>
            <div className={classes.authorContainer}>
              <Typography variant="h3" className={clsx(classes.mobileFontSize, classes.paddingBottom)}>
                <Box component="span" fontWeight="fontWeightMedium">
                  {`Import Data From ${exchange}`}
                </Box>
              </Typography>
              <Typography variant="caption">
                <Box component="span" fontWeight="fontWeightLight">
                  {`US Traders can easily calculate their taxes from trading on ${exchange} and create the right tax documents to submit to the IRS. Digitax fully supports ${exchange} and makes reporting your transactions very easy.`}
                </Box>
              </Typography>
            </div>
          </div>
          <Divider className={classes.headerDivider} />
          <main className={clsx(classes.marginTop, classes.marginBottom)}>
            <Typography className={classes.marginTop} variant="h4">
              {"Let's Begin..."}
            </Typography>
            <Typography className={clsx(classes.italics, classes.marginTop, classes.marginBottom)} variant="body1">
              {`We need to get your transactions from ${exchange}.  We'll be using ${exchange}'s API to get your data.`}
            </Typography>
            <Card variant="outlined" className={clsx(classes.info, classes.alignTogether)}>
              <InfoOutlinedIcon className={classes.marginInfoIcon} />
              <Typography variant="caption">
                <Box component="span" fontWeight="fontWeightLight">
                  {"While other providers allow you to import CSVs, we enforce using the API as this will be less error prone.  Futhermore, for your security, Digitax will never save your API keys."}
                </Box>
              </Typography>
            </Card>
            <Typography variant="body2" className={classes.marginTop}>
              <Box component="span" fontWeight="fontWeightLight">
                {"Pretty simple right?  Follow the instructions for API below."}
              </Box>
            </Typography>
            <Divider className={classes.headerDivider} />
            <Typography className={classes.marginTop} variant="h4">
              {"API Instructions"}
            </Typography>
            <List className={classes.list}>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"1)"}
                </Typography>
                <Typography>
                  {"Login to your Gateio account on their website.  You may have to enter two factor authentication."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"2) "}
                </Typography>
                <Typography>
                  {"On the top righthand corner, click on the portrait icon and in the drop-down menu, select "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"API Management"}
                  </Box>
                  {"."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"3) "}
                </Typography>
                <Typography>
                  {"On the lefthand menu, click on the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"APIv4 Keys"}
                  </Box>
                  {" button."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"4) "}
                </Typography>
                <Typography>
                  {"On the righthand side of the page, click "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"+Create New API Key"}
                  </Box>
                  {", give the API Key a name in the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Remark"}
                  </Box>
                  {" field, and check the box, "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Wallet"}
                  </Box>
                  {", and finally ONLY check "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Read Only"}
                  </Box>
                </Typography>
              </ListItem>
              <ListItem>
                <Card variant="outlined" className={clsx(classes.infoSmall, classes.alignTogether)}>
                  <InfoOutlinedIcon className={classes.marginInfoIcon} />
                  <Typography variant="caption">
                    <Box component="span" fontWeight="fontWeightLight">
                      {"For your security, please double check to make sure Read And Write is NOT selected.  Digitax only needs to be able to read your transactions."}
                    </Box>
                  </Typography>
                </Card>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"5) "}
                </Typography>
                <Typography>
                  {"You will be prompted for two factor authentication again."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"6) "}
                </Typography>
                <Typography>
                  {"Use the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"API Key"}
                  </Box>
                  {" and "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Secret Key"}
                  </Box>
                  {" on Digitax's import form to upload your transactions."}
                </Typography>
              </ListItem>
            </List>
          </main>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HelpContent);