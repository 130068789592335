// this file is completely unused
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import requireAuth from 'modules/components/requireAuth';
import Button from '@material-ui/core/Button';
import { fetchDocumentForUser, fetchDocumentCreationStatus } from 'actions';
import logger from 'modules/utils/logger';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  },
  paper: {
    margin: 'auto'
  },
  header: {
    margin: theme.spacing(5)
  },
  tableCell: {
    width: theme.spacing(15)
  },
  marginNoBottom: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  split: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const displayColumns = [
  { label: 'Name', name: 'name' },
  { label: 'Year', name: 'year' },
  { label: 'Type', name: 'type' },
  { label: 'Method', name: 'method' },
  { label: 'Created At', name: 'created' },
  { label: 'Status', name: 'status' },
  { label: 'Link', name: 'link' }
];

class Documents extends React.Component {
  componentDidMount() {
    const { api: { documentsCreating } } = this.props;
    if (Array.isArray(documentsCreating) && documentsCreating.length > 0) {
      this.props.fetchDocumentCreationStatus();
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    logger.info('clicked!');
    this.props.fetchDocumentForUser({ taxYear: 2069 });
  }

  renderProgressFlow = () => {
    const { classes } = this.props;

    return (
      <div className={classes.marginNoBottom}>
        <Typography variant="subtitle2" display="inline">
          {"1) Import Exchange Data ->  "}
        </Typography>
        <Typography variant="subtitle2" display="inline">
          {"2) Reconcile Transactions  ->  "}
        </Typography>
        <Typography variant="subtitle2" display="inline">
          <Box>
            {"3) Classify Income and Choose Plan  ->  "}
          </Box>
        </Typography>
        <Typography variant="subtitle2" display="inline">
          {"4) Created and Download Tax Forms "}
        </Typography>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderProgressFlow()}
        <Paper className={classes.paper}>
          <div className={classes.split}>
            <Typography variant="h4" className={classes.header} display="inline">
              {"Tax Documents"}
            </Typography>
            <Link
              component={RouterLink}
              to="/dashboard/documents/create"
            >
              <Button
                variant="contained"
                className={clsx(classes.removeTextTransform, classes.header)}
                color="secondary"
              >
                {"Create Document"}
              </Button>
            </Link>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {displayColumns.map(({ label }) => {
                  return (
                    <TableCell key={label} className={classes.tableCell}>
                      {label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link color="secondary" onClick={this.handleClick} type="submit">
                    <Typography variant="subtitle2" className={classes.margin}>
                      {"Fetch 8949"}
                    </Typography>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchDocumentForUser: PropTypes.func.isRequired,
  api: PropTypes.shape({
    documentsCreating: PropTypes.array
  }),
}

const mapStateToProps = (state) => {
  return {
    api: {
      documentsCreating: state.api.documentsCreating
    }
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchDocumentForUser, fetchDocumentCreationStatus })
)(requireAuth(Documents))
