export default [
  { name: 'coinbase', display: 'Coinbase' },
  { name: 'coinbase_pro', display: 'Coinbase Pro' },
  { name: 'binance', display: 'Binance' },
  { name: 'binance_us', display: 'Binance US' },
  { name: 'bitfinex', display: 'Bitfinex' },
  { name: 'bittrex', display: 'Bittrex' },
  { name: 'kraken', display: 'Kraken' },
  { name: 'gemini', display: 'Gemini' },
  { name: 'bitstamp', display: 'Bitstamp' },
  { name: 'gateio', display: 'Gateio' },
];
