export const SUCCESSFUL_FETCH = 'successful_fetch';
export const SUCCESSFUL_CALCULATION_TRIGGER = 'successful_calculation_trigger';
export const ALREADY_UPLOADED = 'already_uploaded';
export const DELETED_EXCHANGE_DATA = 'deleted_exchange_data';
export const API_MESSAGE_TEST = 'api_message_test';
export const APP_ERROR_MESSAGE = 'app_error_message';
export const APP_API_MESSAGE = 'app_api_message';
export const SUCCESSFUL_GENERATE_DOCUMENT_TRIGGER = 'successful_generate_document_trigger';
export const UNMATCHED_TRANSACTIONS_EXIST = 'unmatched_transactions_exixt';
export const SUCCESSFUL_RECONCILE_TRIGGER = 'successful_reconcile_trigger';
export const SUCCESSFUL_ZERO_COST_BASIS_UPLOAD = 'successful_zero_cost_basis_upload';
export const API_FETCH_ERROR = 'api_fetch_error';

export const responseMessages = {
  [SUCCESSFUL_FETCH]:
    {
      header: "We've started to pull your data.",
      bodyMessageUL: [
        'If you have transactions at other exchanges, continue uploading your data.',
        'You can see your upload statuses in the Upload History.',
        'You may need to refresh the webpage to see updated data.'
      ]
    },
  [SUCCESSFUL_CALCULATION_TRIGGER]:
    {
      header: "We've started to calculate your gains and losses.",
      bodyMessageUL: [
        'This may take up to 5 minutes depending on the number of your transactions.',
        'You may need to refresh the webpage to see updated data.'
      ]
    },
  [SUCCESSFUL_RECONCILE_TRIGGER]:
    {
      header: "Reconciliation Complete:",
      bodyMessageUL: [
        'All of your transactions have been matched.',
        'Please continue by calculating your gains and losses.',
      ]
    },
  [UNMATCHED_TRANSACTIONS_EXIST]:
    {
      header: "Unmatched Transactions:",
      bodyMessageUL: [
        "We weren't able to reconcile all of your wallet movements across exchanges.",
        'Please follow the next steps to help us understand where the deposits and withdrawals are coming from.'
      ]
    },
  [ALREADY_UPLOADED]:
    {
      header: "You've already uploaded data from replace_exchange.",
      bodyMessageUL: [
        'If you have new data from this exchange, please delete it with the button on the page.'
      ]
    },
  [DELETED_EXCHANGE_DATA]:
    {
      header: "We have successfully deleted your transactions from replace_exchange.",
      bodyMessageUL: [
        'Please note we have also deleted your previously calculated gain and loss information.'
      ]
    },
  [API_MESSAGE_TEST]:
    {
      header: "Details:",
      bodyMessageUL: [
        'This would be a sample message'
      ]
    },
  [APP_ERROR_MESSAGE]: 
    {
      header: "Details:",
      bodyMessageUL: [
        'replace_message'
      ]
    },
  [APP_API_MESSAGE]:
    {
      header: "Details:",
      bodyMessageUL: [
        'replace_message'
      ]
    },
  [SUCCESSFUL_GENERATE_DOCUMENT_TRIGGER]:
    {
      header: "We've started to generate your tax form.",
      bodyMessageUL: [
        'This may take up to a few minutes depending on the number of your transactions.',
        'You may need to refresh the webpage to see updated data.'
      ]
    },
  [SUCCESSFUL_ZERO_COST_BASIS_UPLOAD]:
    {
      header: "We've successfully applied a zero cost basis to the selected transaction.",
      bodyMessageUL: [
        'If there are more left to classify, please do so.',
        'Otherwise upload more transactions so we can calculate a cost basis for them.',
      ]
    },
  [API_FETCH_ERROR]:
    {
      header: "Error:",
      bodyMessageUL: [
        'Your import of replace_exchange just errored.',
        'Take a look at the Upload History Tab to see what happened.',
        'Please double check your API keys were correct.',
        'If you try again and receive the same error, please contact support@digitax.io.'
      ]
    },
};