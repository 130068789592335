import logger from 'modules/utils/logger';
const VALIDATION_ERROR = 'validation_error';
const PASSPORT_LOGIN_ERROR = 'passport_login_error';
const DUPLICATE_ACCOUNT = 'duplicate_account_error';
const EMAIL_SENT_ERROR = 'email_sent_error';
const EMAIL_CONFIRMATION_TOKEN_ERROR = 'email_confirmation_token_error';
const DEFAULT_ERROR = 'default_error';
const CSRF_ERROR = 'csrf_error';


const responseMessages = {

  [VALIDATION_ERROR]:
  {
    header: "Details:",
    message: 'Input Error'
  },
  [PASSPORT_LOGIN_ERROR]:
  {
    header: "Details:",
    message: "Incorrect Email Or Password"
  },
  // note - i am setting the err.message equal to this.
  [DUPLICATE_ACCOUNT]:
  {
    header: "Details:",
    bodyMessageUL: ['This email is already associated with an account.', 'Have you forgotten your password?']
  },
  [EMAIL_SENT_ERROR]:
  {
    header: "Details",
    message: 'Our Email Service Provider is Experiencing issues.  Please re-try later when the service is back and functioning.'
  },
  [EMAIL_CONFIRMATION_TOKEN_ERROR]:
  {
    header: 'Details',
    message: 'Invalid Email Confirmation Token.  Please Manually Re-send an Email Confirmation Token in the account tab'
  },
  [DEFAULT_ERROR]:
  {
    header: 'Details',
    message: 'Issue Signing Up'
  },
  [CSRF_ERROR]:
  {
    header: 'Details',
    message: 'Please refresh page and retry your action.'
  },
};

export default (err) => {
  logger.info('%O', err);
  logger.info('error is an array: ', Array.isArray(err));
  if (Array.isArray(err) && err[0].name === 'ValidationError') {
    // errorArray.push({ fieldName: key, fieldValue: value, isJoi: true, name: err.name, status: 'error' });
    let message = '';
    for (const e of err) {
      message = message + `${e.fieldValue} is not a valid value for the field: ${e.fieldName} \n`;
    }
    err = { header: 'Details', message };
  } else if (err.name === 'ValidationError') {
    err = responseMessages[VALIDATION_ERROR];
  } else if (err.name === 'PassportLoginError') {
    err = responseMessages[PASSPORT_LOGIN_ERROR];
  } else if (err.name === 'DuplicateAccountError') {
    err.message = responseMessages[DUPLICATE_ACCOUNT];
  } else if (err.name === 'EmailSentError') {
    err = responseMessages[EMAIL_SENT_ERROR];
  } else if (err.name === 'EmailConfirmationTokenError') {
    err = responseMessages[EMAIL_CONFIRMATION_TOKEN_ERROR];
  } else if (err.name === 'CSRFError') {
    err = responseMessages[CSRF_ERROR];
  } else {
    err = responseMessages[DEFAULT_ERROR];
  }
  return err;
}