import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';


const styles = (theme) => ({
  container: {
    // width: '90%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: 'none'
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    }
  },
  colorMain: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1)
  },
  headerPadding: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  progressSize: {
    width: '5px',
    height: 'auto'
  }
});

const steps = [
  'Import Trade Data',
  'Reconcile Movements',
  'Choose Your Plan',
  'Calculate Gains & Losses',
  'Reconcile Trade Cost Basis',
  'Calculate Gains & Losses',
  'Create & Download Tax Documents'
];

// const stepDetails = {
//   0: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   1: 'An ad group contains one or more ads which target a shared set of keywords.',
//   2: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
// }

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0
  };

  componentDidMount() {
    const { nextStepIndex } = this.props;
    this.setState({ activeStep: nextStepIndex });
  }

  getStepContent = (step) => {
    const { classes } = this.props;

    switch (step) {
      case 0: // Import Trade Data
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Upload your transaction data with your exchange's API, CSVs, or manually."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"This is the essential buy, sell, deposit, withdrawal, mining, and interest income that is used in your taxes."}
              </Typography>
            </ListItem>
          </List>
        );
      case 1: // Reconcile Movements
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Please confirm you have imported all of your transactions."}
              </Typography>
            </ListItem>            
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Next, we'll attempt to match deposits and withdrawals between your exchange wallets."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"If we can't match everything, this might be a sign that you are missing transactions."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Alternatively, you want might be receiving crypto via other methods such as AirDrop, and need to classify it."}
              </Typography>
            </ListItem>
          </List>
        );
      case 2: // Choose Your Plan
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"We'll now have all of the transactions used in your gains and losses, so we'll know what plan you need."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Choose and purchase your Digitax plan."}
              </Typography>
            </ListItem>
          </List>
        );
      case 3: // Calculate Gains & Losses
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Calculate your capital gains and losses using FIFO."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"This will let us know if we need to you provide any additional information as we'll need a cost basis for every cryptocurrency sold."}
              </Typography>
            </ListItem>
          </List>
        );
      case 4: // Reconcile Trade Cost Basis
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"We may need more information if you are selling more currency than we are aware existing at that exchange."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"This will be a sign that you are missing transactions to upload.  Altneratively, you can assuming a cost basis of $0 for the missing cryptocurrency.  This is the most tax conservative approach."}
              </Typography>
            </ListItem>
          </List>
        );
      case 5: // Calculate Gains & Losses
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"If you needed to reconcile some trades, you'll need to re-calculate your capital gains and losses for the last time."}
              </Typography>
            </ListItem>
          </List>
        );
      case 6: // Create & Download Tax Documents
        return (
          <List>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Once the last steps are completed, you'll be all ready to create the Tax Documents you need for your tax return."}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="body1">
                {"Filing using TurboTax?  Use the 8949 TurboTax approved form."}
              </Typography>
            </ListItem>
          </List>
        );
      default:
        return `Unknwon step`;
    }
  }

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  renderButton = (stepIndex) => {
    const { classes } = this.props;

    let gotoURL;
    let buttonText;
    if (stepIndex === 0) {
      buttonText = 'Import Your Trades';
      gotoURL = '/dashboard/import';
    } else if (stepIndex === 1) {
      // reconcile movements
      buttonText = 'Go-To Reconcile';
      gotoURL = '/calculate/review';
    } else if (stepIndex === 2) {
      // calculate gains
      buttonText = 'Go-To Choose Plan';
      gotoURL = '/calculate/plan';
    } else if (stepIndex === 3) {
      buttonText = 'Go-To Calculate';
      gotoURL = '/calculate/calc';
    } else if (stepIndex === 4) {
      // review trades and movements with cost basis
      buttonText = 'Go-To Review';
      gotoURL = '/calculate/review';
    } else if (stepIndex === 5) {
      // calculate gains after cost basis review
      buttonText = 'Go-To Calculate';
      gotoURL = '/calculate/calc';
    } else if (stepIndex === 6) {
      buttonText = 'Go-To Documents';
      gotoURL = '/calculate/documents';
    }

    return (
      <Link component={RouterLink} to={gotoURL}>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.removeTextTransform)}
          color="primary"
        >
          {buttonText}
        </Button>
      </Link>
    );
  }

  render() {
    const { activeStep } = this.state;
    const { classes, nextStepIndex, isCalculatingAfterRec, isFetching, isCalculatingBeforeRec } = this.props;

    // const activeStep = _.cloneDeep(nextStepIndex);
    return (
      <div className={classes.container}>
        <div className={classes.colorMain}>
          {
            isFetching || isCalculatingBeforeRec || isCalculatingAfterRec ? (
              <React.Fragment>
                <Typography variant="h4" display="inline" component="h1" className={classes.headerPadding}>
                  {
                    isFetching ?
                    'Currently Fetching...' : 
                    'Currently Calculating...'
                  }
                </Typography>
                <CircularProgress color="secondary" size={'2em'} />
              </React.Fragment>
            ) : (
              <Typography variant="h4" component="h1" className={classes.headerPadding}>
                {`Next Step: ${steps[nextStepIndex]}`}
              </Typography>
            )
          }
        </div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>
                {label}
                {
                  isFetching && index === 0 ? (
                    <Box display="inline" fontWeight="fontWeightBold">
                      {": In Progress..."}
                    </Box>
                  ) : null
                }
                {
                  isCalculatingBeforeRec && index === 3 ? (
                    <Box display="inline" fontWeight="fontWeightBold">
                      {": In Progress..."}
                    </Box>
                  ) : null
                }
                {
                  isCalculatingAfterRec && index === 5 ? (
                    <Box display="inline" fontWeight="fontWeightBold">
                      {": In Progress..."}
                    </Box>
                  ) : null
                }
              </StepLabel>
              <StepContent>
                {this.getStepContent(index)}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      {"Back"}
                    </Button>
                    <Button
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    {this.renderButton(index)}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>{"All steps completed - you're finished"}</Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              {"Reset"}
            </Button>
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VerticalLinearStepper);