import React from 'react';
import _ from 'lodash';
import BinanceComp from './exchanges/Binance';
import BinanceUS from './exchanges/binance_us';
import CoinbasePro from './exchanges/coinbase_pro';
import Coinbase from './exchanges/coinbase';
import Gemini from './exchanges/gemini';
import Kraken from './exchanges/kraken';
import Gateio from './exchanges/gateio';
import Bitstamp from './exchanges/bitstamp';
import Bittrex from './exchanges/bittrex';
import NotFound from '../notfound';
import exchangesRealMapInverse from 'modules/statics/exchangesRealMapInverse';
import GetStarted from 'modules/components/GetStarted';
import Watermark from 'modules/components/Watermark';
import { Paper } from '@material-ui/core';


class Integrations extends React.Component {
  render() {
    let ComponentToRender = NotFound;
    if (this.props.match && this.props.match.params && _.isString(this.props.match.params.exchange) &&
      exchangesRealMapInverse[this.props.match.params.exchange]
    ) {
      const routeExchange = this.props.match.params.exchange;
      if (routeExchange === 'binance') {
        ComponentToRender = BinanceComp;
      } else if (routeExchange === 'binance_us') {
        ComponentToRender = BinanceUS;
      } else if (routeExchange === 'coinbase_pro') {
        ComponentToRender = CoinbasePro;
      } else if (routeExchange === 'coinbase') {
        ComponentToRender = Coinbase;
      } else if (routeExchange === 'gemini') {
        ComponentToRender = Gemini;
      } else if (routeExchange === 'kraken') {
        ComponentToRender = Kraken;
      } else if (routeExchange === 'bitstamp') {
        ComponentToRender = Bitstamp;
      } else if (routeExchange === 'bittrex') {
        ComponentToRender = Bittrex;
      } else if (routeExchange === 'gateio') {
        ComponentToRender = Gateio;
      }

      return (
        <React.Fragment>
          <Paper>
            <ComponentToRender exchange={exchangesRealMapInverse[routeExchange]} />
            <GetStarted />
            <Watermark />
          </Paper>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ComponentToRender />
        <Paper>
          <GetStarted />
          <Watermark />
        </Paper>
      </React.Fragment>
    );
  }
}


export default Integrations;