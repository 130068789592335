import coinbaseTrades from './coinbase_trades';
import coinbaseWallet from './coinbase_wallet';
import logger from 'modules/utils/logger';

export default ({ exchange, files }) => {
  let dataToUpload = [];

  files.forEach(data => {
    const tradeData = [];
    const walletMovements = [];

    if (data.length > 0) {
      // need to spread the data into two
      // trades and wallets movements
      logger.info('data', data);
      data.forEach(record => {
        if ((record[1] === 'Send' || record[1] === 'Receive') ||
          record[7].includes('Fork')) {
          walletMovements.push(record);
        } else if (record[1] === 'Sell' || record[1] === 'Buy') {
          tradeData.push(record);
        }
      });

      if (data[0][0] === 'Transactions') {
        if (walletMovements.length > 0) {
          logger.info('we have ', walletMovements.length, ' wallet movements')
          dataToUpload.push(coinbaseWallet({ exchange, walletMovements }));
        }
        if (tradeData.length > 0) {
          logger.info('we have ', tradeData.length, ' trades')
          dataToUpload.push(coinbaseTrades({ exchange, tradeData }));
        }
      } else {
        dataToUpload.push({
          isValid: false,
          comment: "We don't recongize this file type",
          tradeData: [],
          type: 'unknown',
          badTrades: []
        });
      }
    }
  });
  return dataToUpload;
}
