export default [
  { id: 'acquiredTime', numeric: false, disablePadding: false, label: 'Acquired Time' },
  { id: 'disposedTime', numeric: false, disablePadding: false, label: 'Disposed Time' },
  { id: 'buyCCY', numeric: false, disablePadding: false, label: 'Buy CCY' },
  { id: 'sellCCY', numeric: false, disablePadding: false, label: 'Sell CCY' },
  { id: 'term', numeric: false, disablePadding: false, label: 'Term' },
  { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
  { id: 'costBasis', numeric: true, disablePadding: false, label: 'Cost Basis ($)' },
  { id: 'proceeds', numeric: true, disablePadding: false, label: 'Proceeds ($)' },
  { id: 'pnl', numeric: true, disablePadding: false, label: 'Gain (Loss) ($)' }
];