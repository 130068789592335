export default {
  free: {
    maxTransactions: 0
  },
  premium: {
    maxTransactions: 250
  },
  trader: {
    maxTransactions: 5000
  },
  professional: {
    maxTransactions: 25000
  }
};