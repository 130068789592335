const SERVER_ERROR = 'server_error';
const NO_USER = 'no_user';

const responseMessages = {
  [SERVER_ERROR]:
    {
      header: "Error Message:",
      bodyMessageUL: [
        'Internal server error, please try again later.'
      ]
    },
  [NO_USER]:
    {
      header: "Error Message:",
      bodyMessageUL: [
        "We didn't fetch a user"
      ]
    }
};

export default (err) => {
  if (err.response && err.response.status) {
    if (err.response.status === 500) {
      err.message = responseMessages[SERVER_ERROR];
    } else if (err.response.status === 401) {
      err.message = responseMessages[NO_USER];
    }
    return err;
  }
  return err;
}