import {
  PLAN_AND_BILLING_TAB,
  ACCOUNT_TAB,
  RESET_PASSWORD_FROM_RECOVERY,
  VALIDATE_DISCOUNT_CODE,
  DASHBOARD_MODAL,
  FETCHING_EXCHANGE_DATA,
  FETCHING_EXCHANGE_DATA_ERROR,
  HEADER_TAB_SELECT,
  CALCULATE_TAB_SELECT,
  SET_SELECTED_EXCHANGE,
  CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE,
  LOADING_STATUSES,
  CALCULATING_GAINS_AND_LOSSES,
  ALREADY_UPLOADED,
  API_MESSAGE_MODAL,
  APP_ERROR_MESSAGE_MODAL,
  APP_API_MESSAGE_MODAL,
  MOBILE_MENU_OPEN,
  WALLET_NAME_UPDATE
} from '../actions/types';
import { NOT_CALCULATING } from '../actions/uiTypes';
import { DELETED_EXCHANGE_DATA } from '../actions/responseMessages';


const INITIAL_STATE = {
  accountTabSelected: 0,
  passwordHasBeenReset: false,
  invalidDiscountCode: null,
  dashboardModalMessage: null,
  isLoadingExchangeData: null,
  fetchExchangeDataError: null,
  fetchExchangeMessage: null,
  importSelectedExchange: null,
  headerTabSelected: false,
  calcGainLossMessage: null,
  isCalculatingGainsLosses: NOT_CALCULATING,
  calculateTabSelected: false,
  deletedExchangeDataMessage: null,
  deleteSelectedExchange: null,
  apiErrorMessage: null,
  appErrorMessage: null,
  appApiMessage: null,
  mobileMenuOpen: false,
  updateWalletName: '',
  userInputKnownWallets: []
};

export default (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case PLAN_AND_BILLING_TAB:
      return { ...state, accountTabSelected: action.payload.tabSelected };
    case ACCOUNT_TAB:
      return { ...state, accountTabSelected: action.payload.tabSelected };
    case RESET_PASSWORD_FROM_RECOVERY:
      return { ...state, passwordHasBeenReset: action.payload.passwordHasBeenReset };
    case VALIDATE_DISCOUNT_CODE:
      return { ...state, discountMessage: action.payload.discountMessage };
    case DASHBOARD_MODAL:
      return { ...state, dashboardModalMessage: action.payload.displayMessage };
    case FETCHING_EXCHANGE_DATA:
      return {
        ...state,
        isLoadingExchangeData: action.payload.isLoadingExchangeData,
        fetchExchangeMessage: action.payload.fetchExchangeMessage,
        importSelectedExchange: action.payload.importSelectedExchange
      };
    case SET_SELECTED_EXCHANGE:
      return { ...state, importSelectedExchange: action.payload.exchange };
    case FETCHING_EXCHANGE_DATA_ERROR:
      return { ...state, fetchExchangeDataError: action.payload.fetchExchangeDataError };
    case HEADER_TAB_SELECT:
      return { ...state, headerTabSelected: action.payload.headerTabSelected };
    case CALCULATING_GAINS_AND_LOSSES:
      return {
        ...state,
        isCalculatingGainsLosses: action.payload.isCalculatingGainsLosses
      };
    case CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE:
      return {
        ...state,
        calcGainLossMessage: action.payload.calcGainLossMessage
      };
    case LOADING_STATUSES:
      return {
        ...state,
        isLoadingExchangeData: action.payload.isLoadingExchangeData,
        isCalculatingGainsLosses: action.payload.isCalculatingGainsLosses
      };
    case CALCULATE_TAB_SELECT:
      return { ...state, calculateTabSelected: action.payload.calculateTabSelected };
    case ALREADY_UPLOADED:
      return { ...state, fetchExchangeMessage: action.payload.fetchExchangeMessage };
    case DELETED_EXCHANGE_DATA:
      return {
        ...state,
        deletedExchangeDataMessage: action.payload.deletedExchangeDataMessage,
        deleteSelectedExchange: action.payload.deleteSelectedExchange
      };
    case API_MESSAGE_MODAL:
      return { ...state, apiErrorMessage: action.payload.apiErrorMessage };
    case APP_ERROR_MESSAGE_MODAL:
      return { ...state, appErrorMessage: action.payload.appErrorMessage };
    case APP_API_MESSAGE_MODAL:
      return { ...state, appApiMessage: action.payload.appApiMessage };
    case MOBILE_MENU_OPEN:
      return { ...state, mobileMenuOpen: action.payload.mobileMenuOpen };
    case WALLET_NAME_UPDATE:
      if (action.payload.walletName) {
        let { userInputKnownWallets } = state;
        let storedWallets = JSON.parse(localStorage.getItem('digitax-userInputKnownWallets'));
        if (Array.isArray(storedWallets)) {
          storedWallets.push(action.payload.walletName);
        } else {
          storedWallets = [ action.payload.walletName ];
        }
        if (Array.isArray(userInputKnownWallets)) {
          userInputKnownWallets.push(action.payload.walletName);
        } else {
          userInputKnownWallets = [ action.payload.walletName ];
        }
        localStorage.setItem('digitax-userInputKnownWallets', JSON.stringify(storedWallets));
        return { ...state, walletName: action.payload.walletName, userInputKnownWallets };
      }
      return { ...state };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
