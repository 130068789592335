import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ApiMessageModal from '../../components/Modals/APIMessageModal';
import StillCalculating from '../../components/StillCalculating';
import StillFetching from './components/StillFetching';
import AlreadyCalculated from '../../components/AlreadyCalculated';
import NotHighEnoughPlan from '../../components/NotHighEnoughPlan';
import NotHighEnoughPlanButCalculated from '../../components/NotHighEnoughPlanButCalculated';
import { triggerCalculateGainLoss, clearCalcGainLossMessages } from 'actions';
import { FETCHING, CALCULATING } from 'actions/uiTypes';
import NotReconciledYet from '../../components/NotReconciledYet';
import StillHaveUnmatched from '../../components/StillHaveUnmatched';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const styles = theme => ({
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    textDecoration: 'none',
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  headerMargin: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4),
      width: '70%'
    },
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  marginTop: {
    marginTop: theme.spacing(2)
  }
});


class Calc extends React.Component {
  state = {
    apiMessageOpen: false
  }

  handleCalculateGainLossClick = (event, v) => {
    this.props.triggerCalculateGainLoss();
  }

  handleAPIMessageClose = () => {
    this.setState({ apiMessageOpen: false });
    this.props.clearCalcGainLossMessages();
  }

  render() {
    const { classes } = this.props;
    const {
      tradeCount,
      movementCount,
      unmatchedMovements,
      unmatchedTrades
    } = this.props.exchangeAndTaxData;
    const { calcGainLossMessage, isLoadingExchangeData, isCalculatingGainsLosses } = this.props.ui;
    const { mostRecentPnlCalcRun, mostRecentReconcileRun } = this.props.pnl;
    const { apiFetching } = this.props.api;
    const { isPlanSufficient } = this.props.auth;
    let { apiMessageOpen } = this.state;

    if (!isPlanSufficient && mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome === 'success') {
      return <NotHighEnoughPlanButCalculated />;
    }

    if (!isPlanSufficient) {
      return <NotHighEnoughPlan />;
    }

    if (calcGainLossMessage) {
      apiMessageOpen = true;
    }

    if (isLoadingExchangeData === FETCHING) {
      return <StillFetching />;
    }

    if (!mostRecentReconcileRun) {
      return (
        <NotReconciledYet />
      );
    }

    if (unmatchedTrades.length > 0 || unmatchedMovements.length > 0) {
      return (
        <StillHaveUnmatched />
      );
    }

    // const { ui: { isCalculatingGainsLosses } } = this.props;
    const { api: { calculatingGainLoss } } = this.props;
    // console.log('isCalculatingGainsLosses', isCalculatingGainsLosses);
    // console.log('calculatingGainLoss', calculatingGainLoss);
    if ((isCalculatingGainsLosses === CALCULATING) || (Array.isArray(calculatingGainLoss) &&
      calculatingGainLoss.length > 0)
    ) {
    // if (isCalculatingGainsLosses === CALCULATING) {
      return (
        <React.Fragment>
          <StillCalculating apiFetching={apiFetching} />
          <ApiMessageModal
            open={apiMessageOpen}
            handleClose={this.handleAPIMessageClose}
            apiMessage={calcGainLossMessage}
          />
        </React.Fragment>
      );
    }

    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun && 
      mostRecentPnlCalcRun.outcome === 'success'
    ) {
      return <AlreadyCalculated />;
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={clsx(classes.marginTop, classes.importButtonWrapper)}>
            {
              mostRecentPnlCalcRun !== null && mostRecentPnlCalcRun.outcome === 'error' ? (
                <Tooltip arrow title="Your last calculation attempt errored.  If this keeps happening after confirming you have all of your transactions, please reach out support@digitax.io.">
                  <ErrorOutlineIcon className={classes.marginLeft} />
                </Tooltip>
              ) : null
            }
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.headerMargin)}>
              {
                mostRecentPnlCalcRun !== null && mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis' ? 
                  "Now that you've applied a zero cost basis, are you ready to re-calculate your Gains and Losses?" :
                  "Are you ready to calculate your Gains and Losses?"
              }
            </Typography>
            <Divider />
          </div>
          <div className={classes.margin}>
            <div className={classes.importButtonWrapper}>
              <Typography variant="body1" className={classes.textAlignCenter}>
                {`We have ${tradeCount} trades and ${movementCount} wallet movements.  Please make sure you have not left out any exchanges.`}
              </Typography>
            </div>
            <div className={classes.importButtonWrapper}>
              <Button
                variant="contained"
                className={clsx(classes.importButton, classes.margin)}
                onClick={this.handleCalculateGainLossClick}
                color="secondary"
              >
                <Typography variant="subtitle2">
                  {"Calculate!"}
                </Typography>
              </Button>
            </div>
          </div>
        </Paper>
        <ApiMessageModal
          open={apiMessageOpen}
          handleClose={this.handleAPIMessageClose}
          apiMessage={calcGainLossMessage}
        />
      </div>
    );
  }
}

Calc.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    movementCount: PropTypes.number,
    tradeCount: PropTypes.number,
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
  }),
  ui: PropTypes.shape({
    calcGainLossMessage: PropTypes.object,
    isLoadingExchangeData: PropTypes.string,
    isCalculatingGainsLosses: PropTypes.string
  }),
  api: PropTypes.shape({
    apiFetching: PropTypes.array,
  }),
  auth: PropTypes.shape({
    isPlanSufficient: PropTypes.bool
  }),
  pnl: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.object,
    mostRecentReconcileRun: PropTypes.object,
  }),
  triggerCalculateGainLoss: PropTypes.func.isRequired,
  clearCalcGainLossMessages: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      tradeCount: state.exchangeAndTaxData.tradeCount,
      movementCount: state.exchangeAndTaxData.movementCount,
    },
    ui: {
      calcGainLossMessage: state.ui.calcGainLossMessage,
      isCalculatingGainsLosses: state.ui.isCalculatingGainsLosses,
      isLoadingExchangeData: state.ui.isLoadingExchangeData,
    },
    api: {
      apiFetching: state.api.apiFetching,
      calculatingGainLoss: state.api.calculatingGainLoss
    },
    auth: {
      isPlanSufficient: state.auth.isPlanSufficient
    },
    pnl: {
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun,
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun,
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps,
    {
      triggerCalculateGainLoss,
      clearCalcGainLossMessages
    }
  )
)(Calc);