import { WALLET } from '../../types';
import logger from 'modules/utils/logger';

export default ({ exchange, walletMovements }) => {
  let newData = [];
  let badTrades = [];

  let counter = 0;
  walletMovements.forEach(movement => {
    try {
      if (movement[0] !== 'deposit' && movement[0] !== 'withdrawal') {
        return;
      }

      const error = validate(movement);
      const thisRecord = createTableData(
        error,
        counter,
        movement[1], // datetime
        movement[0], // type
        exchange,
        movement[4], // coin
        movement[2], // amount
        0.0, // exchangeFee
        '', // addressTo
        '', // addressFrom
        movement[5] // exchangeTradeId
      );

      if (JSON.stringify(error) !== "{}") {
        badTrades.push({ error, movemnt: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on coinbase wallet upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData: newData,
        type: WALLET,
        badTrades
      };
    }
  });

  logger.info('uh oh', badTrades, 'badTrades');
  if (badTrades.length === 0) {
    logger.info('coinbase pro wallet movements are valid');
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('some coinbase pro wallet movements are invalid');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else {
    logger.info('all coinbase pro wallet movements are invalid')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  }
}

// id, datetime, type, exchange, coin, amount, exchangefee, addressFrom, addressTo,
function validate(trade) {
  const type = trade[0];
  const datetime = trade[1];
  const amount = trade[2];
  // const balance = trade[3]; // don't think we want this. We should be summing across in our database
  const amountUnit = trade[4];
  // const transferId = trade[5];
  // const tradeId = trade[6];
  // const orderId = trade[7]; // don't think i want this

  const error = {};
  if (!type) {
    error.type = "We need either a withdrawal or deposit";
  }
  let testDate;
  if (Number.isInteger(datetime)) {
    // test for unix date
    testDate = new Date(datetime * 1000);
  } else {
    testDate = new Date(datetime);
  }
  if (Object.prototype.toString.call(testDate) === "[object Date]") {
    // it is a date
    if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
      // date is not valid
      error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    } else {
      // date is valid
    }
  } else {
    // not a date
    error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  if (isNaN(amount)) {
    error.amount = "amount needs to be a number";
  }
  // if (isNaN(balance)) {
  //   error.balance = "balance needs to be number";
  // }
  if (!amountUnit) {
    error.amountUnit = "We need an amount unit";
  }

  return error;
}

function createTableData(
  error,
  counter,
  datetime,
  type,
  exchange,
  coin,
  amount,
  exchangeFee,
  addressTo,
  addressFrom,
  exchangeTradeId
) {
  let newDateTime = 'N/A'
  if (!error.datetime) {
    if (Number.isInteger(datetime)) {
      newDateTime = new Date(datetime * 1000)
    } else {
      newDateTime = new Date(datetime);
    }
  }

  return {
    id: counter,
    dateTime: newDateTime,
    type,
    exchange,
    coin,
    amount: Math.abs(parseFloat(amount)),
    exchangeFee: parseFloat(0.0),
    addressTo,
    addressFrom: '',
    exchangeTradeId: '',
    source: 'csv'
  };
}
