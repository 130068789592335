import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import exchanges from 'modules/statics/exchanges';
import APIKeyModal from './Modals/APIKeys';
import APIKeyModalPP from './Modals/APIKeysWithPP';
import APIKeyModalClientID from './Modals/APIKeysWithClientID';
import {
  clearFetchingExchangeDataError,
  clearFetchingExchangeDataMessages,
  setSelectedExchange,
  deleteExchangeData,
  clearDeletedExchangeDataMessages
} from 'actions';
import exchangeModalMap from './statics/exchangeModals';
import ExchangePaper from './ExchangePaper';
import exchangesMapInverse from 'modules/statics/exchangesRealMapInverse';
import OAuthModal from './Modals/OAuth';
import logger from 'modules/utils/logger';


const styles = theme => ({
  container: {
    paddingBottom: theme.spacing(2)
    // margin: theme.spacing(2)
  },
  outerMargin: {
    margin: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  margin: {
    margin: theme.spacing(4)
  },
  bold: {
    fontWeight: 'bold'
  },
  // buttonWidth: {
  //   minWidth: 150
  // },
  progress: {
    // wish the below did something
    width: 30,
    marginLeft: theme.spacing(0.5)
  },
  highlight: {
    color: theme.secondary
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  marginLefter: {
    marginLeft: theme.spacing(1.5)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  extraMobileMargin: {
    marginLeft: theme.spacing(2)
  },
  marginTopExchangeList: {
    marginTop: theme.spacing(8)
  },
  rootFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  containerWidth: {
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    },
  },
  exchangeWidth: {
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
  },
  buttonWidth: {
    width: '100%',
    // paddingTop: '3em'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  appbarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  appbar: {
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
    marginTop: theme.spacing(2)
  },
  marginTop: {
    marginTop: theme.spacing(5)
  },
  marginTopOne: {
    marginTop: theme.spacing(1)
  },
  marginBottom: {
    marginBottom: theme.spacing(5)
  },
  paddingBottom: {
    paddingBottom: theme.spacing(5)
  },
  marginRightButton: {
    marginRight: theme.spacing(7)
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  subHeader: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center'
    },
  },
  marginTopDivider: {
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4)
  }
  // marginExchange: {
  //   [theme.breakpoints.up('md')]: {
  //     margin: theme.spacing(2)
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     margin: theme.spacing(0.5)
  //   }
  // }
});

const tooltipsOpen = {};
exchanges.forEach(exchange => {
  tooltipsOpen[exchange.name] = {
    fetching: false,
    warning: false,
    delete: false,
    error: false
  }
});

const INITIAL_STATE = {
  selectedExchange: null,
  modalOpen: false,
  errorModalOpen: false,
  apiMessageOpen: false,
  selectedDeleteExchange: null,
  deleteExchangeDataModalOpen: false,
  tooltipOpen: tooltipsOpen,
  currentSearch: '',
  exchangeMapInverseFilteredObj: _.cloneDeep(exchangesMapInverse)
}

class APIUploadTab extends React.Component {
  state = INITIAL_STATE;

  instructionsClick = (event) => {
    event.preventDefault();

    logger.info('called')
    // create pop-up
  }

  handleAPIKeyModalClose = () => {
    this.setState({ modalOpen: false, selectedExchange: null });
  }

  handleErrorModalClose = () => {
    this.setState({ errorModalOpen: false });
    this.props.clearFetchingExchangeDataError();
  }

  handleAPIMessageClose = () => {
    this.setState({ apiMessageOpen: false });
    this.props.clearFetchingExchangeDataMessages();
    this.props.clearDeletedExchangeDataMessages();
  }

  handleToolTipClose = (exchangeName, type) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[exchangeName][type] = false;
    this.setState({ tooltipOpen });
  }

  handleToolTipOpenClose = (exchangeName, type) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[exchangeName][type] = !tooltipOpen[exchangeName][type];
    this.setState({ tooltipOpen });
  }

  handleClick = name => event => {
    // const { userImports } = this.props;
    event.preventDefault();

    const { selectedExchange } = this.state;
    if (name === selectedExchange) {
      this.setState({ selectedExchange: null });
    } else {
      this.setState({ selectedExchange: name });
    }
    this.props.setSelectedExchange(name);
  }

  handleDeleteClick = name => event => {
    this.setState({ selectedDeleteExchange: name });
  }

  handleDeleteExchangeModalClose = () => {
    this.setState({ selectedDeleteExchange: null });
  }

  updateSearch = (event) => {
    this.setState({ currentSearch: event.target.value });

    const exchangeMapInverseFilteredObj = {};
    Object.keys(exchangesMapInverse).filter(exchange => 
      exchange.startsWith(event.target.value.toLowerCase()) || event.target.value === ''
    ).forEach(exchange => {
      exchangeMapInverseFilteredObj[exchange] = exchangesMapInverse[exchange]
    });

    this.setState({ exchangeMapInverseFilteredObj });
  }

  render() {
    const {
      classes,
      ui: {
        fetchExchangeDataError,
        fetchExchangeMessage,
        // importSelectedExchange,
        // deleteSelectedExchange,
        deletedExchangeDataMessage
      }
    } = this.props;
    let { exchangeAndTaxData: { userImports } } = this.props;
    let {
      selectedExchange,
      modalOpen,
      // errorModalOpen,
      // apiMessageOpen,
      // selectedDeleteExchange,
      // deleteExchangeDataModalOpen,
      exchangeMapInverseFilteredObj
    } = this.state;
    const { api: { apiFetching } } = this.props;

    if (fetchExchangeDataError !== null) {
      // errorModalOpen = true;
      modalOpen = false;
      // apiMessageOpen = false;
    }

    // let apiMessage;
    if (fetchExchangeMessage !== null) {
      // errorModalOpen = false;
      modalOpen = false;
      // apiMessageOpen = true;
      // apiMessage = fetchExchangeMessage;
    }

    if (deletedExchangeDataMessage !== null) {
      // errorModalOpen = false;
      modalOpen = false;
      // apiMessageOpen = true;
      // apiMessage = deletedExchangeDataMessage;
    }

    // if (selectedDeleteExchange !== null) {
    //   deleteExchangeDataModalOpen = true;
    // }

    let uploadedExchanges = [];
    let uploadingExchanges = [];
    let erroredExchanges = [];
    userImports.forEach(rec => {
      rec.createdAt = new Date(rec.createdAt);
      rec.updatedAt = new Date(rec.updatedAt);
    });
    if (userImports.length > 0) {
      // pluck the most recent uploads
      const mostRecentUploads = {};
      userImports.forEach(({ exchange, ...rest }) => {
        if (!(exchange in mostRecentUploads)) {
          mostRecentUploads[exchange] = { exchange, ...rest };
        } else {
          if (rest.createdAt.getTime() > mostRecentUploads[exchange].createdAt.getTime()) {
            mostRecentUploads[exchange] = { exchange, ...rest };
          }
        }
      });

      const mostRecentUserImports = [];
      Object.keys(mostRecentUploads).forEach(exchange => {
        mostRecentUserImports.push(mostRecentUploads[exchange]);
      });

      uploadedExchanges = [...new Set(mostRecentUserImports.map(
        ({ exchange, outcome }) => {
          if (exchange && !['fetching', 'timeout', 'error', 'deleted'].includes(outcome)) {
            return exchange;
          }
          return null;
        }
      ).filter(v => v !== null))];

      if (apiFetching) {
        uploadingExchanges = apiFetching.map(({ exchange }) => {
          if (exchange) {
            return exchange;
          }
          return null;
        }).filter(v => v !== null);
      }

      erroredExchanges = [...new Set(mostRecentUserImports.map(
        ({ exchange, outcome }) => {
          if (exchange && ['timeout', 'error'].includes(outcome)) {
            return exchange;
          }
          return null;
        }
      ).filter(v => v !== null))];
    }

    let selectedExchangeObj = exchanges.filter(exch => exch.name === selectedExchange);
    if (selectedExchangeObj.length > 0) {
      selectedExchangeObj = selectedExchangeObj[0];
    }

    let ModalToShow = () => null;
    if (selectedExchange !== null) {
      logger.info('selectedExchange', selectedExchange);
      modalOpen = true;
      if (exchangeModalMap[selectedExchange].oauth) {
        ModalToShow = () => (
          <OAuthModal
            open={modalOpen}
            handleClose={this.handleAPIKeyModalClose}
            exchange={selectedExchangeObj}
          />
        );
      } else if (exchangeModalMap[selectedExchange].pp) {
        ModalToShow = () => (
          <APIKeyModalPP
            open={modalOpen}
            handleClose={this.handleAPIKeyModalClose}
            exchange={selectedExchangeObj}
          />
        );
      } else if (exchangeModalMap[selectedExchange].clientID) {
        ModalToShow = () => (
          <APIKeyModalClientID
            open={modalOpen}
            handleClose={this.handleAPIKeyModalClose}
            exchange={selectedExchangeObj}
          />
        );
      } else {
        ModalToShow = () => (
          <APIKeyModal
            open={modalOpen}
            handleClose={this.handleAPIKeyModalClose}
            exchange={selectedExchangeObj}
          />
        );
      }
    }

    // this is choosing the right hand side of the page component
    // gives some info about the exchange
    // const ExchangeComponent = ApiImports(selectedExchange);
    return (
      <div className={classes.container}>
        <div>
          {/* <div className={classes.appbarContainer}>
            <AppBar position="static" className={classes.appbar}>
              <Toolbar>
                <div className={clsx(classes.grow, classes.search)}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search for an exchange..."
                    classes={{
                      root: classes.inputRoot, // div container for input classes
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={this.state.currentSearch}
                    onChange={this.updateSearch}
                  />
                </div>
              </Toolbar>
            </AppBar>
          </div> */}
          <div className={classes.rootFlex}>
            <div className={clsx(classes.marginTopExchangeList, classes.rootFlex, classes.containerWidth)}>
              {
                Object.keys(exchangeMapInverseFilteredObj).map(exchange => (
                  <div className={classes.exchangeWidth} key={exchange}>
                    <Button className={classes.buttonWidth} onClick={this.handleClick(exchange)}>
                      <ExchangePaper
                        isUploaded={uploadedExchanges.includes(exchange)}
                        isFetching={uploadingExchanges.includes(exchange)}
                        didErrorOnFetch={erroredExchanges.includes(exchange)}
                        exchange={exchangeMapInverseFilteredObj[exchange]}
                      />
                    </Button>
                  </div>
                ))
              }
            </div>
          </div>
          <Divider className={classes.marginTopDivider} />
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <Typography variant="h4" className={clsx(classes.marginTopOne, classes.header, classes.textAlignCenter)}>
              {"Don't see your exchange?"}
            </Typography>
          </div>
          <div className={clsx(classes.paddingBottom, classes.rootFlex, classes.marginTopOne)}>
            <Typography variant="subtitle2" className={clsx(classes.marginTop, classes.subHeader)}>
              {"If you know all of your transaction details, Digitax allows you to insert your exchange transactions manually.  Otherwise, send an email to "}
              <Box component="span" display="inline" fontWeight="fontWeightBold" className={classes.linkColor}>
                {"support@digitax.io"}
              </Box>
              {" with the exchange you would like added to our API integration.  We will work around the clock to support our customers."}
            </Typography>
          </div>
        </div>
        <ModalToShow />
      </div>
    );
  }
}

APIUploadTab.propTypes = {
  classes: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    fetchExchangeDataError: PropTypes.object,
    fetchExchangeMessage: PropTypes.object,
    importSelectedExchange: PropTypes.string,
    deleteSelectedExchange: PropTypes.string,
    deletedExchangeDataMessage: PropTypes.object
  }),
  exchangeAndTaxData: PropTypes.shape({
    userImports: PropTypes.array,
  }),
  clearDeletedExchangeDataMessages: PropTypes.func.isRequired,
  clearFetchingExchangeDataMessages: PropTypes.func.isRequired,
  clearFetchingExchangeDataError: PropTypes.func.isRequired,
  setSelectedExchange: PropTypes.func.isRequired,
  deleteExchangeData: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      userImports: state.exchangeAndTaxData.userImports,
    },
    ui: {
      fetchExchangeDataError: state.ui.fetchExchangeDataError,
      fetchExchangeMessage: state.ui.fetchExchangeMessage,
      importSelectedExchange: state.ui.importSelectedExchange,
      deletedExchangeDataMessage: state.ui.deletedExchangeDataMessage,
      deleteSelectedExchange: state.ui.deleteSelectedExchange
    },
    api: {
      apiFetching: state.api.apiFetching
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps,
    {
      clearFetchingExchangeDataMessages,
      clearFetchingExchangeDataError,
      setSelectedExchange,
      deleteExchangeData,
      clearDeletedExchangeDataMessages
    }
  )
)(APIUploadTab);
