import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import HeaderBar from './HeaderBar';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  layoutBody: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

const HeaderBarWrapped = withRouter(HeaderBar);

class Header extends React.Component {
  render() {
    const { classes } = this.props; // kind of just for an example, we can place div containers around components like this
    return (
      <div className={clsx(classes.root, classes.layoutBody)}>
        <HeaderBarWrapped />
      </div>
    );
  }
};

// type forcing the classes prop on this.props
Header.propTypes = {
  classes: PropTypes.object.isRequired
};

// wraps Header with styles and redux
export default compose(
  withStyles(styles)
)(Header);

// this is the same as the below

// const HeaderWithStyles = withStyles(styles)(Header);
// export default connect(mapStateToProps)(HeaderWithStyles);
