import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { storeHeaderTabChange } from 'actions';


const styles = theme => ({
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    width: '50%',
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  }
});

class NoTransactions extends React.Component {
  handleHeaderTabChangeImport = (event, v) => {
    this.props.storeHeaderTabChange(1);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.importButtonWrapper}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"Hey! We can't calculate anything until you import some transactions!"}
            </Typography>
            <Divider />
          </div>
          <div className={clsx(classes.margin, classes.importButtonWrapper)}>
            <Link component={RouterLink} to="/dashboard/import" color="inherit">
              <Button
                variant="contained"
                component="span"
                className={clsx(classes.importButton, classes.margin)}
                onClick={this.handleHeaderTabChangeImport}
                color="secondary"
              >
                <Typography variant="subtitle2">
                  {"Import Trade Data"}
                </Typography>
              </Button>
            </Link>
          </div>
        </Paper>
      </div>
    );
  }
}


export default compose(
  withStyles(styles),
  connect(null, { storeHeaderTabChange })
)(NoTransactions);