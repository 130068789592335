import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import exchangeImageLookup from '../utils/exchangeImageLookup';
import exchangesMap from 'modules/statics/exchangesMap';


const styles = theme => ({
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // verticalAlign: 'middle'
    // height: '100%'
  },
  height: {
    height: '7em'
  },
  imgSize: {
    [theme.breakpoints.up('md')]: {
      width: '8em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    height: 'auto'
  }
});

class ExchangePaper extends React.Component {
  render() {
    const { classes, exchange } = this.props;

    return (
      <Paper className={clsx(classes.height, classes.alignItemsCenter)}>
        {/* <Typography variant="subtitle1">
          {exchange}
        </Typography> */}
        <img src={exchangeImageLookup(exchangesMap[exchange])} className={classes.imgSize} alt={exchange} />
      </Paper>
    );
  }
}

export default withStyles(styles)(ExchangePaper);