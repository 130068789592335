import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { cancelPaymentSession } from 'actions';
import queryString from 'query-string';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(5)
  },
  text: {
    marginBottom: theme.spacing(2)
  }
});

class ProgressSpinner extends React.Component {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.session_id) {
      this.props.cancelPaymentSession({ checkoutSessionId: params.session_id });
    } else {
      // should never happen... maybe throw some timeout?
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.margin}>
          <Typography variant="body1" className={classes.text}>
            {
              "Just a few seconds, we're loading your information."
            }
          </Typography>
          <CircularProgress color="primary" />
        </div>
      </div>
    );
  }
}


ProgressSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  connect(null, { cancelPaymentSession })
)(ProgressSpinner);
