import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
// import Avatar from '@material-ui/core/Avatar';
// import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Dot from 'modules/components/Dot';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import binance_img from 'modules/statics/exchangeIcons/binance_logo.png';

const styles = theme => ({
  root: {
    // width: '84vw',
    // height: '100vh'
  },
  cardMargin: {
    marginTop: theme.spacing(1)
  },
  container: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(12)
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1)
    }
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  paddingTop: {
    paddingTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  paddingBottom: {
    // marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  authorContainer: {
    marginTop: theme.spacing(0.5)
  },
  marginRightIcon: {
    marginRight: theme.spacing(2)
  },
  infoIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  marginRightWrittenBy: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  // imgSize: {
  //   width: '100%',
  //   height: 'auto'
  // },
  italics: {
    // textDecoration: 'emphasis'
  },
  alignTogether: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  headerDivider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  marginTwoLine: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2.6)
  },
  marginOneLine: {
    marginRight: theme.spacing(1),
    // marginBottom: theme.spacing(1.4)
  },
  info: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      height: '6em',
      backgroundColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      height: '10em',
      backgroundColor: theme.palette.primary.main
    },
  },
  marginInfoIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  list: {
    listStylePosition: 'outside'
  },
  dot: {
    paddingTop: '0.1em',
    width: '0.5em',
    height: 'auto'
  },
  arrow: {
    marginBottom: '0.8em',
    // width: '1em',
    // height: 'auto'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  imgSize: {
    [theme.breakpoints.up('md')]: {
      width: '10%',
      objectFit: 'contain',
      height: '8em',
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      width: '25%',
      objectFit: 'contain',
      height: '8em',
      marginRight: theme.spacing(2)
    },
  },
  mobileFontSize: {
    fontSize: '30px'
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
});
// { "Integrate Digitax to your transactions with Binance's API or upload the downloadable CSVs from Binance's portal to Digitax." }


class HelpContent extends React.Component {
  handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  render() {
    const { classes, exchange } = this.props;
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/exchanges">
            {"Exchange Integrations"}
          </Link>
          <Typography color="textPrimary">{exchange + ' Detail'}</Typography>
        </Breadcrumbs>
        <Divider className={classes.cardMargin} />
        <div className={classes.container}>
          <div className={clsx(classes.marginTop, classes.alignTogether)}>
            <img src={binance_img} className={classes.imgSize} alt={exchange} />
            <div className={classes.authorContainer}>
              <Typography variant="h3" className={clsx(classes.mobileFontSize, classes.paddingBottom)}>
                <Box component="span" fontWeight="fontWeightMedium">
                  {"Import Data From Binance"}
                </Box>
              </Typography>
              <Typography variant="caption">
                <Box component="span" fontWeight="fontWeightLight">
                  {"US Traders can easily calculate their taxes from trading on Binance and create the right tax documents to submit to the IRS. Digitax fully supports Binance and makes reporting your transactions very easy."}
                </Box>
              </Typography>
            </div>
          </div>
          <Divider className={classes.headerDivider} />
          <main className={clsx(classes.marginTop, classes.marginBottom)}>
            <Typography className={classes.marginTop} variant="h4">
              {"Let's Get Started"}
            </Typography>
            <Typography className={clsx(classes.italics, classes.marginTop, classes.marginBottom)} variant="body1">
              {"We need to get your transactions from Binance.  We'll be using the API to get your data."}
            </Typography>
            {/* <List>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"a)"}
                </Typography>
                <Typography>
                  {"Connect Digitax to Binance's API with read-only access to your account, you'll need to create an API Key, and pass Digitax this information."}
                </Typography>
              </ListItem>
               <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"b)"}
                </Typography>
                <Typography>
                  {"Download CSVs from Binance's website, and upload them to Digitax."}
                </Typography>
              </ListItem>
            </List> */}
            <Card variant="outlined" className={clsx(classes.info, classes.alignTogether)}>
              <InfoOutlinedIcon className={classes.marginInfoIcon} />
              <Typography variant="caption">
                <Box component="span" fontWeight="fontWeightLight">
                  {"While other providers allow you to import CSVs, we enforce using the API as this will be less error prone. This is because Binance gives Digitax better details through the API about your withdrawals, deposits, and trades. Futhermore, for your security, Digitax will never save your API keys."}
                </Box>
              </Typography>
            </Card>
            <Typography variant="body2" className={classes.marginTop}>
              <Box component="span" fontWeight="fontWeightLight">
                {"Pretty simple right?  Follow the instructions for API below."}
              </Box>
            </Typography>
            <Divider className={classes.headerDivider} />
            <Typography className={classes.marginTop} variant="h4">
              {"API Instructions"}
            </Typography>
            <List className={classes.list}>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"1)"}
                </Typography>
                <Typography>
                  {"Login to your "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Binance"}
                  </Box>
                  {" account.  You will likely need to do two factor authentication."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"2) "}
                </Typography>
                <Typography>
                  {"On the top righthand corner, click on the Portrait and in the drop-down menu, select "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"API Management"}
                  </Box>
                  {"."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"3) "}
                </Typography>
                <Typography>
                  {"Enter a name for the new key, and click the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Create API"}
                  </Box>
                  {" button."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"4) "}
                </Typography>
                <Typography>
                  {"Check the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Enable Reading"}
                  </Box>
                  {" permission.  Uncheck all of the other permissions for your safety."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"5) "}
                </Typography>
                <Typography>
                  {"Enter the "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"API Key"}
                  </Box>
                  {" and "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Secret Key"}
                  </Box>
                  {" that you just generated in Digitax's import form."}
                </Typography>
              </ListItem>
            </List>
            {/* <Divider className={classes.headerDivider} />
            <Typography className={classes.marginTop} variant="h4">
              {"CSV Instructions"}
            </Typography>
            <List className={classes.list}>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"1)"}
                </Typography>
                <Typography>
                  {"Login to your Binance account.  You will likely need to do two factor authentication."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"2) "}
                </Typography>
                <Typography>
                  {"On the top Toolbar, hover-over Wallet, and in the drop down menu, select Overview."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"3) "}
                </Typography>
                <Typography>
                  {"Click on Transaction History on the top righthand corner of the page."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"4) "}
                </Typography>
                <Typography>
                  {"Again on the top righthand corner of the page, click Generate all statements."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"5) "}
                </Typography>
                <Typography>
                  {"Now in the opened form, one by one, you'll be downloading the transactions for each tax year you traded in."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <List>
                  <ListItem alignItems="flex-start">
                    <Card variant="outlined" className={clsx(classes.info, classes.alignTogether)}>
                      <InfoOutlinedIcon className={classes.marginInfoIcon} />
                      <Typography variant="caption">
                        <Box  component="span" fontWeight="fontWeightLight">
                          {"Recent Update: Binance only let's you generate 5 reports per month.  So be careful here as if you've traded during five years, Digitax needs all five reports."}
                        </Box>
                      </Typography>
                    </Card>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"6) "}
                </Typography>
                <Typography>
                  {"In the 'Range' field, click Customize and then adjust the following:"}
                </Typography>
              </ListItem>
              <ListItem>
                <List dense disablePadding>
                  <ListItem alignItems="flex-start">
                    <FiberManualRecordIcon className={clsx(classes.dot, classes.marginRight)} />
                    <Typography>
                      {"Date field to the beginning and end of each year you traded in."}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <List dense disablePadding>
                      <ListItem>
                        <KeyboardArrowRightIcon className={clsx(classes.arrow, classes.marginRight)} />
                        <Typography>
                          {"For example, I traded during 2020, so I select dates from '2020-01-01' and '2020-12-31'"}
                        </Typography>
                      </ListItem>
                    </List>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <FiberManualRecordIcon className={clsx(classes.dot, classes.marginRight)} />
                    <Typography>
                      {"Confirm that the Account and Coin fields are set to All."}
                    </Typography>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <FiberManualRecordIcon className={clsx(classes.dot, classes.marginRight)} />
                    <Typography>
                      {"Confirm that the Hide transfer record is unchecked."}
                    </Typography>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Typography className={classes.marginRight}>
                  {"7) "}
                </Typography>
                <Typography>
                  {"Click Download. Repeat step 6) for each tax year you have ever traded in. Once you have all of the documents, upload these files to Digitax in the CSV tab."}
                </Typography>
              </ListItem>
            </List> */}
          </main>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HelpContent);