export const TYPE = 'type'; // TNT QUESTION: why not have wallet_type?  seems like we would want that as well but idk
export const BUY_CURRENCY = 'buyCCY';
export const BUY_AMOUNT = 'buyAmt';
export const SELL_CURRENCY = 'sellCCY';
export const SELL_AMOUNT = 'sellAmt';
export const FEE_CURRENCY = 'feeCCY';
export const FEE_AMOUNT = 'feeAmt';
export const DATE = 'date';
export const EXCHANGE = 'exchange';
export const MARGIN = 'margin';
export const DELETE = 'delete';
