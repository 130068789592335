import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  height: {
    height: '8em'
  },
  removeTransform: {
    textTransform: 'none'
  },
  marginTop: { 
    marginTop: theme.spacing(5)
  },
  marginBelowDivider: {
    marginTop: theme.spacing(15),
  },
  marginContainer: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15)
  },
  subTitle2Margin: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
});

// this should be sitting within a Paper for the white background
class GetStarted extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={clsx(classes.marginContainer, classes.textAlignCenter)}>
        <Divider />
        <div className={clsx(classes.marginTop, classes.alignCenter, classes.marginBelowDivider)}>
          <Typography variant="h4">
            {'Calculate your cryptocurrency taxes today'}
          </Typography>
        </div>
        <div className={classes.subTitle2Margin}>
          <Typography variant="subtitle1">
            {'Created by a CPA to save CPA fees.'}
          </Typography>
        </div>
        <Link component={RouterLink} to="/signin" color="inherit">
          <Button
            variant="contained"
            className={clsx(classes.marginTop, classes.removeTransform)}
            color="primary"
            size="large"
          >
            {'Get Started'}
          </Button>
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(GetStarted);