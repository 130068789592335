import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import GetStarted from 'modules/components/GetStarted';
import Watermark from 'modules/components/Watermark';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  btnFree: {
    textTransform: 'none'
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(7)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10)
    }
  },
  marginDivider: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  invisible: {
    visibility: 'hidden'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '31%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  backgroundGradient: {
    background: 'linear-gradient(45deg, #2CBD72 30%, #FFFFFF 90%)'
  },
  textDecoration: {
    textDecoration: 'underline'
  },
  // subHeader: {
  //   [theme.breakpoints.up('md')]: {
  //     width: '60%',
  //     textAlign: 'center'
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     width: '80%',
  //     textAlign: 'center'
  //   },
  // },
  marginSubHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  subHeader: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'center'
  },
});

class AboutUs extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Card variant="outlined">
        <div className={clsx(classes.alignCenter, classes.margin, classes.textAlignCenter)}>
          <Typography variant="h1">
            {"Full service cryptocurrency tax firm"}
          </Typography>
        </div>
        <div className={clsx(classes.alignCenter, classes.marginSubHeader)}>
          <div className={classes.subHeader}>
            <Typography variant="subtitle2">
              {"Digitax offers a cryptocurrency tax software platform for all kinds of traders.  Founded by a CPA that was confronted with the challenge of doing his own cryptocurrency taxes, he decided to found Digitax to help himself and others."}
            </Typography>
          </div>
        </div>      
        <GetStarted />
        <Watermark />
      </Card>
    );
  }
}

export default withStyles(styles)(AboutUs);