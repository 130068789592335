// this what our columns should look like

// const example = {
//   product: 'BTC-USD',
//   side: 'BUY',
//   tradeDateTime: '2017-12-08T16:10:23.433Z',
//   size: 0.69,
//   sizeUnit: 'BTC',
//   price: 16900.89,
//   exchangeFee: 0.89,
//   total: 0.69 * 16900.89 - 0.89, // size * price - fee,
//   totalUnit: 'USD'
// };

// tradeDateTime is in UTC.  as it is in our database
// order matters
export default [
  { id: 'product', numeric: false, disablePadding: true, label: 'Product' },
  { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
  { id: 'side', numeric: false, disablePadding: false, label: 'Side' },
  { id: 'tradeDateTime', numeric: true, disablePadding: false, label: 'Trade Time' },
  { id: 'size', numeric: true, disablePadding: false, label: 'Size' },
  { id: 'sizeUnit', numeric: false, disablePadding: false, label: 'Size Unit' },
  { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'exchangeFee', numeric: true, disablePadding: false, label: 'Exchange Fee' },
  { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
  { id: 'totalUnit', numeric: false, disablePadding: false, label: 'Total Unit' },
  { id: 'feeUnit', numeric: false, disablePadding: false, label: 'Fee Unit' },
  { id: 'priceUnit', numeric: false, disablePadding: false, label: 'Price Unit' },
];
