import React from 'react';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import requireAuth from 'modules/components/requireAuth';
import PasswordDrawer from './components/PasswordDrawer';
import PlanAndBillingTab from './components/PlanAndBillingTab';
import AccountTab from './components/AccountTab';
import MyData from './components/MyData';
import { gotoAccountTab, gotoPlanAndBillingTab, gotoMyDataTab, resendVerificationEmail } from 'actions';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}


TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  containerDesktopMargin: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(10),
      marginLeft: theme.spacing(10),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4)
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  header: {
    marginBottom: theme.spacing(6)
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none'
  },
  tabText: {
    textTransform: 'none'
  },
  padding: {
    padding: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
});


class AccountManagement extends React.Component {
  state = {
    tabSelected: 0,
    isDrawerOpen: false,
    confirmEmailSnackbarOpen: false
  };

  componentDidMount() {
    const { auth: { isEmailConfirmed } } = this.props;
    if (!isEmailConfirmed) {
      this.setState({ confirmEmailSnackbarOpen: true });
    }
  }

  // componentDidUpdate() {
  // places the values on the fields, only if the values are all blank
  // const { email, password, firstName, lastName } = this.props.auth;
  // if (
  //   email === '' &&
  //   password === '' &&
  //   firstName === '' &&
  //   lastName === '' &&
  //   this.props.auth.email
  // ) {
  //   logger.info('preety sure this is never called is an unnecessary');
  //   this.setState({ ...this.props.auth });
  // };
  // }

  toggleDrawer = (openClose) => () => {
    this.setState({ isDrawerOpen: openClose });
  };

  handleTabChange = (event, tabSelected) => {
    // this.setState({ tabSelected });
    if (tabSelected === 0) {
      this.props.gotoAccountTab();
    }
    if (tabSelected === 1) {
      this.props.gotoPlanAndBillingTab();
    }
    if (tabSelected === 2) {
      this.props.gotoMyDataTab();
    }
  };

  handleSnackBarClose = () => {
    this.setState({ confirmEmailSnackbarOpen: false });
  }

  resendVerifyEmailButton = () => {
    const { email } = this.props.auth;

    return (
      <Button
        onClick={() => this.props.resendVerificationEmail({ email })}
        color="secondary"
        size="small"
        style={{ textTransform: 'none' }}
      >
        {"Resend Verification Email?"}
      </Button>
    );
  }

  render() {
    const { classes, accountTabSelected } = this.props;
    const { isDrawerOpen, confirmEmailSnackbarOpen } = this.state;

    return (
      <React.Fragment>
        <Paper className={clsx(classes.containerDesktopMargin, classes.padding)}>
          <Typography variant="h4" className={classes.header}>
            {"Account Details"}
          </Typography>
          <div className={classes.root}>
            <AppBar position="static" color="inherit" className={classes.appBar}>
              <Tabs value={accountTabSelected} onChange={this.handleTabChange}>
                <Tab label="Account" className={classes.tabText} />
                <Tab label="Plan & Billing" className={classes.tabText} />
                <Tab label="My Data" className={classes.tabText} />
              </Tabs>
              <Divider />
            </AppBar>
            {
              accountTabSelected === 0 ? (
                <AccountTab toggleDrawer={this.toggleDrawer} />
              ) : null
            }
            {
              accountTabSelected === 1 ? (
                <PlanAndBillingTab />
              ) : null
            }
            {
              accountTabSelected === 2 ? (
                <MyData />
              ) : null
            }
            {
              isDrawerOpen ? (
                <PasswordDrawer open toggleDrawer={this.toggleDrawer} />
              ) : null
            }
          </div>
          <Divider />
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={confirmEmailSnackbarOpen}
          onClose={this.handleSnackBarClose}
          key={'confirmEmail'}
        >
          <SnackbarContent message="You have not confirmed your email yet." action={this.resendVerifyEmailButton()} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

AccountManagement.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  gotoAccountTab: PropTypes.func.isRequired,
  gotoMyDataTab: PropTypes.func.isRequired,
  gotoPlanAndBillingTab: PropTypes.func.isRequired,
  resendVerificationEmail: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default compose(
  connect(mapStateToProps, { gotoAccountTab, gotoMyDataTab, gotoPlanAndBillingTab, resendVerificationEmail }),
  withStyles(styles)
)(requireAuth(AccountManagement));
