import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
// import { STORE_CSRF_TOKEN } from 'actions/types';
import logger from 'modules/utils/logger';
import { displayErrorModal } from 'actions/helpers';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SecurityIcon from '@material-ui/icons/Security';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


axios.defaults.withCredentials = true;

const styles = (theme) => ({
  button: {
    textTransform: 'none',
    marginTop: theme.spacing(1),
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginRight: {
    marginRight: theme.spacing(1)
  }
});

class internalCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      processing: false
    };
  }

  handleClick = async (event) => {
    // get Stripe.js instance

    const stripe = await stripePromise;

    const {
      planName,
      isFbarAdded,
      areExtraTransactionsAdded,
      quantityOfExtraTransactions,
      taxYear,
      firstName,
      lastName,
      csrfToken
    } = this.props;
    const headers = {
      'csrf-token': csrfToken
    }

    let _planNameToPost = planName;
    if (!_planNameToPost) {
      _planNameToPost = this.props.currentPlanName;
    }

    const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/create-checkout-session`,
      {
        cardType: "card",
        currency: "usd",
        // idempotentKey: uuid,
        planName: _planNameToPost,
        taxYear: parseInt(taxYear),
        areExtraTransactionsAdded,
        quantityOfExtraTransactions,
        isFbarAdded,
        firstName,
        lastName
      }, {
      headers
    }).catch(err => {
      // need to do a better job of displaying the error from the backend
      if (typeof err === 'object' && typeof err.message === 'string' && typeof err.name === 'string') {
        displayErrorModal({ header: err.name, message: err.message }, this.props.dispatch);
      }
      logger.info(err);
      logger.info('error in calling axios.post create-checkout-session');
      throw err;
    });

    if (res && res.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', res.headers['csrf-token']);
    }
    // if (res && res.headers['csrf-token']) {
    //   this.props.dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: res.headers['csrf-token'] } });
    // }
    const session = await res.data;

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    /*
     axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/create-payment-intent/`,
      {
        amount: parseFloat(total),
        cardType: "card",
        currency: "usd",
        idempotentKey: uuid,
        planName,
        taxYear: parseInt(taxYear),
        areExtraTransactionsAdded,
        isFbarAdded,
        discountCode
      },
      {
        headers
      }
    ).then(async response => {
      if (response.headers['csrf-token']) {
        this.props.dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: response.headers['csrf-token'] } });
      }
      }*/
  }

  render() {
    const { classes, planName, isFbarAdded, areExtraTransactionsAdded } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={this.handleClick}
          className={classes.button}
          disabled={planName === null && !isFbarAdded && !areExtraTransactionsAdded}
        >
          <SecurityIcon className={classes.marginRight} />
          <Typography variant="body1">
            <Box component="span" fontWeight="fontWeightMedium">
              {"Purchase Now"}
            </Box>
          </Typography>
        </Button>
      </React.Fragment>
    )
  }

}
export default withStyles(styles)(internalCheckout);
