import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Box from '@material-ui/core/Box';
import { storeCalculateTabChange, triggerReconcileMovements } from 'actions';


const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
    // padding: theme.spacing(4)
  },
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4),
      width: '50%'
    },
    padding: theme.spacing(4)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  iconSize: {
    width: '50px',
    height: 'auto'
  },
  info: {
    height: '4em',
    backgroundColor: theme.palette.primary.main
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginLeft: theme.spacing(1)
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  }
});


class NotCalculatedYet extends React.Component {

  handleUndoClick = () => {
    this.props.triggerReconcileMovements({ reset: true });
  }

  handleClick = () => {
    this.props.storeCalculateTabChange(2);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.importButtonWrapper}>
            <Typography variant="subtitle1" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"It looks like something errored on the last calculation run.  Please double check you have imported all of your transactions."}
            </Typography>
            <Divider />
          </div>
          <Card variant="outlined" className={clsx(classes.marginTop, classes.info, classes.alignTogether)}>
            <InfoOutlinedIcon className={classes.marginInfoIcon} />
            <Typography variant="caption" className={classes.marginRight}>
              <Box component="span" fontWeight="fontWeightLight">
                {"It's also possible you've made a mistake when reconciling your wallet movements. Undo classifications and please double everything."}
              </Box>
            </Typography>
            <Button
              onClick={this.handleUndoClick}
              className={clsx(classes.marginRight, classes.removeTextTransform)}
            >
              {"Undo"}
            </Button>
          </Card>
          {/* <div className={classes.margin}>
            <div className={classes.importButtonWrapper}>
              <Typography variant="body1">
                {`Are you ready to re-calculate?`}
              </Typography>
            </div>
            <div className={classes.importButtonWrapper}>
              <Link component={RouterLink} to="/calculate/calc">
                <Button
                  variant="contained"
                  className={clsx(classes.importButton, classes.margin)}
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <Typography variant="subtitle2">
                    {"Go-To Calculate!"}
                  </Typography>
                </Button>
              </Link>
            </div>
          </div> */}
        </Paper>
      </div>
    );
  }
}


export default compose(
  connect(null, { storeCalculateTabChange, triggerReconcileMovements }),
  withStyles(styles)
)(NotCalculatedYet);