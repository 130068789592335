import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import missing_transaction_history_image from 'modules/statics/help/Missing-Transaction-History.png';
import partial_basis_detail_image from 'modules/statics/help/Partial-Basis-Detail.png';
import api_upload_table_image from 'modules/statics/help/API-Upload-Table.png';

const styles = theme => ({
  root: {
    // width: '84vw',
    // height: '100vh'
  },
  cardMargin: {
    marginTop: theme.spacing(1)
  },
  container: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(12)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  paddingTop: {
    paddingTop: theme.spacing(3)
  },
  authorContainer: {
    marginTop: theme.spacing(0.5)
  },
  marginRightAvatar: {
    marginRight: theme.spacing(2)
  },
  marginRightWrittenBy: {
    margin: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  imgSize: {
    width: '100%',
    height: 'auto'
  },
  italics: {
    // textDecoration: 'emphasis'
  }
});

class HelpContent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Breadcrumbs aria-label="navigation">
          <Link component={RouterLink} color="inherit" to="/">
            {"Digitax Help"}
          </Link>
          {/* <Link color="inherit" href="/getting-started/installation/" onClick={this.handleClick}>
            {"Common Questions"}
          </Link> */}
          <Typography color="textPrimary">{"Missing Transaction History"}</Typography>
        </Breadcrumbs>
        <Divider className={classes.cardMargin} />
        <div className={classes.container}>
          <Typography variant="h4" component="h1">
            {"Missing Transaction History"}
          </Typography>
          <div className={clsx(classes.marginTop, classes.flexBox)}>
            <Avatar color="primary" className={clsx(classes.avatar, classes.marginRightAvatar)} />
            <div className={classes.authorContainer}>
              <Typography display="inline" variant="caption">
                <Box display="inline" fontWeight="fontWeightLight">
                  {"Written By: "}
                </Box>
              </Typography>
              <Typography display="inline" variant="caption">
                {" Jack Dealtrey"}
              </Typography>
              <div>
                <Typography fontWeight="fontWeightLight" variant="caption">
                  {"Last Updated on 1/18/21"}
                </Typography>
              </div>
            </div>
          </div>
          <main className={classes.marginTop}>
            <Card variant="outlined" className={classes.marginTop}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://res.cloudinary.com/digitax/image/upload/v1614739783/Missing-Transaction-History_qdnj34.png"
              >
                <img src={missing_transaction_history_image} className={classes.imgSize} alt="missing-transaction-history" />
              </Link>
            </Card>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"We classify a transaction as unmatched when you are sending or trading an amount that is larger than your balance in the wallet at the time of the transaction. This typically means you are missing some prior transaction."}
            </Typography>
            <Typography className={clsx(classes.italics, classes.marginTop)} variant="subtitle1">
              {"Looking at the transaction above, the user is selling 7.85 WTC for BTC.  The warning is stating that Digitax is missing transaction history for at least some of the 7.85 WTC."}
            </Typography>
            <Typography className={classes.marginTop} variant="h5">
              <Box fontWeight="fontWeightMedium">
                {"What does this mean?"}
              </Box>
            </Typography>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"Simply, we need to recognize a gain or loss for the WTC being sold.  Since we do not have a cost basis for the full WTC 7.85, we cannot completely calculate that gain or loss."}
            </Typography>
            <Divider className={classes.marginTop} />
            <Typography className={classes.marginTop} variant="h4">
              {"How Do I Fix This?"}
            </Typography>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"If you've uploaded with a CSV or manually using the Manual Entry Form, consider using an API if available for your exchange.  If not, double check that you are properly inserted all of your transactions."}
            </Typography>
            <Typography className={classes.marginTop} variant="h5">
              <Box fontWeight="fontWeightMedium">
                {"What If I Used An API?"}
              </Box>
            </Typography>
            <Card variant="outlined" className={classes.marginTop}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://res.cloudinary.com/digitax/image/upload/v1614739783/Missing-Transaction-History_qdnj34.png"
              >
                <img src={api_upload_table_image} className={classes.imgSize} alt="api-upload-table" />
              </Link>
            </Card>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"Unfortunately many of the exchange APIs are not perfect.  Typically, Digitax has observed that if using the API, the missing transactions will be very small, and thus API upload is stll the preferred method."}
            </Typography>
            <Divider className={classes.marginTop} />
            <Typography className={classes.marginTop} variant="h4">
              {"So now what?"}
            </Typography>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"If you've confirmed your CSV or manually transaction history is accurate, or used an API, and still have this warning, you can apply a cost basis of $0.00 to the missing balance.  This is the most conservative tax approach and is an acceptable practice."}
            </Typography>
            <Typography className={classes.marginTop} variant="h5">
              <Box fontWeight="fontWeightMedium">
                {"Partial Basis"}
              </Box>
            </Typography>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"If we know a basis for some of the sale, we'll breakout all of the individual transactions that are leading into that basis.  This may also help you understand what is missing."}
            </Typography>
            <Card variant="outlined" className={classes.marginTop}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://res.cloudinary.com/digitax/image/upload/v1614739783/Missing-Transaction-History_qdnj34.png"
              >
                <img src={partial_basis_detail_image} className={classes.imgSize} alt="partial-basis-detail" />
              </Link>
            </Card>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"We'll also show how much your gains could be overstated.  Here, we are missing transaction history for 0.04845 WTC.  If are OK with this, you can click the button to Use Zero Cost Basis."}
            </Typography>
            <Typography className={classes.marginTop} variant="h5">
              <Box fontWeight="fontWeightMedium">
                {"Tax Preferred Approach"}
              </Box>
            </Typography>
            <Typography className={classes.marginTop} variant="subtitle1">
              {"Tax authorities recommend the $0.00 cost basis approach for cryptocurrencies with missing transactions.  Digitax will not allow you to use any other user-defined cost basis for balances that are missing transactions."}
            </Typography>
          </main>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HelpContent);