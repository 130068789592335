import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Content from './Content';


const styles = theme => ({
  mainGrid: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1)
    }
  }
});

class PrivacyPolicy extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="md">
          <main>
            <Grid container spacing={5} className={classes.mainGrid}>
              <Paper className={classes.mainGrid}>
                <Content />
              </Paper>
            </Grid>
          </main>
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(PrivacyPolicy);