export default {
  coinbase: {
    oauth: true,
  },
  coinbase_pro: {
    pp: true
  },
  binance: {
    pp: false
  },
  gateio: {
    pp: false
  },
  bittrex: {
    pp: false
  },
  bitfinex: {
    pp: false
  },
  kraken: {
    pp: false
  },
  gemini: {
    pp: false
  },
  binance_us: {
    pp: false
  },
  bitstamp: {
    pp: false,
    clientID: true
  }
}