export default (filename, rows) => {
  let csvFile = '';

  rows.forEach((row, rowNum) => {
    let header = '';
    if (rowNum === 0) {
      Object.keys(row).forEach(key => {
        if (![undefined, 'users_id', 'batchId', 'import_id', 'blockId', 'dbId', 'id'].includes(key)) {
          header += key + ',';
        }
      });
      csvFile += header + '\n';
    }
    let rowVal = '';
    let firstFound = false
    Object.keys(row).forEach(key => {
      if (![undefined, 'users_id', 'batchId', 'import_id', 'blockId', 'dbId', 'id'].includes(key)) {
        let innerValue = row[key] === null ? '' : row[key].toString();
        if (row[key] instanceof Date) {
          innerValue = row[key].toLocaleString();
        };
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        if (firstFound) {
          rowVal += ',';
        }
        rowVal += result;
        if (result !== "") {
          firstFound = true;
        }
      }
    });
    csvFile += rowVal + '\n';
  });

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}