import React from 'react';


export default (ChildComponent) => {
  class ComposedComponent extends React.Component {
    // our component get got rendered
    componentDidMount() {
      // localStorage.removeItem('jboxxx_digitax_route');
      localStorage.setItem('jboxxx_digitax_route', this.props.match.url);
    }

    render() {
      return <ChildComponent { ...this.props } />;
    }
  }

  return ComposedComponent;
};
