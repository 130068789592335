import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  rootFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  seventyWidth: {
    width: '70%'
  },
  fiftyWidth: {
    width: '50%'
  },
  subHeader: {
    width: '60%',
    textAlign: 'center'
  },
  subTitle2Margin: {
    marginTop: theme.spacing(7),
  },
  marginTopFromHeader: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    },
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  paddingTopHowTo: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  removeTransform: {
    textTransform: 'none'
  },
  marginRightButton: {
    marginRight: theme.spacing(7)
  },
  fiftyPctWidth: {
    width: '35%',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  marginRightFromCard: {
    marginRight: theme.spacing(17)
  },
  marginRightFromIcon: {
    marginRight: theme.spacing(30)
  },
  forceWidthContent: {
    [theme.breakpoints.up('md')]: {
      width: '75%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    }
  },
  hundredWidth: {
    width: '100%'
  },
  marginTopSubtitle: {
    marginTop: '3px'
  },
  marginBottom: {
    marginBottom: theme.spacing(10)
  },
  howToColor: {
    backgroundColor: theme.palette.primary.main,
    // boxShadow: '0px 0px 15px 10px rgba(181, 229, 200, 0.4)',
    // borderRadius: '20px'
  },
  arrowSize: {
    width: '2.5%',
    height: 'auto'
  },
  arrowPointRight: {
    transform: 'rotate(313deg)'
  },
  arrowPointLeft: {
    transform: 'rotate(45deg)'
  },
  iconSize: {
    width: '35%',
    height: 'auto'
  },
  iconMarginRight: {
    marginRight: theme.spacing(15)
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  paddingDescription: {
    padding: theme.spacing(2)
  },
  paddingHeader: {
    padding: theme.spacing(1)
  }
});

const UPLOAD_TITLE = 'Upload';
const UPLOAD_DESCRIPTION = "Upload your transaction data with your exchange's API, CSVs, or manually.  This is the essential buy, sell, deposit, withdrawal, mining, and interest income that is used in your taxes."

const CALCULATE_TITLE = 'Calculate';
const CALCULATE_DESCRIPTION = 'Digitax will then attempt to calculate your gains and losses from your transactions.  Our algorithm is designed for investors of all kinds, and is frequently updated as the applicable tax code grows alongside the cryptocurrency space.';

const RECONCILE_TITLE = 'Reconcile';
const RECONCILE_DESCRIPTION = 'Though most users will be able to skip this step, we may need to you to help us reconcile some of your transactions.  If necessary, this will help us get the clearest picture into your cryptocurrency income.  This is largely because exchanges are inconsistent in the way they report transactions.';

const DOWNLOAD_TITLE = 'Download';
const DOWNLOAD_DESCRIPTION = "Once reconciled, we'll calculate your taxes again and create your tax forms.  The most common form is the 8949 which is attached as an addendum to your return.  We also support TurboTax and FBARs.";

class HowItWorks extends React.Component {

  Card = ({ title, description }) => {
    const { classes } = this.props;

    return (
      <Paper className={clsx(classes.forceWidthContent, classes.howToColor, classes.textAlignCenter)}>
        <Typography variant="h4" className={classes.paddingHeader}>
          {title}
        </Typography>
        <Divider />
        <div className={classes.paddingDescription}>
          <Typography variant="subtitle2">
            {description}
          </Typography>
        </div>
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.hideOnDesktop}>
          <div className={clsx(classes.paddingTopHowTo, classes.marginHowItWorks, classes.alignCenter, classes.howToColor)}>
            <Typography variant="h3">
              {'How it works'}
            </Typography>
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTopFromHeader)}>
            {this.Card({ title: UPLOAD_TITLE, description: UPLOAD_DESCRIPTION })}
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            {this.Card({ title: CALCULATE_TITLE, description: CALCULATE_DESCRIPTION })}
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            {this.Card({ title: RECONCILE_TITLE, description: RECONCILE_DESCRIPTION })}
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            {this.Card({ title: DOWNLOAD_TITLE, description: DOWNLOAD_DESCRIPTION })}
          </div>
        </div>

        <div className={classes.hideOnMobile}>
          <div className={clsx(classes.paddingTopHowTo, classes.marginHowItWorks, classes.alignCenter, classes.howToColor)}>
            <Typography variant="h3">
              {'How it works'}
            </Typography>
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTopFromHeader)}>
            <div className={clsx(classes.alignCenter, classes.seventyWidth)}>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                {this.Card({ title: UPLOAD_TITLE, description: UPLOAD_DESCRIPTION })}
              </div>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                <CloudUploadTwoToneIcon fontSize="large" className={clsx(classes.iconSize)} />
              </div>
            </div>
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon className={clsx(classes.arrowSize, classes.arrowPointRight)} />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            <div className={clsx(classes.alignCenter, classes.seventyWidth)}>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                <TrendingUpIcon fontSize="large" className={clsx(classes.iconSize)} />
              </div>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                {this.Card({ title: CALCULATE_TITLE, description: CALCULATE_DESCRIPTION })}
              </div>
            </div>
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon className={clsx(classes.arrowSize, classes.arrowPointLeft)} />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            <div className={clsx(classes.alignCenter, classes.seventyWidth)}>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                {this.Card({ title: RECONCILE_TITLE, description: RECONCILE_DESCRIPTION })}
              </div>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                <CheckCircleTwoToneIcon fontSize="large" className={clsx(classes.iconSize)} />
              </div>
            </div>
          </div>
          <div className={clsx(classes.marginTop, classes.rootFlex)}>
            <ArrowDownwardIcon className={clsx(classes.arrowSize, classes.arrowPointRight)} />
          </div>
          <div className={clsx(classes.alignCenter, classes.marginTop)}>
            <div className={clsx(classes.alignCenter, classes.seventyWidth)}>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                <LibraryBooksTwoToneIcon fontSize="large" className={clsx(classes.iconSize)} />
              </div>
              <div className={clsx(classes.alignCenter, classes.fiftyWidth)}>
                {this.Card({ title: DOWNLOAD_TITLE, description: DOWNLOAD_DESCRIPTION })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HowItWorks);