import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { confirmEmail, fetchUserWithCookie } from 'actions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import logger from 'modules/utils/logger';

const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paddingProgress: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    // height: '20vh'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  alignTogether: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginTopVh: {
    marginTop: '5vh'
  }
});


class EmailVerification extends React.Component {
  componentDidMount() {
    const { token } = this.props.match.params;
    if (!this.props.auth.isEmailConfirmed && !this.props.isRecoverInProgress) {
      logger.info('called confirm email');
      this.props.confirmEmail({ token });
    }
  };

  render() {
    const { classes } = this.props;

    logger.info('rendered', this.props.auth.isEmailConfirmed);
    if (this.props.auth && this.props.auth.errorMessage && this.props.auth.errorMessage.confirmEmail) {
      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.alignTogether}>
              <ErrorOutlineIcon />
              <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
                {this.props.auth.errorMessage.confirmEmail}
              </Typography>
            </div>
            <div className={classes.alignCenter}>
              <Link href="/dashboard">
                <Button
                  variant="contained"
                  className={clsx(classes.textAlignCenter, classes.margin, classes.removeTextTransform)}
                  color="primary"
                >
                  <Typography variant="subtitle1">
                    {"Return to your dashboard"}
                  </Typography>
                </Button>
              </Link>
            </div>
          </Paper>
        </div>
      );
    }

    // const dummy = true
    // if (dummy) {
    if (!this.props.auth.isEmailConfirmed) {
      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={clsx(classes.marginTopVh, classes.alignTogether)}>
              <Typography variant="h5" className={classes.textAlignCenter}>
                {"We're confirming your email. Please wait a moment..."}
              </Typography>
            </div>
            <div className={clsx(classes.alignCenter, classes.paddingProgress)}>
              <CircularProgress color="primary" />
            </div>
          </Paper>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"Thank you for verifying your email."}
            </Typography>
          </div>
          <div className={classes.alignCenter}>
            <Link href="/dashboard">
              <Button
                variant="contained"
                className={clsx(classes.textAlignCenter, classes.margin, classes.removeTextTransform)}
                color="primary"
              >
                <Typography variant="subtitle1">
                  {"Return to your dashboard"}
                </Typography>
              </Button>
            </Link>
          </div>
        </Paper>
      </div>
    );
  }
}

EmailVerification.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object,
  confirmEmail: PropTypes.func.isRequired,
  fetchUserWithCookie: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    isRecoverInProgress: state.api.isRecoverInProgress
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { confirmEmail, fetchUserWithCookie })
)(EmailVerification);
