import { TRADES } from '../../types';
// import currencies from 'modules/statics/currencies';
import logger from 'modules/utils/logger';

// trade[0]: type,
// trade[1]: date,
// trade[2]: exchange,
// trade[3]: buy_currency,
// trade[4]: buy_amount,
// trade[5]: sell_currency,
// trade[6]: sell_amount,
// trade[7]: fee_currency,
// trade[8]: fee_amount,
// trade[9]: margin

export default ({ exchange, tradeData }) => {
  let newData = [];
  let badTrades = [];

  let counter = -1;
  let errors = [];
  tradeData.forEach(trade => {
    try {
      // handle header if they included one
      if (trade[0] === 'Trade_Time_(UTC)' || trade[0].includes('time')) {
        counter++;
        return
      }

      counter++;
      const thisRecord = createTableData({
        counter,
        type: TRADES,
        date: trade[0],
        exchange: trade[1],
        buyCCY: trade[2],
        buyAmt: trade[3],
        sellCCY: trade[4],
        sellAmt: trade[5],
        feeCCY: trade[6],
        feeAmt: trade[7],
        margin: trade[8]
      });

      const error = validate({
        counter,
        type: TRADES,
        side: 'BUY',
        date: trade[0],
        exchange: trade[1],
        buyCCY: trade[2],
        buyAmt: trade[3],
        sellCCY: trade[4],
        sellAmt: trade[5],
        feeCCY: trade[6],
        feeAmt: trade[7],
        margin: trade[8]
      });
      if (JSON.stringify(error) !== '{}') {
        badTrades.push({
          error, trade: thisRecord, id: counter
        });
        return;
      }

      newData.push({ ...thisRecord, id: counter });
    } catch (err) {
      logger.info(`errored on cryptotaxadvisors upload on id: ${counter}`, err);
      errors.push({
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData,
        type: TRADES,
        badTrades
      });
    }
  });

  if (badTrades.length === 0) {
    logger.info('cryptotaxadvisors trades are valid');
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('cryptotaxadvisors trades are invalid')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "cryptotaxadvisors: We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else if (errors.length > 0) {
    logger.info('cryptotaxadvisors trades are invalid')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "cryptotaxadvisors: We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else {
    logger.info('all cryptotaxadvisors trades are invalid');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  }
}

function validate({
  side,
  date,
  exchange,
  buyCCY,
  buyAmt,
  sellCCY,
  sellAmt,
  feeCCY,
  feeAmt,
  margin
}) {
  const error = {};

  if (!(['buy', 'sell', 'b', 's'].includes(side.toLowerCase()))) {
    error.side = 'Type must be either BUY or SELL';
  }

  try {
    let testDate
    if (Number.isInteger(date)) {
      // test for unix date
      testDate = new Date(date * 1000);
    } else {
      testDate = new Date(date);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  if (!exchange) {
    // do we really need one?
    error.exchange = 'You must provide an exchange.';
  }

  if (!buyCCY || !sellCCY) {
    error.product = 'You must provide both buy and sell currencies';
  }
  // if (!('product' in error)) {
  //   let foundOne = false;
  //   let foundTwo = false;
  //   currencies.forEach(ccy => {
  //     if (ccy.ticker === buyCCY) {
  //       foundOne = true;
  //     }
  //     if (ccy.ticker === sellCCY) {
  //       foundTwo = true;
  //     }
  //   });
  //   if (!foundOne || !foundTwo) {
  //     error.product = "We don't recognize one of these currencies";
  //   }
  // }

  if (isNaN(buyAmt) || isNaN(sellAmt)) {
    error.size = "You must provide a buy and a sell amount";
  }

  if (isNaN(feeAmt)) {
    error.exchangeFee = "If you don't know your fee amount, please use 0.";
  }

  // let found = false;
  // if (!feeCCY) {
    // currencies.forEach(ccy => {
    //   if (ccy.ticker === feeCCY) {
    //     found = true;
    //   }
    // });
  // }

  if (!feeCCY) {
    error.feeUnit = "If you don't know your fee currency, please use your buy currency";
  }

  console.log('error here', error);

  return error;
}

function createTableData(trade) {
  let date;
  try {
    if (Number.isInteger(trade.date)) {
      // test for unix date
      date = new Date(trade.date * 1000);
    } else {
      date = new Date(trade.date);
    }
  } catch (err) {
    trade.error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    date = 'N/A';
  }

  return {
    id: trade.counter,
    product: trade.buyCCY + '-' + trade.sellCCY,
    exchange: trade.exchange.toLowerCase(),
    side: 'BUY',
    tradeDateTime: date,
    size: parseFloat(trade.buyAmt),
    sizeUnit: trade.buyCCY,
    price: parseFloat(trade.sellAmt) / parseFloat(trade.buyAmt),
    exchangeFee: parseFloat(trade.feeAmt),
    total: Math.abs(parseFloat(trade.sellAmt)),
    totalUnit: trade.sellCCY,
    feeUnit: trade.feeCCY,
    priceUnit: trade.sellCCY,
    source: 'csv'
  };
}
