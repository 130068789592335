import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dot from 'modules/components/Dot';
import plans from 'modules/statics/planTypesDisplay';
import { storeCalculateTabChange } from 'actions';


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    textTransform: 'none'
  },
  space: {
    justifyContent: 'space-between'
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    }
  },
  marginPrice: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  invisible: {
    display: 'none'
  },
  loading: {
    textAlign: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // alignItems: 'center'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  marginBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      minWidth: '55%',
      maxWidth: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '80%',
      // maxWidth: '95%',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '23%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    marginBottom: theme.spacing(2)
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  removeTransform: {
    textTransform: 'none'
  },
  forceWidth: {
    flexBasis: '100%'
  },
  price: {
    fontSize: '30px'
  },
  taxYearLetters: {
    paddingBottom: '1px',
    alignSelf: 'flex-end'
  },
  dollarSign: {
    paddingTop: '2px',
    alignSelf: 'flex-start'
  },
  paddingZero: {
    margin: 0
  }
});

// function wrapWithDivider(children, key) {
//   return (
//     <div key={key}>
//       {children}
//       <Divider />
//     </div>
//   );
// }

class PlanAndBillingTab extends React.Component {
  // handleClick = () => {
  //   this.props.storeCalculateTabChange(4);
  // }

  renderPlan = (planName, isCurrentPlan, isRecommendedPlan, currentPlanPrice) => {
    const { classes } = this.props;
    
    if (plans.hasOwnProperty(planName)) {
      const { header, price, captions } = plans[planName];
      
      const displayUpgrade = parseFloat(plans[planName].price )> currentPlanPrice;

      const thisPlanNameUpgradeRoute = `/upgrade`;
      // old box around the plan type... more framey ui
      // <Card variant="outlined" key={planName} className={clsx(classes.paper)}>
      return (
        <div key={planName} className={classes.paper}>
          {/* {
            isRecommendedPlan ? (
              <div className={clsx(classes.flex, classes.marginBottom)}>
                <Typography variant="subtitle1" className={classes.bold}>
                  {"Recommended"}
                </Typography>
              </div>
            ) : null
          } */}
          {/* {
            isCurrentPlan === isRecommendedPlan && isCurrentPlan ? (
              <div className={clsx(classes.alignCenter, classes.margin)}>
                <Link component={RouterLink} to="/calculate/documents">
                  <Button
                    variant="contained"
                    className={clsx(classes.importButton, classes.removeTransform)}
                    color="secondary"
                    onClick={this.handleClick}
                  >
                    <Typography variant="subtitle2">
                      {"View Documents"}
                    </Typography>
                  </Button>
                </Link>
              </div>
            ) : null
          } */}
          <Paper elevation={3}>
            <div className={classes.flex}>
              <Typography
                variant="h4"
                className={clsx(classes.margin, { [classes.bold]: isRecommendedPlan })}
              >
                {`${header}`}
              </Typography>
            </div>
            {
              isCurrentPlan ? (
                <div className={classes.flex}>
                  <Typography variant="subtitle1" className={classes.bold}>
                    {"This is your current plan"}
                  </Typography>
                </div>
              ) : null
            }
            <div className={clsx(classes.margin, classes.flex)}>
              <Typography
                variant="h6"
                className={clsx({ [classes.bold]: isRecommendedPlan }, classes.dollarSign)}
              >
                {`$`}
              </Typography>
              <Typography
                variant="h6"
                className={clsx({ [classes.bold]: isRecommendedPlan }, classes.price)}
              >
                {`${price}`}
              </Typography>
              <Typography
                variant="subtitle2"
                className={clsx({ [classes.bold]: isRecommendedPlan }, classes.taxYearLetters)}
              >
                {`/tax year`}
              </Typography>
            </div>
            <div>
              <List>
                {
                  captions.map((caption, index) => (
                    <ListItem key={index} className={classes.paddingZero}>
                      <Dot />
                      <Typography
                        variant="body2"
                        component={'span'}
                        className={clsx({ [classes.bold]: isRecommendedPlan })}
                      >
                        {caption}
                      </Typography>
                    </ListItem>
                  ))
                }
              </List>
              <div className={clsx(classes.marginBox, classes.alignCenter)}>
                {displayUpgrade || planName === 'professional' ? (
                  <Link component={RouterLink} to={thisPlanNameUpgradeRoute}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      color="secondary"
                    >
                      {'Upgrade'}
                    </Button>
                  </Link>
                ) : null}
              </div>
            </div>
          </Paper>
        </div>
      );
        // {/* </Card> */}
    } else {
      return <div>Something went wrong</div>;
    }
  }
  // confused why that is working but whatever
  renderPlans = (currentPlanName, recommendedPlanName) => {
    const currentPlanPrice = parseFloat(plans[currentPlanName].price);

    let listItems = [];
    Object.keys(plans).forEach(planName => {
      const isCurrentPlan = currentPlanName === planName;
      const isRecommendedPlan = recommendedPlanName === planName;
      listItems.push(
        this.renderPlan(planName, isCurrentPlan, isRecommendedPlan, currentPlanPrice)
        // wrapWithDivider(this.renderPlan(plan, isCurrentPlan, currentPlanPrice), plan)
      );
    });
    return listItems;
  }

  render() {
    const { classes, recommendedPlanName, auth: { planName } } = this.props;

    if (planName) {
      return (
        <div className={clsx(classes.root, classes.margin)}>
          <div className={classes.marginBox}>
            <div className={classes.flex}>
              {this.renderPlans(planName, recommendedPlanName)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
}

PlanAndBillingTab.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    planName: PropTypes.string.isRequired
  })
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default compose(
  connect(mapStateToProps, { storeCalculateTabChange }),
  withStyles(styles)
)(PlanAndBillingTab);