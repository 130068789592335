export default [
  // { id: 'classify', numeric: false, disablePadding: true, label: 'Classify' },
  { id: 'product', numeric: false, disablePadding: true, label: 'Product' },
  { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
  { id: 'side', numeric: false, disablePadding: false, label: 'Side' },
  { id: 'tradeDateTime', numeric: true, disablePadding: false, label: 'Trade Time' },
  { id: 'size', numeric: true, disablePadding: false, label: 'Quantity' },
  { id: 'sizeUnit', numeric: false, disablePadding: false, label: 'Quantity Unit' },
  { id: 'price', numeric: true, disablePadding: false, label: 'Price' },
  { id: 'exchangeFee', numeric: true, disablePadding: false, label: 'Exchange Fee' },
  { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
  { id: 'totalUnit', numeric: false, disablePadding: false, label: 'Total Unit' },
  { id: 'feeUnit', numeric: false, disablePadding: false, label: 'Fee Unit' },
  { id: 'priceUnit', numeric: false, disablePadding: false, label: 'Price Unit' },
];
