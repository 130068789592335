import {
  AUTH_USER,
  AUTH_ERROR,
  SIGN_UP,
  SIGN_OUT,
  RECOVER_ACCOUNT,
  RECOVER_ACCOUNT_ERROR,
  FETCH_ACCOUNT_DETAILS_ON_RECOVER,
  FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  STORE_CSRF_TOKEN,
  UPDATE_PLAN_FOR_TAX_YEAR,
  UPDATE_FBAR_FOR_TAX_YEAR,
  UPDATE_MAX_TRANSACTIONS,
  UPGRADE_ACCOUNT,
  IS_PLAN_SUFFICIENT
} from '../actions/types';

const INITIAL_STATE = {
  token: '',
  firstName: '',
  lastName: '',
  email: '',
  errorMessage: {},
  callbackMessage: '',
  password: '',
  planName: 'free',
  recoverEmail: '',
  isEmailConfirmed: false,
  csrfToken: null,
  maxTransactions: 0,
  isPlanSufficient: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, ...action.payload };
    case UPGRADE_ACCOUNT:
      if (typeof action.payload.maxTransactions === 'number') {
        return { ...state, planName: action.payload.planName, maxTransactions: action.payload.maxTransactions };
      } else {
        return { ...state, planName: action.payload.planName };
      }
    case AUTH_ERROR:
      //  logger.info('%O',action.payload.errorMessage);
      return { ...state, errorMessage: action.payload.errorMessage };
    case SIGN_UP:
      return { ...state, ...action.payload };
    case SIGN_OUT:
      return { ...INITIAL_STATE, callbackMessage: action.payload };
    case RECOVER_ACCOUNT:
      return { ...INITIAL_STATE, recoverEmail: action.payload.recoverEmail };
    case RECOVER_ACCOUNT_ERROR:
      return { ...INITIAL_STATE, errorMessage: { email: action.payload.errorMessage } };
    case FETCH_ACCOUNT_DETAILS_ON_RECOVER:
      return { ...state, ...action.payload };
    case FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR:
      return { ...state, ...action.payload };
    case CONFIRM_EMAIL:
      return { ...state, ...action.payload };
    case CONFIRM_EMAIL_ERROR:
      return { ...state, errorMessage: { confirmEmail: action.payload.errorMessage } };
    case STORE_CSRF_TOKEN:
      // this case should be unused as this point
      sessionStorage.setItem('digitax-csrf-token', action.payload.csrfToken);
      return { ...state, csrfToken: action.payload.csrfToken };
    case UPDATE_PLAN_FOR_TAX_YEAR:
      return { ...state, planName: action.payload.planName };
    case UPDATE_FBAR_FOR_TAX_YEAR:
      return { ...state, hasPurchasedFbarForTaxYear: action.payload.hasPurchasedFbarForTaxYear };
    case UPDATE_MAX_TRANSACTIONS:
      return { ...state, maxTransactions: action.payload.maxTransactions };
    case IS_PLAN_SUFFICIENT:
      return { ...state, isPlanSufficient: action.payload.isPlanSufficient };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
