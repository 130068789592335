import { TRADES } from '../../types';
import logger from 'modules/utils/logger';

export default ({ exchange, tradeData }) => {
  let newData = [];
  let badTrades = [];

  // tradeData.forEach(arrayOfTradeData => {
  let counter = -1;
  tradeData.forEach(trade => {
    try {
      if (counter === -1) {
        counter += 1;
        return; // skip the header... perhaps make this nicer
      }

      const error = validate(trade);

      const thisRecord = createTableData(
        counter,
        trade[0], // date
        trade[1], // product
        trade[2], // side
        trade[3], // price
        trade[4], // size
        trade[5], // total
        trade[6], // fee
        trade[7],  // feeCoin (or really just our units).
        error
      );

      if (JSON.stringify(error) !== "{}") {
        logger.info(error, trade);
        badTrades.push({ error, trade: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on binance upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData: newData,
        type: TRADES,
        badTrades
      };
    }
  });

  if (badTrades.length === 0) {
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else if (newData.length > 0) {
    return {
      isValid: false,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else {
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  }
}

function validate(trade) {
  const date = trade[0];
  const product = trade[1];
  const side = trade[2];
  const price = trade[3];
  const size = trade[4];
  const total = trade[5];
  const fee = trade[6];
  const feeCoin = trade[7];

  const error = {};

  try {
    let testDate
    if (Number.isInteger(date)) {
      // test for unix date
      testDate = new Date(date * 1000);
    } else {
      testDate = new Date(date);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  if (!product.includes(feeCoin)) {
    error.product = "We are missing the fee coin in our product, this makes the trade illegible";
  }
  if (side !== "BUY" && side !== "SELL") {
    error.side = "We don't recognize this side";
  }
  if (isNaN(price)) {
    error.price = "Price needs to be a number";
  }
  if (isNaN(size)) {
    error.size = "Size needs to be number";
  }
  if (isNaN(total)) {
    error.total = "Total needs to be a number";
  }
  if (isNaN(fee)) {
    error.exchangeFee = "Fee needs to be a number";
  }
  if (!product.includes(feeCoin)) {
    error.feeUnit = "Fee coin needs to be in our product";
  }

  return error;
}

function createTableData(
  counter,
  tradeDateTime,
  _product,
  side,
  price,
  size,
  total,
  fee,
  feeCoin,
  error
) {
  // feeCoin is purchased, sellCoin is sold

  // need to determine which way the product is going
  const feeCoinStartIndex = _product.search(feeCoin);

  let sellCoin;
  let product;
  if (feeCoinStartIndex === 0) {
    sellCoin = _product.slice(feeCoin.length, _product.length);
    product = _product.slice(0, feeCoin.length) + '-' +
      _product.slice(feeCoin.length, _product.length)
  } else {
    sellCoin = _product.slice(0, feeCoinStartIndex);
    product = _product.slice(0, feeCoinStartIndex) + '-' +
      _product.slice(feeCoinStartIndex, _product.length)
  }

  let sizeUnit;
  if (side === 'BUY') {
    sizeUnit = feeCoin;
  } else {
    sizeUnit = sellCoin;
  }
  // price is price of fee coin per other coin
  // size is size of fee coin which is purchased
  // total is size of selling coin which was sold
  const exchangeFee = fee;
  const totalUnit = sellCoin;
  const feeUnit = feeCoin;
  const priceUnit = feeCoin;

  let date = 'N/A';
  if (!error.tradeDateTime) {
    if (Number.isInteger(tradeDateTime)) {
      date = new Date(tradeDateTime * 1000);
    } else {
      date = new Date(tradeDateTime);
    }
  }

  return {
    id: counter,
    product,
    side,
    tradeDateTime: date,
    size: parseFloat(size),
    sizeUnit,
    price: parseFloat(price),
    exchangeFee: parseFloat(exchangeFee),
    total: Math.abs(parseFloat(total)),
    totalUnit,
    feeUnit,
    priceUnit,
    source: 'csv'
  };
}

// this what we want the output to look like
// const example = {
//   product: 'BTC-USD',
//   side: 'BUY',
//   tradeDateTime: '2017-12-08T16:10:23.433Z',
//   size: 0.69,
//   sizeUnit: 'BTC',
//   price: 16900.89,
//   exchangeFee: 0.89,
//   total: 0.69 * 16900.89 - 0.89, // size * price - fee,
//   totalUnit: 'USD'
// };


// // binance data comes in like the following
// let array = [
//   0: Data(UTC), "2017-12-30 22:04:20"
//   1: Market, ( ie what is traded for what, no sapce ) "ICXETH",
//   2: Type, "BUY",
//   3: Price, "0.00746", // price of ICX per ETH
//   4: Amount, "231.17", // we're calling it size
//   5: Total, "1.7245282", // total of ETH being sold
//   6: Fee, "0.23117" // total fee of ICX to exchange
//   7: Fee Coin, "ICX"
// ]
