import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dot from 'modules/components/Dot';
import plans from 'modules/statics/planTypesDisplay';


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  button: {
    textTransform: 'none'
  },
  space: {
    justifyContent: 'space-between'
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    }
  },
  marginPrice: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  invisible: {
    visibility: 'hidden'
  },
  loading: {
    textAlign: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // alignItems: 'center'
  },
  marginBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '23%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    [theme.breakpoints.between('sm','sm')]: {
      minHeight: '100%',
      width: '45%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  removeTransform: {
    textTransform: 'none'
  },
  forceWidth: {
    flexBasis: '100%'
  },
  price: {
    fontSize: '30px'
  },
  taxYearLetters: {
    paddingBottom: '1px',
    alignSelf: 'flex-end'
  },
  dollarSign: {
    paddingTop: '2px',
    alignSelf: 'flex-start'
  },
  paddingZero: {
    margin: 0
  }
});

// function wrapWithDivider(children, key) {
//   return (
//     <div key={key}>
//       {children}
//       <Divider />
//     </div>
//   );
// }

class PlanAndBillingTab extends React.Component {

  renderPlan = ({ planName, isCurrentPlan, priceOfCurrentPlanName }) => {
    const { classes } = this.props;
    const { auth: { email } } = this.props;
    if (plans.hasOwnProperty(planName)) {
      const { header, price, captions } = plans[planName];
      const thisPlanNameUpgradeRoute = `/upgrade`;

      return (
        <Paper elevation={3} key={planName} className={clsx(classes.paper)}>
          <div className={classes.flex}>
            <Typography
              variant="h4"
              className={clsx(classes.margin, { [classes.bold]: isCurrentPlan })}
            >
              {`${header}`}
            </Typography>
          </div>
          {
            isCurrentPlan ? (
              <div className={classes.flex}>
                <Typography variant="subtitle1" className={clsx(classes.textAlignCenter, classes.bold)}>
                  {"This is your current plan"}
                </Typography>
              </div>
            ) : null
          }
          <div className={clsx(classes.margin, classes.flex)}>
            <Typography
              variant="h6"
              className={clsx({ [classes.bold]: isCurrentPlan }, classes.dollarSign)}
            >
              {`$`}
            </Typography>
            <Typography
              variant="h6"
              className={clsx({ [classes.bold]: isCurrentPlan }, classes.price)}
            >
              {`${price}`}
            </Typography>
            <Typography
              variant="subtitle2"
              className={clsx({ [classes.bold]: isCurrentPlan }, classes.taxYearLetters)}
            >
              {`/tax year`}
            </Typography>
          </div>
          <div className={classes.flex}>
            <List>
              {
                captions.map((caption, index) => (
                  <ListItem key={index} className={classes.paddingZero}>
                    <Dot />
                    <Typography
                      variant="body2"
                      className={clsx({ [classes.bold]: isCurrentPlan })}
                    >
                      {caption}
                    </Typography>
                  </ListItem>
                ))
              }
            </List>
            <div className={clsx(classes.marginBox, classes.flex)}>
              {email ? (
                parseFloat(price) >= priceOfCurrentPlanName ? (
                  <Link component={RouterLink} to={thisPlanNameUpgradeRoute}>
                    <Button variant="contained" className={classes.button} color="primary">
                      {'Upgrade'}
                    </Button>
                  </Link>
                ) : null
              ) : (
                <Link component={RouterLink} to={'/signin'}>
                  <Button variant="contained" className={classes.button} color="primary">
                    {'Get Started'}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Paper>
      );
    } else {
      return <div>Something went wrong</div>;
    }
  }

  renderPlans = (currentPlanName) => {
    let listItems = [];
    const priceOfCurrentPlanName = parseFloat(plans[currentPlanName].price);
    Object.keys(plans).forEach(planName => {
      const isCurrentPlan = currentPlanName === planName;
      // const isCurrentPlan = false;
      listItems.push(
        this.renderPlan({ planName, isCurrentPlan, priceOfCurrentPlanName })
        // wrapWithDivider(this.renderPlan(plan, isCurrentPlan, currentPlanPrice), plan)
      );
    });
    return listItems;
  }

  render() {
    const { classes, auth: { planName } } = this.props;
    if (planName) {
      return (
        <div className={clsx(classes.root, classes.margin)}>
          <div className={classes.marginBox}>
            <div className={classes.flex}>
              {this.renderPlans(planName)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
}

PlanAndBillingTab.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    planName: PropTypes.string.isRequired
  })
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(PlanAndBillingTab);