import _ from 'lodash';
import {
  UPLOAD_TRADEDATA,
  UPLOAD_TRADEDATA_ERROR,
  UPLOAD_WALLETDATA,
  UPLOAD_WALLETDATA_ERROR,
  FETCH_TRANSACTION_DATA,
  FETCH_TRANSACTION_DATA_APPEND,
  DELETE_RECORD,
  DELETE_RECORD_ERROR,
  SIGN_OUT,
  UPDATE_TAX_YEAR,
  CLASSIFY_UNMATCHED_TRADE,
  CLASSIFY_UNMATCHED_MOVEMENT,
  UPDATE_COST_BASIS,
  CREATE_NEW_MVMT_FOR_STORAGE,
  CLEAR_ACTIVE_UNMATCHED,
  UNMATCHED_UPDATED,
  PARTIAL_BASIS_DETAIL,
  ZERO_COST_BASIS
} from '../actions/types';
import { TRADES, WALLET } from 'modules/utils/tradeDataValidators/types';

const INITIAL_STATE = {
  hasUploadedData: false,
  tradeDataByExchange: {
    // coinbase: [
    //   {
    //     otherProps: 'maybe-pnl',
    //     trades: [],
    //     batchId: 0,
    //     error: null
    //   },
    // ]
  },
  walletDataByExchange: {
    // coinbase: [
    //   {
    //     otherProps: 'maybe-pnl',
    //     trades: [],
    //     batchId: 0,
    //     error: null
    //   },
    // ]
  },
  apiFetching: {
    // [
    //   {
    //     exchange: 'coinbase',
    //     timeStarted: new Date('2020-01-01')
    //   },
    // ]
  },
  documentsCreating: {
    // [
    //   {
    //     timeStarted: new Date('2020-01-01')
    //   },
    // ]
  },
  userImports: [
    // straight records from the database
  ],
  unmatchedTrades: [
    // straight records from the database
  ],
  unmatchedMovements: [
    // straight records from the database
  ],
  pnlTransactions: [
    // straight recods from the database, might want to only send what we care about
  ],
  movementsWithMissingBasis: [
    // straight records from db
  ],
  unmatchedTradeRecords: [
    // straight records from db
  ],
  unmatchedMovementRecords: [
    // straight records from db
  ],
  isFBARNecessary: true,
  taxYear: null,
  adjustedTradeArray: [],
  currentlyAdjustingMovement: {},
  partialBasisDetail: {},
  knownWallets: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TRANSACTION_DATA:
      return { ...state, ...action.payload };
    case PARTIAL_BASIS_DETAIL:
      const partialBasisDetail = {};
      action.payload.partialBasisDetail.forEach(row => {
        // logger.info('row', row);
        if (row.sale_without_basis_trade_id) {
          if (!partialBasisDetail[row.sale_without_basis_trade_id]) {
            partialBasisDetail[row.sale_without_basis_trade_id] = [];
          }
          partialBasisDetail[row.sale_without_basis_trade_id].push({
            unmatched_trade_id: row.unmatched_trade_id,
            buy_trade_id: row.buy_trade_id,
            quantityWithMissingBasis: parseFloat(row.quantityWithMissingBasis),
            coin: row.coin,
            exchange: row.exchange,
            tradeDateTime: row.tradeDateTime,
            costBasisPerCoin: parseFloat(row.costBasisPerCoin),
            quantityWithBasis: parseFloat(row.quantityWithBasis),
            totalQuantitySold: parseFloat(row.totalQuantitySold)
          });
        }
        if (row.mvmt_without_basis_id) {
          if (!partialBasisDetail[row.mvmt_without_basis_id]) {
            partialBasisDetail[row.mvmt_without_basis_id] = [];
          }
          partialBasisDetail[row.mvmt_without_basis_id].push({
            unmatched_trade_id: row.unmatched_trade_id,
            buy_trade_id: row.buy_trade_id,
            quantityWithMissingBasis: parseFloat(row.quantityWithMissingBasis),
            coin: row.coin,
            exchange: row.exchange,
            tradeDateTime: row.tradeDateTime,
            costBasisPerCoin: parseFloat(row.costBasisPerCoin),
            quantityWithBasis: parseFloat(row.quantityWithBasis),
            totalQuantityMoved: parseFloat(row.totalQuantityMoved)
          });
        }
      });

      return { ...state, partialBasisDetail }
    case FETCH_TRANSACTION_DATA_APPEND:
      Object.keys(action.payload).forEach(payloadKey => {
        if (payloadKey !== 'type' && payloadKey in state && Array.isArray(state[payloadKey])) {
          state[payloadKey].push(action.payload[payloadKey]);
        }
      });
      return { ...state };
    // these two below use the same case
    case UPLOAD_TRADEDATA:  // this is really trade data
      let exchangeData = [];
      const newStateTradeDataByExchange = state.tradeDataByExchange;
      if (action.payload !== null) {
        if (action.payload.exchange in state.tradeDataByExchange) {
          // get previous exchange data and give our new record by an ID
          exchangeData = state.tradeDataByExchange[action.payload.exchange];

          // create the new record with our action's payload
          const newExchangeData = action.payload.tradeDataByExchange[action.payload.exchange];
          const maxId = _.maxBy(exchangeData, 'batchId').batchId;
          newExchangeData.batchId = maxId + 1;

          // add new record to the old array of exchange data
          exchangeData.push(newExchangeData);

          // add to our new state object
          newStateTradeDataByExchange[action.payload.exchange] = exchangeData;
        } else {
          // build the new record and assign.
          exchangeData.push({
            ...action.payload.tradeDataByExchange[action.payload.exchange],
            batchId: 0
          });
          newStateTradeDataByExchange[action.payload.exchange] = exchangeData;
        }
        return {
          ...state,
          tradeDataByExchange: newStateTradeDataByExchange,
          hasUploadedData: true
        };
      }
      return { ...state };
    case UPLOAD_TRADEDATA_ERROR:
      return { ...state };
    case UPLOAD_WALLETDATA:  // this is really trade data
      let exchangeData2 = [];
      const newStateWalletDataByExchange = state.walletDataByExchange;
      if (action.payload !== null) {
        if (action.payload.exchange in state.walletDataByExchange) {
          // get previous exchange data and give our new record by an ID
          exchangeData2 = state.walletDataByExchange[action.payload.exchange];

          // create the new record with our action's payload
          const newExchangeData = action.payload.walletDataByExchange[action.payload.exchange];
          const maxId = _.maxBy(exchangeData2, 'batchId').batchId;
          newExchangeData.batchId = maxId + 1;

          // add new record to the old array of exchange data
          exchangeData2.push(newExchangeData);

          // add to our new state object
          newStateWalletDataByExchange[action.payload.exchange] = exchangeData2;
        } else {
          // build the new record and assign.
          exchangeData2.push({
            ...action.payload.walletDataByExchange[action.payload.exchange],
            batchId: 0
          });
          newStateWalletDataByExchange[action.payload.exchange] = exchangeData2;
        }
        return {
          ...state,
          walletDataByExchange: newStateWalletDataByExchange,
          hasUploadedData: true
        };
      }
      return { ...state };
    case UPLOAD_WALLETDATA_ERROR:
      return { ...state };
    case DELETE_RECORD:
      // we need to remove this trade from our state.
      // This will happen on refresh
      action.payload.forEach(({ type, exchange, dbId }) => {
        if (type === TRADES) {
          let remainingTrades;
          state.tradeDataByExchange[exchange] = state.tradeDataByExchange[exchange].map(record => {
            remainingTrades = record['trades'].filter(({ id }) => id !== dbId);
            if (remainingTrades.length === 0) {
              return null;
            }
            return {
              ...record,
              trades: record['trades'].filter(({ id }) => id !== dbId)
            };
          }).filter(v => v !== null);
        } else if (type === WALLET) {
          // we know this is a wallet entry
          let remainingMovements;
          state.walletDataByExchange[exchange] = state.walletDataByExchange[exchange].map(record => {
            if (remainingMovements.length === 0) {
              return null;
            }
            return {
              ...record,
              trades: record['trades'].filter(({ id }) => id !== dbId)
            };
          }).filter(v => v !== null);
        }
      });
      return { ...state };
    case DELETE_RECORD_ERROR:
      // not sure how to handle this badboy yet
      return state;
    case SIGN_OUT:
      return { ...INITIAL_STATE };
    case UPDATE_TAX_YEAR:
      return { ...state, taxYear: action.payload.taxYear };
    case CLASSIFY_UNMATCHED_TRADE:
      // action.payload = { id, batchId, trade_id }
      const { tradeDataByExchange, unmatchedTrades } = state;
      if (tradeDataByExchange) {
        Object.keys(tradeDataByExchange).forEach(exchange => {
          tradeDataByExchange[exchange].forEach(importRecord => {
            importRecord.trades.forEach(trade => {
              if (trade.id === action.payload.trade_id) {
                trade.adjustmentType = action.payload.adjustmentType;
              }
            });
          });
        });
      }

      if (unmatchedTrades) {
        unmatchedTrades.forEach(trade => {
          if (trade.id === action.payload.trade_id) {
            trade.adjustmentType = action.payload.adjustmentType;
          }
        });
      }

      state.adjustedTradeArray.push(action.payload.trade_id);
      return { ...state };
    case CLASSIFY_UNMATCHED_MOVEMENT:
      const { walletDataByExchange, unmatchedMovements } = state;
      let mvmtBeingAdjusted = {};
      if (walletDataByExchange) {
        Object.keys(walletDataByExchange).forEach(exchange => {
          walletDataByExchange[exchange].forEach(importRecord => {
            importRecord.trades.forEach(movement => {
              if (movement.id === action.payload.trade_id) {
                movement.adjustmentType = action.payload.adjustmentType;
                mvmtBeingAdjusted = { ...movement };
              }
            });
          });
        });
      }

      if (unmatchedMovements) {
        unmatchedMovements.forEach(movement => {
          if (movement.id === action.payload.trade_id) {
            movement.adjustmentType = action.payload.adjustmentType;
            movement.donorCostBasis = null;
            movement.donorDateAcquired = null;
          }
        });
      }

      if (['gift_received', 'deposit', 'withdrawal'].includes(action.payload.adjustmentType)) {
        state.currentlyAdjustingMovement = { ...mvmtBeingAdjusted };

        // state.currentlyAdjustingMovement.id = action.payload.trade_id;
        // state.currentlyAdjustingMovement.adjustmentType = action.payload.adjustmentType;
      }
      return { ...state };
    case UPDATE_COST_BASIS:
      if (JSON.stringify(action.payload.currentlyAdjustingMovement) === "{}") {
        return { ...state, currentlyAdjustingMovement: {} };
      } else {
        const { currentlyAdjustingMovement } = action.payload;
        const { walletDataByExchange, unmatchedMovements } = state;
        if (walletDataByExchange) {
          Object.keys(walletDataByExchange).forEach(exchange => {
            walletDataByExchange[exchange].forEach(importRecord => {
              importRecord.trades.forEach(movement => {
                if (movement.id === currentlyAdjustingMovement.id) {
                  movement.donorCostBasis = action.payload.donorCostBasis;
                  movement.donorDateAcquired = action.payload.donorDateAcquired;
                }
              });
            });
          });
        }

        if (unmatchedMovements) {
          unmatchedMovements.forEach(movement => {
            if (movement.id === currentlyAdjustingMovement.id) {
              movement.donorCostBasis = action.payload.donorCostBasis;
              movement.donorDateAcquired = action.payload.donorDateAcquired;
            }
          });
        }
      }
      state.currentlyAdjustingMovement = {};
      return { ...state };
    case CREATE_NEW_MVMT_FOR_STORAGE:
      if (JSON.stringify(action.payload.currentlyAdjustingMovement) === "{}") {
        return { ...state, currentlyAdjustingMovement: {} };
      } else {
        const { currentlyAdjustingMovement } = action.payload;
        const { walletDataByExchange, unmatchedMovements } = state;
        if (walletDataByExchange) {
          Object.keys(walletDataByExchange).forEach(exchange => {
            walletDataByExchange[exchange].forEach(importRecord => {
              importRecord.trades.forEach(movement => {
                if (movement.id === currentlyAdjustingMovement.id) {
                  // to be INSERTED as exchange on the backend
                  movement.subTypeDescription = action.payload.storage;
                  movement.dateMoved = action.payload.dateMoved;
                  if (action.payload.costBasis) {
                    movement.costBasis = action.payload.costBasis;
                  }
                  if (action.payload.dateAcquired) {
                    movement.dateAcquired = action.payload.dateAcquired;
                  }
                }
              });
            });
          });
        }

        if (unmatchedMovements) {
          unmatchedMovements.forEach(movement => {
            if (movement.id === currentlyAdjustingMovement.id) {
              // to be INSERTED as exchange on the backend
              movement.subTypeDescription = action.payload.storage;
              movement.dateMoved = action.payload.dateMoved;
              if (action.payload.costBasis) {
                movement.costBasis = action.payload.costBasis;
              }
              if (action.payload.dateAcquired) {
                movement.dateAcquired = action.payload.dateAcquired;
              }
            }
          });
        }
      }
      state.currentlyAdjustingMovement = {};
      return { ...state };
    case CLEAR_ACTIVE_UNMATCHED:
      return { ...state, currentlyAdjustingMovement: {} };
    case UNMATCHED_UPDATED:
      return {
        ...state,
        unmatchedMovements: action.payload.unmatchedMovements,
        unmatchedTrades: action.payload.unmatchedTrades
      };
    case ZERO_COST_BASIS:
      const _partialBasisDetail = state.partialBasisDetail;
      const idsToPop = [];
      if (action.payload.transactionIDs) {
        Object.keys(state.partialBasisDetail).forEach(row_id => {
          state.partialBasisDetail[row_id].some(row => {
            if (action.payload.transactionIDs.includes(row.unmatched_trade_id)) {
              idsToPop.push(row_id);
              return true;
            }
            return false;
          });
        });
        idsToPop.forEach(row_id => {
          delete _partialBasisDetail[row_id];
        });

        const unmatchedTradesToPop = [];
        state.unmatchedTrades.forEach((trade, idx) => {
          if (action.payload.transactionIDs.includes(trade.unmatched_trade_id)) {
            unmatchedTradesToPop.push(idx);
          }
        });

        const missingBasisMvmtsToPop = [];
        state.movementsWithMissingBasis.forEach((mvmt, idx) => {
          if (action.payload.transactionIDs.includes(mvmt.unmatched_trade_id)) {
            missingBasisMvmtsToPop.push(idx);
          }
        });

        const _unmatchedTrades = state.unmatchedTrades.filter((v, i) => !unmatchedTradesToPop.includes(i));
        const _movementsWithMissingBasis = state.movementsWithMissingBasis.filter((v, i) => !missingBasisMvmtsToPop.includes(i));
        return {
          ...state,
          partialBasisDetail: _partialBasisDetail,
          unmatchedTrades: _unmatchedTrades,
          movementsWithMissingBasis: _movementsWithMissingBasis
        };
      } else if (action.payload.unmatchedTrades && action.payload.movementsWithMissingBasis) {
        return {
          ...state,
          unmatchedTrades: action.payload.unmatchedTrades,
          movementsWithMissingBasis: action.payload.movementsWithMissingBasis
        };
      }
      return { ...state };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
