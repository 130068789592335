import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => {
  return (
    <SvgIcon style={{width:24, height:24}} viewBox="0 0 24 24">
      <path fill="#000000" d="M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z" />
    </SvgIcon>
  );
};
