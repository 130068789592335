/* eslint-disable no-use-before-define */
import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { passWalletName } from 'actions';

const filter = createFilterOptions();

class FreeSoloCreateOptionDialog extends React.Component {
  state = {
    currentAddressName: '',
    addressNames: []
  }

  componentDidMount() {
    this.setState({ addressNames: this.props.knownAddressNames.map(address => ({ title: address })) });
  }

  addToAddressNames = value => {
    const { addressNames } = this.state;

    if (value.title) {
      addressNames.push(value);
    } else {
      addressNames.push({ title: value });
    }
    this.setState({ addressNames });
  }

  handleAddressClick = value => {
    if (value) {
      if (value && value.title) {
        this.props.passWalletName({ walletName: value.title });
      } else if (value) {
        this.props.passWalletName({ walletName: value });
      }
      if (value) {
        this.setState({ currentAddressName: value });
      } else {
        this.setState({ currentAddressName: { title: '' } });
      }
    }
  } 

  render() {
    return (
      <React.Fragment>
        <Autocomplete
          value={this.state.currentAddressName}
          // is called on press of Add 'name'
          onChange={(event, newValue) => {
            if (newValue && newValue.inputValue) {
              this.addToAddressNames({ title: newValue.inputValue })
              this.handleAddressClick(newValue.inputValue)
            } else {
              this.handleAddressClick(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          id="addressName-autocomplete-controlled"
          options={this.state.addressNames}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(option) => option.title}
          style={{ width: 247 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Eg. Wallet Name Or Storage" />
          )}
        />
      </React.Fragment>
    );
  }
}

export default connect(null, { passWalletName })(FreeSoloCreateOptionDialog);