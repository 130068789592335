// TODO add a wizard field

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { addDonorCostBasisToMovement } from 'actions';


const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-25%, -25%)`,
      top: `25%`,
      left: `35%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 25%)`,
    }
    // midWidth: '60%'
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  textField: {
    margin: theme.spacing(2),
    minWidth: 75
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1),
  },
  description: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  marginButtons: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  fontHandleMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  marginLabel: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  }
});

// TODO add a wizard field?
const FIELDS = [
  { name: 'donorCostBasis', label: 'Original Cost Basis' },
  { name: 'donorDateAcquired', label: 'Original Purchase Date' },
];

const REQUIRED_FIELDS = [
  { name: 'donorCostBasis' },
  { name: 'donorDateAcquired' }
];

const INITIAL_STATE = {
  fields: {
    donorCostBasis: '',
    donorDateAcquired: ''
  },
  disabled: {
    submit: false,
    clear: false
  },
  errors: {
    donorCostBasis: null,
    donorDateAcquired: null
  }
};

// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

function isNumeric(value) {
  return /^\d*\.?\d*$/.test(value);
}

class _Modal extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  componentDidMount() {
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  reset = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  clickUpdateCostBasis = () => {
    const { currentlyAdjustingMovement } = this.props;
    this.props.handleClose();
    this.props.addDonorCostBasisToMovement(
      {
        currentlyAdjustingMovement,
        donorCostBasis: parseFloat(this.state.fields.donorCostBasis),
        donorDateAcquired: new Date(this.state.fields.donorDateAcquired)
      }
    );
  }

  handleChange = name => ({ target: { value } }) => {
    const { fields, disabled } = this.state;

    // TODO make this a wizard!
    if (name === 'donorCostBasis' && isNumeric(value)) {
      fields[name] = value;
    } else if (name === 'donorDateAcquired') {
      fields[name] = value;
    }

    disabled.submit = fields.donorCostBasis !== "";
    disabled.clear = REQUIRED_FIELDS.some(({ name }) => fields[name] !== '');

    this.setState({ fields, disabled });
  }

  // todo make this a wizard field
  renderTextField = ({ name, label }) => {
    const { fields } = this.state;
    const { classes, currentlyAdjustingMovement } = this.props;
    let placeholder = label;
    let value = fields[name];
    if (name === 'donorDateAcquired' && 'dateTime' in currentlyAdjustingMovement) {
      placeholder = null;
      value = currentlyAdjustingMovement.dateTime;
    }

    if (name === 'donorCostBasis') {
      return (
        <div className={classes.textField} key={name}>
          <List>
            <ListItem>
              <TextField
                placeholder={label}
                label={label}
                value={fields[name]}
                onChange={this.handleChange(name)}
                autoComplete="off"
              />
              <Typography display="inline" className={classes.marginLabel}>
                {`USD / ${currentlyAdjustingMovement.coin}`}
              </Typography>
            </ListItem>
          </List>
        </div>
      );
    } else {
      return (
        <div className={classes.textField} key={name}>
          <List>
            <ListItem>
              <TextField
                id="datetime-local"
                placeholder={placeholder}
                label={label}
                onChange={this.handleChange(name)}
                autoComplete="off"
                type="datetime-local"
                defaultValue={(value && !isNaN(Date.parse(new Date(value)))) ? new Date(value).toISOString().slice(0, 16) : null}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ListItem>
          </List>
        </div>
      );
    }
  }

  // For example, if you sell the currency for more than the original price paid for by whoever gave you the currency, you will realize a capital gain. 
  render() {
    const { classes, open, handleClose } = this.props;
    const { disabled } = this.state;
    return (
      <Modal open={open} onClose={handleClose}>
        <div className={clsx(classes.paper, classes.outerDiv)}>
          <Card variant="outlined">
            <div className={clsx(classes.flex, classes.grey)}>
              <Typography variant="h6" className={classes.marginLeftOne} display="inline">
                {`Cost Basis for Gift`}
              </Typography>
              <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            </div>
            <Divider />
            <Card className={classes.margin} variant="outlined">
              <div className={classes.grey}>
                <Typography variant="subtitle1" className={classes.marginLeftOne}>
                  {"Details"}
                </Typography>
              </div>
              <Divider />
              <Typography variant="subtitle2" className={classes.description}>
                {"When you receive cryptocurrency as a gift, you inherit the cost basis of the original purchase of the currency.  Say for example whoever gave you the currency purchased it for $5 per coin, you would enter 5 below. This is essential information so we can correctly classify the future sale of the currency as a gain or a loss. Additionally, the donor's holding period is added onto your holding period.  This is also important because we need to be able to classify the eventual capital gain or loss upon sale as Long Term or Short Term."}
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {"If you don't know this information, please enter a cost basis of 0 and do not adjust the date field.  This will assume the most tax conservative approach."}
              </Typography>
              {
                FIELDS.map(({ name, label }) => (
                  this.renderTextField({ name, label })
                ))
              }
              <div className={classes.marginButtons}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!disabled.submit}
                  className={clsx(classes.marginLeftFour, classes.removeTextTransform)}
                  onClick={this.clickUpdateCostBasis}
                >
                  {"Add Cost Basis"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!disabled.clear}
                  onClick={this.reset}
                  className={clsx(classes.btnClear, classes.removeTextTransform)}
                >
                  {"Clear"}
                </Button>
              </div>
            </Card>
            <Typography variant="caption" className={classes.margin}>
              {"This is very important to make sure we can calculate your accurate tax liabilty."}
            </Typography>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(null, { addDonorCostBasisToMovement })
)(_Modal);