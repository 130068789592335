import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { storeCalculateTabChange } from 'actions';


const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4),
      width: '50%'
    },
    padding: theme.spacing(2)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});


class ReadyToCalculate extends React.Component {
  handleClick = () => {
    this.props.storeCalculateTabChange(2);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.importButtonWrapper}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"Everything is reconciled."}
            </Typography>
            <Divider />
          </div>
          <div className={classes.margin}>
            <div className={classes.importButtonWrapper}>
              <Typography variant="body1">
                {`You're now ready to calculate your gains and losses.`}
              </Typography>
            </div>
            <div className={classes.importButtonWrapper}>
              <Link component={RouterLink} to="/calculate/calc">
                <Button
                  variant="contained"
                  className={clsx(classes.importButton, classes.margin)}
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <Typography variant="subtitle2">
                    {"Go-To Calculate!"}
                  </Typography>
                </Button>
              </Link>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

ReadyToCalculate.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    tradeDataByExchange: PropTypes.object,
    walletDataByExchange: PropTypes.object,
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
  })
};

export default compose(
  connect(null, { storeCalculateTabChange }),
  withStyles(styles)
)(ReadyToCalculate);