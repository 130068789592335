import React from 'react';
import { connect } from 'react-redux';
import SignInForm from './components/SignInForm';
import history from '../../history';

class SignIn extends React.Component {
  componentDidMount() {
    if (this.props.auth.email !== '') {
      history.push('/dashboard');
    }
  }

  render() {
    return (
      <SignInForm />
    );
  }
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(SignIn);
