import React from 'react';

const HelpContent = () => {
  return (
    <div>
      WalletMovementsUnmatched
    </div>
  );
}

export default HelpContent;