import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { storeCalculateTabChange } from 'actions';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  }
});


class CenterBox extends React.Component {
  handleClick = () => {
    this.props.storeCalculateTabChange(3);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <Typography variant="subtitle1" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"You've already calculated your gains and losses."}
            </Typography>
          </div>
          <div className={classes.alignCenter}>
            <Link component={RouterLink} to="/calculate/gainLoss">
              <Button
                variant="contained"
                className={clsx(classes.textAlignCenter, classes.margin, classes.removeTextTransform)}
                color="secondary"
                onClick={this.handleClick}
              >
                <Typography variant="subtitle1">
                  {"Review your trades"}
                </Typography>
              </Button>
            </Link>
          </div>
        </Paper>
      </div>
    );
  }
}


export default compose(
  connect(null, { storeCalculateTabChange }),
  withStyles(styles)
)(CenterBox);