import { TRADES } from '../../types';
import currencies from 'modules/statics/currencies';
import logger from 'modules/utils/logger';

export default ({ exchange, tradeData }) => {
  let newData = [];
  let badTrades = [];
  // tradeData.forEach(arrayOfTradeData => {
  let counter = -1;
  tradeData.forEach(trade => {
    try {
      if (counter === -1) {
        counter += 1;
        return; // skip the header... perhaps make this nicer
      }

      const error = validate(trade);
      const thisRecord = createTableData(
        error,
        counter,
        trade[1], // product
        trade[2], // side
        trade[3], // tradeDateTime
        trade[4], // size
        trade[5], // sizeUnit
        trade[6], // price
        trade[7], // exchangeFee
        trade[8], // total
        trade[9], // totalUnit
      );

      if (JSON.stringify(error) !== "{}") {
        badTrades.push({ error, trade: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on coinbase upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData,
        type: TRADES,
        badTrades
      };
    }
  });
  // });
  if (badTrades.length === 0) {
    logger.info('coinbase pro trades are valid');
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('some coinbase pro trades are invalid')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else {
    logger.info('all coinbase pro trades are invalid');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  }
};

function validate(trade) {
  const error = {};
  const product = trade[1];
  const side = trade[2];
  const tradeDateTime = trade[3];
  const size = trade[4];
  const sizeUnit = trade[5];
  const price = trade[6];
  const exchangeFee = trade[7];
  const total = trade[8];
  const totalUnit = trade[9];
  if (product.split('-').length !== 2) {
    error.product = 'Product requires two currencies.';
  }
  if (!('product' in error)) {
    let foundOne = false;
    let foundTwo = false;
    const tickerOne = product.split('-')[0];
    const tickerTwo = product.split('-')[1];
    currencies.forEach(ccy => {
      if (ccy.ticker === tickerOne) {
        foundOne = true;
      }
      if (ccy.ticker === tickerTwo) {
        foundTwo = true;
      }
    });
    if (!foundOne || !foundTwo) {
      error.product = "We don't recognize one of these currencies";
    }
  }
  if (side !== 'BUY' && side !== 'SELL') {
    error.side = "Side needs to be either 'BUY' or 'SELL'.";
  }

  try {
    let testDate
    if (Number.isInteger(tradeDateTime)) {
      // test for unix date
      testDate = new Date(tradeDateTime * 1000);
    } else {
      testDate = new Date(tradeDateTime);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }
  if (isNaN(size)) {
    error.size = "Size is not a number.";
  }
  if (!product.includes(sizeUnit)) {
    error.sizeUnit = "Size Unit does not apply to trade.";
  }
  if (isNaN(price)) {
    error.price = "Price is not a number.";
  }
  if (isNaN(exchangeFee)) {
    error.exchangeFee = "Exchange Fee is not a number.";
  }
  if (isNaN(total)) {
    error.total = "Total is not a number.";
  }
  if (!product.includes(totalUnit)) {
    error.totalUnit = "Total Unit does not apply to trade.";
  }
  return error;
}

function createTableData(
  error,
  counter,
  product,
  side,
  tradeDateTime,
  size,
  sizeUnit,
  price,
  exchangeFee,
  total,
  totalUnit
) {
  let date;
  if (error.date) {
    date = 'Missing';
  } else {
    if (Number.isInteger(tradeDateTime)) {
      date = new Date(tradeDateTime * 1000);
    } else {
      date = new Date(tradeDateTime);
    }
  }
  const feeUnit = totalUnit;
  const priceUnit = totalUnit;
  return {
    id: counter,
    product,
    side,
    tradeDateTime: date,
    size: parseFloat(size),
    sizeUnit,
    price: parseFloat(price),
    exchangeFee: parseFloat(exchangeFee),
    total: Math.abs(parseFloat(total)),
    totalUnit,
    feeUnit,
    priceUnit,
    source: 'csv'
  };
}

// const example = {
//   product: 'BTC-USD',
//   side: 'BUY',
//   tradeDateTime: '2017-12-08T16:10:23.433Z',
//   size: 0.69,
//   sizeUnit: 'BTC',
//   price: 16900.89,
//   exchangeFee: 0.89,
//   total: 0.69 * 16900.89 - 0.89, // size * price - fee,
//   totalUnit: 'USD'
// };
