import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ToolTip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  TYPE,
  BUY_CURRENCY,
  BUY_AMOUNT,
  SELL_CURRENCY,
  SELL_AMOUNT,
  FEE_CURRENCY,
  FEE_AMOUNT,
  DATE,
  EXCHANGE,
  MARGIN,
  DELETE
} from './tradeTypes';
import {
  WALLET_DATE,
  WALLET_EXCHANGE,
  CURRENCY,
  AMOUNT,
  WALLET_FEE_AMOUNT,
  ADDRESS_TO,
  ADDRESS_FROM,
  EXCHANGE_TRADE_ID
} from './walletTypes';
import { TRADES, WALLET } from 'modules/utils/tradeDataValidators/types';
import exchangesMap from 'modules/statics/exchangesMap';
import currencies from '../../statics/currencies';
import validate from '../../utils/tradeDataValidators';
import { uploadManualTradeData } from 'actions';
import logger from 'modules/utils/logger';

const tradeTypesDefault = {
  [TYPE]: '',
  [BUY_CURRENCY]: '',
  [BUY_AMOUNT]: '',
  [SELL_CURRENCY]: '',
  [SELL_AMOUNT]: '',
  [FEE_CURRENCY]: '',
  [FEE_AMOUNT]: '',
  [DATE]: '',
  [EXCHANGE]: '',
  [MARGIN]: '',
  [DELETE]: DELETE
};

const tradeTypesErrorDefault = {
  [TYPE]: null,
  [BUY_CURRENCY]: null,
  [BUY_AMOUNT]: null,
  [SELL_CURRENCY]: null,
  [SELL_AMOUNT]: null,
  [FEE_CURRENCY]: null,
  [FEE_AMOUNT]: null,
  [DATE]: null,
  [EXCHANGE]: null,
  [MARGIN]: null,
  [DELETE]: null
};
// TNT QUESTION: why is everything different except TYPE?  Why the differences at all?  As discussed below, these differences aren't always consistent in our code (might cause some issues imo)
const walletTypesDefault = {
  [TYPE]: '',
  [WALLET_DATE]: '',
  [WALLET_EXCHANGE]: '',
  [CURRENCY]: '',
  [AMOUNT]: '',
  [WALLET_FEE_AMOUNT]: '',
  [ADDRESS_TO]: '',
  [ADDRESS_FROM]: '',
  [EXCHANGE_TRADE_ID]: '',
  [DELETE]: DELETE
};

const walletTypesErrorDefault = {
  [TYPE]: null,
  [WALLET_DATE]: null,
  [WALLET_EXCHANGE]: null,
  [CURRENCY]: null,
  [AMOUNT]: null,
  [WALLET_FEE_AMOUNT]: null,
  [ADDRESS_TO]: null,
  [ADDRESS_FROM]: null,
  [EXCHANGE_TRADE_ID]: null,
  [DELETE]: null
};

const displayTradeFields = [
  { label: 'Date', placeholder: '08/01/2020', type: DATE, align: "left", required: true },
  { label: 'Exchange', placeholder: 'E.g. Coinbase', type: EXCHANGE, align: "left", required: true },
  { label: 'Buy Currency', placeholder: 'BTC', type: BUY_CURRENCY, align: "left", required: true },
  { label: 'Buy Amount', placeholder: '0.4', type: BUY_AMOUNT, align: "left", required: true },
  { label: 'Sell Currency', placeholder: 'USD', type: SELL_CURRENCY, align: "left", required: true },
  { label: 'Sell Amount', placeholder: '100', type: SELL_AMOUNT, align: "left", required: true },
  { label: 'Fee Currency', placeholder: 'USD', type: FEE_CURRENCY, align: "left", required: true },
  { label: 'Fee Amount', placeholder: '1.2', type: FEE_AMOUNT, align: "left", required: true },
  { label: 'Margin', placeholder: 'None', type: MARGIN, align: "left", required: false },
  { label: 'Click to Delete', placeholder: 'None', type: DELETE, align: "center" }
];

const displayWalletFields = [
  { label: 'Date', placeholder: '08/01/2020', type: WALLET_DATE, align: "left", required: true },
  { label: 'Type', placeholder: 'Type', type: TYPE, align: "center", required: true },
  { label: 'Exchange', placeholder: 'E.g. Coinbase', type: WALLET_EXCHANGE, align: "left", required: true },
  { label: 'Currency', placeholder: 'BTC', type: CURRENCY, align: "left", required: true },
  { label: 'Amount', placeholder: '1.2', type: AMOUNT, align: "left", required: true },
  { label: 'Fee Amount', placeholder: '5', type: WALLET_FEE_AMOUNT, align: "left", required: false },
  { label: 'Address To', placeholder: '(Optional)', type: ADDRESS_TO, align: "left", required: false },
  { label: 'Address From', placeholder: '(Optional)', type: ADDRESS_FROM, align: "left", required: false },
  { label: 'Exchange Trade Id', placeholder: '(Optional)', type: EXCHANGE_TRADE_ID, align: "left", required: false },
  { label: 'Click to Delete', placeholder: 'None', type: DELETE, align: "center" }
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    paddingBottom: theme.spacing(3)
  },
  tableHeaderCell: {
    // textAlign: 'center'
  },
  tableCell: {
    minWidth: 120,
    padding: 0
  },
  table: {
    margin: theme.spacing(2),
    minWidth: 650
  },
  menuTextField: {
    width: theme.spacing(15)
  },
  submitButton: {
    margin: theme.spacing(4),
    textTransform: 'none'
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4)
  },
  margin: {
    margin: theme.spacing(2)
  },
  marginTop: {
    paddingTop: theme.spacing(4)
  }
});

// function isDateValid(dt) {
//   // add 0 padding
//   if (dt.slice(1, 2) === '/') {
//     dt = '0' + dt;
//   }
//   const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
//   return date_regex.test(dt);
// }


class ManualEntryGrid extends React.Component {
  state = {
    tradeRows: [
      {
        index: 0,
        ...tradeTypesDefault,
        errors: { ...tradeTypesErrorDefault }
      },
      {
        index: 1,
        ...tradeTypesDefault,
        errors: { ...tradeTypesErrorDefault }
      }
    ],
    walletRows: [
      {
        index: 0,
        ...walletTypesDefault,
        errors: { ...walletTypesErrorDefault }
      },
      {
        index: 1,
        ...walletTypesDefault,
        errors: { ...walletTypesErrorDefault }
      }
    ],
    tradeTypes: [
      { value: 'BUY', label: 'Buy' },
      { value: 'SELL', label: 'Sell' }
    ],
    walletTypes: [
      { value: 'fork', label: 'Fork' },
      { value: 'deposit', label: 'Deposit' },
      { value: 'withdrawal', label: 'Withdrawal' },
      { value: 'mining', label: 'Mining' },
      { value: 'spent', label: 'Spent' },
      { value: 'income', label: 'Income' },
      { value: 'gift', label: 'Gift' },
      { value: 'stolen', label: 'Stolen' },
      { value: 'airdrop', label: 'Airdrop' },
      { value: 'lost_access', label: 'Lost Access' }
    ],
    marginTypes: [
      { value: 'Y', label: 'Yes' },
      { value: 'N', label: 'No' }
    ]
  };

  handleChange = (rowIndex, type, transType) => event => {
    if (transType === TRADES) {
      const { tradeRows } = this.state;
      tradeRows[rowIndex][type] = event.target.value;
      if (tradeRows[rowIndex][type] === '') {
        tradeRows[rowIndex]['errors'][type] = null;
      }
      this.setState({ tradeRows });
    } else {
      const { walletRows } = this.state;
      walletRows[rowIndex][type] = event.target.value;
      if (walletRows[rowIndex][type] === '') {
        walletRows[rowIndex]['errors'][type] = null;
      }
      this.setState({ walletRows });
    }
  }

  // validates the data when a user clicks off the field and it has a value
  // i find it annoying on websites when they error immediately without any user input
  // remember this a controlled component
  handleOnBlur = (rowIndex, type, transType) => event => {
    if (event.target.value !== '') {
      if (transType === TRADES) {
        const { tradeRows } = this.state;
        if (type === DATE) {
          // if (isDateValid(tradeRows[rowIndex][DATE])) {
          //   tradeRows[rowIndex]['errors'][DATE] = null; // clear error
          // } else {
          //   tradeRows[rowIndex]['errors'][DATE] = "Please enter in mm/dd/yyyy format";
          // }
          this.setState({ tradeRows });
          // note I need to handle by Type ***
          // and perhaps resize based on the type
        } else if (
          type === FEE_AMOUNT ||
          type === SELL_AMOUNT ||
          type === BUY_AMOUNT
        ) {
          if (isNaN(tradeRows[rowIndex][type])) {
            tradeRows[rowIndex]['errors'][type] = "Please enter a number";
          } else {
            tradeRows[rowIndex]['errors'][type] = null; // clear error
          }
          this.setState({ tradeRows });
        } else if (
          type === FEE_CURRENCY ||
          type === SELL_CURRENCY ||
          type === BUY_CURRENCY
        ) {
          let found = false;
          currencies.forEach(ccy => {
            if (ccy.ticker === tradeRows[rowIndex][type]) {
              found = true;
            }
          });
          if (!found) {
            tradeRows[rowIndex]['errors'][type] = "We don't recognize that currency";
          } else {
            tradeRows[rowIndex]['errors'][type] = null; // clear error
          }
          this.setState({ tradeRows });
        } else if (type === EXCHANGE) {
          const { exchangeAndTaxData: { tradeDataByExchange } } = this.props;
          if (tradeRows[rowIndex][type] in exchangesMap) {
            const inputtedExchange = exchangesMap[tradeRows[rowIndex][type]];
            const hasAlreadyUploadedFromAPI = Object.keys(tradeDataByExchange).some(exchange => {
              if (inputtedExchange === exchange) {
                return tradeDataByExchange[exchange].some(upload => {
                  if ('trades' in upload) {
                    return upload['trades'].some(trade => {
                      if ('source' in trade) {
                        return trade['source'] === 'api';
                      }
                      return false;
                    });
                  }
                  return false;
                });
              }
              return false;
            });
            if (hasAlreadyUploadedFromAPI) {
              tradeRows[rowIndex]['errors'][EXCHANGE] = "You've already uploaded using the API from this exchange. Please delete the API data if you want to continue with this upload.";
              this.setState({ tradeRows });
            }
          } else {
            if (tradeRows[rowIndex]['errors'][EXCHANGE]) {
              tradeRows[rowIndex]['errors'][EXCHANGE] = null;
              this.setState({ tradeRows });
            }
          }
        }
      } else {
        const { walletRows } = this.state;
        if (type === WALLET_DATE) {
          // if (isDateValid(walletRows[rowIndex][WALLET_DATE])) {
          //   walletRows[rowIndex]['errors'][WALLET_DATE] = null;
          // } else {
          //   walletRows[rowIndex]['errors'][WALLET_DATE] = "Please enter in mm/dd/yyyy format";
          // }
          this.setState({ walletRows });
          // note I need to handle by Type ***
          // and perhaps resize based on the type
        } else if (type === AMOUNT || type === FEE_AMOUNT) { // TNT QUESTION: shouldn't this be WALLET_FEE_AMOUNT?  I'm still confused on why they would be different, unless its purely for error handling with the errorDefaults
          if (isNaN(walletRows[rowIndex][type])) {
            walletRows[rowIndex]['errors'][type] = "Please enter a number";
          } else {
            walletRows[rowIndex]['errors'][type] = null;
          }
          this.setState({ walletRows });
        } else if (type === CURRENCY) {
          let found = false;
          currencies.forEach(ccy => {
            if (ccy.ticker === walletRows[rowIndex][type]) {
              found = true;
            }
          });
          if (!found) {
            walletRows[rowIndex]['errors'][type] = "We don't recognize that currency";
          } else {
            walletRows[rowIndex]['errors'][type] = null;
          }
          this.setState({ walletRows });
        } else if (type === WALLET_EXCHANGE) {
          const { exchangeAndTaxData: { walletDataByExchange } } = this.props;
          if (walletRows[rowIndex][type] in exchangesMap) {
            const inputtedExchange = exchangesMap[walletRows[rowIndex][type]];
            const hasAlreadyUploadedFromAPI = Object.keys(walletDataByExchange).some(exchange => {
              if (inputtedExchange === exchange) {
                return walletDataByExchange[exchange].some(upload => {
                  if ('trades' in upload) {
                    return upload['trades'].some(movement => {
                      if ('source' in movement) {
                        return movement['source'] === 'api';
                      }
                      return false;
                    });
                  }
                  return false;
                });
              }
              return false;
            });
            if (hasAlreadyUploadedFromAPI) {
              walletRows[rowIndex]['errors'][WALLET_EXCHANGE] = "You've already uploaded using the API from this exchange. Please delete the API data if you want to continue with this upload.";
              this.setState({ walletRows });
            }
          } else {
            if (walletRows[rowIndex]['errors'][WALLET_EXCHANGE]) {
              walletRows[rowIndex]['errors'][WALLET_EXCHANGE] = null;
              this.setState({ walletRows });
            }
          }
        }
      }
    }
  }

  handleTrashCanClick = (rowToDelete, transType) => event => {
    if (transType === TRADES) {
      let { tradeRows } = this.state;
      tradeRows = tradeRows.filter(({ index }) => index !== rowToDelete);

      // reindex tradeRows
      let count = -1;
      tradeRows = tradeRows.map(row => {
        count++;
        return { ...row, index: count }
      });
      this.setState({ tradeRows });
    } else {
      let { walletRows } = this.state;
      walletRows = walletRows.filter(({ index }) => index !== rowToDelete);

      // reindex tradeRows
      let count = -1;
      walletRows = walletRows.map(row => {
        count++;
        return { ...row, index: count }
      });
      this.setState({ walletRows });
    }
  }

  appendRow = (transType) => event => {
    if (transType === TRADES) {
      let { tradeRows } = this.state;
      const maxIndex = _.maxBy(tradeRows, 'index');
      if (maxIndex) {
        tradeRows.push({
          index: maxIndex.index + 1,
          ...tradeTypesDefault,
          errors: { ...tradeTypesErrorDefault }
        });
      } else {
        tradeRows.push({
          index: 0,
          ...tradeTypesDefault,
          errors: { ...tradeTypesErrorDefault }
        });
      }
      this.setState({ tradeRows });
    } else {
      let { walletRows } = this.state;
      const maxIndex = _.maxBy(walletRows, 'index');
      if (maxIndex) {
        walletRows.push({
          index: maxIndex.index + 1,
          ...walletTypesDefault,
          errors: { ...walletTypesErrorDefault }
        });
      } else {
        walletRows.push({
          index: 0,
          ...walletTypesDefault,
          errors: { ...walletTypesErrorDefault }
        });
      }
      this.setState({ walletRows });
    }
  }

  handleSubmit = dummy => event => {
    // check for errors next
    const tradeRowsToSubmit = this.state.tradeRows.map(row => {
      if (displayTradeFields.every(field => field.required ? row[field.type] !== '' : true)) {
        return { ...row, [TYPE]: 'TRADE', [DATE]: new Date(new Date(row[DATE]).toISOString()) };
      }
      return null;
    }).filter(row => row !== null);

    const walletRowsToSubmit = this.state.walletRows.map(row => {
      if (row[TYPE] !== '' && displayWalletFields.every(
        field => field.required ? row[field.type] !== '' : true
      )) {
        return { ...row, [WALLET_DATE]: new Date(new Date(row[WALLET_DATE]).toISOString()) };
      }
      return null;
    }).filter(row => row !== null);

    if (tradeRowsToSubmit.length > 0) {
      const checkedTradeRows = validate({ tradeData: tradeRowsToSubmit, exchange: 'manual_entry' });
      if (checkedTradeRows[TRADES].isValid && checkedTradeRows[TRADES].tradeData.length > 0) {
        // TNT QUESTION/NOTE: if you enter wallet + tradeData, it only uploads trades
        // send trades
        logger.info('handleSubmit for ManualEntryGrid about to be done for trades and tradeData: ', checkedTradeRows[TRADES].tradeData);
        // logger.info('checkedTradeRows[TRADES].tradeData', checkedTradeRows[TRADES].tradeData);

        this.props.uploadManualTradeData({ type: TRADES, transactions: checkedTradeRows[TRADES].tradeData });
      }
    }

    if (walletRowsToSubmit.length > 0) {
      const checkedWalletRows = validate({ tradeData: walletRowsToSubmit, exchange: 'manual_entry' });
      if (checkedWalletRows[WALLET].isValid && checkedWalletRows[WALLET].tradeData.length > 0) {
        // send wallet transactions
        logger.info('handleSubmit for ManualEntryGrid about to be done for wallet and walletData: ', checkedWalletRows[WALLET].tradeData);
        // logger.info('checkedTradeRows[WALLET].tradeData', checkedWalletRows[WALLET].tradeData);
        this.props.uploadManualTradeData({ type: WALLET, transactions: checkedWalletRows[WALLET].tradeData });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      tradeRows,
      walletRows,
      marginTypes,
      walletTypes
    } = this.state;

    let disabled = true;
    let readyRows = 0;

    tradeRows.forEach(row => {
      if (Object.keys(row['errors']).every(type => row['errors'][type] === null) &&
        displayTradeFields.every(field => field.required ? row[field.type] !== '' : true)
      ) {
        readyRows++;
        disabled = false;
      }
    });
    walletRows.forEach(row => {
      if (Object.keys(row['errors']).every(type => row['errors'][type] === null) &&
        displayWalletFields.every(field => field.required ? row[field.type] !== '' : true)
      ) {
        readyRows++;
        disabled = false;
      }
    });

    let rowCountTrade = 0;
    let rowCountWallet = 0;
    return (
      <Paper className={classes.root}>
        {
          disabled ?
            <IconButton
              aria-label="CloudUpload"
              onClick={this.handleSubmit('dummy')}
              className={classes.submitButton}
              disabled={disabled}
              variant="contained"
              color="primary"
            >
              <CloudUpload />
            </IconButton> :
            (
              <ToolTip title="Upload to Digitax">
                <IconButton
                  aria-label="CloudUpload"
                  onClick={this.handleSubmit('dummy')}
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                >
                  <CloudUpload />
                </IconButton>
              </ToolTip>
            )
        }
        <Typography display="inline">
          {"We see"}
          {
            readyRows === 1 ? ` ${readyRows} record ` : ` ${readyRows} records `
          }
          {"ready.  Don't worry about blank or incomplete rows."}
        </Typography>
        <Typography variant="subtitle1" className={classes.margin}>
          {"Please enter your trades between two currencies below.  One transaction per row.  Don't worry about blank rows."}
        </Typography>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {displayTradeFields.map(({ label, align }) => (
                <TableCell
                  id={`${label}-label`}
                  align={align}
                  key={label}
                  className={
                    clsx(classes.tableCell, classes.tableHeaderCell)
                  }
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.marginTop}>
            {tradeRows.map((row, index) => (
              <TableRow key={index}>
                {displayTradeFields.map(({ label, placeholder, type }) => {
                  rowCountTrade += 1;
                  const ariaLabel = label.toLowerCase().replace(' ', '-');
                  if (type === DELETE) {
                    return (
                      <TableCell key={label}>
                        <Button onClick={this.handleTrashCanClick(index, TRADES)} aria-label={`trade-add-delete`}>
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    );
                  }
                  if (type === MARGIN) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <TextField
                          select
                          placeholder={placeholder}
                          id={`${ariaLabel}-${rowCountTrade}-basic`}
                          label={placeholder}
                          align="right"
                          className={classes.menuTextField}
                          value={row.margin}
                          onChange={this.handleChange(index, type, TRADES)}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          variant="outlined"
                        >
                          {
                            marginTypes.map(_type => (
                              <MenuItem key={_type.value} value={_type.value}>
                                {_type.label}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </TableCell>
                    );
                  }
                  if (type === DATE) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <TextField
                          placeholder={placeholder}
                          label={'Trade Date'}
                          id={`trade-datetime-local-${rowCountTrade}`}
                          type="datetime-local"
                          variant="outlined"
                          // defaultValue={placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={this.handleChange(index, type, TRADES)}
                          onBlur={this.handleOnBlur(index, type, TRADES)}
                          value={row[type]}
                          error={row['errors'][type] !== null}
                        />
                      </TableCell>
                    );
                  }
                  // everything else
                  if (row['errors'][type] !== null) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <ToolTip placement="top-start" title={row['errors'][type]}>
                          <TextField
                            placeholder={placeholder}
                            id={`${ariaLabel}-${rowCountTrade}-basic`}
                            label={placeholder}
                            variant="outlined"
                            onChange={this.handleChange(index, type, TRADES)}
                            onBlur={this.handleOnBlur(index, type, TRADES)}
                            value={row[type]}
                            error={row['errors'][type] !== null}
                          />
                        </ToolTip>
                      </TableCell>
                    );
                  }
                  // no errors
                  return (
                    <TableCell
                      align="right"
                      key={label}
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      <TextField
                        placeholder={placeholder}
                        id={`${ariaLabel}-${rowCountTrade}-basic`}
                        label={placeholder}
                        variant="outlined"
                        onChange={this.handleChange(index, type, TRADES)}
                        onBlur={this.handleOnBlur(index, type, TRADES)}
                        value={row[type]}
                        error={row['errors'][type] !== null}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div className={classes.addButton}>
          <Button onClick={this.appendRow(TRADES)} aria-label={`trade-add-button`}>
            <AddIcon />
          </Button>
        </div>

        <Divider className={classes.margin} />

        <Typography variant="subtitle1" className={classes.margin}>
          {"If you have wallet transactions (sending or receiving cryptocurrency), please insert them below."}
        </Typography>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {displayWalletFields.map(({ label, align }) => (
                <TableCell
                  align={align}
                  key={label}
                  className={
                    clsx(classes.tableCell, classes.tableHeaderCell)
                  }
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {walletRows.map((row, index) => (
              <TableRow key={index}>
                {displayWalletFields.map(({ label, placeholder, type }) => {
                  rowCountWallet += 1;
                  const ariaLabel = label.toLowerCase().replace(' ', '-');
                  if (type === DELETE) {
                    return (
                      <TableCell key={label}>
                        <Button onClick={this.handleTrashCanClick(index, WALLET)} aria-label={`wallet-add-delete`}>
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    );
                  }
                  if (type === TYPE) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <TextField
                          select
                          align="right"
                          className={classes.menuTextField}
                          placeholder={placeholder}
                          id={`${ariaLabel}-${rowCountWallet}-basic`}
                          label={placeholder}
                          value={row.type}
                          onChange={this.handleChange(index, type, WALLET)}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu
                            }
                          }}
                          variant="outlined"
                        >
                          {
                            walletTypes.map(_type => (
                              <MenuItem key={_type.value} value={_type.value}>
                                {_type.label}
                              </MenuItem>
                            ))
                          }
                        </TextField>
                      </TableCell>
                    );
                  }
                  if (type === WALLET_DATE) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <TextField
                          placeholder={placeholder}
                          label={'Movement Date'}
                          id={`wallet-datetime-local-${rowCountWallet}`}
                          type="datetime-local"
                          variant="outlined"
                          // defaultValue={placeholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={this.handleChange(index, type, WALLET)}
                          onBlur={this.handleOnBlur(index, type, WALLET)}
                          value={row[type]}
                          error={row['errors'][type] !== null}
                        />
                      </TableCell>
                    );
                  }
                  // everything else
                  if (row['errors'][type] !== null) {
                    return (
                      <TableCell
                        align="right"
                        key={label}
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        <ToolTip placement="top-start" title={row['errors'][type]}>
                          <TextField
                            placeholder={placeholder}
                            id={`${ariaLabel}-${rowCountWallet}-basic`}
                            label={placeholder}
                            variant="outlined"
                            onChange={this.handleChange(index, type, WALLET)}
                            onBlur={this.handleOnBlur(index, type, WALLET)}
                            value={row[type]}
                            error={row['errors'][type] !== null}
                          />
                        </ToolTip>
                      </TableCell>
                    );
                  }
                  // no errors
                  return (
                    <TableCell
                      align="right"
                      key={label}
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      <TextField
                        placeholder={placeholder}
                        id={`${ariaLabel}-${rowCountWallet}-basic`}
                        label={placeholder}
                        variant="outlined"
                        onChange={this.handleChange(index, type, WALLET)}
                        onBlur={this.handleOnBlur(index, type, WALLET)}
                        value={row[type]}
                        error={row['errors'][type] !== null}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div className={classes.addButton}>
          <Button onClick={this.appendRow(WALLET)} aria-label={`wallet-add-button`}>
            <AddIcon />
          </Button>
        </div>
      </Paper>
    );
  }
}

ManualEntryGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  uploadManualTradeData: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    exchangeAndTaxData: {
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange,
      walletDataByExchange: state.exchangeAndTaxData.walletDataByExchange
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { uploadManualTradeData })
)(ManualEntryGrid);
