import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';

const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-25%, -25%)`,
      top: `25%`,
      left: `35%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 25%)`,
    }
    // midWidth: '60%'
  },
  marginText: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  alignCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1)
  },
  marginLeftFour: {
    marginLeft: theme.spacing(4)
  },
  marginButtons: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  dialogContent: {
    paddingBottom: theme.spacing(2)
  },
  marginCenter: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid black',
    width: '100%',
    minHeight: '3em'
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  alignLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  instructionsContainer: {
    marginTop: theme.spacing(1)
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  exitToAppIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  instructionsText: {
    marginTop: '2px',
    marginRight: theme.spacing(0.5)
  },
  headerAlign: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  marginRightOne: {
    marginRight: theme.spacing(1)
  },
  redColor: {
    backgroundColor: red[300]
  }
});


class _Modal extends React.Component {
  render() {
    const { classes, open, accountProps, handleClick, handleClose } = this.props;

    let message;
    let caption;

    if (accountProps === 'exchangeData') {
      message = 'Are you sure you want to delete your exchange data?';
      caption = 'This will also delete any reconcilations or gains and loss calculation we may have done.';
    } else if (accountProps === 'pnlData') {
      message = 'Are you sure you want to delete your gains and losses data?';
      caption = 'You will need to recalculate and re-create your tax documents again.';
    } else if (accountProps === 'yourAccount') {
      message = 'Are you sure you want to delete your account?';
      caption = 'You will need to sign up with a new account to use Digitax again.';
    }

    // you'd think open={open} would do it.  oh but it doesn't do it fast enough.
    if (open) {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title">
            <div className={classes.headerAlign}>
              <div className={classes.alignLeft}>
                <Typography variant="h5">
                  {`Details`}
                </Typography>
              </div>
              <Tooltip title="Close" aria-label="close-form">
                <Button className={classes.closeIcon} onClick={handleClose}>
                  <CloseIcon />
                </Button>
              </Tooltip>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent} >
            <Divider className={classes.marginBottom} />
            <Typography className={classes.marginBottomText}>
              {message}
            </Typography>
            <div className={clsx(classes.marginText, classes.headerAlign)}>
              <InfoOutlinedIcon className={classes.marginRight} />
              <Typography
                variant="subtitle2"
                display="block"
              >
                {caption}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={clsx(classes.marginTop, classes.removeTextTransform, classes.redColor)}
              onClick={() => handleClick(accountProps)}
            >
              <DeleteIcon className={classes.marginRightOne} />
              <Typography variant="body1" className={classes.removeTextTransform}>
                <Box component="span" fontWeight="fontWeightMedium">
                  {"Delete"}
                </Box>
              </Typography>
            </Button>
          </DialogContent>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(_Modal);