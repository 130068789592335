import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      order,
      orderBy,
      displayColumns,
      classes
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableCell}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const tableHeadStyles = theme => ({
  tableCell: {
    whiteSpace: 'nowrap'
  }
});

const EnhancedTableHead = withStyles(tableHeadStyles)(_EnhancedTableHead);


EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  displayColumns: PropTypes.array.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { classes, title } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {title}
        </Typography>
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  },
  dateCellWidth: {
    // minWidth: '250px'
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'tradeDateTime',
    data: [],
    page: 0,
    rowsPerPage: 10,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  buildTableData = (pnlTransactions) => {
    let tempData = [];
    let id = 0; // id for the sortingtable
    pnlTransactions.forEach(trade => {
      tempData.push({
        id,
        disposedTime: new Date(trade.disposedTime),
        acquiredTime: new Date(trade.acquiredTime),
        buyCCY: trade.buyCCY,
        sellCCY: trade.sellCCY,
        term: trade.term === 'ST' ? 'ShortTerm' : 'LongTerm',
        quantity: parseFloat(trade.quantity),
        proceeds: parseFloat(trade.proceeds),
        costBasis: parseFloat(trade.costBasis),
        pnl: parseFloat(trade.pnl)
      });
      id++;
    });
    return tempData;
  }

  componentDidMount() {
    const { pnlTransactions } = this.props;

    if (JSON.stringify(pnlTransactions) !== "{}") {
      const tableData = this.buildTableData(pnlTransactions);
      this.setState({ data: tableData });
    }
  }

  render() {
    const { classes, displayColumns, title } = this.props;
    const { order, orderBy, rowsPerPage, page, data } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar title={title} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell className={classes.dateCellWidth} scope="row">
                        {n.acquiredTime.toLocaleString()}
                      </TableCell>
                      <TableCell className={classes.dateCellWidth}  align="left">{n.disposedTime.toLocaleString()}</TableCell>
                      <TableCell align="left">{n.buyCCY}</TableCell>
                      <TableCell align="left">{n.sellCCY}</TableCell>
                      <TableCell align="left">{n.term}</TableCell>
                      <TableCell align="right">{_.round(n.quantity, 4)}</TableCell>
                      <TableCell align="right">{_.round(n.costBasis, 4)}</TableCell>
                      <TableCell align="right">{_.round(n.proceeds, 4)}</TableCell>
                      <TableCell align="right">
                        {
                          n.pnl > 0 ? 
                            _.round(n.pnl, 4) :
                            '(' + String(_.round(n.pnl, 4)).replace('-','') + ')'
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  pnlTransactions: PropTypes.array.isRequired
};

export default compose(
  connect(null),
  withStyles(styles)
)(EnhancedTable);
