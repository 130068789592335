// the App components handles routing
// pages/<some string>.... will have a index.js file if necessary which handes the body
// -- We will pull shared components from the components directory
// -- ** using this convention, this file is in the namespace of page/ (slash) ** our index.
import React from 'react';
import { compose } from 'redux';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollToTop from 'modules/components/ScrollToTop';
import ErrorBoundary from 'modules/components/ErrorBoundary';
import history from '../history';
import { withStyles } from '@material-ui/core/styles';
import {
  fetchUserWithCookie,
  clearAppErrorMessage,
  clearAppApiMessage
} from '../actions';
import Header from 'modules/components/Header';
import _Home from 'pages/landing/Home'; // note this is the index page ie the homepage
import _SignUp from 'pages/signup';
import _SignIn from 'pages/signin';
import _SignInRecover from 'pages/signin/recover';
import _SignInRecoverEmailSent from 'pages/signin/recover/emailsent';
import _PasswordReset from 'pages/account/passwordreset';
import _Pricing from 'pages/pricing';
import Dashboard from 'pages/dashboard';
import DashboardImport from 'pages/dashboard/import';
import DashboardDocuments from 'pages/dashboard/documents';
import CreateReports from 'pages/dashboard/documents/create';
import AccountManagement from 'pages/account';
import EmailVerification from 'pages/email/verification';
import CircularProgress from 'modules/components/CircularProgress';
import NotFound from 'pages/notfound';
import UpgradeForm from 'pages/upgrade';
import CalculateTabs from 'pages/calculate';
import CheckoutSuccess from 'pages/checkout/success';
import CheckoutCancel from 'pages/checkout/cancel';
import routeLogger from 'modules/components/routeLogger'; // helper function for all unauth'd routes
import logger from 'modules/utils/logger';
import ErrorMessageModal from 'modules/components/Modals/ErrorMessageModal';
import ApiMessageModal from 'modules/components/Modals/ApiMessageModal';
import DEMO_mobile from 'pages/DEMO_mobile';
import _WhatWeOffer from 'pages/whatweoffer';
import _ExchangesWeCover from 'pages/exchanges';
import _AboutUs from 'pages/aboutus';
import _TermsOfService from 'pages/termsofservice';
import _PrivacyPolicy from 'pages/privacypolicy';
import _ContactUs from 'pages/contactus';
import _Help from 'pages/help';
import _Integrations from 'pages/integrations';
import _Blog from 'pages/blog';

const styles = theme => ({
  rootColor: {
    backgroundColor: 'red'
  }
});

// should wrap all unauthenticated routes.  requireAuth logs the routes outside of this.
const Home = routeLogger(_Home);
const Blog = routeLogger(_Blog);
const SignUp = routeLogger(_SignUp);
const SignIn = routeLogger(_SignIn);
const SignInRecover = routeLogger(_SignInRecover);
const SignInRecoverEmailSent = routeLogger(_SignInRecoverEmailSent);
const Pricing = routeLogger(_Pricing);
const PasswordReset = routeLogger(_PasswordReset);
const WhatWeOffer = routeLogger(_WhatWeOffer);
const ExchangesWeCover = routeLogger(_ExchangesWeCover);
const AboutUs = routeLogger(_AboutUs);
const ContactUs = routeLogger(_ContactUs);
const TermsOfService = routeLogger(_TermsOfService);
const PrivacyPolicy = routeLogger(_PrivacyPolicy);
const Help = routeLogger(_Help);
const Integrations = routeLogger(_Integrations);

class App extends React.Component {
  componentDidMount() {
    // check login status.  If we are, we might need to send an API request if we don't have our username
    // imagining the auth prop to be our user model
    // We can think about ways to reduce this in the future by using localStorage
    if (!this.props.auth.email && !window.location.pathname.includes('/email/verification') &&
        !window.location.pathname.includes('/success') && !window.location.pathname.includes('/cancel')
    ) {// && !('token' in this.props.match.params)) {
      // grab the user model via cookie if we have one

      logger.info('before fetch', this.props);
      let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
      logger.info('gotoRoute', gotoRoute);
      const year = localStorage.getItem('digitax_tax_year');
      if (year) {
        this.props.fetchUserWithCookie(gotoRoute, year);
        return;
      } else {
        const taxYear = 2020;
        this.props.fetchUserWithCookie(gotoRoute, taxYear);
        return;
      }
    }
  }

  handleErrorMessageClose = () => {
    this.props.clearAppErrorMessage();
  }

  handleApiMessageClose = () => {
    this.props.clearAppApiMessage();
  }

  // handles all routing on the app
  render() {
    const {
      auth: { callbackMessage },
      ui: { appErrorMessage, appApiMessage },
      api: { isInProgress, isSignoutInProgress },
      accountTabSelected
    } = this.props;

    let isErrorModalOpen = false;
    if (appErrorMessage) {
      isErrorModalOpen = true;
    }
    let isApiModalOpen = false;
    if (appApiMessage) {
      isApiModalOpen = true;
    }

    return (
      <React.Fragment>
        <Router history={history}>
          <ErrorBoundary>
            <ScrollToTop>
              <Header />
              <CircularProgress />
              {
                !isInProgress && !isSignoutInProgress ?
                  <Switch>
                    <Route
                      path="/"
                      exact
                      render={(props) => <Home {...props} callbackMessage={callbackMessage} />}
                    />
                    <Route path="/signup" exact component={SignUp} />
                    <Route path="/signin" exact component={SignIn} />
                    <Route path="/whatweoffer" exact component={WhatWeOffer} />
                    <Route path="/exchanges" exact component={ExchangesWeCover} />
                    <Route path="/aboutus" exact component={AboutUs} />
                    <Route path="/blog/:route" component={Blog} />
                    <Route path="/contactus" exact component={ContactUs} />
                    <Route path="/signin/recover" exact component={SignInRecover} />
                    <Route path="/signin/recover/emailsent" exact component={SignInRecoverEmailSent} />
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/dashboard/import" exact component={DashboardImport} />
                    <Route path="/dashboard/documents" exact component={DashboardDocuments} />
                    <Route path="/dashboard/documents/create" exact component={CreateReports} />
                    <Route
                      path="/account"
                      exact
                      render={(props) => <AccountManagement {...props} accountTabSelected={accountTabSelected} />}
                    />
                    <Route path="/account/passwordreset/:token/:users_id" component={PasswordReset} />
                    <Route path="/email/verification/:token" component={EmailVerification} />
                    <Route path="/upgrade" exact component={UpgradeForm} />
                    <Route path="/checkout/success" component={CheckoutSuccess} />
                    <Route path="/checkout/cancel" component={CheckoutCancel} />
                    <Route path="/pricing" exact component={Pricing} />
                    <Route path="/calculate/:route" component={CalculateTabs} />
                    <Route path="/help/:route" component={Help} />
                    <Route path="/integrations/:exchange" component={Integrations} />
                    <Route path="/test" exact component={DEMO_mobile} />
                    <Route path="/termsofservice" exact component={TermsOfService} />
                    <Route path="/privacypolicy" exact component={PrivacyPolicy} />
                    <Route component={NotFound} />
                  </Switch> :
                  null
              }
              <ErrorMessageModal
                open={isErrorModalOpen}
                handleClose={this.handleErrorMessageClose}
                apiMessage={appErrorMessage}
              />
              <ApiMessageModal
                open={isApiModalOpen}
                handleClose={this.handleApiMessageClose}
                apiMessage={appApiMessage}
              />
            </ScrollToTop>
          </ErrorBoundary>
        </Router>
      </React.Fragment>
    );
  }
}


// <Route path="/account" exact component={AccountManagement} />

App.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string,
    callbackMessage: PropTypes.string
  }),
  ui: PropTypes.shape({
    appErrorMessage: PropTypes.object,
    appApiMessage: PropTypes.object
  }),
  api: PropTypes.shape({
    isInProgress: PropTypes.bool,
    isSignoutInProgress: PropTypes.bool,
  }),
  accountTabSelected: PropTypes.number.isRequired,
  fetchUserWithCookie: PropTypes.func.isRequired,
  clearAppErrorMessage: PropTypes.func.isRequired,
  clearAppApiMessage: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ui: {
      appErrorMessage: state.ui.appErrorMessage,
      appApiMessage: state.ui.appApiMessage,
    },
    api: {
      isInProgress: state.api.isInProgress,
      isSignoutInProgress: state.api.isSignoutInProgress,
    },
    accountTabSelected: state.ui.accountTabSelected
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      fetchUserWithCookie,
      clearAppErrorMessage,
      clearAppApiMessage
    }
  ),
  withStyles(styles)
)(App);
