import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { updateAccountDetail } from 'actions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  gridRoot: {
    display: 'flex',
    flexGrow: 1,
  },
  gridPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
    maxWidth: theme.spacing(100),
    justifyContent: 'space-between'
  },
  gridPaperMobile: {
    color: theme.palette.text.secondary,
    width: '100%',
    maxWidth: theme.spacing(100),
    justifyContent: 'space-between'
  },
  gridFieldMargin: {
    marginBottom: theme.spacing(2)
  },
  paperMargin: {
    marginBottom: theme.spacing(2)
  },
  gridTypography: {
    minWidth: '20%'
  },
  removeShadow: {
    boxShadow: 'none'
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 200
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      // width: '100%'
      minWidth: 180
    }
  },
  textFieldMobile: {
    marginLeft: theme.spacing(1),
    // width: '100%'
    minWidth: 180
  },
  inline: {
    display: 'inline-flex'
  },
  marginMobileText: {
    marginRight: theme.spacing(2)
  },
  pencilInvisble: {
    visibility: 'hidden'
  },
  flexBox: {
    display: 'flex',
    wrap: 'flex-wrap'
  },
  editButton: {
    marginLeft: 'auto',
    textTransform: 'none'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  removePadding: {
    padding: 0
  },
  paddingDesktop: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  yourAccountWidth: {
    width: '30%',
    length: '100%'
  },
  accountFieldsWidth: {
    [theme.breakpoints.up('md')]: {
      width: '65%',
      length: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      length: '100%'
    }
  }
});

function PencilIcon() {
  return (
    <SvgIcon style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
      <path fill="#000000" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
    </SvgIcon>
  );
}

const displayFields = [
  { displayField: 'Email', stateField: 'email' },
  { displayField: 'Password', stateField: 'password' },
  { displayField: 'First Name', stateField: 'firstName' },
  { displayField: 'Last Name', stateField: 'lastName' }
];

const FIELDS_TO_CHECK = ['email', 'password', 'firstName', 'lastName'];

const INITIAL_STATE = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  disabled: {
    email: true,
    password: true,
    firstName: true,
    lastName: true,
  },
  pencilInvisible: true,
  errors: {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  },
  callbackMessage: { email: '' },
  savedFieldValues: {}
};

let savedState = {};

class AccountTab extends React.Component {
  // constructor(props) {
  //   super(props);
  //   const { email, firstName, lastName, usaState } = this.props.auth;
  //   // helps us maintain state across Tab switching
  //   // Note this only works if we have a single instance of AccountTab.  Which we will.
  //   // logger.info('created');
  //   // logger.info(savedState);
  //   // logger.info('above was savedState');
  //   // if (JSON.stringify(savedState) === "{}") {
  //   //   logger.info('here');
  //   //   logger.info({ ...INITIAL_STATE, email: email });
  //   this.state = {
  //     ...INITIAL_STATE,
  //     email: email,
  //     password: 'cha boi',
  //     firstName: firstName,
  //     lastName: lastName,
  //     usaState
  //   };
  //   // } else {
  //   //   logger.info('set state with savedState');
  //   //   this.state = savedState;
  //   // }
  // }

  // the below is equivalent to the above.
  // https://reactjs.org/docs/react-component.html#constructor
  state = INITIAL_STATE;

  componentDidMount() {
    // places the values on the fields
    const { email, firstName, lastName } = this.props.auth;
    if (email !== '' && firstName !== '' && lastName !== '') {
      this.setState({
        email,
        password: 'cha boi',
        firstName,
        lastName
      });
    };
  }

  // componentWillUnmount() {
  // here to maintain state across tab switching... because this component will unmount
  //   savedState = this.state;
  // }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  validate = (field) => {
    const errors = {};

    if (this.state[field] === this.state.savedFieldValues[field]) {
      errors.noChange = {};
      errors.noChange[field] = 'Nothing was updated';
      return errors;
    }

    if (field === 'email') {
      const { email } = this.state;
      if (
        email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
      ) {
        errors[field] = 'Email not changed.  Please enter a valid email address.'
      }
    } else if (field === 'firstName') {
      const { firstName } = this.state;
      if (firstName.length > 50) {
        errors[field] = 'Please make a first name of 25 characters or less';
      }
    } else {
      // field is lastname
      const { lastName } = this.state;
      if (lastName.length > 50) {
        errors[field] = 'Please make a last name of 50 characters or less';
      }
    }

    return errors;
  }

  handleEditClick = field => event => {
    const { savedFieldValues, disabled } = this.state;

    // logger.info(this.state);
    // logger.info(event);

    // this means they are trying to overwrite their info
    // this is onclick of the pencil when the text is editable
    if (!disabled[field]) {
      // need to validate the change
      // todo on noCHANGE!!!
      const errors = this.validate(field);

      if (JSON.stringify(errors) === "{}") {
        disabled[field] = disabled[field] ? false : true;
        this.setState({ disabled, errors });
      } else {
        if ('noChange' in errors) {
          disabled[field] = disabled[field] ? false : true;
        }
        this.setState({ errors, disabled });
      }
    } else {
      savedFieldValues[field] = this.state[field];
      disabled[field] = disabled[field] ? false : true;
      this.setState({ savedFieldValues, disabled });
    }
  }

  renderRightHandGridItems = ({ displayField, stateField }) => {
    const { classes } = this.props;
    const {
      errors,
      callbackMessage,
      disabled,
      pencilInvisible
    } = this.state;
    // this returns in the new mobile
    return (
      <div key={displayField}>
        <Paper className={clsx(classes.gridPaper, classes.paperMargin, classes.inline)}>
          <Typography className={classes.gridTypography} variant="body1">
            {displayField}
          </Typography>
          {
            <TextField
              id={`${stateField}-basic`}
              className={classes.textField}
              label={displayField}
              value={
                stateField === 'password' ?
                  'thisisaplaceholderiown' :
                  this.state[stateField]
              }
              onChange={this.handleChange(stateField)}
              disabled={disabled[stateField]}
              type={stateField === 'password' ? 'password' : 'default'}
            />
          }
          {
            !pencilInvisible ? (
              <Button
                onClick={
                  stateField === 'password' ?
                    this.props.toggleDrawer(!this.state.drawerOpen) :
                    this.handleEditClick(stateField)
                }
                aria-label={`edit-${stateField}`}
              >
                {PencilIcon()}
              </Button>
            ) : null
          }
        </Paper>
        {errors[stateField] !== '' ?
          (
            <div>
              <Typography color="error">
                {errors[stateField]}
              </Typography>
            </div>
          ) :
          null}
        {callbackMessage[stateField] !== '' ?
          (
            <div>
              <Typography variant="body1">
                {callbackMessage[stateField]}
              </Typography>
            </div>
          ) :
          null}
      </div>
    );
  }

  handleEditSaveClick = () => {
    // i need to move the callback information in the handle edit click to down here.
    const { pencilInvisible } = this.state;
    if (pencilInvisible) { // saves state before making it editable
      savedState = this.state;
    } else {
      const changedFields = {};
      FIELDS_TO_CHECK.forEach(field => {
        if (savedState[field] !== this.state[field]) {
          changedFields[field] = this.state[field];
        }
      });
      if (JSON.stringify(changedFields) !== "{}") {
        // call our action creator to change the non-email field
        const { email, firstName, lastName } = this.state;
        this.props.updateAccountDetail({ email, firstName, lastName });
      }
    }
    this.setState({ pencilInvisible: !pencilInvisible });
  }

  renderEditButton = () => {
    const { classes } = this.props;
    const { pencilInvisible } = this.state;
    return (
      <div className={clsx(classes.marginTop, classes.flexBox)}>
        <Button
          variant="contained"
          color={pencilInvisible ? "secondary" : "primary"}
          className={classes.editButton}
          onClick={() => this.handleEditSaveClick()}
        >
          {pencilInvisible ? "Edit" : "Save"}
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={clsx(classes.gridRoot, classes.paddingDesktop)}>
        <div className={clsx(classes.hideOnMobile, classes.yourAccountWidth)}>
          <Paper
            className={clsx(classes.gridPaper, classes.removeShadow)}
          >
            <Typography variant="subtitle1">
              {'Your Account'}
            </Typography>
          </Paper>
        </div>
        <div className={classes.accountFieldsWidth}>
          {displayFields.map(field => this.renderRightHandGridItems(field))}
          {this.renderEditButton()}
        </div>
      </div>
    );
  }
}


AccountTab.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }),
  updateAccountDetail: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default compose(
  connect(mapStateToProps, { updateAccountDetail }),
  withStyles(styles)
)(AccountTab);
