import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import NotCalculatedYet from '../../components/NotCalculatedYet';
import NeedToReCalculateZeroCB from '../../components/NeedToReCalculateZeroCB';
import NeedToReCalculateError from '../../components/NeedToReCalculateError';
import StillHaveUnmatched from '../../components/StillHaveUnmatched';
import NotReconciledYet from '../../components/NotReconciledYet';
import NotHighEnoughPlan from './components/NotHighEnoughPlan';
import Button from '@material-ui/core/Button';
import { fetchDocumentForUser } from 'actions';
import { documentTypesMap } from 'modules/statics/documentTypes';
import logger from 'modules/utils/logger';


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  },
  paper: {
    margin: 'auto',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '95%'
    }
  },
  paperNoDocuments: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      marginTop: theme.spacing(4)
    }
  },
  header: {
    margin: theme.spacing(2)
  },
  tableCell: {
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(23)
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    },
  },
  marginNoBottom: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  split: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textAlignLeft: {
    textAlign: 'left'
  },
  margin: {
    margin: theme.spacing(2)
  },
  removeUnderline: {
    textDecoration: 'none'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  linkText: {
    color: '#2b7b4c',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
});

const displayColumns = [
  { label: 'Type', field: 'documentLabel' },
  { label: 'Year', field: 'taxYear' },
  { label: 'Method', field: 'method' },
  { label: 'Created At', field: 'createdAt' },
  { label: 'Status', field: 'status' },
  { label: 'Comment', field: 'comment' },
  { label: 'Link', field: 'link' }
];

class Documents extends React.Component {
  handleDownloadClick = doc => event => {
    event.preventDefault();
    logger.info('doc', doc);
    this.props.fetchDocumentForUser({
      taxYear: doc.taxYear,
      documentType: doc.documentType,
      documentCreationId: doc.id
    });
  }

  render() {
    const {
      classes,
      pnl: { mostRecentPnlCalcRun, mostRecentReconcileRun },
      exchangeAndTaxData: { unmatchedTrades, unmatchedMovements },
      auth: { isPlanSufficient }
    } = this.props;

    if (!isPlanSufficient) {
      return <NotHighEnoughPlan />;
    }

    if (!mostRecentReconcileRun) {
      return <NotReconciledYet />;
    }

    if (unmatchedTrades.length > 0 || unmatchedMovements.length > 0) {
      return <StillHaveUnmatched />;
    }

    if (!mostRecentPnlCalcRun) {
      return <NotCalculatedYet />;
    }

    // there should be no unmatched but
    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun &&
      mostRecentPnlCalcRun.outcome === 'error'
    ) {
      return <NeedToReCalculateError />;
    }

    // there should be no unmatched but
    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun &&
      mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis'
    ) {
      return <NeedToReCalculateZeroCB />;
    }

    // const csrfToken = sessionStorage.getItem('digitax-csrf-token')
    // could use this structure and open in new tab?
    // const baseURL = `http://localhost:6969/api/getDocument?csrfToken=${csrfToken}`;

    // todo link documents created with displayColumns
    // or abstract away into a table
    if (('documents' in this.props && 'documentsCreated' in this.props.documents &&
      Array.isArray(this.props.documents.documentsCreated) &&
      this.props.documents.documentsCreated.length > 0) || (
        'api' in this.props && 'documentsCreating' in this.props.api &&
        Array.isArray(this.props.api.documentsCreating) && this.props.api.documentsCreating.length > 0
      )
    ) {
      let documentsToDisplay = [];
      let thisTaxYear;
      if ('api' in this.props && 'documentsCreating' in this.props.api &&
        Array.isArray(this.props.api.documentsCreating) && this.props.api.documentsCreating.length > 0
      ) {
        this.props.api.documentsCreating.forEach(doc => {
          documentsToDisplay.push({
            status: 'Pending',
            method: 'FIFO',
            link: `dummy_link`,
            comment: 'Building',
            taxYear: String(parseInt(doc.taxYear)),
            createdAt: new Date(doc.timeStarted).toLocaleString(),
            createdAtTimestamp: new Date(doc.createdAt).getTime(),
            documentLabel: documentTypesMap[doc.documentType],
            documentType: doc.documentType,
            isError: false
          });
        });
      }
      // displaying the max createdAt by documentType and taxYear as returned by sql from the backend
      this.props.documents.documentsCreated.forEach(doc => {
        thisTaxYear = String(parseInt(doc.taxYear));
        documentsToDisplay.push({
          id: doc.id,
          status: doc.outcomeDescription === 'success' ? 'Ready' : 'Error',
          method: 'FIFO',
          link: 'dummy',
          // link: `${baseURL}&taxYear=${thisTaxYear}&documentType=${doc.documentType}`,
          comment: doc.outcomeDescription === 'success' ? 'Success' : 'Contact Support if this persists after trying again.',
          taxYear: thisTaxYear,
          createdAt: new Date(doc.createdAt).toLocaleString(),
          createdAtTimestamp: new Date(doc.createdAt).getTime(),
          documentLabel: documentTypesMap[doc.documentType],
          documentType: doc.documentType,
          isError: doc.outcomeDescription !== 'success'
        });
      });
      documentsToDisplay = _.sortBy(documentsToDisplay, 'createdAtTimestamp')
      documentsToDisplay.reverse();
      return (
        <Paper className={classes.paper}>
          <div className={classes.split}>
            <Typography variant="h4" className={classes.header} display="inline">
              {"Tax Documents"}
            </Typography>
            <Link component={RouterLink} to="/dashboard/documents/create">
              <Button
                variant="contained"
                className={clsx(classes.removeTextTransform, classes.header)}
                color="secondary"
              >
                {"Create Document"}
              </Button>
            </Link>
          </div>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    displayColumns.map(({ label }) => (
                      <TableCell key={label} className={classes.tableCell}>
                        {label}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  documentsToDisplay.map((doc, index) => (
                    <TableRow key={index}>
                      {
                        displayColumns.map(({ field }) => {
                          if (field === 'link' && doc[field] === 'dummy_link') {
                            return (
                              <TableCell key={field} className={classes.tableCell}>
                                <CircularProgress />
                              </TableCell>
                            );
                          } else if (field === 'link') {
                            // return (
                            //   <TableCell key={field} className={classes.tableCell}>
                            //     <a href={doc[field]} className={classes.removeUnderline}>
                            //       <Typography variant="subtitle2" color="secondary">
                            //         Download
                            //       </Typography>
                            //     </a>
                            //   </TableCell>
                            // );
                            if (doc.isError) {
                              return (
                                <TableCell key={field} className={classes.tableCell}>
                                  <Typography variant="subtitle2">
                                    {"Creation Errored"}
                                  </Typography>
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={field} className={classes.tableCell}>
                                <Typography
                                  onClick={this.handleDownloadClick(doc)}
                                  variant="subtitle2"
                                  color="secondary"
                                  className={classes.linkText}
                                >
                                  {"Download"}
                                </Typography>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={field} className={classes.tableCell}>
                              {doc[field]}
                            </TableCell>
                          );
                        })
                      }
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        </Paper>
      );
    }

    // <Button
    //   onClick={this.handleDownloadClick(doc)}
    //   className={classes.removeTextTransform}
    // >
    //   <Typography onClick={this.handleDownloadClick(doc)} variant="subtitle2" color="secondary" className={classes.linkText}>
    //     {"Download"}
    //   </Typography>
    // </Button>
    return (
      <div className={classes.root}>
        <Paper className={classes.paperNoDocuments}>
          <div className={classes.alignCenter}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"You haven't created any documents yet."}
            </Typography>
          </div>
          <div className={classes.alignCenter}>
            <Link component={RouterLink} to="/dashboard/documents/create">
              <Button
                variant="contained"
                className={
                  clsx(classes.textAlignCenter, classes.margin, classes.removeTextTransform)
                }
                color="secondary"
              >
                <Typography variant="subtitle1">
                  {"Create Documents"}
                </Typography>
              </Button>
            </Link>
          </div>
        </Paper>
      </div>
    );
  }
}

// <TableCell>
//   <Link color="secondary" onClick={this.handleClick} type="submit">
//     <Typography variant="subtitle2" className={classes.margin}>
//       Fetch 8949
//                     </Typography>
//   </Link>
// </TableCell>

function mapStateToProps(state) {
  return {
    documents: {
      documentsCreated: state.documents.documentsCreated
    },
    exchangeAndTaxData: {
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements
    },
    api: {
      documentsCreating: state.api.documentsCreating
    },
    auth: {
      // csrfToken: state.auth.csrfToken,
      isPlanSufficient: state.auth.isPlanSufficient
    },
    pnl: {
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun,
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun,
    }
  };
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchDocumentForUser: PropTypes.func.isRequired,
  documents: PropTypes.shape({
    documentsCreated: PropTypes.array
  }),
  exchangeAndTaxData: PropTypes.shape({
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
    tradeDataByExchange: PropTypes.object
  }),
  api: PropTypes.shape({
    documentsCreating: PropTypes.array
  }),
  auth: PropTypes.shape({
    // csrfToken: PropTypes.string,
    isPlanSufficient: PropTypes.bool
  }),
  pnl: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.object,
    mostRecentReconcileRun: PropTypes.object,
  }),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { fetchDocumentForUser })
)(Documents);
