import {
  VALIDATE_DISCOUNT_CODE
} from 'actions/types';

const INITIAL_STATE = {
  reducePriceBy: 0
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VALIDATE_DISCOUNT_CODE:
      return {
        ...state,
        reducePriceBy: action.payload.reducePriceBy
      };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
