import React from 'react';


export default () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 775.94 156.63">
      <defs>
      </defs>
      <title>Digitax</title>
      <path style={{ fill: '#2cdb72' }} d="M48.34,61.15H34.62V47.43H48.34ZM10.13,31.55H23.84V17.84H10.13ZM98.21,17.83H83.67V31.55H98.21c38.73,0,39.37,43.75,39.38,45.6,0,15.64-4.24,27.71-12.61,35.87-12.67,12.37-31.06,12.07-31.22,12.06H48.34V77.15H34.62v61.64H94c4,0,24.9-.76,40.45-15.83,11.2-10.85,16.88-26.27,16.88-45.82a79.53,79.53,0,0,0-6.36-29C136.21,28.32,120,17.83,98.21,17.83Zm-25.64,0H34.62V31.55H72.57ZM91.25,61.15s5.25,0,8.86,3.42c2.65,2.54,4,8.26,4,13.76v.45c.09,1.26-.19,6.6-3,9.67-1.77,1.88-4.41,2.8-8.09,2.8H83.09V77H69.37v28H93c9.43,0,15-3.89,18.1-7.15,6.94-7.4,6.85-17.62,6.75-19.68,0-9.29-2.8-18.27-8.21-23.47a27.32,27.32,0,0,0-18.73-7.23h-32V61.16h32.4ZM183,138.85h16.63V17.61H183Zm538.3-63,41.05-58.19H742.78l-32,45.2-31.87-45.2h-19.4L700.69,76.5l-44.34,62.35h19.57l34.82-49,35.16,49h19.92ZM519.87,17.61H425.13V32.85h39v106h16.63v-106h39.14Zm70.5,0,53.69,121.24h-18L613.23,109.4H549.32l-12.65,29.45H519.18L573.05,17.61Zm16.45,76.73L581.19,35.45,555.9,94.34ZM271.68,37.53a47.33,47.33,0,0,1,23.91-6.24A52.08,52.08,0,0,1,314.9,35a52.8,52.8,0,0,1,16.71,10.47l9.53-12.12a66.43,66.43,0,0,0-20.87-12.3,70,70,0,0,0-24.68-4.5A66.15,66.15,0,0,0,263,24.71,61,61,0,0,0,239.82,47a59.17,59.17,0,0,0-8.49,31.09,61.29,61.29,0,0,0,31.35,53.77,63.89,63.89,0,0,0,32,8.23,73.09,73.09,0,0,0,24.42-4.33,73.44,73.44,0,0,0,21.48-11.6V79.61h-.08v-.88H295.68V93.37h30.21v22.27a57.87,57.87,0,0,1-14.63,7,50.73,50.73,0,0,1-15.5,2.52,46.77,46.77,0,0,1-23.9-6.33,47.37,47.37,0,0,1-17.32-17.14,46.93,46.93,0,0,1-.09-47.2A46.23,46.23,0,0,1,271.68,37.53ZM380.8,138.85h16.63V17.61H380.8Z" />
    </svg>
  );
}
