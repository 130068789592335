import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const Content = () => {
  return (
    <div>
      <Typography variant="h5">
        {"Terms"}
      </Typography>
      <List>
        <ListItem>
          {'This Terms of Service Agreement (the "Agreement") is a legally binding contract that shall govern our relationship with our Visitors and others who may interact or interface with Digitax, located at 515 Berger Road Easton, PA 18042 and our subsidiaries and affiliates, in association with the use of the Digitax app and website which includes www.digitax.io, (collectively "Digitax"), and its Services, which shall be defined below.'}
        </ListItem>
        <ListItem>
          {'This Agreement specifically incorporates by reference, the entirety of our Privacy Policy and Disclaimer.'}
        </ListItem>
      </List>
      <Typography variant="h5">
        {"Assent & Acceptance"}
      </Typography>
      <List>
        <ListItem>
          {'By using Digitax, you warrant that you have read and reviewed this Agreement and you agree to be bound by it. If you do not agree to be bound by this Agreement, please exit Digitax immediately. We only agree to provide use of Digitax and the Services to you if you assent to this Agreement.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Definitions"}
      </Typography>
      <List>
        <ListItem>
          {'"EFFECTIVE DATE": "Effective Date" means the date this Agreement comes into force and effect.'}
        </ListItem>

        <ListItem>
          {'"PARTIES": The parties to this Agreement are the owner and operator of Digitax, Digital Taxes Co, and you, as the Visitor or User of Digitax. Hereinafter, the parties will individually be referred to as "Party" and collectively as "Parties."'}
        </ListItem>

        <ListItem>
          {'"PARTIES": The parties to this Agreement are the owner and operator of Digitax, Digital Taxes Co, and you, as the Visitor or User of Digitax. Hereinafter, the parties will individually be referred to as "Party" and collectively as "Parties."'}
        </ListItem>

        <ListItem>
          "DIGITAX": The publisher, owner, and operator of Digitax is Digital Taxes Co. Digital Taxes Co shall be described as Owner or Operator. If Digitax's property shall be referred to through first-person pronouns, it shall be through the use of the following: us, we, our, ours, etc.
        </ListItem>

        <ListItem>
          {'"YOU": Should you agree to this Agreement and continue your use of Digitax, you will be referred to as either you, the Visitor, the User (if you have registered for any Services on Digitax), or if any second-person pronouns are required and applicable, such pronouns as "your", "yours", etc.'}
        </ListItem>

        <ListItem>
          {'"SERVICES": "Services" means any Services that Digitax makes available for use or sale.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Digitax Services"}
      </Typography>

      <List>
        <ListItem>
          {"Digitax is a dashboard for tracking cryptocurrency asset purchases, sales, transfers, and related taxes. Digitax also offers additional paid Services which include tax-related services and detailed transaction information. Digitax also offers referrals to regulated services such as access to third-party tax professionals."}
        </ListItem>
        <ListItem>
          {'Any and all visitors to Digitax, despite whether they are registered or not, shall be deemed "Visitors" of the Digitax Services, as described in this Agreement. Once an individual registers for the Services, through the process of creating an account, the Visitor shall then be considered a "User". All Users are also Visitors.'}
        </ListItem>
        <ListItem>
          {'At our sole and exclusive discretion, we may offer additional Services and/or products, or we may update, modify or revise any current content and Services. If we do so, this Agreement shall apply to any and all additional Services and/or products and any and all updated, modified or revised Services unless otherwise stipulated. We hereby reserve the right to cancel and cease offering any of the aforementioned Services and/or products at our discretion. You, as the end Visitor and/or User, acknowledge, accept and agree that we shall not be held liable for any such updates, modifications, revisions, suspensions or discontinuance of any of our Services and/or products. Your continued use of the Services provided, after such posting of any updates, changes, and/or modifications, shall constitute your acceptance of such updates, changes and/or modifications, and as such, frequent review of this Agreement and any and all applicable terms and policies should be made by you to ensure you are aware of all terms and policies currently in effect. Should you not agree to the updated, revised or modified terms, you must stop using Digitax and the provided Services immediately.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Age Restriction"}
      </Typography>

      <List>
        <ListItem>
          {"No minors under 18 years of age are permitted to use Digitax. By using Digitax as an individual, you represent and warrant that you are at least 18 years of age and may legally enter into a binding contract with Digitax. We disclaim any liability for any misrepresentation of your or any other User's age. You further represent and warrant that you are not prohibited from using Digitax under the laws of the United States or any applicable jurisdiction. We assume no responsibility or liability for any misrepresentation of your age or your legal authority to use Digitax."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Registration & Privacy"}
      </Typography>

      <List>
        <ListItem>
          {'When you register, Digitax may collect information such as your email address and depending on the Services you choose, other information, such as billing info and verification data or cryptocurrency wallet information. Once you register with Digitax and sign in to our Services, you are no longer anonymous to Us.'}
        </ListItem>
        <ListItem>
          {'As a User, you herein consent to the collection and use of the information provided, including the transfer of information within the United States and other countries for storage, processing or use by Digitax and/or our subsidiaries and affiliates.'}
        </ListItem>
        <ListItem>
          {'You can obtain further details about our collection, use, storage, and disclosure of data on our Privacy Policy.'}
        </ListItem>
      </List>


      <Typography variant="h5">
        {"Account and Security"}
      </Typography>

      <List>
        <ListItem>
          {'When you create an account, you are the sole authorized user of your account. You shall be responsible for maintaining the secrecy and confidentiality of your password and for all activities that transpire on or within your account.'}
        </ListItem>
        <ListItem>
          {'You are also responsible for ensuring the continued accuracy of any information you may provide to Us. Your registration information will allow you to use Digitax and the Services. You must not share such information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. Email notification will suffice to support@digitax.io. You are exclusively responsible for your account, including for any act or omission of any user(s) that may access your account, if such act or omission, when undertaken by you, would be deemed a violation of this Agreement.'}
        </ListItem>

        <ListItem>
          {'Providing false or inaccurate information, or using Digitax or the Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.'}
        </ListItem>

        <ListItem>
          {'You hereby acknowledge and agree that Digitax shall not be held liable for any loss and/or damage arising from any failure to comply with this Agreement.'}
        </ListItem>
      </List>
      
      <Typography variant="h5">
        {"Payment & Billing"}
      </Typography>

      <List>
        <ListItem>
          {'If you elect to purchase any of the paid Services available on Digitax, you will be asked for billing information, including, but not limited to, your credit card number and billing address. you may be asked for additional information, such as but not limited to, card security code or other information for billing or verification purposes.'}
        </ListItem>

        <ListItem>
          {'You may be also be asked to provide additional cryptocurrency portfolio and wallet information, and other information that will assist us in providing the Services to you. You may further be asked for API access for certain accounts which may be aggregated for you on Digitax.'}
        </ListItem>

        <ListItem>
          {'At the time of your election of paid Service, you will pay the amount required to access the Service.'}
        </ListItem>

        <ListItem>
          {'At the time of your purchase, you will be shown a breakdown of what you will be billed. You authorize us to charge your credit card for the amount shown to you at the time of your purchase.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Conduct"}
      </Typography>
      <List>
        <ListItem>
          {"As a Visitor or User of Digitax agree not to make use of Digitax's Services for the purpose of:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {'Implying any endorsement by Digitax;'}
            </ListItem>
            <ListItem>
              {"Disrupting the normal flow of communication, or otherwise acting in any manner that would negatively affect other Vistor's or User's ability to participate in any real-time interactions;"}
            </ListItem>
            <ListItem>
              {'Interfering with or disrupting any Digitax Services, servers and/or networks that may be connected or related to our website, including, but not limited to, the use of any device software and/or routine to bypass the robot exclusion headers;'}
            </ListItem>
            <ListItem>
              {"Violating any local, state, federal, national or international law, intentionally, or unintentionally."}
            </ListItem>
            <ListItem>
              {'Providing support or resources, concealing and/or disguising the character, location, and/or source to any organization delegated by the United States government as a "foreign terrorist organization" in accordance to Section 219 of the Immigration Nationality Act;'}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {'Digitax reserves the right to access, preserve and/or disclose User account information and/or content if it is requested to do so by law or in good faith belief that any such action is deemed reasonably necessary for:'}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"Compliance with any legal process, or Enforcement of this Agreement, or Responding to any intellectual property claim by any other Visitor, User or third party, or Responding to requests for customer service; or Protecting the rights, property or the personal safety of Digitax, its Visitors and Users, including the general public."}
            </ListItem>

            <ListItem>
              {"Digitax reserves the right to include the use of security components that may permit digital information or material to be protected. Such use of information and/or material is subject to usage guidelines and regulations established by Digitax or any other content providers supplying content services to Digitax. You are hereby prohibited from making any attempt to override or circumvent any of the embedded usage rules in our Services. Furthermore, unauthorized reproduction, publication, distribution, or exhibition of any information or materials supplied by our Services, despite whether done so in whole or in part, is expressly prohibited."}
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Global Use & Import/Export Compliance"}
      </Typography>
      <List>
        <ListItem>
          {"You hereby agree to comply with all local rules relating to online conduct and that which is considered acceptable content. Furthermore, you state that you:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"Are not on the list of prohibited individuals which may be identified on any government export exclusion report nor a User of any other government which may be part of an export-prohibited country identified in applicable export and import laws and regulations;"}
            </ListItem>
            <ListItem>
              {"Agree not to post, transfer nor upload any software, technology or any other technical data which would be in violation of the U.S. or other applicable export and/or import laws."}
            </ListItem>          
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Content"}
      </Typography>
      <List>
        <ListItem>
          {"The license to use, for the sole purposes of providing the Services to you, any financial or cryptocurrency information you may provide to Digitax, including but not limited to prior history of cryptocurrency investing, personal cryptocurrency portfolio data, and prior tax information. This license is nonexclusive and revocable at any time. You hereby expressly consent to allow us to use any personal information for the sole and express purpose of providing the Services to you. If you purchase any paid Services through which Digitax agrees to rebalance, through its automated investment tool, your cryptocurrency portfolio or portfolios, you hereby consent to and authorize Digitax to manage your portfolio or portfolios in such capacity."}
        </ListItem>
      </List>


      <Typography variant="h5">
        {"Indemnity"}
      </Typography>
      <List>
        <ListItem>
          {"You hereby agree to indemnify and hold Digitax, our subsidiaries, affiliates, agents, employees, officers, partners and/or licensors harmless against any and all claims or demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of Digitax or the Services, your breach of this Agreement, or your conduct or actions or the conduct or actions of any other Digitax User. You agree that Digitax shall be able to select its own legal counsel and may participate in its own defense."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Commercial Reuse"}
      </Typography>
      <List>
        <ListItem>
          {"You agree not to replicate, duplicate, copy, trade, sell, resell nor exploit for any commercial reason any part, use of, or access to Digitax's sites or apps."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Use and Storage"}
      </Typography>
      <List>
        <ListItem>
          {"You hereby acknowledge that Digitax may set up any such practices and/or limits regarding the use of our Services, including, without limitation, the maximum number of email messages that may be sent and/or received by any User, the maximum volume or size of any email message that may be sent from or may be received by an account on our Service, the maximum disk space allowable that shall be allocated on Digitax's servers on the User's behalf, and/or the maximum number of times and/or duration that any User may access Digitax's Services in a given period of time. You also hereby acknowledge that we reserve the right to delete or remove any account that is no longer active for an extended period of time. Furthermore, Digitax shall reserve the right to modify, alter and/or update these general practices and limits at our sole and exclusive discretion."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"License"}
      </Typography>
      <List>
        <ListItem>
          {'We may provide your with certain information as a result of your use of Digitax or the Services. Such information may include, but is not limited to, documentation, data, or information developed by the Us, and other materials which may assist in your use of Digitax or the Services (the "Materials"). Subject to this Agreement, we grant you a personal, non-exclusive, limited, non-transferable and revocable worldwide and royalty-free license to use the Materials solely in connection with your use of Digitax and the Services ("License"). Through this License, you may temporarily download one copy of the relevant, downloadable Materials (information or software) on our website or apps for personal, non-commercial transitory viewing only.'}
        </ListItem>
        <ListItem>
          {"You may not:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"Modify or copy the Materials;"}
            </ListItem>
            <ListItem>
              {"Use the Materials for any commercial purpose, or for any public display (commercial or non-commercial);"}
            </ListItem>  
            <ListItem>
              {"Attempt to decompile or reverse engineer any software contained on Digitax's website or apps;"}
            </ListItem>
            <ListItem>
              {"Remove any copyright or other proprietary notations from the Materials;"}
            </ListItem>
            <ListItem>
              {'Transfer the Materials to another person or "mirror" the Materials on any other server.'}
            </ListItem>
            <ListItem>
              {'Scan or probe the underlying structure of Digitax;'}
            </ListItem>
            <ListItem>
              {'Violate the security of Digitax or Services through any unauthorized access, circumvention of encryption, or any other tools to interference with any host, Visitor or network;'}
            </ListItem>
            <ListItem>
              {"Use bots, web crawlers, or any similar devices or online tools to access or index data from Digitax;"}
            </ListItem>
            <ListItem>
              {'Attempt to disrupt the experience of other Visitors or Users on Digitax in any way;'}
            </ListItem>
            <ListItem>
              {'Disseminate any virus or other bad code which could harm Digitax or the Services or any device of any Visitor or User.'}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {"This License shall automatically terminate if your violate any of these restrictions and may be terminated by Digitax at any time. This License also terminates upon your cessation of use of Digitax or the Services or otherwise at the termination of this Agreement."}
        </ListItem>
        <ListItem>
          {"Upon terminating your viewing of these materials or upon the termination of this License, you must destroy any downloaded materials in your possession whether in electronic or printed format."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Disclaimer"}
      </Typography>
      <List>
        <ListItem>
          {'Through your use of Digitax, your acknowledge and agree that information provided by Digitax is simply that, information, and should not be considered a substitute for legal advice, tax advice, audit advice, accounting advice, or brokerage advice under the guidance of a licensed professional. Further, the information provided herein should not be taken as financial planning or investment solicitation. No fiduciary relationship has been created between your and Digitax.'}
        </ListItem>
        <ListItem>
          {"You hereby understand and acknowledge that through your use of Digitax, you are not being represented by an attorney, certified financial planner, broker, or other regulated advisor. Certain aspects of the Digitax Services may provide access to such a professional or provide tax-related information, but if your are in need of legal or financial advice, including a review of any financial or tax decisions, we advise your to consult the appropriate advisor, such as your own attorney, accountant, or other professional."}
        </ListItem>
        <ListItem>
          {'You agree that your use of Digitax and Services is at your sole and exclusive risk and that any Services provided by Digitax are on an "As Is" basis. Digitax hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability.'}
        </ListItem>
        <ListItem>
          {"The maximum liability of Digitax arising from or relating to your use of Digitax or Services is limited to the greater of one hundred ($100) US Dollars or the amount you paid to Digitax in the last three (3) months. This applies to any and all claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Please see our full disclaimer here:"}
      </Typography>

      <Typography variant="h5">
        {"Limitations"}
      </Typography>
      <List>
        <ListItem>
          {"To the fullest extent permissible by law, Digitax, including any employees, affiliates, service providers, contractors or agents, shall not liable for any damages that may occur to you as a result of your use of Digitax or Services, even if a representative has been notified in any manner of the possibility of any such damage. This section applies to any and all claims by you, including, but not limited to, loss of data, loss of goodwill, lost profits or revenues, consequential, indirect, special exemplary or punitive damages, negligence, strict liability, fraud, or torts of any kind, whether such claims are direct or indirect and whether such claims are alleged to be from your use of Digitax, your interaction with another Visitor, or your interaction with any third-party."}
        </ListItem>
        <ListItem>
          {"Some or all of the limitations provided in this subsection may not be applicable to you, depending upon your jurisdiction."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Accuracy of Materials"}
      </Typography>
      <List>
        <ListItem>
          {"The materials appearing on Digitax could include technical, typographical, or photographic errors. Digitax does not warrant that any of the materials on its website or apps are accurate, complete or current. Digitax may make changes to the materials contained on its website or apps at any time without notice. However, Digitax does not make any commitment to update the materials."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Links"}
      </Typography>
      <List>
        <ListItem>
          {"Either Digitax or any third parties may provide links to other websites and/or resources on Digitax or through any of the Services. Thus, you acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and as such, we do not endorse nor are we responsible or liable for any content, products, advertising or any other materials, on or available from such external links, third party sites, or other resources. Furthermore, you acknowledge and agree that Digitax shall not be responsible or liable, directly or indirectly, for any such damage or loss which may be a result of, caused or allegedly caused by or in connection with the use of, or the reliance on, any such content, goods or Services made available on or through any such external link, third party site, or other resource."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Modifications"}
      </Typography>
      <List>
        <ListItem>
          {"Digitax may revise this Agreement at any time without notice. It is your responsibility to periodically check this page for any such modification, revision or amendment. Any changes made are assumed to have been accepted by your continued use of Digitax."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Downtime"}
      </Typography>
      <List>
        <ListItem>
          {"Digitax may need to interrupt your access to Digitax to perform maintenance or emergency services on a scheduled or unscheduled basis. you agree that your access to Digitax or the Services may be affected by unanticipated or unscheduled downtime, for any reason, but that Digitax shall have no liability for any damage or loss caused as a result of such downtime."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Proprietary Rights"}
      </Typography>
      <List>
        <ListItem>
          {"You hereby acknowledge and agree that Digitax's Services and any essential software that may be used in conjunction with Digitax's Services ('Software') contains proprietary and confidential material that is protected federal intellectual property rights and other applicable laws. Such material may be copyrighted or patented. Furthermore, you herein acknowledge and agree that any content which may be contained in any advertisements or information presented by and through our Services or by advertisers is protected by copyrights, trademarks, patents and/or other proprietary rights and laws. Therefore, except for that which is expressly permitted by applicable law or as authorized by Digitax or such applicable licensor, you agree not to alter, modify, lease, rent, loan, sell, distribute, transmit, broadcast, publicly perform and/or create any plagiaristic works which are based on or derived from Digitax Services (e.g. any content or Software), in whole or part."}
        </ListItem>
        <ListItem>
          {"Digitax has granted you personal, non-transferable and non-exclusive rights and/or a license to make use of our Software, as long as you do not, and shall not, allow any third party to duplicate, alter, modify, create or plagiarize work from, reverse engineer, reverse assemble or otherwise make an attempt to locate or discern any source code, sell, assign, sublicense, grant a security interest in and/or otherwise transfer any such right in the Software. Furthermore, you agree not to alter or change the Software in any manner, nature or form, and as such, not to use any modified versions of the Software, including and without limitation, for the purpose of obtaining unauthorized access to our Services. Lastly, you also agree not to access or attempt to access our Services through any means other than through the interface which is provided by Digitax for use in accessing our Services."}
        </ListItem>
      </List>


      <Typography variant="h5">
        {"Termination"}
      </Typography>
      <List>
        <ListItem>
          {"As a User of Digitax, you may cancel or terminate your account, associated email address and/or access to our Services via the settings page at any time."}
        </ListItem>
        <ListItem>
          {"As a User, you agree that Digitax may, without any prior notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"Any breach or violation of this Agreement or any other incorporated agreement, regulation and/or guideline;"}
            </ListItem>

            <ListItem>
              {"By way of requests from law enforcement or any other governmental agencies;"}
            </ListItem>

            <ListItem>
              {"The discontinuance, alteration and/or material modification to our Services, or any part thereof;"}
            </ListItem>

            <ListItem>
              {"Unexpected technical or security issues and/or problems;"}
            </ListItem>

            <ListItem>
              {"Any extended periods of inactivity;"}
            </ListItem>

            <ListItem>
              {"Any engagement by you in any fraudulent or illegal activities; and/or"}
            </ListItem>

            <ListItem>
              {"The nonpayment of any associated fees that may be owed by you in connection with your Digitax account Services."}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {"Furthermore, you herein agree that any and all terminations, suspensions, discontinuances, and or limitations of access for cause shall be made at our sole discretion and that we shall not be liable to you or any other third party with regards to the termination of your account, associated email address and/or access to any of our Services."}
        </ListItem>
        <ListItem>
          {"The termination of your account with Digitax shall include any and/or all of the following:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"Removal of any access to all or part of the Services offered within Digitax;"}
            </ListItem>
            <ListItem>
              {"Deletion of your password and any and all related information, files, and any such content that may be associated with or inside your account, or any part thereof; and"}
            </ListItem>
            <ListItem>
              {"Barring of any further use of all or part of our Services."}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {"If your account is terminated by Us, you will not be entitled to a refund of any money spent on Digitax. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Warranty Disclaimers"}
      </Typography>
      <List>
        <ListItem>
          {"You acknowledge and agree that:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"The use of digitax services and software are at your own risk. our services and software shall be provided on an as is and/or as available basis. digitax and our subsidiaries, affiliates, officers, employees, agents, partners and licensors expressly disclaim any and all warranties of any kind whether expressed or implied, including, but not limited to any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement."}
            </ListItem>
            <ListItem>
              {"Digitax and our subsidiaries, officers, employees, agents, partners and licensors make no such warranties that (i) digitax services or software will meet your requirements; (ii) digitax services or software shall be uninterrupted, timely, secure or error-free; (iii) that such results which may be obtained from the use of the digitax services or software will be accurate or reliable; (iv) quality of any products, services, any information or other material which may be purchased or obtained by you through our services or software will meet your expectations; and (v) that any such errors contained in the software shall be corrected."}
            </ListItem>
            <ListItem>
              {"Any information or material downloaded or otherwise obtained by way of digitax services or software shall be accessed by your sole discretion and sole risk, and as such you shall be solely responsible for and hereby waive any and all claims and causes of action with respect to any damage to your computer and/or internet access, downloading and/or displaying, or for any loss of data that could result from the download of any such information or material."}
            </ListItem>
            <ListItem>
              {"No advice and/or information, despite whether written or oral, that may be obtained by you from digitax or by way of or from our services or software shall create any warranty not expressly stated in the terms of service."}
            </ListItem>
            <ListItem>
              {"A small percentage of some users may experience some degree of epileptic seizure when exposed to certain light patterns or backgrounds that may be contained on a computer screen or while using our services. certain conditions may induce a previously unknown condition or undetected epileptic symptom in users who have shown no history of any prior seizure or epilepsy. should you, anyone you know or anyone in your family have an epileptic condition, please consult a physician if you experience any of the following symptoms while using our services: dizziness, altered vision, eye or muscle twitches, loss of awareness, disorientation, any involuntary movement, or convulsions."}
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Limitation of Liability"}
      </Typography>
      <List>
        <ListItem>
          {"You explicitly acknowledge, understand and agree that digitax and our subsidiaries, affiliates, officers, employees, agents, partners and licensors shall not be liable to you for any punitive, indirect, incidental, special, consequential or exemplary damages, including, but not limited to, damages which may be related to the loss of any profits, goodwill, use, data and/or other intangible losses, even though we may have been advised of such possibility that said damages may occur, and result from:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"The use or inability to use our service."}
            </ListItem>
            <ListItem>
              {'The cost of procuring substitute goods and services.'}
            </ListItem>
            <ListItem>
              {'Unauthorized access to or the alteration of your transmissions and/or data.'}
            </ListItem>
            <ListItem>
              {'Statements or conduct of any such third party on our service.'}
            </ListItem>
            <ListItem>
              {'And any other matter which may be related to our service.'}
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Release"}
      </Typography>
      <List>
        <ListItem>
          {"In the event you have a dispute, you agree to release Digitax (and its officers, directors, employees, parents, affiliates, partners and any other third parties) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out of or in any way connected to such dispute."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Financial Matters"}
      </Typography>
      <List>
        <ListItem>
          {"Digitax's Services are provided primarily for informational purposes. The Services shall not be a substitute for trading advice, investment advice, legal advice, or tax advice from a licensed professional. Certain of the paid Services may provide access to an advisor, but Digitax and our licensors shall not be responsible or liable for the accuracy or usefulness of any information transmitted and/or made available by way of our Services, and shall not be responsible or liable for any trading and/or investment decisions based on any such information."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Exclusion and Limitations"}
      </Typography>
      <List>
        <ListItem>
          {"There are some jurisdictions which do not allow the exclusion of certain warranties or the limitation of exclusion of liability for incidental or consequential damages. Therefore, some of the above limitations of sections warranty disclaimers and limitation of liability may not apply to you."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Third parties"}
      </Typography>
      <List>
        <ListItem>
          {"You herein acknowledge, understand and agree, that unless otherwise expressly provided in this Agreement, that there shall be no third-party beneficiaries to this Agreement."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Notice"}
      </Typography>
      <List>
        <ListItem>
          {"Digitax may furnish you with notices, including those with regards to any changes to this Agreement, through the following mediums, the list of which should be considered nonexhaustive: email, regular mail, MMS or SMS, text messaging, postings on our website or app, or other reasonable means currently known or which may be hereinafter developed. Any such notices may not be received if you violate any aspects of this Agreement by accessing our Services in an unauthorized manner. Your acceptance of this Agreement constitutes your agreement that you are deemed to have received any and all notices that would have been delivered had you accessed our Services in an authorized manner."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Trademark"}
      </Typography>
      <List>
        <ListItem>
          {"You herein acknowledge and agree that all of the Digitax trademarks, copyright, trade name, service marks, and other Digitax logos and any brand features, and/or product and service names are trademarks and as such, are and shall remain the property of Digitax. You agree not to display and/or use in any manner the Digitax logo or marks without obtaining Digitax's prior written consent."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Copyright and Intellectual Property"}
      </Typography>
      <List>
        <ListItem>
          {"Digitax will always respect the intellectual property of others, and we ask that all of our Visitors do the same. With regards to appropriate circumstances and at its sole discretion, Digitax may disable and/or terminate the accounts of any Visitor who violates this Agreement and/or infringes the rights of others. If you feel that your work has been duplicated in such a way that would constitute copyright infringement, or if you believe your intellectual property rights have been otherwise violated, you should provide us the following information:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"The electronic or the physical signature of the individual that is authorized on behalf of the owner of the copyright or other intellectual property interest;"}
            </ListItem>
            <ListItem>
              {"A description of the copyrighted work or other intellectual property that you believe has been infringed upon;"}
            </ListItem>
            <ListItem>
              {"A description of the location of the site which you allege has been infringing upon your work;"}
            </ListItem>
            <ListItem>
              {"Your physical address, telephone number, and email address;"}
            </ListItem>
            <ListItem>
              {"A statement, in which you state that the alleged and disputed use of your work is not authorized by the copyright owner, its agents or the law;"}
            </ListItem>
            <ListItem>
              {"And finally, a statement, made under penalty of perjury, that the aforementioned information in your notice is truthful and accurate, and that you are the copyright or intellectual property owner, representative or agent authorized to act on the copyright or intellectual property owner's behalf."}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {"Digitax Agent for notice of claims of copyright or other intellectual property infringement can be contacted at copyright@digitax.io."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Entire Agreement"}
      </Typography>
      <List>
        <ListItem>
          {"This Agreement constitutes the complete understanding between the Parties with respect to any and all use of Digitax or any of the Services rendered. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of Digitax. You may also be subject to additional terms and conditions when you use or purchase certain other Digitax Services, affiliate Services, third-party content or third-party software."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Arbitration"}
      </Typography>
      <List>
        <ListItem>
          {"In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration, under the Consumer Arbitration Rules of the American Arbitration Association. The arbitration shall be conducted in Easton, Pennsylvania. The arbitration shall be conducted by a single arbitrator and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by applicable and governing Federal law as well as the law of the state of Pennsylvania. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims, claims based on Federal and state law, and claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by Digitax will not be subject to arbitration and may, as an exception to this subpart, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims - i.e. you hereby understand and agree that you are giving up your right to a jury trial or other litigation to assert any claims against Digitax. Through this Agreement, you are also relinquishing any right to be engaged in a class action or other group proceeding against Digitax."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Governing Law"}
      </Typography>
      <List>
        <ListItem>
          {"Through your use of Digitax or the Services, you agree that the laws of the State of Pennsylvania shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between you and Digitax, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of the state and federal courts of Easton, Pennsylvania. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine."}
        </ListItem>
      </List>


      <Typography variant="h5">
        {"Waiver and Severability of Terms"}
      </Typography>
      <List>
        <ListItem>
          {"In the event that we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part. If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of this Agreement shall continue in full force."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"No Right of Survivorship Non-Transferability"}
      </Typography>
      <List>
        <ListItem>
          {"You acknowledge, understand and agree that your account is non-transferable and any rights to your ID and/or contents within your account shall terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents therein permanently deleted."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Claim Limitation"}
      </Typography>
      <List>
        <ListItem>
          {"You acknowledge, understand and agree that regardless of any statute or law to the contrary, any claim or action arising out of or related to the use of our Services or this Agreement must be filed within one year after said claim or cause of action arose or shall be forever barred."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"General Provisions"}
      </Typography>
      <List>
        <ListItem>
          {"LANGUAGE: All communications made or notices given pursuant to this Agreement shall be in the English language."}
        </ListItem>
        <ListItem>
          {"HEADINGS FOR CONVENIENCE ONLY: Headings of parts and subparts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement."}
        </ListItem>
        <ListItem>
          {"NO AGENCY, PARTNERSHIP OR JOINT VENTURE: No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties."}
        </ListItem>
        <ListItem>
          {"ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please email us at the following address: support@digitax.io."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Violations"}
      </Typography>
      <List>
        <ListItem>
          {"Please report any violations of these terms to Digitax at support@digitax.io."}
        </ListItem>
      </List>
    </div>
  );
}

export default Content;