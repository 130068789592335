import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import CloseIcon from '@material-ui/icons/Close';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-25%, -25%)`,
      top: `25%`,
      left: `35%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 25%)`,
    }
    // midWidth: '60%'
  },
  marginText: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  alignCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1)
  },
  marginLeftFour: {
    marginLeft: theme.spacing(4)
  },
  marginButtons: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  // dialog: {
  //   width: '60%'
  // },
  marginCenter: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  alignLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  instructionsContainer: {
    marginTop: theme.spacing(1)
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  exitToAppIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  instructionsText: {
    marginTop: '2px',
    marginRight: theme.spacing(0.5)
  },
  headerAlign: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    // paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  oauthLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  dot: {
    width: theme.spacing(1.2),
    height: 'auto',
    marginRight: theme.spacing(1)
  },
  errorIcon: {
    width: theme.spacing(3),
    height: 'auto',
    marginRight: theme.spacing(1)
  },
  title: {
    paddingBottom: '0px'
  },
  divider: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2)
  },
  removePaddingTop: {
    paddingTop: 0
  }
});

class _Modal extends React.Component {

  handleCloseWrapped = () => {
    this.props.handleClose();
  }

  renderTextField = ({ name, label }) => {
    const { fields } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.marginText} key={name}>
        <TextField
          id={name}
          name={name}
          variant="outlined"
          placeholder={label}
          label={label}
          value={fields[name]}
          onChange={this.handleChange(name)}
          autoComplete="off"
          fullWidth
        />
      </div>
    );
  }

  render() {
    const { classes, open, handleClose, apiMessage } = this.props;

    if (open) {
      return (
        <Dialog className={classes.dialog} open={open} onClose={handleClose}>
          <DialogTitle id="form-dialog-title" className={classes.title}>
            <div className={classes.headerAlign}>
              {/* <ErrorOutlineOutlinedIcon className={classes.errorIcon} /> */}
              <Typography variant="subtitle1" className={classes.marginLeftOne}>
                {apiMessage ? apiMessage.header : 'App Message:'}
              </Typography>
            </div>
          </DialogTitle>
          <Divider className={classes.divider} />
          <DialogContent className={classes.removePaddingTop}>
            <List>
              {(apiMessage && Array.isArray(apiMessage.bodyMessageUL)) ? apiMessage.bodyMessageUL.map((message, index) => {
                return (
                  <ListItem key={index}>
                    <FiberManualRecordIcon className={classes.dot} />
                    <Typography variant="body1" className={classes.fontHandleMobile}>
                      {message}
                    </Typography>
                  </ListItem>
                );
              }) : null}
            </List>
          </DialogContent>
        </Dialog>
      );
    }
    return null;
  }
}


export default withStyles(styles)(_Modal);