import { PNL_CALC_RUN, TRIGGER_CALCULATE, RECONCILE_RUN, ZERO_COST_BASIS } from '../actions/types';

const INITIAL_STATE = {
  mostRecentPnlCalcRun: null,
  mostRecentReconcileRun: null,
  triggerCalculate: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PNL_CALC_RUN:
      return { ...state, mostRecentPnlCalcRun: action.payload.mostRecentPnlCalcRun };
    case RECONCILE_RUN:
      return { ...state, mostRecentReconcileRun: action.payload.mostRecentReconcileRun };      
    case TRIGGER_CALCULATE: 
      return { ...state, triggerCalculate: action.payload };
    case ZERO_COST_BASIS:
      const _mostRecentPnlCalcRun = state.mostRecentPnlCalcRun
      _mostRecentPnlCalcRun.outcome = 'added_zero_cost_basis';
      return { ...state, mostRecentPnlCalcRun: _mostRecentPnlCalcRun };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
