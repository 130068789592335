import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { updatePassword, clearAPIAuthErrors } from 'actions';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';


const styles = theme => ({
  list: {
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    }
  },
  fullList: {
    width: 'auto',
  },
  margin: {
    margin: theme.spacing(2)
  },
  btnRoot: {
    flexGrow: 1,
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  btnClear: {
    marginRight: theme.spacing(2)
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  forceWidth: {
    width: '100%'
  },
  iconRoot: {
    marginTop: theme.spacing(5)
  },
  backButton: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});


const DRAWER_FIELDS = [
  { displayField: 'Old Password', stateField: 'oldPassword' },
  { displayField: 'New Password', stateField: 'newPassword' },
  { displayField: 'Confirm Password', stateField: 'confirmPassword' }
];

const INITIAL_STATE = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  errors: {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  },
  callbackMessage: '',
  disabled: true
};

// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

class PasswordDrawer extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  handleChange = name => event => {
    const { oldPassword, newPassword, confirmPassword, errors } = this.state;
    let disabled = true;

    if (errors[name] !== '') {
      errors[name] = '';
    }

    if ( oldPassword !== '' && newPassword !== '' && confirmPassword !== '' ) {
      disabled = false;
    }

    this.setState({ [name]: event.target.value, errors, disabled });
  };

  handleClear = event => {
    this.props.clearAPIAuthErrors();
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  validatePassword = ({ oldPassword, newPassword, confirmPassword }) => {
    const errors = {};

    if (newPassword.length === 0) {
      errors.newPassword = 'You must enter a new password to change it';
    }
    if (newPassword === oldPassword) {
      errors.newPassword = 'You new password is the same as your old password ';
    }
    if (newPassword && confirmPassword && newPassword !== confirmPassword ) {
      errors.newPassword = 'New passwords do not match'
    }
    if (newPassword && newPassword.length < 6) {
      errors.newPassword = 'Please enter a password of at least 6 characters'
    }

    return errors;
  }

  handleSaveSubmit = event => {
    // async below because we need to get our password hash from the server...
    // or the server handles the request entirely..
    const newErrors = this.validatePassword(this.state);

    if (JSON.stringify(newErrors) === "{}") {
      this.props.updatePassword({
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword
      });

      this.setState({ disabled: true });
    } else {
      const newStateErrors = { ...this.state.errors, ...newErrors };
      this.setState({ errors: newStateErrors, disabled: true });
    };
  }

  renderDrawerList = () => {
    const { classes, callbackMessage, errorMessage } = this.props;
    const { errors } = this.state;
    return (
      <div className={clsx(classes.list, classes.margin)}>
        <Typography variant="h4">
          {"Change your Password below:"}
        </Typography>
          {DRAWER_FIELDS.map(({ displayField, stateField }) => (
            <div key={stateField} className={classes.forceWidth}>
              <TextField
                id={`${stateField}-basic`}
                className={classes.textField}
                label={displayField}
                value={this.state[stateField]}
                onChange={this.handleChange(stateField)}
                type="password"
                error={!(errors[stateField] === '')}
              />
              {
                errors[stateField] !== '' ? (
                    <div>
                      <Typography color="error">
                        {errors[stateField]}
                      </Typography>
                    </div>
                  ) : null
              }
            </div>
          ))}
        {
          callbackMessage !== '' ? (
            <div>
              <Typography>
                {callbackMessage}
              </Typography>
            </div>
          ) : null
        }
        {
          JSON.stringify(errorMessage) !== "{}" ? (
            <div>
              <Typography color="error">
                {errorMessage}
              </Typography>
            </div>
          ) : null
        }
        <Divider />
      </div>
    );
  }
  
  render() {
    const { classes } = this.props;
    const { disabled } = this.state;
    return (
      <div>
        <SwipeableDrawer
          anchor="right"
          open={this.props.open}
          onClose={this.props.toggleDrawer(false)}
          onOpen={this.props.toggleDrawer(true)}
        >
          <Card variant="outlined" className={classes.margin}>
            {this.renderDrawerList()}
            <div className={clsx(classes.marginBottom, classes.btnRoot)}>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnClear}
                onClick={() => this.handleClear()}
              >
                {"Clear"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.handleSaveSubmit()}
                disabled={disabled}
              >
                {"Save"}
              </Button>
              <div className={clsx(classes.backButton, classes.hideOnDesktop)}>
                <Button
                  variant="outlined"
                  onClick={this.props.toggleDrawer(false)}
                >
                  {"Go back"}
                </Button>
              </div>
            </div>
          </Card>
        </SwipeableDrawer>
      </div>
    );
  }
}

// {/* <div>
//   <KeyboardArrowRight />
// </div> */}

PasswordDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  callbackMessage: PropTypes.string,
  errorMessage: PropTypes.object,
  updatePassword: PropTypes.func.isRequired,
  clearAPIAuthErrors: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    callbackMessage: state.auth.callbackMessage,
    errorMessage: state.auth.errorMessage
  };
}

export default compose(
  connect(mapStateToProps, { updatePassword, clearAPIAuthErrors }),
  withStyles(styles)
)(PasswordDrawer);
