import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(5)
  },
  text: {
    marginBottom: theme.spacing(2)
  }
});

class ProgressSpinner extends React.Component {
  render() {
    const { isInProgress, isSignoutInProgress, classes } = this.props;
    if (isInProgress || isSignoutInProgress) {
      return (
        <div className={classes.root}>
          <div className={classes.margin}>
            <Typography variant="body1" className={classes.text}>
              {
                isInProgress ?
                  "Just a few seconds, we're loading your information." :
                  "We're signing you out, just a few seconds."
              }
            </Typography>
            <CircularProgress color="primary" />
          </div>
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    isInProgress: state.api.isInProgress,
    isSignoutInProgress: state.api.isSignoutInProgress
  };
}

ProgressSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  isSignoutInProgress: PropTypes.bool.isRequired
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(ProgressSpinner);
