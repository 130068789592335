import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import exchangeImageLookup from 'pages/exchanges/utils/exchangeImageLookup';
import exchangesMap from 'modules/statics/exchangesMap';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  container: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  bottomRight: {
    alignSelf: 'flex-end',
    // marginLeft: 'auto'
  },
  size: {
    width: '100%',
    height: '7em'
  },
  // imgSize: {
  //   width: '8em',
  //   height: 'auto'
  // },
  alignItemsCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // verticalAlign: 'middle'
    // height: '100%'
  },
  height: {
    width: '100%',
    // minHeight: '200px',
    // paddingTop: '3em'
    // height: '7em',
    // paddingTop: '3em' 
  },
  imgSize: {
    marginTop: '2em',
    width: '80%',
    objectFit: 'contain',
    height: '8em'
  },
  borderTop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginTop: 'auto',
    // alignSelf: 'flex-end',
    borderTop: '1px solid black',
    width: '100%',
    minHeight: '3em'
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  checkMargin: {
    // paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  fetching: {
    marginTop: '0.4em'
  },
});

class ExchangePaper extends React.Component {
  render() {
    const { classes, exchange, isUploaded, isFetching } = this.props;
    return (
      <Paper className={clsx(classes.height, classes.alignItemsCenter)}>
        <img src={exchangeImageLookup(exchangesMap[exchange])} className={classes.imgSize} alt={exchange} />
        <div className={classes.borderTop}>
          <Typography variant="body2" display="inline" className={classes.removeTextTransform}>
            {isUploaded ? 'Completed': (isFetching ? 'Fetching...' : 'Click to Connect')}
          </Typography>
          {
            isUploaded ? (
              <CheckCircleIcon
                display="inline"
                className={classes.checkMargin}
                color="secondary"
              />
            ) : (
              isFetching ? (
                <div className={clsx(classes.fetching, classes.checkMargin)}>
                  <CircularProgress color="secondary" size={'1.3em'} />
                </div>
              ) : null
            )
          }
        </div>
      </Paper>
    );
    // return (
    //   <Paper className={clsx(classes.size, classes.container)}>
    //     <img src={exchangeImageLookup(exchangesMap[exchange])} className={classes.imgSize} alt={exchange} />
    //     <Divider />
    //     {/* <Typography variant="body1">
    //       {exchange}
    //     </Typography>
    //     {isUploaded ? (
    //       <div className={classes.bottomRight}>
    //         <CheckCircleIcon color="secondary" />
    //       </div>
    //     ) : null} */}
    //     {/* <Typography variant="subtitle1">
    //       {exchange}
    //     </Typography> */}
    //   </Paper>
    // );
  }
}

export default withStyles(styles)(ExchangePaper);