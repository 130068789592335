export const PDF_8949 = {
  type: '8949_pdf', label: 'Form 8949 PDF'
};
export const CSV_8949 = {
  type: '8949_csv', label: 'Form 8949 CSV'
};
export const TXT_8949 = {
  type: '8949_txt', label: 'Form 8949 TXT'
};
export const TURBOTAX_8949 = {
  type: '8949_turbotax', label: 'Form 8949 Turbo Tax'
};
export const SCHEDULE_1 = {
  type: 'schedule1', label: 'Schedule 1'
};
export const FBAR = {
  type: 'fbar', label: 'FBAR CSV'
};
export const INCOME_TAX_SUMMARY = {
  type: 'income_tax_summary', label: 'Income Tax Summary'
};

export const documentTypesMap = {
  [PDF_8949.type]: PDF_8949.label,
  [CSV_8949.type]: CSV_8949.label,
  [TXT_8949.type]: TXT_8949.label,
  [TURBOTAX_8949.type]: TURBOTAX_8949.label,
  [SCHEDULE_1.type]: SCHEDULE_1.label,
  [FBAR.type]: FBAR.label,
  [INCOME_TAX_SUMMARY.type]: INCOME_TAX_SUMMARY.label,
};