import React from 'react';
import { connect } from 'react-redux';
import SignUpForm from './components/SignUpForm';
import history from '../../history';

class SignUp extends React.Component {
  componentDidMount() {
    if (this.props.auth.email !== '') {
      history.push('/dashboard');
    }
  }

  render() {
    return (
      <SignUpForm />
    );
  }
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(SignUp);
