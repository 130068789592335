import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Dot from 'modules/components/Dot';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  height: {
    height: '8em'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imgSize: {
    width: '7em',
    height: 'auto'
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1.2),
      // marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '30%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  marginContainer: {
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  marginHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  marginSubHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  subHeader: {
    [theme.breakpoints.down('sm')]: {
      // marginLeft: '5%',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '20%',
      width: '60%',
    },
    marginBottom: theme.spacing(10)
    // textAlign: 'center'
  },
  marginBottomFirstDescription: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2)
  },
  marginBottomSecondDescription: {
    marginBottom: theme.spacing(5.5),
    marginRight: theme.spacing(2)
  },
  marginBottomThirdDescription: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2)
  },  
  marginDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  padding: {
    padding: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  alignStart: {
    alignItems: 'start'
  },
  underline: {
    textDecoration: 'underline'
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});


const taxDocuments = [
  { title: 'IRS Form 8949', description: ['Report of gains and losses of capital assets', "Demonstrates cost basis of purchases and proceeds from sales"] },
  { title: 'FBAR', description: ['Report of Certain Foreign Financial Accounts', 'Required if holding $10,000 worth of crypto in wallet or exchange outside of US at any point during the year'] }
];

class TaxDocuments extends React.Component {
  renderDocument = (document) => {
    const { classes } = this.props;

    return (
      <Paper key={document.title} className={classes.paper}>
        <Typography variant="h4" className={classes.textAlignCenter}>
          {document.title}
        </Typography>
        <Divider className={classes.marginDivider} />
        <List>
          {
            document.description.map((caption, index) => (
              <ListItem key={index} className={classes.paddingZero}>
                <Dot />
                <Typography variant="body2">
                  {caption}
                </Typography>
              </ListItem>
            ))
          }
        </List>
      </Paper>
    );
  }

  // At the top of the 2020 US tax return, you need to specify whether or not you traded cryptocurrency during the 2020 tax year."}

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.marginContainer}>
        <Typography variant="h1" className={clsx(classes.marginHeader, classes.alignCenter, classes.textAlignCenter)}>
          {"Crypto Tax Forms"}
        </Typography>
        <div className={clsx(classes.flex, classes.subHeader)}>
          <List>
            <ListItem className={classes.alignStart} key="text1">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                <Box display="inline" fontWeight="fontWeightBold">
                  {"The IRS is cracking down. "}
                </Box>
                {"  At the top of the 2020 US tax return, you need to specify whether or not you traded cryptocurrency during the 2020 tax year.  If you did, almost certainly the "}
                <Link
                  target="_blank"
                  href="https://www.irs.gov/instructions/i8949"
                  color="inherit"
                  className={classes.underline}
                >
                  {'IRS Form 8949'}
                </Link>
                {" will be required."}
              </Typography>
            </ListItem>
            <ListItem className={classes.alignStart} key="text2">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                {"Use TurboTax to file?  We've got you covered and will create the TurboTax friendly 8949 for you."}
              </Typography>
            </ListItem>
            <ListItem className={classes.alignStart} key="text3">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                {"The forms below could be required as addendums to your US Tax return.  Digitax will take away the hassle and produce them for you."}
              </Typography>
            </ListItem>            
          </List>
        </div>
        <div>
          <div className={classes.hideOnMobile}>
            <div className={classes.alignCenter}>
              {
                taxDocuments.map(document => this.renderDocument(document))
              }
            </div>
          </div>
          <div className={classes.hideOnDesktop}>
            <div>
              {
                taxDocuments.map(document => this.renderDocument(document))
              }
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(TaxDocuments);