import { WALLET } from '../../types';
import logger from 'modules/utils/logger';

// trade[0]: type,
// trade[1]: date,
// trade[2]: exchange,
// trade[3]: currency,
// trade[4]: amount,
// trade[5]: fee_amount,
// trade[6]: address_to,
// trade[7]: address_from,
// trade[8]: exchange_trade_id

export default ({ exchange, walletMovements }) => {
  let newData = [];
  let badTrades = [];

  let counter = 0;
  walletMovements.forEach(movement => {
    try {
      // handle header if they included one
      if (movement[1].toLowerCase() === 'type') {
        counter++;
        return
      }

      const error = validate(movement);
      const thisRecord = createTableData({
        error,
        datetime: movement[0],
        type: movement[1],
        exchange: movement[2],
        coin: movement[3],
        amount: movement[4],
        feeAmt: movement[5],
        addressTo: movement[6],
        addressFrom: movement[7],
        exchangeTradeId: null
      });

      if (JSON.stringify(error) !== '{}') {
        badTrades.push({ error, trade: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (err) {
      logger.info(`errored on cryptotaxadvisors upload on id: ${counter}`, err);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        walletMovements,
        type: WALLET,
        badTrades
      };
    }
  });

  if (badTrades.length === 0) {
    logger.info('cryptotaxadvisors wallet movements are valid');
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('cryptotaxadvisors wallet movements are invalid')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "cryptotaxadvisors: We have some wallet movements that look right, check some of them tho",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else {
    logger.info('all cryptotaxadvisors wallet movements are invalid');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  }
}

function validate(movement) {
  // const type = movement[0];
  const datetime = movement[0];
  const type = movement[1];
  const exchange = movement[2];
  const coin = movement[3];
  const amount = movement[4];
  const feeAmt = movement[5];

  const error = {};
  try {
    let testDate;
    if (Number.isInteger(datetime)) {
      // test for unix date
      testDate = new Date(datetime * 1000);
    } else {
      testDate = new Date(datetime);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  if (!type || !['withdrawal', 'deposit'].includes(type.toLowerCase())) {
    error.type = "Type must be either Withdrawal or Deposit";
  }
  // let found = false;
  // currencies.forEach(ccy => {
  //   if (ccy.ticker === coin) {
  //     found = true;
  //   }
  // });
  // if (!found) {
  //   error.coin = "We don't recognize this currency";
  // }
  if (!coin) {
    error.coin = "We need a cryptocurrency."
  }

  if (!exchange) {
    error.exchange = "We need an exchange.";
  }
  if (isNaN(amount)) {
    error.amount = "We need an amount.";
  }
  if (isNaN(feeAmt)) {
    error.exchangeFee = "We need an fee amount.  Place 0 if you don't know.";
  }

  return error;
}

function createTableData({
  error,
  counter,
  type,
  datetime,
  exchange,
  coin,
  amount,
  feeAmt,
  addressTo,
  addressFrom,
  exchangeTradeId
}) {
  let newDateTime = 'N/A';
  try {
    if (!error.datetime) {
      if (Number.isInteger(datetime)) {
        newDateTime = new Date(datetime * 1000)
      } else {
        newDateTime = new Date(datetime);
      }
    }
  } catch (err) {
    error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    newDateTime = 'N/A';
  }

  return {
    id: counter,
    dateTime: newDateTime,
    type,
    exchange,
    coin,
    amount: Math.abs(parseFloat(amount)),
    exchangeFee: Math.abs(parseFloat(feeAmt)),
    addressTo,
    addressFrom,
    exchangeTradeId,
    source: 'csv'
  };
}
