import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { updateAccountProps } from 'actions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import AreYouSureModal from './Modals/AreYouSure';


const styles = theme => ({
  gridRoot: {
    display: 'flex',
    flexGrow: 1,
  },
  gridPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: '100%',
    maxWidth: theme.spacing(100),
    justifyContent: 'space-between'
  },
  gridPaperMobile: {
    color: theme.palette.text.secondary,
    width: '100%',
    maxWidth: theme.spacing(100),
    justifyContent: 'space-between'
  },
  gridFieldMargin: {
    marginBottom: theme.spacing(2)
  },
  paperMargin: {
    marginBottom: theme.spacing(2)
  },
  gridTypography: {
    minWidth: '20%'
  },
  removeShadow: {
    boxShadow: 'none'
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 200
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      // width: '100%'
      minWidth: 180
    }
  },
  textFieldMobile: {
    marginLeft: theme.spacing(1),
    // width: '100%'
    minWidth: 180
  },
  inline: {
    display: 'inline-flex'
  },
  marginMobileText: {
    marginRight: theme.spacing(2)
  },
  pencilInvisble: {
    visibility: 'hidden'
  },
  flexBox: {
    display: 'flex',
    wrap: 'flex-wrap'
  },
  editButton: {
    marginLeft: 'auto',
    textTransform: 'none'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  removePadding: {
    padding: 0
  },
  paddingDesktop: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  },
  yourAccountWidth: {
    width: '30%',
    length: '100%'
  },
  accountFieldsWidth: {
    [theme.breakpoints.up('md')]: {
      width: '65%',
      length: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      length: '100%'
    }
  },
  removeTransform: {
    textTransform: 'none'
  },
});

const displayFields = [
  { displayField: 'Exchange Data', stateField: 'exchangeData' },
  { displayField: 'PNL Data', stateField: 'pnlData' },
  { displayField: 'Your Account', stateField: 'yourAccount' },
];


const INITIAL_STATE = {
  exchangeData: false,
  pnlData: false,
  yourAccount: true,
  disabled: {
    exchangeData: true,
    pnlData: true,
    yourAccount: true,
  },
  selectedProps: null,
  pencilInvisible: true,
  modalOpen: false
};

class AccountTab extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  componentDidMount() {
    const { tradeCount, movementCount, pnlTransactions } = this.props.exchangeAndTaxData;

    if (tradeCount > 0 || movementCount > 0) {
      this.setState({ exchangeData: true });
    }

    if (Array.isArray(pnlTransactions) && pnlTransactions.length > 0) {
      this.setState({ pnlData: true });
    }
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false, selectedProps: null });
  }

  deletePropsClick = (event, stateField) => {
    event.preventDefault();
    let selectedProps = '';
    if (stateField === 'exchangeData') {
      selectedProps = 'exchangeData';
    } else if (stateField === 'pnlData') {
      selectedProps = 'pnlData';
    } else if (stateField === 'yourAccount') {
      selectedProps = 'yourAccount';
    }
    this.setState({ modalOpen: true, selectedProps });
  }

  deletePropsClickModal = (accountProps) => {
    this.props.updateAccountProps({
      exchangeData: accountProps === 'exchangeData',
      pnlData: accountProps === 'pnlData',
      yourAccount: accountProps === 'yourAccount',
    });
  }

  renderRightHandGridItems = ({ displayField, stateField }) => {
    const { classes } = this.props;
    const { tradeCount, movementCount, pnlTransactions } = this.props.exchangeAndTaxData;

    let isDisabled = true;
    if (stateField === 'exchangeData' && (tradeCount > 0 || movementCount > 0)) {
      isDisabled = false;
    }

    if (stateField === 'pnlData' && (Array.isArray(pnlTransactions) && pnlTransactions.length > 0)) {
      isDisabled = false;
    }

    if (stateField === 'yourAccount') {
      isDisabled = false;
    }

    // this returns in the new mobile
    return (
      <Card key={displayField} className={clsx(classes.gridPaper, classes.paperMargin, classes.inline)}>
        <Typography className={classes.gridTypography} variant="body1">
          {displayField}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.removeTransform}
          disabled={isDisabled}
          onClick={(event) => this.deletePropsClick(event, stateField)}
        >
          {"Delete"}
        </Button>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    const { modalOpen, selectedProps } = this.state;

    return (
      <div className={clsx(classes.gridRoot, classes.paddingDesktop)}>
        <div className={clsx(classes.hideOnMobile, classes.yourAccountWidth)}>
          <Paper className={clsx(classes.gridPaper, classes.removeShadow)}>
            <Typography variant="subtitle1">
              {'Your Data'}
            </Typography>
          </Paper>
        </div>
        <div className={classes.accountFieldsWidth}>
          {displayFields.map(field => this.renderRightHandGridItems(field))}
        </div>
        <AreYouSureModal
          open={modalOpen}
          handleClose={this.handleModalClose}
          accountProps={selectedProps}
          handleClick={this.deletePropsClickModal}
        />
      </div>
    );
  }
}


AccountTab.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    pnlTransactions: PropTypes.array,
    movementCount: PropTypes.number,
    tradeCount: PropTypes.number,
  }),
  updateAccountProps: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      pnlTransactions: state.exchangeAndTaxData.pnlTransactions,
      tradeCount: state.exchangeAndTaxData.tradeCount,
      movementCount: state.exchangeAndTaxData.movementCount,
    },
  };
}

export default compose(
  connect(mapStateToProps, { updateAccountProps }),
  withStyles(styles)
)(AccountTab);
