import { WALLET } from '../../types';
import currencies from 'modules/statics/currencies';
import logger from 'modules/utils/logger';

// // binance data comes in like the following
// let array = [
//   0: Data(UTC), "2017-12-30 22:04:20"
//   1: Market, ( ie what is traded for what, no sapce ) "ICXETH",
//   2: Type, "BUY",
//   3: Price, "0.00746", // price of ICX per ETH
//   4: Amount, "231.17", // we're calling it size
//   5: Total, "1.7245282", // total of ETH being sold
//   6: Fee, "0.23117" // total fee of ICX to exchange
//   7: Fee Coin, "ICX"
// ]

export default ({ exchange, walletMovements }) => {
  let newData = [];
  let badTrades = [];
  let type;
  let exchangeFee;

  // walletMovements.forEach(arrayOfMovements => {
  let counter = -1;
  walletMovements.forEach(movement => {
    try {
      if (counter === -1) {
        counter += 1;
        return; // skip the header... perhaps make this nicer
      }

      const error = validate(movement);
      exchangeFee = parseFloat(!movement[3] ? 0.0 : movement[3]);
      type = exchangeFee === 0.0 ? 'deposit' : 'withdrawal';

      const thisRecord = createTableData(
        error,
        counter,
        movement[0], // datetime
        type,
        exchange,
        movement[1], // coin
        exchangeFee,
        movement[2], // amount
        type === 'withdrawal' ? movement[4] : '',
        type === 'deposit' ? movement[4] : '',
        movement[5]
      );

      if (JSON.stringify(error) !== "{}") {
        logger.info('error and movement below');
        logger.info(error, movement);
        badTrades.push({ error, movement: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on binance wallet upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData: newData,
        type: WALLET,
        badTrades
      };
    }
  });

  logger.info('newData', newData);
  if (badTrades.length === 0) {
    logger.info('ok here', newData[0]);
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('ah', newData[0]);
    return {
      isValid: true,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else {
    logger.info('wtf', newData[0]);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  }
}

// id, datetime, type, exchange, coin, amount, exchangefee, addressFrom, addressTo,
function validate(trade) {
  const datetime = trade[0];
  const coin = trade[1];
  const amount = trade[2];
  // const exchangeFee = trade[3];
  const addressTo = trade[4];
  // const transferId = trade[5];
  const status = trade[8];

  const error = {};

  try {
    let testDate;
    if (Number.isInteger(datetime)) {
      // test for unix date
      testDate = new Date(datetime * 1000);
    } else {
      testDate = new Date(datetime);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  let found = false;
  currencies.forEach(ccy => {
    if (ccy.ticker === coin) {
      found = true;
    }
  });
  if (!found) {
    error.coin = "We don't recognize this currency";
  }
  if (isNaN(amount)) {
    error.amount = "Amount needs to be a number";
  }
  if (!addressTo) {
    error.feeCoin = "AddressTo needs to exist";
  }
  // handle cancelled
  if (status !== 'Completed') {
    error.status = "Movement cancelled";
  }
  return error;
}

function createTableData(
  error,
  counter,
  datetime,
  type,
  exchange,
  coin,
  exchangeFee,
  amount,
  addressTo,
  addressFrom,
  exchangeTradeId
) {

  let newDateTime = 'N/A'
  if (!error.datetime) {
    if (Number.isInteger(datetime)) {
      newDateTime = new Date(datetime * 1000)
    } else {
      newDateTime = new Date(datetime);
    }
  }

  return {
    id: counter,
    dateTime: newDateTime,
    type,
    exchange,
    coin,
    amount: Math.abs(parseFloat(amount)),
    exchangeFee,
    addressTo,
    addressFrom,
    exchangeTradeId,
    source: 'csv'
  };
}
