import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from '@material-ui/core/TextField';
import plans from 'modules/statics/planTypesDisplay';
import InternalCheckout from './components/StripeCheckout';
import requireAuth from 'modules/components/requireAuth';
import {
  validateDiscountCode,
  upgradeAccountAfterPurchase,
  fetchDocumentsForUser,
  fetchUserWithCookie
} from 'actions';
import logger from 'modules/utils/logger';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Container } from '@material-ui/core';
import planTypesTransLimits from 'modules/statics/planTypesTransLimits';


const styles = (theme) => ({
  body: {
    height: '100%',
    paddingBottom: theme.spacing(12),
  },
  paddingHeader: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2)
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  flexCenter: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  flexSpaceBetween: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  widthContainer: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  widthContainerLarge: {
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  widthContainerSmall: {
    [theme.breakpoints.up('md')]: {
      width: '30%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  watermark: {
    margin: theme.spacing(2)
  },
  displayFlexFlexEnd: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      // justifyContent: 'flex-end'
    }
  },
  alignCenter: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  controlWidth: {
    // marginLeft: theme.spacing(10),
    // maxWidth: '800px',
    [theme.breakpoints.up('md')]: {
      // width: '85%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    },
  },
  marginTopMobile: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  marginTop: {
    marginTop: theme.spacing(1)
  },
  header: {
    margin: theme.spacing(2)
  },
  paymentForm: {
    backgroundColor: '#f2f2f2'
  },
  whiteBackground: {
    backgroundColor: '#ffffff'
  },
  underline: {
    textDecoration: 'underline'
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  linkText: {
    color: '#2b7b4c',
    cursor: 'pointer'
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  planBorder: {
    border: '2px solid #2cdb72'
  },
  planButton: {
    borderRadius: '12px',
    textTransform: 'none',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      width: '22%',
      // height: '100%',
      // marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // padding: theme.spacing(1.5)
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      width: '45%',
      marginTop: theme.spacing(1),
      // marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
      // padding: theme.spacing(1.5)
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // padding: theme.spacing(1.5)
    }
  },
  planCard: {
    padding: theme.spacing(1.5)
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  checkMargin: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  marginSubHeader: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  flexStart: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  fullWidth: {
    width: '100%'
  }
});

const checkoutLineItems = [
  {
    name: 'planName', description: 'Access Package',
  },
  {
    name: 'fbar', description: 'FBAR Download'
  },
  {
    name: 'addTransactions', description: 'Add 25,000 Transactions'
  },
  {
    name: 'priorPurchasePrice', description: 'Prior Plan Purchase'
  },
  {
    name: 'discountCode', description: 'Discount Code'
  },
  {
    name: 'total', description: 'Total'
  }
];

function capitalize(string) {
  if (string.length > 1) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return string;
}

class UpgradeForm extends React.Component {
  state = {
    isFbarAdded: false,
    areExtraTransactionsAdded: false,
    quantityOfExtraTransactions: 0,
    total: 0,
    discountCode: '',
    priorPurchasePrice: 0,
    priorPurchasePlan: null,
    selectedPlan: null,
  };

  componentDidMount() {
    // const { planName } = this.props.match.params;
    // if (!(planName in plans)) {
    //   return;
    // }
    const _planName = this.props.auth.planName;
    const priorPurchasePrice = parseFloat(plans[_planName].price);
    if (!isNaN(priorPurchasePrice)) {
      this.setState({ priorPurchasePrice: priorPurchasePrice, priorPurchasePlan: _planName });
    } else {
      this.setState({ priorPurchasePrice: 0, priorPurchasePlan: 'free' });
    }

    // const originalPrice = parseFloat(plans[planName].price);
    // const finalPrice = originalPrice - priorPurchasePrice;
    // this.setState({ total: finalPrice.toString() });
  }

  handleFbarClick = () => {
    const { isFbarAdded } = this.state;
    this.setState({ isFbarAdded: !isFbarAdded });
    let { total } = this.state;
    if (!isFbarAdded) {
      this.setState({ total: (parseFloat(total) + 50.0).toFixed(2).toString() });
    } else {
      if (parseFloat(total) - 50.0 > 0) {
        this.setState({ total: (parseFloat(total) - 50.0).toFixed(2).toString() });
      } else {
        this.setState({ total: (0).toFixed(2).toString() });
      }
    }
  }

  handleAddExtraTransactionsClick = () => {
    const { quantityOfExtraTransactions, total } = this.state;

    let _quantityOfExtraTransactions = quantityOfExtraTransactions + 1;

    this.setState({
      areExtraTransactionsAdded: true,
      quantityOfExtraTransactions: _quantityOfExtraTransactions,
      total: (parseFloat(total) + 100.0).toFixed(2).toString()
    });
  }

  handleRemoveExtraTransactionsClick = () => {
    const { areExtraTransactionsAdded, quantityOfExtraTransactions, total } = this.state;

    let _quantityOfExtraTransactions = quantityOfExtraTransactions;

    if (_quantityOfExtraTransactions > 0) {
      _quantityOfExtraTransactions -= 1;
    }

    let _areExtraTransactionsAdded = areExtraTransactionsAdded;
    if (_quantityOfExtraTransactions === 0) {
      _areExtraTransactionsAdded = false
    } else {
      _areExtraTransactionsAdded = true
    }
    let _total = parseFloat(total) - 100.0;
    if (_total < 0) {
      _total = 0;
    }
    this.setState({
      areExtraTransactionsAdded: _areExtraTransactionsAdded,
      quantityOfExtraTransactions: _quantityOfExtraTransactions,
      total: _total.toFixed(2).toString()
    });
  }

  handleDiscountChange = ({ target: { value } }) => {
    this.setState({ discountCode: value });
  }

  handleApplyDiscountCodeClick = () => {
    const { reducePriceBy } = this.props;
    if (reducePriceBy > 0) {
      logger.info('discount code has already been applied, not allowing again');
      return;
    }
    this.props.validateDiscountCode(this.state.discountCode);
  }

  handleCardClick = (planName) => {
    const { selectedPlan } = this.state;
    const { priorPurchasePrice } = this.state;
    if (planName !== selectedPlan) {
      const originalPrice = parseFloat(plans[planName].price);
      const finalPrice = originalPrice - parseFloat(priorPurchasePrice);
      this.setState({
        total: finalPrice < 0 ? '0.00' : finalPrice.toString(),
        selectedPlan: planName,
      });
    } else {
      this.setState({
        total: '0.00',
        selectedPlan: null,
        quantityOfExtraTransactions: 0,
        areExtraTransactionsAdded: false,
      });
    }
  }

  renderItemsToCheckout = () => {
    const { classes, reducePriceBy } = this.props;
    // const { planName } = this.props.match.params;
    const {
      total,
      // priorPurchasePrice,
      // priorPurchasePlan,
      selectedPlan,
      quantityOfExtraTransactions
    } = this.state;
    const { taxYear } = this.props.exchangeAndTaxData;
    let _total = total;
    if (reducePriceBy > 0) {
      _total -= reducePriceBy;
    }

    // const _planName = this.props.auth.planName;
    const priorPurchasePlan = this.props.auth.planName;
    const priorPurchasePrice = parseFloat(plans[this.props.auth.planName].price);

    return (
      <Card variant="outlined">
        <Typography variant="h3" className={clsx(classes.marginBottom, classes.header)}>
          {"Checkout"}
        </Typography>
        <Divider />
        <Table>
          {/* <TableHead>
            <TableRow>
              <TableCell component='th' scope='row' align='left'>
                <Typography variant="h5">
                  {"Item"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">
                  {"Price"}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {
              checkoutLineItems.map(({ name, description }) => {
                if (name === 'planName' && this.props.auth.planName !== 'professional') {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography variant="subtitle2" display="inline">
                          {`${description}: `}
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          <Link component={RouterLink} to="/calculate/plan" color="primary" className={clsx(classes.linkTextColor, classes.underline)}>
                            {plans[selectedPlan] ? `${plans[selectedPlan].header}` : 'Select a plan'}
                          </Link>
                          {`  (${taxYear})`}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        <Typography display="inline">
                          {plans[selectedPlan] ? `$ ${plans[selectedPlan].price}.00` : '0.00'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                } else if ((name === 'fbar' && this.props.auth.planName !== 'free' && !this.props.auth.hasPurchasedFbarForTaxYear) || (name === 'fbar' && this.props.auth.planName === 'free' && selectedPlan && !this.props.auth.hasPurchasedFbarForTaxYear)) {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography
                          variant="subtitle2"
                          className={classes.grow}
                          display="inline"
                        >
                          {description}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        {
                          this.state.isFbarAdded ?
                            (
                              <div>
                                <Typography
                                  display="inline"
                                  className={clsx(classes.linkTextColor, classes.removeTextTransform)}
                                  onClick={() => this.handleFbarClick()}
                                >
                                  <Box display="inline" component="span" fontWeight="fontWeightBold">
                                    {"Remove"}
                                  </Box>
                                </Typography>
                                <Typography display="inline" component="span" className={classes.marginLeft}>
                                  {'$ 50.00'}
                                </Typography>
                              </div>
                            ) :
                            (
                              <Typography
                                display="inline"
                                className={clsx(classes.linkTextColor, classes.removeTextTransform)}
                                onClick={() => this.handleFbarClick()}
                                component={'span'}
                              >
                                <Box component="span" fontWeight="fontWeightBold">
                                  {"Add"}
                                </Box>
                              </Typography>
                            )
                        }
                      </TableCell>
                    </TableRow>
                  );
                } else if ((name === 'addTransactions' && selectedPlan === 'professional') || (name === 'addTransactions' && this.props.auth.planName === 'professional')) {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography
                          variant="subtitle2"
                          className={classes.grow}
                          display="inline"
                        >
                          {`${description} (${quantityOfExtraTransactions})`}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        {
                          this.state.areExtraTransactionsAdded ?
                            (
                              <div>
                                <ButtonGroup  size="small" aria-label="small outlined button group">
                                  <Button onClick={this.handleAddExtraTransactionsClick}>+</Button>
                                  <Button disabled>{this.state.quantityOfExtraTransactions}</Button>
                                  <Button onClick={this.handleRemoveExtraTransactionsClick}>-</Button>
                                </ButtonGroup>
                                <Typography display="inline" component="span" className={classes.marginLeft}>
                                  {`$ ${quantityOfExtraTransactions * 100.00}`}
                                </Typography>
                              </div>
                            ) : (
                              <Typography
                                display="inline"
                                className={clsx(classes.linkTextColor, classes.removeTextTransform)}
                                onClick={this.handleAddExtraTransactionsClick}
                              >
                                <Box component="span" fontWeight="fontWeightBold">
                                  {"Add"}
                                </Box>
                              </Typography>
                            )
                        }
                      </TableCell>
                    </TableRow>
                  );
                } else if (selectedPlan && name === 'priorPurchasePrice' && priorPurchasePrice > 0) {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography
                          variant="subtitle2"
                          className={classes.grow}
                          display="inline"
                        >
                          {`${description}: `}
                        </Typography>
                        <Typography variant="subtitle2" display="inline">
                          <Link component={RouterLink} to="/calculate/plan" color="primary" className={clsx(classes.linkTextColor, classes.underline)}>
                            {plans[priorPurchasePlan] ? `${plans[priorPurchasePlan].header}` : 'Select a plan'}
                          </Link>
                          {` (${taxYear})`}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        <Typography display="inline">
                          {`$ -${priorPurchasePrice.toFixed(2)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                }
                else if (name === 'total') {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography
                          variant="subtitle2"
                          className={classes.grow}
                          display="inline"
                        >
                          {description}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        <Typography display="inline">
                          {`$ ${parseFloat(_total).toFixed(2)}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                } else if (name === 'discountCode' && reducePriceBy > 0) {
                  return (
                    <TableRow key={name}>
                      <TableCell component='th' scope='row' align='left'>
                        <Typography
                          variant="subtitle2"
                          className={classes.grow}
                          display="inline"
                        >
                          {description}
                        </Typography>
                      </TableCell>
                      <TableCell scope='row' align="right">
                        <Typography display="inline">
                          {`-$ ${reducePriceBy}.00`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })
            }
          </TableBody>
        </Table>
      </Card>
    );
  }

  // unused now becuase of the sweet counter but didn't want to delete it... :/
//                                 <Typography
//   display="inline"
//   className={clsx(classes.linkTextColor, classes.removeTextTransform)}
//   onClick={this.handleAddExtraTransactionsClick}
// >
//   <Box component="span" fontWeight="fontWeightBold">
//     {"Add Again "}
//   </Box>
// </Typography>

//                                 <Typography
//   display="inline"
//   className={clsx(classes.linkTextColor, classes.removeTextTransform, classes.marginLeft)}
//   onClick={this.handleRemoveExtraTransactionsClick}
// >
//   <Box display="inline" component="span" fontWeight="fontWeightBold">
//     {"Remove"}
//   </Box>
// </Typography>

  renderWatermark = () => {
    const { classes } = this.props;
    return (
      <div className={classes.watermark}>
        <Typography variant="caption">
          {"By clicking 'Pay', you agree to Digitax's "}
          <Link component={RouterLink} to="/termsofservice" className={clsx(classes.linkTextColor, classes.underline)}>
            {'terms of service'}
          </Link>
          {' and '}
          <Link component={RouterLink} to="/privacypolicy" className={clsx(classes.linkTextColor, classes.underline)}>
            {'privacy policy'}
          </Link>
          {' and agree to have your card charged.'}
        </Typography>
      </div>
    );
  }

  renderDiscountInput = () => {
    const { classes } = this.props;
    const { discountCode } = this.state;
    return (
      <div className={classes.marginBottom}>
        <TextField
          label={'Discount Code'}
          id={'discount-code-label'}
          value={discountCode}
          onChange={(event) => this.handleDiscountChange(event)}
          placeholder="Enter code"
          variant="outlined"
          fullWidth
          className={classes.whiteBackground}
        />
      </div>
    );
  }

  renderApplyDiscountLink = () => {
    const { classes } = this.props;
    const { discountCode } = this.state;

    return (
      <Button
        className={clsx(classes.marginBottom, classes.removeTextTransform)}
        onClick={() => this.handleApplyDiscountCodeClick()}
        variant="contained"
        color="primary"
        disabled={discountCode.length === 0}
      >
        {"Apply"}
      </Button>
    );
  }

  renderCheckoutButton = () => {
    // idk if this is needed here, or if it might be needed instead at the success redirect, prob success redirect imo
    const { classes } = this.props;
    // const { upgradeAccountAfterPurchase } = this.props
    let { taxYear } = this.props.exchangeAndTaxData;
    logger.info(`the tax year is ${taxYear}`);
    if (taxYear == null) {
      const year = localStorage.getItem('digitax_tax_year');
      if (year) {
        taxYear = year;
      } else {
        taxYear = '2019';
      }
    }
    const { auth } = this.props;
    logger.info('auth object is %O', auth);
    const { firstName, lastName, usaState } = auth;
    const csrfToken = sessionStorage.getItem('digitax-csrf-token');
    let { isFbarAdded, areExtraTransactionsAdded, quantityOfExtraTransactions } = this.state;
    return (
      <div>
        <Divider className={classes.marginBottom} />
        <InternalCheckout
          planName={this.state.selectedPlan}
          isFbarAdded={isFbarAdded}
          currentPlanName={this.props.auth.planName}
          areExtraTransactionsAdded={areExtraTransactionsAdded}
          quantityOfExtraTransactions={quantityOfExtraTransactions}
          taxYear={taxYear}
          firstName={firstName}
          lastName={lastName}
          usaState={usaState}
          csrfToken={csrfToken}
          dispatch={this.props.dispatch}
        />
        <Divider className={classes.marginBottom} />
      </div>
    )
  }
  /*
    // when full, change color of text
    renderPaymentForm = () => {
      const { classes, reducePriceBy, upgradeAccountAfterPurchase } = this.props;
      const { planName } = this.props.match.params;
      let { taxYear } = this.props.exchangeAndTaxData;
  
      logger.info(`the tax year is ${taxYear}`);
      if (taxYear == null) {
        const year = localStorage.getItem('digitax_tax_year');
        if (year) {
          taxYear = year;
        } else {
          taxYear = '2019';
        }
      }
  
      logger.info(`the changed tax year is ${taxYear}`);
      const { auth } = this.props;
      logger.info('auth object is %O', auth);
      const { firstName, lastName, usaState } = auth;
      logger.info(`firstName is ${firstName} lastname is ${lastName} and usaState is ${usaState}`);
      let { total, isFbarAdded, areExtraTransactionsAdded, discountCode } = this.state;
      if (reducePriceBy > 0) {
        total -= reducePriceBy;
      }
      return (
        <Card variant="outlined" className={classes.paymentForm}>
          <CardContent>
            <Typography variant="h5" className={classes.marginBottom}>
              {"Payment Details"}
            </Typography>
            <Divider className={classes.marginBottom} />
            {this.renderDiscountInput()}
            {this.renderApplyDiscountLink()}
            <Checkout
              total={parseFloat(total)}
              planName={planName}
              upgradeAccountAfterPurchase={upgradeAccountAfterPurchase}
              isFbarAdded={isFbarAdded}
              areExtraTransactionsAdded={areExtraTransactionsAdded}
              discountCode={discountCode}
              taxYear={taxYear}
              firstName={firstName}
              lastName={lastName}
              usaState={usaState}
              csrfToken={auth.csrfToken}
              dispatch={this.props.dispatch}
            />
          </CardContent>
          <Divider className={classes.marginBottom} />
          {this.renderWatermark()}
        </Card>
      );
    }*/

  renderPlan = (planName, priorPurchasePrice) => {
    const { classes } = this.props;
    const { selectedPlan } = this.state;

    const isDisabled = priorPurchasePrice >= plans[planName].price;

    return (
      <Button
        key={planName}
        variant="outlined"
        disableElevation
        disableTouchRipple
        disabled={isDisabled}
        className={clsx(classes.planButton, { [classes.planBorder]: planName === selectedPlan })}
        onClick={(event) => {
          event.preventDefault(); // see solution posted by jboxxx https://stackoverflow.com/questions/24501497/why-the-onclick-element-will-trigger-twice-for-label-element/67188157#67188157
          this.handleCardClick(planName);
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label={planName}
            name={planName}
            value={selectedPlan}
          >
            <FormControlLabel
              value={isDisabled ? "disabled" : planName}
              className={classes.alignTogether}
              control={
                <Radio color="secondary" />
              }
              disabled={isDisabled}
              label={(
                <div className={classes.paddingBottom}>
                  <Typography className={classes.fullWidth} variant="h5">
                    {plans[planName].header}
                  </Typography>
                  <Typography variant="body2" className={clsx(classes.fullWidth, classes.marginTop)}>
                  {`${planTypesTransLimits[planName].maxTransactions.toLocaleString()} transactions`}
                  </Typography>
                  <Typography variant="body2" className={clsx(classes.fullWidth, classes.marginTop)}>
                    {`$${plans[planName].price}`}
                  </Typography>
                </div>
              )}
            />
          </RadioGroup>
        </FormControl>
      </Button>
    );
  }

        // <Card key={planName} variant="outlined" className={clsx(classes.planCard)}>
        // </Card>

  selectPlan = (planName) => {
    const originalPrice = parseFloat(plans[planName].price);
    const currentPlanName = this.props.auth.planName;
    const priorPurchasePrice = parseFloat(plans[currentPlanName].price).toFixed(2);

    this.setState({ priorPurchase: priorPurchasePrice.toString() });
    const finalPrice = originalPrice - priorPurchasePrice;
    this.setState({ total: finalPrice.toString() });
  }

  renderAllPlanAttributes = () => {
    const { classes } = this.props;

    return (
      <Card variant="outlined">
        <Typography variant="subtitle1" className={classes.marginSubHeader}>
          <Box component="span" fontWeight="fontWeightMedium">
            {"Included With All Plans"}
          </Box>
        </Typography>
        <Divider className={classes.marginSubHeader} />
        <div className={classes.alignTogether}>
          <CheckCircleIcon
            display="inline"
            className={classes.checkMargin}
            color="secondary"
          />
          <Typography>
            {"8949 Forms"}
          </Typography>
        </div>
        <div className={classes.alignTogether}>
          <CheckCircleIcon
            display="inline"
            className={classes.checkMargin}
            color="secondary"
          />
          <Typography>
            {"TurboTax 8949 Support"}
          </Typography>
        </div>
        <div className={classes.alignTogether}>
          <CheckCircleIcon
            display="inline"
            className={classes.checkMargin}
            color="secondary"
          />
          <Typography>
            {"Schedule One"}
          </Typography>
        </div>
        <div className={classes.alignTogether}>
          <CheckCircleIcon
            display="inline"
            className={classes.checkMargin}
            color="secondary"
          />
          <Typography>
            {"Income Tax Summary"}
          </Typography>
        </div>
        <div className={classes.alignTogether}>
          <CheckCircleIcon
            display="inline"
            className={classes.checkMargin}
            color="secondary"
          />
          <Typography>
            {"Unlimited Revisions"}
          </Typography>
        </div>
      </Card>
    )
  }

  render() {
    const { classes } = this.props;
    const currentPlanName = this.props.auth.planName;
    const priorPurchasePrice = parseFloat(plans[currentPlanName].price);

    const {
      exchangeAndTaxData: {
        tradeDataByExchange,
        taxYear,
        totalTransactions
      }
    } = this.props;

    let hasPnlTransactionsThisTaxYear = false;


    const taxYearStartTimestamp = new Date(`${taxYear}-01-01`).getTime();
    const taxYearEndTimestamp = new Date(`${taxYear + 1}-01-01`).getTime();

    Object.keys(tradeDataByExchange).forEach(exchange => {
      tradeDataByExchange[exchange].forEach(({ trades }) => {
        trades.some(trade => {
          trade.timestamp = new Date(trade.tradeDateTime).getTime();
          if (trade.timestamp >= taxYearStartTimestamp && trade.timestamp < taxYearEndTimestamp) {
            if (trade.side === 'BUY' && trade.product.slice(-4) === '-USD') {
              // skip as we're buying a crypto with usd
            } else {
              hasPnlTransactionsThisTaxYear = true;
              return true;
            }
          }
          return false;
        });
      });
    });

    // need to add in purchases here.
    let recommendedPlanName = 'premium';
    Object.keys(planTypesTransLimits).some(_planName => {
      if (planTypesTransLimits[_planName].maxTransactions > totalTransactions) {
        recommendedPlanName = _planName;
        return true;
      }
      return false;
    });

    let shouldGetFBAR = false;
    if (!hasPnlTransactionsThisTaxYear && totalTransactions > 0) {
      recommendedPlanName = 'free';
      Object.keys(tradeDataByExchange).forEach(exchange => {
        if (['binance', 'bitstamp'].includes(exchange)) {
          shouldGetFBAR = true;
        }
      });
    }

    return (
      <Paper className={classes.body}>
        <Container maxWidth="md">
          <Typography variant="h3" component="h1" className={classes.paddingHeader}>
            {`Choose your tax plan`}
          </Typography>
          <Typography variant="subtitle2">
            {`Current Plan: ${capitalize(currentPlanName)} / Recommended: ${capitalize(recommendedPlanName)} ${shouldGetFBAR ? ' and FBAR' : ''}`}
          </Typography>
          <div className={clsx(classes.marginBottom, classes.flexSpaceBetween)}>
            {Object.keys(plans).map(plan => this.renderPlan(plan, priorPurchasePrice))}
          </div>
          <div className={clsx(classes.marginBottom, classes.flex)}>
            <div className={classes.widthContainerLarge}>
              <div className={clsx(classes.displayFlexFlexEnd, classes.alignCenter)}>
                <div className={clsx(classes.marginRight, classes.controlWidth)}>
                  {this.renderItemsToCheckout()}
                  {this.renderCheckoutButton()}
                  <Typography variant="subtitle2">
                    {"You will be redirected to a secure payment page hosted by Stripe. This is a one-time payment."}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.widthContainerSmall}>
              <div className={classes.alignCenter}>
                <div className={clsx(classes.controlWidth, classes.marginTopMobile)}>
                  {this.renderAllPlanAttributes()}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Paper>
    );
  }
  /*
    render() {
      const { classes } = this.props;
      const { planName } = this.props.match.params;
  
      if (!(planName in plans)) {
        history.push('/notfound');
        return <CircularProgress />;
      }
  
      return (
        <Paper className={classes.body}>
          <div className={clsx(classes.textAlignCenter, classes.alignCenter)}>
            <Typography variant="h3" component="h1" className={classes.paddingHeader}>
              {`Upgrade Account: ${_.upperFirst(planName)}`}
            </Typography>
          </div>
          <Divider className={classes.marginBottom} />
          <div className={clsx(classes.marginBottom, classes.flex)}>
            <div className={classes.widthContainer}>
              <div className={clsx(classes.displayFlexFlexEnd, classes.alignCenter)}>
                <div className={clsx(classes.marginRight, classes.controlWidth)}>
                  {this.renderItemsToCheckout()}
                </div>
              </div>
            </div>
            <div className={classes.widthContainer}>
              <div className={classes.alignCenter}>
                <div className={clsx(classes.controlWidth, classes.marginTopMobile)}>
                  {this.renderPaymentForm()}
                </div>
              </div>
            </div>
          </div>
          <Divider className={classes.marginTop} />
        </Paper>
      );
    }*/
}

UpgradeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  invalidDiscountCode: PropTypes.string,
  discountMessage: PropTypes.string,
  reducePriceBy: PropTypes.number,
  validateDiscountCode: PropTypes.func.isRequired,
  upgradeAccountAfterPurchase: PropTypes.func.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    taxYear: PropTypes.number,
    planName: PropTypes.string
  })
};

function mapStateToProps(state) {
  return {
    invalidDiscountCode: state.ui.invalidDiscountCode,
    discountMessage: state.ui.discountMessage,
    reducePriceBy: parseFloat(state.payment.reducePriceBy),
    exchangeAndTaxData: {
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange,
      taxYear: state.exchangeAndTaxData.taxYear,
      totalTransactions: state.exchangeAndTaxData.totalTransactions,
      pnlTransactions: state.exchangeAndTaxData.pnlTransactions
    },
    auth: state.auth
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      validateDiscountCode,
      upgradeAccountAfterPurchase,
      fetchDocumentsForUser,
      fetchUserWithCookie,
    }, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(requireAuth(UpgradeForm));