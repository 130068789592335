import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

export default (ChildComponent) => {
  class ComposedComponent extends React.Component {
    // our component get got rendered
    componentDidMount() {
      // localStorage.removeItem('jboxxx_digitax_route');
      localStorage.setItem('jboxxx_digitax_route', this.props.match.url);
      this.shouldNavigateAway();
    }

    // our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    // if we don't have the auth token, we should should navigate away
    shouldNavigateAway() {
      if (!this.props.email) {
        history.push('/');
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => {
    return { email: state.auth.email };
  };

  return connect(mapStateToProps)(ComposedComponent);
};
