import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { deleteExchangeData } from 'actions';
import exchangeMapInverse from 'modules/statics/exchangesMapInverse';
import exchangesMap from 'modules/statics/exchangesMap';
import logger from 'modules/utils/logger';
import { red } from '@material-ui/core/colors';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      displayColumns,
      classes
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && numSelected !== 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'header-checkbox-label' }}
            />
          </TableCell>
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableCell}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const tableHeadStyles = theme => ({
  tableCell: {
    whiteSpace: 'nowrap'
  }
});

const EnhancedTableHead = withStyles(tableHeadStyles)(_EnhancedTableHead);

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  marginRightButton: {
    marginRight: theme.spacing(3),
    backgroundColor: red[300]
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  arrowColor: {
    color: '#2b7b4c'
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, title, deleteClick } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <div className={classes.alignTogether}>
            <Typography className={clsx(classes.marginRight, classes.linkTextColor)} variant="h5">
              {`${numSelected} selected to delete`}
            </Typography>
            <ArrowForward className={classes.arrowColor} />
          </div>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 ?
            (
              <Tooltip title="Delete">
                <Button
                  aria-label="ClassificationUpload"
                  className={classes.marginRightButton}
                  onClick={deleteClick}
                >
                  <DeleteIcon className={classes.marginRight} />
                  <Typography variant="body1" className={classes.removeTextTransform}>
                    <Box component="span" fontWeight="fontWeightMedium">
                      {"Delete"}
                    </Box>
                  </Typography>
                </Button>
              </Tooltip>
            ) :
            null
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  buildTableData = userImports => {
    const tempData = [];

    // sort descending
    userImports = _.sortBy(userImports, 'createdAt');
    userImports.reverse();

    let rowCount = 0;
    userImports.forEach(importRecord => {
      tempData.push({
        id: rowCount,
        dbId: importRecord.id,
        exchange: exchangeMapInverse[importRecord.exchange] ? exchangeMapInverse[importRecord.exchange] : importRecord.exchange,
        createdAt: importRecord.createdAt,
        finishedAt: importRecord.finishedAt,
        outcome: importRecord.outcome === 'success' ? 'completed' : importRecord.outcome,
        outcomeDescription: importRecord.outcomeDescription,
        source: importRecord.importType
      });
      rowCount++;
    });

    return tempData;
  }

  handleDeleteRecordsClick = event => {
    const { selected, data } = this.state;

    logger.info('selected', selected);
    let selectedExchangesToDelete = [];
    let record;
    selected.forEach(rowNumber => {
      record = data.slice(rowNumber, rowNumber + 1)[0];
      logger.info('record', record);
      selectedExchangesToDelete.push(exchangesMap[record.exchange] ? exchangesMap[record.exchange] : record.exchange);
    });

    logger.info('selectedExchangesToDelete', selectedExchangesToDelete);

    this.props.deleteExchangeData({ _exchanges: selectedExchangesToDelete });
  }

  componentDidMount() {
    const { userImports } = this.props;

    if (JSON.stringify(userImports) !== "{}") {
      const tableData = this.buildTableData(userImports);
      this.setState({ data: tableData });
    }
  }

  render() {
    const { classes, displayColumns, title } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let rowCount = 0;
    return (
      <Paper className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          deleteClick={this.handleDeleteRecordsClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  rowCount += 1;
                  const isSelected = this.isSelected(n.id);
                  const createdAtDateEST = new Date(new Date(n.createdAt).getTime());
                  let finishedAtDateEST = 'pending';
                  if (n.finishedAt !== null) {
                    finishedAtDateEST = new Date(new Date(n.finishedAt).getTime()).toLocaleString();
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => this.handleClick(event, n.id)}
                      >
                        <Checkbox checked={isSelected} inputProps={{ 'aria-label': `${rowCount}-checkbox-label` }} />
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        {n.exchange}
                      </TableCell>
                      <TableCell align="left">{createdAtDateEST.toLocaleString()}</TableCell>
                      <TableCell align="left">{finishedAtDateEST}</TableCell>
                      <TableCell align="left">{n.outcome}</TableCell>
                      <TableCell align="left">{n.outcomeDescription}</TableCell>
                      <TableCell align="left">{n.source}</TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows-per-page' },
            native: true,
          }}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  userImports: PropTypes.array.isRequired,
  deleteExchangeData: PropTypes.func.isRequired
};

export default compose(
  connect(null, { deleteExchangeData }),
  withStyles(styles)
)(EnhancedTable);
