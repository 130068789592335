import React from 'react';
import clsx from 'clsx';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import UnmatchedTrades from './UnmatchedTrades';
import UnmatchedMovements from './UnmatchedMovements';
import MovementsWithMissingBasis from './MovementsWithMissingBasis';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import { clearCalcGainLossMessages } from 'actions';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    }
  },
  paper: {
    margin: theme.spacing(2),
    width: '95%'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  root100: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    // margin: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  tabWidth: {
    [theme.breakpoints.down('sm')]: {
      width: '30%'
    },
    [theme.breakpoints.up('md')]: {
      width: '10%'
    }
  },
  iconSize: {
    width: '50px',
    height: 'auto'
  }
});


class Unmatched extends React.Component {
  state = {
    // apiMessageOpen: false,
    tabSelected: 0
  }

  // handleAPIMessageClose = () => {
  //   this.setState({ apiMessageOpen: false });
  //   this.props.clearCalcGainLossMessages();
  // }

  handleTabChange = (event, tabSelected) => {
    this.setState({ tabSelected });
  }

  renderNeedToReconcileFirst = () => {
    const { classes } = this.props;
    
    return (
      <div className={classes.root100}>
        <List>
          <ListItem alignItems="flex-start">
            <KeyboardArrowRightIcon className={classes.marginRight}/>
            <Typography variant="h5">
              {"Please reconcile your wallet movements first."}
            </Typography>
          </ListItem>
          <ListItem alignItems="flex-start">
            <KeyboardArrowRightIcon className={classes.marginRight} />
            <Typography variant="h5">
              {"This will help us get an accurate picture of your cryptocurrency balance at each exchange over time."}
            </Typography>
          </ListItem>
        </List>
      </div>
    );
  }

  renderTradesTab = () => {
    const { classes } = this.props;
    const { unmatchedTrades } = this.props.exchangeAndTaxData;
    const { mostRecentReconcileRun } = this.props.pnl;

    if (!mostRecentReconcileRun.outcome) {
      return this.renderNeedToReconcileFirst();
    }

    if (mostRecentReconcileRun.outcome === 'reconciled' &&
        mostRecentReconcileRun.outcomeDescription === 'has_unmatched'
    ) {
      return this.renderNeedToReconcileFirst();
    }

    // trades without basis
    if (unmatchedTrades.length > 0) {
      return <UnmatchedTrades />;
    }
    return (
      <div className={classes.root}>
        <div className={classes.alignCenter}>
          <List>
            <ListItem>
              <CheckIcon className={clsx(classes.iconSize, classes.marginRight)} />
              <Typography variant="h4" component="h1" className={clsx(classes.textAlignCenter)}>
                {"Nothing to review"}
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    );
  }

  renderMovementsTab = () => {
    const { classes } = this.props;
    const { unmatchedMovements, movementsWithMissingBasis } = this.props.exchangeAndTaxData;
    const { mostRecentReconcileRun } = this.props.pnl;

    if (!mostRecentReconcileRun.outcome) {
      return this.renderNeedToReconcileFirst();
    }

    // if (mostRecentReconcileRun.outcome !== 'reconciled') {
    //   return this.renderNeedToReconcileFirst();
    // }
    
    // movements not matching
    if (unmatchedMovements.length > 0) {
      return <UnmatchedMovements />;
    }

    // movements with basis
    if (movementsWithMissingBasis.length > 0) {
      return <MovementsWithMissingBasis />
    }

    return (
      <div className={classes.root}>
        <div className={classes.alignCenter}>
          <List>
            <ListItem>
              <CheckIcon className={clsx(classes.iconSize, classes.marginRight)} />
              <Typography variant="h4" component="h1" className={clsx(classes.textAlignCenter)}>
                {"Nothing to review"}
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { tabSelected } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <AppBar position="static" color="primary">
              <Tabs value={tabSelected} onChange={this.handleTabChange}>
                <Tab
                  className={clsx(classes.tabWidth, classes.removeTextTransform)}
                  label="Wallet Movements"
                />
                <Tab
                  className={clsx(classes.tabWidth, classes.removeTextTransform)}
                  label="Trades"
                />
              </Tabs>
            </AppBar>
          </div>
          <div>
            {tabSelected === 0 ? this.renderMovementsTab() : null}
            {tabSelected === 1 ? this.renderTradesTab() : null}
          </div>
        </Paper>
        {/* <ApiMessageModal
          open={apiMessageOpen}
          handleClose={this.handleAPIMessageClose}
          apiMessage={calcGainLossMessage}
        /> */}
      </div>
    );
  }
}

Unmatched.propTypes = {
  classes: PropTypes.object.isRequired,
  ui: PropTypes.shape({
    calcGainLossMessage: PropTypes.object,
    isCalculatingGainsLosses: PropTypes.string
  }),
  clearCalcGainLossMessages: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    ui: {
      calcGainLossMessage: state.ui.calcGainLossMessage,
      isCalculatingGainsLosses: state.ui.isCalculatingGainsLosses
    },
    exchangeAndTaxData: {
      movementsWithMissingBasis: state.exchangeAndTaxData.movementsWithMissingBasis,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
    },
    pnl: {
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun
    }
  };
}


export default compose(
  connect(mapStateToProps, { clearCalcGainLossMessages }),
  withStyles(styles)
)(Unmatched);