import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Unmatched from './components/Unmatched';
import NotReconciled from './components/NotReconciled';
import ReadyToCalculate from '../../components/ReadyToCalculate';
import AlreadyCalculated from '../../components/AlreadyCalculated';
import NeedToReCalculateZeroCB from '../../components/NeedToReCalculateZeroCB';
import NeedToReCalculateError from '../../components/NeedToReCalculateError';
import NothingToReviewButCalculated from '../../components/NothingToReviewButCalculated';
// import NotHighEnoughPlan from '../../components/NotHighEnoughPlan';

class Review extends React.Component {
  render() {
    const {
      exchangeAndTaxData: { unmatchedTrades, unmatchedMovements, movementsWithMissingBasis }
    } = this.props;
    const { pnl: { mostRecentReconcileRun, mostRecentPnlCalcRun } } = this.props;
    const { auth: { isPlanSufficient } } = this.props;

    if (!isPlanSufficient && mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome === 'success') {
      return <NothingToReviewButCalculated />;
    }

    // put in most recent unmatched run
    if (!mostRecentReconcileRun) {
      return <NotReconciled />;
    }
    if (unmatchedTrades.length > 0 || unmatchedMovements.length > 0 || movementsWithMissingBasis.length > 0) {
      return <Unmatched />;
    }
    // there should be no unmatched but
    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun &&
      mostRecentPnlCalcRun.outcome === 'error'
    ) {
      return <NeedToReCalculateError />;
    }

    if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome && 
      mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis'
    ) {
      return <NeedToReCalculateZeroCB />;
    }

    if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome && 
      mostRecentPnlCalcRun.outcome === 'success'
    ) {
      return <AlreadyCalculated />;
    }

    return <ReadyToCalculate />;
  }
}


Review.propTypes = {
  triggerCalculateGainLoss: PropTypes.array,
  clearCalcGainLossMessages: PropTypes.array,
  exchangeAndTaxData: PropTypes.shape({
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
    movementsWithMissingBasis: PropTypes.array
  }),
  pnl: PropTypes.shape({
    mostRecentReconcileRun: PropTypes.object,
    mostRecentPnlCalcRun: PropTypes.object,
  }),
  auth: PropTypes.shape({
    isPlanSufficient: PropTypes.bool
  })
}


function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      movementsWithMissingBasis: state.exchangeAndTaxData.movementsWithMissingBasis,
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange
    },
    pnl: {
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun,
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun
    },
    auth: {
      isPlanSufficient: state.auth.isPlanSufficient
    }
  };
}

export default compose(
  connect(mapStateToProps)
)(Review);