import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import csv from 'csv';
import { withStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import { Link as RouterLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import _displayExchanges from 'modules/statics/exchanges';
import logger from 'modules/utils/logger';


const styles = theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  importButton: {
    textTransform: 'none'
  },
  hideInput: {
    display: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  formRoot: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  submitButton: {
    textTransform: 'none',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  documentsButton: {
    textTransform: 'none',
    marginTop: theme.spacing(2)
  },
  documentsPaper: {
    marginTop: theme.spacing(5),
    // height: theme.spacing( 10.5,
    paddingBottom: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  uploadCompleteText: {
    paddingTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(0.5)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4)
    }
  },
  dropZoneWidth: {
    [theme.breakpoints.up('md')]: {
      // width: '70%'
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  inputExchangeWidth: {
    [theme.breakpoints.up('md')]: {
      width: '25%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
});

function parse(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      csv.parse(reader.result, (err, uploadData) => {
        if (!err) {
          resolve(uploadData);
        }
      });
    };
    // Make sure to handle error states
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
  });
}

function handleDropzoneChange(files, callback) {
  if (typeof files === 'object') { // if called from the upload button, the files are passed as an object
    let filesCopy = [];
    Object.keys(files).forEach(key => {
      filesCopy.push(files[key]);
    });
    files = filesCopy;
  }

  // attempt to parse
  // we have to do it this way in case a user submits more than one file per open window
  files.forEach(file => {
    parse(file).then(data => {
      callback(data); // note we are sending the callback for each file, not all files at once
    }, err => {
      logger.info(err); // this needs to be handled
    })
  });
}

// this input works... but is unnecessary.  And the style handling is a pain
// <input
//   accept="/*"
//   className={classes.hideInput}
//   id="contained-button-file"
//   multiple
//   type="file"
//   onChange={(e) => handleDropzoneChange(e.target.files, callback)}
// />
// <label htmlFor="contained-button-file">
//   <Button variant="contained" component="span" className={classes.importButton}>
//     Upload
//   </Button>
// </label>

const displayExchanges = _.cloneDeep(_displayExchanges);
displayExchanges.push({ name: '', display: 'None' });

function ImportGrid(props) {
  const {
    classes,
    handleDropzoneChangeCallback,
    // pendingSubmitExchange,
    // handleSelectChange,
    // handleSubmit,
    // hasUploaded,
    dropZoneKey,
    // hasUploadedDataToDb
  } = props;

  return (
    <div className={classes.flex}>
      <div className={classes.dropZoneWidth}>
        <Paper className={clsx(classes.marginRight, classes.paper)}>
          <DropzoneArea
            labelId={"file-dropzone-area-label"}
            acceptedFiles={['.csv']}
            onChange={(files) => handleDropzoneChange(files, handleDropzoneChangeCallback)}
            showFileNameInPreview={true}
            dropzoneText={'Drag and drop your CSV here, or click to choose a file!'}
            key={dropZoneKey}
          />
        </Paper>
      </div>
    </div>
  );
}


// {/* <Paper className={classes.documentsPaper}>
//               <Typography
//                 variant="subtitle2"
//                 className={classes.uploadCompleteText}
//               >
//                 {
//                   hasUploadedDataToDb
//                     ? 'Looks good.  Are you done uploading trades?'
//                     : 'First upload files to create tax documents'
//                 }
//               </Typography>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 disabled={!hasUploadedDataToDb}
//                 className={classes.documentsButton}
//               >
//                 <Link component={RouterLink} to="/dashboard/documents" color="inherit">
//                   Create Documents
//                 </Link>
//               </Button>
//             </Paper> */}
ImportGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDropzoneChangeCallback: PropTypes.func.isRequired,
  // pendingSubmitExchange: PropTypes.string.isRequired,
  // handleSelectChange: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
  hasUploaded: PropTypes.bool.isRequired,
  dropZoneKey: PropTypes.number.isRequired,
  hasUploadedDataToDb: PropTypes.bool
};

export default withStyles(styles)(ImportGrid);
