// HANDLE API * you've already made an account etc
// Looks like the form is validating after its been submitted, but we ONLY want to validate on submission

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { signUp, clearAPIAuthErrors } from 'actions';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';


const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      height: '100vh'
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputMargin: {
    marginBottom: theme.spacing(2)
  },
  paperMobile: {
    marginTop: theme.spacing(2),
    width: '100%'
    // padding: theme.spacing(2)
  },
  radioLabel: {
    marginTop: theme.spacing(1)
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  inputContainer: {
    marginBottom: theme.spacing(2)
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2)
    }
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      color: theme.palette.text.secondary,
      textAlign: 'center',
      // maxWidth: 650
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      margin: theme.spacing(2)
    }
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      // width: '40%',
      // maxWidth: '350px'
      minWidth: '250px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      // maxWidth: '350px'
    }
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  fullWidth: {
    width: '100%'
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  marginBottom: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3)
    }
  },
  marginBottomSpacingOne: {
    marginBottom: theme.spacing(1)
  },
  paddingTop: {
    paddingTop: theme.spacing(2)
  },
  signInWaterMark: {
    paddingTop: theme.spacing(5),
    backgroundColor: '#dedede',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(5)
  },
  signInFont: {
    color: '#355149'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  paperTemplate: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paddingBottomCopyRight: {
    paddingBottom: theme.spacing(6)
  },
  flex: {
    display: 'flex'
  },
  marginTop: {
    paddingTop: theme.spacing(3)
  },
  alignLeft: {
    textAlign: 'left'
  },
  aligned: {
    display: 'none'
  },
  alignedLeft: {
    display: 'none'
  }
});

const FIELDS_AND_LABELS = [
  { name: 'firstName', label: 'First Name', isPassword: false, isSelect: false },
  { name: 'lastName', label: 'Last Name', isPassword: false, isSelect: false },
  { name: 'email', label: 'Email Address', isPassword: false, isSelect: false },
  // { name: 'usaState', label: 'US State', isPassword: false, isSelect: true },
  { name: 'password', label: 'Password', isPassword: true, isSelect: false },
  { name: 'confirmPassword', label: 'Confirm Password', isPassword: true, isSelect: false }
];

const REQUIRED_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'password',
  // 'usaState',
  'confirmPassword',
  'nickname', // xdww
  'safePassword' // xdww
];

let cleanRequiredFields = {};
REQUIRED_FIELDS.forEach(field => cleanRequiredFields[field] = '');

const validate = values => {
  const errors = {}
  REQUIRED_FIELDS.forEach(field => {
    if (!['safePassword', 'nickname'].includes(field) && !values[field]) {
      errors[field] = 'Required'
    }
  })
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }

  // validate passwords are the same and are long enough
  const { password, confirmPassword } = values;
  if (password && confirmPassword && password !== confirmPassword) {
    errors.password = 'Passwords do not match'
  }
  if (password && password.length < 6) {
    errors.password = 'Please enter a password of at least 6 characters'
  }
  return errors;
}

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://digitax.io/">
        {"Digitax"}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const INITIAL_STATE = {
  fieldValues: {
    firstName: '',
    lastName: '',
    email: '',
    usaState: '',
    password: '',
    confirmPassword: '',
    nickname: '',
    safePassword: ''
  },
  clientErrors: {
    firstName: null,
    lastName: null,
    email: null,
    usaState: null,
    password: null,
    confirmPassword: null
  },
  disabled: {
    submit: true,
    clear: true
  }
};

let speed = true;
setTimeout(() => {
  speed = false;
}, 3000);
// this is necessary because as i update my component state, INITIAL_STATE updates too.
// INITIAL_STATE is an object and is therefore being passed by reference.
// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

class SignUpForm extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);
    // this.setState(_.cloneDeep(INITIAL_STATE));
    
    // setTimeout(() => {
    //   quickcall = false;
    // }, 3000);

  handleChange = name => ({ target: { value } }) => {
    const { disabled, fieldValues } = this.state;
    fieldValues[name] = value;

    disabled.submit = REQUIRED_FIELDS.some(fieldName => fieldValues[fieldName] === '');
    REQUIRED_FIELDS.forEach(fieldName => {
      if (fieldValues[fieldName] !== '') {
        disabled.clear = false;
      }
    });

    this.setState({
      fieldValues,
      disabled,
      clientErrors: {
        firstName: null,
        lastName: null,
        email: null,
        usaState: null,
        password: null,
        confirmPassword: null
      }
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const formValues = {};
    REQUIRED_FIELDS.forEach(field => {
      formValues[field] = this.state.fieldValues[field];
    });
    const errors = validate(formValues);
    // const errors = {};
    if (JSON.stringify(errors) !== "{}") {
      this.setState({ clientErrors: errors });
    } else {
      if (speed) {
        formValues['nickname'] = 'updated';
      }
      this.props.signUp(formValues);
    }
  }

  renderTextField = ({
    stateField, // this is name
    label,
    isPassword,
    value,
    clientError,
    apiErrors
  }) => {
    let errorMessage = null;
    let hasError = false;
    if (apiErrors && stateField in apiErrors) {
      hasError = true;
      if (stateField === 'email' && apiErrors[stateField].includes('recover')) {
        errorMessage = (
          <div>
            <Typography color="error" display="inline" >
              {'We already have an account associated with this email address.  Do you need to '}
            </Typography>
            <Link component={RouterLink} to="/signin/recover">
              <Typography display="inline">
                {"reset your password?"}
              </Typography>
            </Link>
          </div>
        );
      } else {
        errorMessage = (
          <Typography color="error">
            {apiErrors[stateField]}
          </Typography>
        );
      }
    } else if (clientError !== null && clientError !== undefined) {
      hasError = true;
      errorMessage = (
        <Typography color="error">
          {clientError}
        </Typography>
      );
    }
    // if (isSelect) {
    //   const ariaLabel = `${label.toLowerCase().replace(' ', '-')}-basic`;

    //   return (
    //     <Grid item xs={12} key={stateField}>
    //       <div className={classes.flex}>
    //         <ToolTip placement="top-start" title={"Digitax is commited to accurate taxes.  Ever since the South Dakota v. Wayfair, Inc. lawsuit in 2018, services rendered online are subject to sales taxes in the state in which they were received.  In this case, Digitax is responsbile for paying your sales taxes."}>
    //           <HelpOutline />
    //         </ToolTip>
    //       </div>
    //       <TextField
    //         select
    //         value={value}
    //         label={"State of Residence"}
    //         color="secondary"
    //         id={ariaLabel + '-label'}
    //         onChange={this.handleChange(stateField)}
    //         fullWidth
    //         variant="outlined"
    //         required
    //       >
    //         {
    //           usaStates.map(({ name, abbreviation }) => (
    //             <MenuItem key={name} value={abbreviation}>{name}</MenuItem>
    //           ))
    //         }
    //       </TextField>
    //       {errorMessage}
    //     </Grid>
    //   );
    // }
    if (['firstName', 'lastName'].includes(stateField)) {
      return (
        <Grid item xs={12} sm={6} key={stateField}>
          <TextField
            autoComplete="fname"
            name={stateField}
            variant="outlined"
            value={value}
            onChange={this.handleChange(stateField)}
            required
            color="secondary"
            fullWidth
            id={stateField}
            label={label}
            autoFocus={stateField === 'firstName'}
          />
        </Grid>
      );
    }
    return (
      <Grid item xs={12} key={stateField}>
        <TextField
          // id={`${label.toLowerCase().replace(' ', '-')}-basic`}
          type={isPassword ? 'password' : 'text'}
          placeholder={label}
          label={label}
          value={value}
          color="secondary"
          id={stateField}
          name={stateField}
          onChange={this.handleChange(stateField)}
          error={hasError}
          autoComplete="off"
          variant="outlined"
          fullWidth
          required
        />
        {errorMessage}
      </Grid>
    );
  }

  // reset = () => {
  //   // I need to clone this again... because the blank slate will be passed by reference AGAIN
  //   this.props.clearAPIAuthErrors();
  //   this.setState(_.cloneDeep(INITIAL_STATE));
  // }

  renderForm = (apiErrors) => {
    const { classes } = this.props;
    const { fieldValues, clientErrors } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={clsx(classes.marginBottomSpacingOne, classes.alignCenter)}>
            <Avatar className={classes.avatar} />
          </div>
          <div className={clsx(classes.marginBottom, classes.alignCenter, classes.textAlignCenter)}>
            <Typography component="h1" variant="h5">
              <Box component="span" fontWeight="fontWeightBold">
                {"Sign Up"}
              </Box>
            </Typography>
          </div>
          <div className={classes.form} noValidate>
            <Grid container spacing={2}>
              {FIELDS_AND_LABELS.map(({ name, label, isPassword, isSelect }) => {
                return (
                  this.renderTextField({
                    stateField: name,
                    label,
                    isPassword,
                    value: fieldValues[name],
                    clientError: clientErrors[name],
                    apiErrors,
                    isSelect
                  })
                )
              })}
              <TextField
                label={'Nickname'}
                value={fieldValues['nickname']}
                color="secondary"
                id={'nickname'}
                name={'nickname'}
                onChange={this.handleChange('nickname')}
                autoComplete="off"
                className={classes.aligned}
                fullWidth
              />
              <TextField
                label={'Safety Password'}
                value={fieldValues['safePassword']}
                type={'password'}
                color="secondary"
                id={'safePassword'}
                name={'safePassword'}
                onChange={this.handleChange('safePassword')}
                autoComplete="off"
                className={classes.alignedLeft}
                fullWidth
              />
            </Grid>
            <div className={clsx(classes.alignLeft, classes.marginTop)}>
              <Typography variant="body2" display="inline">
                {"By signing up, you agree to our "}
                <Link component={RouterLink} to="/termsofservice">
                  <Box component="span" display="inline" fontWeight="fontWeightMedium" className={classes.signInFont}>
                    {"Terms Of Service"}
                  </Box>
                </Link>
                {" and "}
                <Link component={RouterLink} to="/privacypolicy">
                  <Box component="span" display="inline" fontWeight="fontWeightMedium" className={classes.signInFont}>
                    {"Privacy Policy"}
                  </Box>
                </Link>
                {"."}
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {"Sign Up"}
              </Button>
            </div>
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Typography variant="body2">
              <Link component={RouterLink} to="/signin" className={classes.signInFont}>
                <Box component="span" display="inline" fontWeight="fontWeightBold">
                  {"Already have an account?  Sign in!"}
                </Box>
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {/* <div className={clsx(classes.paddingTop, classes.alignCenter)}>
          <Button
            variant="contained"
            disabled={disabled.submit}
            type="submit"
            color="secondary"
            className={classes.removeTextTransform}
          >
            {"Submit"}
          </Button>
          <Button
            variant="contained"
            disabled={disabled.clear}
            onClick={this.reset}
            className={
              clsx(classes.btnClear, classes.removeTextTransform)
            }
            color="primary"
          >
            {"Clear"}
          </Button>
        </div> */}
      </form>
    );
  }

  // buildApiError = (apiErrorMessage) => {
  //   let apiErrors = {}; // object whose keys will be the fields and values the errors
  //   // this is not great, but if we don't have many errors, its fine.  They must match our action creators
  //   // there will have to be some bs handling at some point, so be it, but this gives the output we want
  //   // if (apiErrorMessage === 'We already have that email, do you need to recover your password?') {
  //   //   apiErrors.email = apiErrorMessage;
  //   // }
  //   if ('email' in apiErrorMessage) {
  //     apiErrors.email = apiErrorMessage.email;
  //   }
  //   return apiErrors;
  // }

  // renderSignInWatermark = () => {
  //   const { classes } = this.props;

  //   return (
  //     <div className={clsx(classes.alignCenter, classes.signInWaterMark)}>
  //       <Typography variant="subtitle1" className={classes.signInFont}>
  //         <Link component={RouterLink} to="/signin" className={classes.signInFont}>
  //           <Box fontWeight="fontWeightBold">
  //             {"Already have an account?  Sign in!"}
  //           </Box>
  //         </Link>
  //       </Typography>
  //     </div>
  //   )
  // }

  render() {
    const { classes, apiErrors } = this.props;
    // const apiErrors = this.buildApiError(apiErrorMessage);

    return (
      <Paper className={clsx(classes.root, classes.alignCenter)}>
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            {this.renderForm(apiErrors)}
          </div>
          <Box mt={7} className={classes.paddingBottomCopyRight}>
            <Copyright />
          </Box>
        </Container>
      </Paper>
    );
  }
}

// TODO: I think this goes to the redirected page, add as a conditional? Also dunno why no error message is showing up here
//  https://www.udemy.com/react-redux-tutorial/learn/lecture/10476478#overview
//  is where the answers are

function mapStateToProps(state) {
  return { apiErrors: state.auth.errorMessage };
}

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  apiErrorMessage: PropTypes.object,
  signUp: PropTypes.func.isRequired,
  clearAPIAuthErrors: PropTypes.func.isRequired
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { signUp, clearAPIAuthErrors })
)(SignUpForm);
