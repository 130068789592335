import exchangesMapInverse from 'modules/statics/exchangesMapInverse';
import bitstamp_img from 'modules/statics/exchangeLogos/bitstamp.png';
import coinbase_img from 'modules/statics/exchangeLogos/coinbase.jpg';
import coinbase_pro_img from 'modules/statics/exchangeLogos/coinbase_pro.png';
import binance_img from 'modules/statics/exchangeLogos/binance.png';
import binance_us_img from 'modules/statics/exchangeLogos/binance_us.png';
import bitfinex_img from 'modules/statics/exchangeLogos/bitfinex.png';
import bittrex_img from 'modules/statics/exchangeLogos/bittrex.png';
import kraken_img from 'modules/statics/exchangeLogos/kraken.png';
import gemini_img from 'modules/statics/exchangeLogos/gemini.jpeg';
import gateio_img from 'modules/statics/exchangeLogos/gateio.png';


export default (exchange) => {
  if (!(exchange in exchangesMapInverse)) {
    return "Missing Image";
  }

  if (exchange === 'coinbase') {
    return coinbase_img;
  } else if (exchange === 'coinbase_pro') {
    return coinbase_pro_img;
  } else if (exchange === 'binance') {
    return binance_img;
  } else if (exchange === 'binance_us') {
    return binance_us_img;
  } else if (exchange === 'bitfinex') {
    return bitfinex_img;
  } else if (exchange === 'bittrex') {
    return bittrex_img;
  } else if (exchange === 'kraken') {
    return kraken_img;
  } else if (exchange === 'gemini') {
    return gemini_img;
  } else if (exchange === 'bitstamp') {
    return bitstamp_img;
  } else if (exchange === 'gateio') {
    return gateio_img;
  }
}

// not sure if the below manner is actually better from performance basis


// export default async (exchange) => {
//   if (!(exchange in exchangesMapInverse)) {
//     return "Missing Image";
//   }
//   const exchangeImg = await import(`modules/statics/exchangeLogos/${exchange}.jpg`);

//   return exchangeImg;
// }