// builds our custom theme.  This can be adjusted further down the app
// Components can be customized with:
//      1)  withStyles
//      2)  (i think) subsequent withRoot files

import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
// import orange from '@material-ui/core/colors/orange';
// import green from '@material-ui/core/colors/green';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';

// // A theme with custom primary and secondary color.
// // It's optional.
// const theme = createTheme({
//   palette: {
//     primary: {
//       light: orange[300],
//       main: orange[500],
//       dark: orange[700],
//     },
//     secondary: {
//       light: green[300],
//       main: green[500],
//       dark: green[700],
//     },
//   },
//   typography: {
//     useNextVariants: true,
//   },
// });

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
