import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { storeHeaderTabChange } from 'actions';


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(5)
  },
  marginTop: {
    marginTop: theme.spacing(5)
  }
});

class NotFound extends React.Component {
  componentDidMount() {
    this.props.storeHeaderTabChange(false);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.margin}>
          <Typography variant="subtitle1" className={classes.text}>
            {"Sorry!  We can't find the page you're looking for."}
          </Typography>
          <div className={classes.marginTop}>
            <Link component={RouterLink} to="/" color="inherit">
              <Typography variant="body1" color="secondary">
                {"Would you like to go to our homepage?"}
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(styles),
  connect(null, { storeHeaderTabChange })
)(NotFound);
