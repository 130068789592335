import coinbaseProTrades from './coinbase_pro_trades';
import coinbaseProWallet from './coinbase_pro_wallet';

export default ({ exchange, files }) => {
  let dataToUpload = [];

  files.forEach(data => {
    if (data.length > 0) {
      if (data[0][0] === 'type') {
        dataToUpload.push(coinbaseProWallet({
          exchange,
          walletMovements: data
        }));
      } else if (data[0][0] === 'trade id') {
        dataToUpload.push(coinbaseProTrades({ exchange, tradeData: data }));
      } else {
        dataToUpload.push({
          isValid: false,
          comment: "We don't recongize this file type",
          tradeData: [],
          type: 'unknown',
          badTrades: []
        });
      }
    }
  });
  return dataToUpload;
}
