import React from 'react';
import { withStyles } from '@material-ui/core';
import Mobile from './components/Mobile';
import Desktop from './components/Desktop';


const styles = theme => ({
  hideOnMobile: {
    // max-width media query between 0 and md
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    // min-width media query from md and up
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});


class TestComponent extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.hideOnMobile}>
          <Desktop />
        </div>
        <div className={classes.hideOnDesktop}>
          <Mobile />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TestComponent);
