import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import PricingDisplay from './components/PricingDisplay';
import planTypesTransLimits from 'modules/statics/planTypesTransLimits';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { storeCalculateTabChange } from 'actions';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  removeTransform: {
    textTransform: 'none'
  },
  margin: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
      margin: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10)
    }
  },
  marginButton: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      // margin: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(4)
    }
  },
  marginDivider: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  invisible: {
    visibility: 'hidden'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '31%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  marginAuto: {
    margin: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  backgroundGradient: {
    background: 'linear-gradient(45deg, #2CBD72 30%, #FFFFFF 90%)'
  },
  textDecoration: {
    textDecoration: 'underline'
  },
  subHeader: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center'
    },
  },
  marginSubHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subHeaderWidth: {
    width: '80%',
  }
});

class Pricing extends React.Component {
  state = {
    recommendedPlanName: null,
    hasPnlTransactionsThisTaxYear: null,
    shouldGetFBAR: false
  };

  componentDidMount() {
    // const {
    //   exchangeAndTaxData: {
    //     tradeDataByExchange,
    //     taxYear,
    //     totalTransactions
    //   }
    // } = this.props;
    
    // let hasPnlTransactionsThisTaxYear = false;

    // // const taxYearStartTime = new Date(`${taxYear.toString()}-01-01 00:00:00`);
    // // const taxYearEndTime = new Date(`${taxYear.toString()}-12-31 24:00:00`);

    // // if (Array.isArray(pnlTransactions) && pnlTransactions.length > 0) {
    // //   if (
    // //     pnlTransactions.filter(({ disposedTimestamp }) => {
    // //       return disposedTimestamp < taxYearEndTime && disposedTimestamp >= taxYearStartTime;
    // //     }).length > 0
    // //   ) {
    // //     hasPnlTransactionsThisTaxYear = true;
    // //   }
    // // } else {
    // const taxYearStartTimestamp = new Date(`${taxYear}-01-01`).getTime();
    // const taxYearEndTimestamp = new Date(`${taxYear + 1}-01-01`).getTime();

    // Object.keys(tradeDataByExchange).forEach(exchange => {
    //   tradeDataByExchange[exchange].forEach(({ trades }) => {
    //     trades.some(trade => {
    //       trade.timestamp = new Date(trade.tradeDateTime).getTime();
    //       if (trade.timestamp >= taxYearStartTimestamp && trade.timestamp < taxYearEndTimestamp) {
    //         if (trade.side === 'BUY' && trade.product.slice(-4) === '-USD') {
    //           // skip as we're buying a crypto with usd
    //         } else {
    //           hasPnlTransactionsThisTaxYear = true;
    //           return true;
    //         }
    //       }
    //       return false;
    //     });
    //   });
    // });

    // // need to add in purchases here.
    // let recommendedPlanName = 'premium';
    // Object.keys(planTypesTransLimits).some(_planName => {
    //   if (planTypesTransLimits[_planName].maxTransactions > totalTransactions) {
    //     recommendedPlanName = _planName;
    //     return true;
    //   }
    //   return false;
    // });

    // let shouldGetFBAR = false;
    // if (!hasPnlTransactionsThisTaxYear && totalTransactions > 0) {
    //   recommendedPlanName = 'free';
    //   Object.keys(tradeDataByExchange).forEach(exchange => {
    //     if (['binance', 'bitstamp'].includes(exchange)) {
    //       shouldGetFBAR = true;
    //     }
    //   });
    // }

    // this.setState({ recommendedPlanName, shouldGetFBAR, hasPnlTransactionsThisTaxYear, totalTransactions });
  }

  handleClick = () => {
    this.props.storeCalculateTabChange(2);
  }

  render () {
    const { classes } = this.props;
    const {
      auth: { planName },
      exchangeAndTaxData: {
        tradeDataByExchange,
        taxYear,
        totalTransactions }
    } = this.props;
    // const { recommendedPlanName, shouldGetFBAR, hasPnlTransactionsThisTaxYear } = this.state;

    let hasPnlTransactionsThisTaxYear = false;

    // const taxYearStartTime = new Date(`${taxYear.toString()}-01-01 00:00:00`);
    // const taxYearEndTime = new Date(`${taxYear.toString()}-12-31 24:00:00`);

    // if (Array.isArray(pnlTransactions) && pnlTransactions.length > 0) {
    //   if (
    //     pnlTransactions.filter(({ disposedTimestamp }) => {
    //       return disposedTimestamp < taxYearEndTime && disposedTimestamp >= taxYearStartTime;
    //     }).length > 0
    //   ) {
    //     hasPnlTransactionsThisTaxYear = true;
    //   }
    // } else {
    const taxYearStartTimestamp = new Date(`${taxYear}-01-01`).getTime();
    const taxYearEndTimestamp = new Date(`${taxYear + 1}-01-01`).getTime();

    Object.keys(tradeDataByExchange).forEach(exchange => {
      tradeDataByExchange[exchange].forEach(({ trades }) => {
        trades.some(trade => {
          trade.timestamp = new Date(trade.tradeDateTime).getTime();
          if (trade.timestamp >= taxYearStartTimestamp && trade.timestamp < taxYearEndTimestamp) {
            if (trade.side === 'BUY' && trade.product.slice(-4) === '-USD') {
              // skip as we're buying a crypto with usd
            } else {
              hasPnlTransactionsThisTaxYear = true;
              return true;
            }
          }
          return false;
        });
      });
    });

    // need to add in purchases here.
    let recommendedPlanName = 'premium';
    Object.keys(planTypesTransLimits).some(_planName => {
      if (planTypesTransLimits[_planName].maxTransactions > totalTransactions) {
        recommendedPlanName = _planName;
        return true;
      }
      return false;
    });

    // maybe change this to look at currency ledger?
    let shouldGetFBAR = false;
    if (!hasPnlTransactionsThisTaxYear && totalTransactions > 0) {
      recommendedPlanName = 'free';
      Object.keys(tradeDataByExchange).forEach(exchange => {
        if (['binance', 'bitstamp'].includes(exchange)) {
          shouldGetFBAR = true;
        }
      });
    }

    // this.setState({ recommendedPlanName, shouldGetFBAR, hasPnlTransactionsThisTaxYear, totalTransactions });

    // hasPnlTransactionsThisTaxYear i should probably tell the user they only need an fbar for this case year

    return (
      <Card variant="outlined">
        <div className={clsx(classes.alignCenter, classes.margin)}>
          <div className={classes.hideOnMobile}>
            <Typography variant="h1">
              {`Recommended: `}
              {recommendedPlanName === 'free' && shouldGetFBAR ? 'FBAR' : _.capitalize(recommendedPlanName)}
            </Typography>
          </div>
          <div className={classes.hideOnDesktop}>
            <Typography variant="h3" className={classes.textAlignCenter}>
              {`Recommended: `}
              {recommendedPlanName === 'free' && shouldGetFBAR ? 'FBAR' : _.capitalize(recommendedPlanName)}
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.alignCenter, classes.marginSubHeader)}>
          <div className={classes.subHeaderWidth}>
            <Typography variant="subtitle2" className={classes.textAlignCenter}>
              {!hasPnlTransactionsThisTaxYear ? 'You do not have any taxable cryptocurrency trades eligible for this tax year. ' : `Recommendation is based on the ${totalTransactions} transactions we need to calculate your taxes.`}
              {shouldGetFBAR ?
                (
                  !hasPnlTransactionsThisTaxYear ?
                    ' However, based on your exchange balances we do recommend getting an FBAR for this tax year.' :
                    ' Based on your exchange balances we also recommend getting an FBAR for this tax year.'
                ) : null}
            </Typography>
          </div>
        </div>
        {
          recommendedPlanName === planName ? (
            <div className={clsx(classes.alignCenter, classes.marginButton)}>
              <Link component={RouterLink} to="/calculate/calc">
                <Button
                  variant="contained"
                  className={clsx(classes.removeTransform)}
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <Typography variant="subtitle2">
                    {"Go-To Calculate"}
                  </Typography>
                </Button>
              </Link>
            </div>
          ) : null
        }
        <Divider className={classes.marginDivider} />
        <PricingDisplay recommendedPlanName={recommendedPlanName} />
      </Card>
    );
  }
}


Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    tradeDataByExchange: PropTypes.object,
    taxYear: PropTypes.number.isRequired,
    totalTransactions: PropTypes.number,
    pnlTransactions: PropTypes.array
  }),
  auth: PropTypes.shape({
    planName: PropTypes.string.isRequired,
  })
}

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange,
      taxYear: state.exchangeAndTaxData.taxYear,
      totalTransactions: state.exchangeAndTaxData.totalTransactions,
      pnlTransactions: state.exchangeAndTaxData.pnlTransactions
    },
    auth: {
      planName: state.auth.planName
    }
  };
}
export default compose(
  connect(mapStateToProps, { storeCalculateTabChange }),
  withStyles(styles)
)(Pricing);