export default {
  free: {
    header: 'Free',
    price: '0',
    captions: [
      'Up to 250 transactions',
      'Support for Coinbase and Coinbase Pro',
      'Full Calculation of taxes owed or credits to you',
    ]
  },
  premium: {
    header: 'Premium',
    price: '60',
    captions: [
      'Up to 250 transactions',
      'Support for all exchanges',
      'Full Calculation of taxes owed or credits to you',
      'Completed 8949 Forms'
    ],
  },
  trader: {
    header: 'Trader',
    price: '150',
    captions: [
      'Up to 5,000 transactions',
      'Support for all exchanges',
      'Full Calculation of taxes owed or credits to you',
      'Completed 8949 Forms',
      'CPA Assistance'
    ],
  },
  professional: {
    header: 'Professional',
    price: '350',
    captions: [
      'Up to 25,000 transactions',
      'Support for all exchanges',
      'Full Calculation of taxes owed or credits to you',
      'Completed 8949 Forms',
      'CPA Assistance'
    ],
  }
};