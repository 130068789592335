import React from 'react';
import MissingTransactionHistory from './pages/missing-transaction-history';
import WalletMovementsUnmatched from './pages/wallet-movements-unmatched';
import NotFound from '../notfound';
import BlogHome from './components/Blog';

class HelpTopLevel extends React.Component {
  render() {
    const { url } = this.props.match;
    let ComponentToRender;
    if (url === '/help/missing-transaction-history') {
      ComponentToRender = MissingTransactionHistory;
    } else if (url === '/help/wallet-movements-unmatched') {
      ComponentToRender = WalletMovementsUnmatched;
    } else if (url === '/blog/home') {
      ComponentToRender = BlogHome;
    } else {
      ComponentToRender = NotFound;
    }

    return <ComponentToRender />;
  }
}


export default HelpTopLevel;