import { TRADES } from '../../types';
import currencies from 'modules/statics/currencies';
import logger from 'modules/utils/logger';

// // coinbase data comes in like the following from the tax reports
// let array = [
//   0: Timestamp, "1/18/2019"
//   1: Transaction Type, "Buy, Sell, Send, Receive",
//   2: Asset, "ETH",
//   3: Quantity Transacted, "3.5"
//   4: USD Spot Price at Transaction, "231.17"
//   5: USD Amount Transacted (Inclusive of Coinbase Fees), "1000",
//   6: Address, "location being sent / received"
//   7: Note, ""Bought 3.3400 BTC for $1,000.00 USD. Paid for with Capital One 360 - 360 ... *****1995. Your bitcoin will arrive by the end of day on Friday Jul 17, 2015.""
// ]

export default ({ exchange, tradeData }) => {
  let newData = [];
  let badTrades = [];
  let counter = 0;

  tradeData.forEach(trade => {
    // why is this null, i'm sure it's ezzzzz
    // logger.info('trade', trade);
    try {
      const { error, product, exchangeFee, side } = validate(trade);
      const thisRecord = createTableData({
        error,
        counter,
        product,
        side,
        tradeDateTime: trade[0],
        size: parseFloat(trade[3]),
        sizeUnit: trade[2],
        price: parseFloat(trade[4]),
        exchangeFee,
        feeUnit: 'USD',
        total: parseFloat(trade[5]),
        totalUnit: 'USD',
        priceUnit: 'USD'
      });

      if (JSON.stringify(error) !== "{}") {
        badTrades.push({ error, trade: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on coinbase upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData,
        type: TRADES,
        badTrades
      };
    }
  });

  if (badTrades.length === 0) {
    logger.info('coinbase trades are valid, first trade below');
    logger.info(newData[0]);
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('some coinbase trades are invalid, they are below')
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  } else {
    logger.info('all coinbase trades are invalid, they are below');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: TRADES,
      badTrades
    };
  }
};
// let array = [
//   0: Timestamp, "1/18/2019"
//   1: Transaction Type, "Buy, Sell, Send, Receive",
//   2: Asset, "ETH",
//   3: Quantity Transacted, "3.5"
//   4: USD Spot Price at Transaction, "231.17"
//   5: USD Amount Transacted (Inclusive of Coinbase Fees), "1000",
//   6: Address, "location being sent / received"
//   7: Note, ""Bought 3.3400 BTC for $1,000.00 USD. Paid for with Capital One 360 - 360 ... *****1995. Your bitcoin will arrive by the end of day on Friday Jul 17, 2015.""
// ]
function validate(trade) {
  const error = {};
  const date = trade[0]; // note this isn't in UTC
  const side = trade[1].toUpperCase();
  const currency = trade[2];
  const size = parseFloat(trade[3]);
  const sizeUnit = trade[2];
  const price = parseFloat(trade[4]);
  const total = parseFloat(trade[5]);
  const totalUnit = 'USD';
  const note = trade[7];

  let exchangeFee;
  if (side === 'BUY') {
    exchangeFee = total - size * price;
  }
  if (side === 'SELL') {
    exchangeFee = size * price - total;
  }

  if (exchangeFee < 0) {
    error.exchangeFee = "Needs to be positive";
  }

  let found = false;
  currencies.forEach(ccy => {
    if (ccy.ticker === currency) {
      found = true;
    }
  });

  if (!found) {
    error.currency = "We don't recognize this currency.";
  }

  const splitNote = note.split(/[\r\n]+/)[0];
  let product;

  if (splitNote.includes('Sent') || splitNote.includes('Received')) {
    product = null;
    error.product = 'This is a wallet movement';
  } else if (splitNote.includes('Bought') || splitNote.includes('Sold')) {
    const regexp = /(\w*)\s([+-]?(?:\d*\.)?\d+)\s(\w*)\s(\w*)\s\$(\d{1,3}(?:,\d{3})*(?:\.\d+))\s(\w*)/;
    const matches = regexp.exec(splitNote).slice(1, 7);
    // logger.info(matches);

    product = matches[2] + '-' + matches[5];
    // logger.info(product);
    if (product.split('-').length !== 2) {
      error.product = 'Product requires two currencies.';
    }
    if (!product.includes(sizeUnit)) {
      error.sizeUnit = "Size Unit does not apply to trade.";
    }
    if (!product.includes(totalUnit)) {
      error.totalUnit = "Total Unit does not apply to trade.";
    }
  } else {
    // logger.info('old trade')
    // logger.info(splitNote);
    // could be a fork or from the previous coinbase system
    product = currency + '-USD'
    if (!product.includes(sizeUnit)) {
      error.sizeUnit = "Size Unit does not apply to trade.";
    }
    if (!product.includes(totalUnit)) {
      error.totalUnit = "Total Unit does not apply to trade.";
    }
  }

  if (side !== 'BUY' && side !== 'SELL') {
    error.side = "Side needs to be either 'BUY' or 'SELL'.";
  }

  try {
    let testDate
    if (Number.isInteger(date)) {
      // test for unix date
      testDate = new Date(date * 1000);
    } else {
      testDate = new Date(date);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.tradeDateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }
  if (isNaN(size)) {
    error.size = "Size is not a number.";
  }
  if (isNaN(price)) {
    error.price = "Price is not a number.";
  }
  if (isNaN(exchangeFee)) {
    error.exchangeFee = "Exchange Fee is not a number.";
  }
  if (isNaN(total)) {
    error.total = "Total is not a number.";
  }
  return {
    error,
    product,
    exchangeFee,
    side
  };
}

function createTableData({
  error,
  counter,
  product,
  side,
  tradeDateTime,
  size,
  sizeUnit,
  price,
  exchangeFee,
  feeUnit,
  total,
  totalUnit,
  priceUnit
}) {
  let date = 'N/A';
  if (!error.tradeDateTime) {
    if (Number.isInteger(tradeDateTime)) {
      date = new Date(tradeDateTime * 1000);
    } else {
      date = new Date(tradeDateTime);
    }
  }

  return {
    id: counter,
    product,
    side,
    tradeDateTime: date,
    size: parseFloat(size),
    sizeUnit: sizeUnit,
    price: parseFloat(price), // price
    exchangeFee, // exchangeFee
    feeUnit,
    total: parseFloat(total), // total
    totalUnit,
    priceUnit,
    source: 'csv'
  };
}
