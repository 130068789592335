import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { deleteRecords } from 'actions';
import { WALLET } from 'modules/utils/tradeDataValidators/types';
import exchanges from 'modules/statics/exchangesMapInverse';
import exportMovements from 'modules/utils/exportCSVs/exportMovements';
import GetAppIcon from '@material-ui/icons/GetApp';
import logger from 'modules/utils/logger';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      displayColumns,
      classes
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && numSelected !== 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'header-checkbox-label' }}
            />
          </TableCell>
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableCell}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const tableHeadStyles = theme => ({
  tableCell: {
    whiteSpace: 'nowrap'
  }
});

const EnhancedTableHead = withStyles(tableHeadStyles)(_EnhancedTableHead);

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, title, deleteClick, csvDownloadClick } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected for upload
          </Typography>
        ) : (
          <ListItem>
            <Typography variant="h6" id="tableTitle">
              {title}
            </Typography>
            <IconButton onClick={csvDownloadClick}>
              <GetAppIcon />
            </IconButton>
          </ListItem>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 ?
            (
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={deleteClick}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) :
            null
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'dummy',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  buildTableData = (tradeData) => {
    let tempData = [];
    Object.keys(tradeData).forEach(exchange => {
      // now we might have duplicate ids because this could be from multiple uploads, so handling that
      // state.exchangeAndTaxData.tradeDataByExchange.exchange[blockId].id is our trade object.
      let id = 0; // id for the sortingtable
      let blockId = 0; // index of array in our actual state, ie if we have two uploads
      tradeData[exchange].forEach(({ trades }) => {
        trades.forEach(trade => {
          trade.amount = _.round(trade.amount, 8);
          trade.exchangeFee = _.round(trade.exchangeFee, 8);

          if (!String(trade.amount).includes('.')) {
            trade.amount += ".0";
          }
          if (!String(trade.exchangeFee).includes('.')) {
            trade.size += ".0";
          }
          tempData.push({
            ...trade,
            exchange: exchanges[exchange] ? exchanges[exchange] : exchange,
            id,
            blockId,
            dbId: trade.id,
            dateTime: new Date(trade.dateTime),
            exchangeFee: parseFloat(trade.exchangeFee) !== 0.0 ? String(trade.exchangeFee).padEnd(10, '0') : '0.00000000',
            amount: parseFloat(trade.amount) !== 0.0 ? String(trade.amount).padEnd(10, '0') : '0.00000000'
          });
          id++;
        });
        blockId++;
      });
    });
    return tempData;
  }

  handleDeleteRecordsClick = event => {
    const { selected, data } = this.state;

    let selectedRows = [];
    let record;
    selected.forEach(rowNumber => {
      record = data.slice(rowNumber, rowNumber + 1)[0];
      selectedRows.push({ ...record, id: record.dbId });
    });

    logger.info('selectedRows to delete', selectedRows);
    this.props.deleteRecords({ data: selectedRows, type: WALLET, unmatched: false });
  }

  csvDownloadClick = event => {
    exportMovements('wallet_movements.csv', this.state.data);
  }

  componentDidMount() {
    const { tradeData } = this.props;

    if (JSON.stringify(tradeData) !== "{}") {
      const tableData = this.buildTableData(tradeData);
      this.setState({ data: tableData });
    }
  }

  render() {
    const { classes, displayColumns, title } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let rowCount = 0;
    return (
      <div className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          deleteClick={this.handleDeleteRecordsClick}
          csvDownloadClick={this.csvDownloadClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  rowCount += 1;
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.dbId}
                      selected={isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => this.handleClick(event, n.id)}
                      >
                        <Checkbox checked={isSelected} inputProps={{ 'aria-label': `${rowCount}-checkbox-label` }} />
                      </TableCell>
                      <TableCell scope="row" padding="none">
                        {n.coin}
                      </TableCell>
                      <TableCell align="left">{n.exchange}</TableCell>
                      <TableCell align="left">{n.type}</TableCell>
                      <TableCell align="right">{n.dateTime.toLocaleString()}</TableCell>
                      <TableCell align="right">{n.amount}</TableCell>
                      <TableCell align="right">{n.exchangeFee}</TableCell>
                      <TableCell align="left">{n.addressTo}</TableCell>
                      <TableCell align="left">{n.addressFrom}</TableCell>
                      <TableCell align="left">{n.hash}</TableCell>
                      <TableCell align="left">{n.exchangeTradeId}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows-per-page' },
            native: true,
          }}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tradeData: PropTypes.object.isRequired,
  deleteRecords: PropTypes.func.isRequired
};

export default compose(
  connect(null, { deleteRecords }),
  withStyles(styles)
)(EnhancedTable);
