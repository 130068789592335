// TODO add a wizard field

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AutoFillTextBox from './AutoFillTextBox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { addStorageAndMovement } from 'actions';


const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-20%, -20%)`,
      top: `20%`,
      left: `28%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 5%)`,
    }
    // midWidth: '60%'
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  textField: {
    margin: theme.spacing(2),
    minWidth: 75
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1),
  },
  description: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  marginButtons: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  fontHandleMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginLeft: theme.spacing(1)
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
  },
});

// TODO add a wizard field that fills in the numbers and then BTC/USD
const FIELDS = [
  { name: 'storage', label: 'Eg. Personal Wallet' },
  { name: 'costBasis', label: 'Cost Basis' },
  { name: 'dateMoved', label: 'Date of Movement' },
  { name: 'dateAcquired', label: 'Date of Purchase' },
];

const REQUIRED_FIELDS = [
  // { name: 'costBasis' },
];

const INITIAL_STATE = {
  fields: {
    storage: '',
    dateMoved: '',
    costBasis: '',
    dateAcquired: '',
  },
  disabled: {
    submit: true,
    clear: false
  },
  errors: {
    storage: null,
    dateMoved: null,
    costBasis: null,
    dateAcquired: null
  }
};

// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);


class _Modal extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  componentDidMount() {
    if (this.props.currentlyAdjustingMovement && this.props.currentlyAdjustingMovement.dateTime) {
      this.setState({ dateMoved: this.props.currentlyAdjustingMovement.dateTime });
    }
  }

  reset = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  clickInsertStorage = () => {
    const { currentlyAdjustingMovement } = this.props;
    this.props.handleClose();
    let dateMovedValue = this.state.fields.dateMoved;
    let dateAcquiredValue = this.state.fields.dateAcquired;
    if ('dateTime' in currentlyAdjustingMovement) {
      // the above test should really always be the case...
      if (dateMovedValue === '') {
        dateMovedValue = currentlyAdjustingMovement.dateTime;
      }
      if (dateAcquiredValue === '') {
        dateAcquiredValue = currentlyAdjustingMovement.dateTime;
      }
    }
    this.props.addStorageAndMovement(
      {
        currentlyAdjustingMovement,
        // storage: this.state.fields.storage,
        storage: this.props.ui.walletName,
        costBasis: this.state.fields.costBasis,
        dateAcquired: dateAcquiredValue,
        dateMoved: dateMovedValue,
        isDeposit: true
      }
    );
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  handleChange = name => ({ target: { value } }) => {
    const { fields, disabled } = this.state;

    // maybe make this a wizard someday?
    fields[name] = value;

    disabled.submit = REQUIRED_FIELDS.every(({ name }) => fields[name] !== '');
    disabled.clear = REQUIRED_FIELDS.some(({ name }) => fields[name] !== '');

    this.setState({ fields, disabled });
  }

  // todo make this a wizard field
  renderTextField = ({ name, label, knownWalletNames }) => {
    const { fields } = this.state;
    const { classes, currentlyAdjustingMovement } = this.props;
    let value = fields[name];
    if (['dateMoved', 'dateAcquired'].includes(name) && 'dateTime' in currentlyAdjustingMovement) {
      value = currentlyAdjustingMovement.dateTime;
    }

    if (['dateMoved', 'dateAcquired'].includes(name)) {
      return (
        <div className={classes.textField} key={name}>
          <List disablePadding>
            <ListItem disableGutters>
              <TextField
                id="datetime-local"
                label={name === 'dateMoved' ? 'Date Deposited' : 'Date Acquired'}
                type="datetime-local"
                defaultValue={(value && !isNaN(Date.parse(new Date(value)))) ? new Date(new Date(value).getTime() - (new Date(value).getTimezoneOffset() * 60000)).toISOString().slice(0,16) : null}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleChange(name)}
              />
            </ListItem>
          </List>
        </div>
      )
    } else if (name === 'costBasis') {
      return (
        <div className={classes.textField} key={name}>
          <List disablePadding>
            <ListItem disableGutters>
              <TextField
                placeholder={label}
                label={label}
                value={fields[name]}
                onChange={this.handleChange(name)}
                autoComplete="off"
              />
              <Typography display="inline" className={classes.marginLabel}>
                {`USD / ${currentlyAdjustingMovement.coin}`}
              </Typography>
            </ListItem>
          </List>
        </div>
      );
    }
    return (
      <div className={classes.textField} key={name}>
        <List disablePadding>
          <ListItem disableGutters>
            <AutoFillTextBox knownAddressNames={knownWalletNames} />
          </ListItem>
        </List>
      </div>
    );
  }

  render() {
    const { classes, open, handleClose } = this.props;
    const { exchangeAndTaxData: { knownWallets } } = this.props;
    const { ui: { walletName, userInputKnownWallets } } = this.props;
    const { disabled } = this.state;

    let knownWalletNames = [];
    if (Array.isArray(userInputKnownWallets) && userInputKnownWallets.length > 0) {
      knownWalletNames = _.concat(knownWalletNames, userInputKnownWallets);
    }
    const previouslyInputtedButNotUploaded = JSON.parse(localStorage.getItem('digitax-userInputKnownWallets'));
    if (Array.isArray(previouslyInputtedButNotUploaded) && previouslyInputtedButNotUploaded.length > 0) {
      knownWalletNames = _.concat(knownWalletNames, previouslyInputtedButNotUploaded);
    }
    if (knownWallets && Object.keys(knownWallets).length > 0) {
      knownWalletNames = _.concat(knownWalletNames, Object.keys(knownWallets).map(address => knownWallets[address]));
    }

    knownWalletNames = [...new Set(knownWalletNames)].filter(v => v);

    if (walletName && this.state.dateMoved) {
      disabled.submit = false;
    }

    return (
      <Modal open={open} onClose={handleClose}>
        <div className={clsx(classes.paper, classes.outerDiv)}>
          <Card variant="outlined">
            <div className={clsx(classes.flex, classes.grey)}>
              <Typography variant="h6" className={classes.marginLeftOne} display="inline">
                {'Deposit From Storage'}
              </Typography>
              <CloseIcon className={classes.closeIcon} onClick={handleClose} />
            </div>
            <Divider />
            <Card className={classes.margin} variant="outlined">
              <div className={classes.grey}>
                <Typography variant="subtitle1" className={classes.marginLeftOne}>
                  {"Details"}
                </Typography>
              </div>
              <Divider />
              <Typography variant="subtitle2" className={classes.description}>
                {
                  "Please let us know where you deposited this currency from and, if at a different time than the deposit to the exchange, when it was withdrawn from the wallet.  For example, an exchange name, or a wallet name.  We are defaulting the date moved to the time of the deposit."
                }
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                {"If you know the cost basis for this currency, please enter it.  Otherwise we will try calculate it looking at exchange withdrawals you have previous marked as going to this wallet."}
              </Typography>
              <div className={clsx(classes.marginLeftOne, classes.marginTop, classes.alignTogether)}>
                <InfoOutlinedIcon className={classes.marginInfoIcon} />
                <Typography variant="subtitle2" className={classes.marginRight}>
                  <Box component="span" fontWeight="fontWeightMedium">
                    {"We should be able to calculate the cost basis properly if this wallet has only interacted with exchanges you have imported, and you have properly classified this wallet on other exchange transactions."}
                  </Box>
                </Typography>
              </div>
              {
                FIELDS.map(({ name, label }) => {
                  if (knownWalletNames.includes(walletName) && ['costBasis', 'dateAcquired']) {

                  }
                  return this.renderTextField({ name, label, knownWalletNames })
                })
              }
              <div className={classes.marginButtons}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={disabled.submit}
                  className={clsx(classes.marginLeftFour, classes.removeTextTransform)}
                  onClick={this.clickInsertStorage}
                >
                  {"Confirm"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disabled.clear}
                  onClick={this.reset}
                  className={clsx(classes.btnClear, classes.removeTextTransform)}
                >
                  {"Clear"}
                </Button>
              </div>
            </Card>
            <Typography variant="caption" className={classes.margin}>
              {"This is very important to make sure we can accurately calculate your tax liabilty."}
            </Typography>
          </Card>
        </div>
      </Modal>
    );
  }
}

_Modal.propTypes = {
  exchangeAndTaxData: PropTypes.shape({
    knownWallets: PropTypes.object,
  }),
  ui: PropTypes.shape({
    walletName: PropTypes.string,
    userInputKnownWallets: PropTypes.array
  })
}

const mapStateToProps = (state) => {
  return {
    exchangeAndTaxData: {
      knownWallets: state.exchangeAndTaxData.knownWallets
    },
    ui: {
      walletName: state.ui.walletName,
      userInputKnownWallets: state.ui.userInputKnownWallets
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { addStorageAndMovement })
)(_Modal);