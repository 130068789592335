import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  documentsCreated: [
    // records from the database
  ]
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return { ...state, documentsCreated: action.payload.documentsCreated };
    case FETCH_DOCUMENTS_ERROR:
      return { ...state, ...action.payload };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}