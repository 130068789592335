import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4),
      width: '50%'
    },
    padding: theme.spacing(2)
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    // marginLeft: theme.spacing(1)
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
});


class EmailSent extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.margin}>
            {'If the email address you provided had an account, we will have sent a password reset link.'}
          </Typography>
          <Divider />
          <div className={classes.margin}>
            <div className={classes.alignTogether}>
              <InfoOutlinedIcon className={classes.marginInfoIcon} />
              <Typography variant="body1">
                {`If you have not received an email to reset your password, please make sure that the email you entered is valid.`}
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}


EmailSent.propTypes = {
  recoverEmail: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    recoverEmail: state.auth.recoverEmail,
    errorMessage: state.auth.errorMessage.email
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(EmailSent);