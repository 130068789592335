import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const PrivacyPolicy = () => {
  return (
    <div>
      <Typography variant="h5">
        {'Effective: January 2, 2021'}
      </Typography>
      <List>
        <ListItem>
          {'Digital Taxes Co ("Digitax") is an online software platform that calculates capital gains and tax liabilities from cryptocurrency transactions ("Services").  The Services are operated by Digitax (the “Company”, “we” or “us”) for users of the Service (“you”). This Privacy Policy sets forth our policy with respect to information that is collected from visitors to the Site and Users of the Services. Under applicable law, Digitax is the “data controller” of personal data collected through the Services.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Information We Collect"}
      </Typography>
      <List>
        <ListItem>
          {"Information You Provide:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"We collect information from you when you voluntarily provide such information, such as when you register for access to the Services or use certain Services."}
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          {"Other Information We Collect:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"May include but not be limited to IP address, device ID, and your activities within the Services."}
            </ListItem>
            <ListItem>
              {"Info through other Services: You may give us permission to collect your information in other services. For example, you may connect your account at Digitax to a cryptocurrency exchange. When you do this, it allows us to obtain information from your account at the exchange (for example, trades and transfers)."}
            </ListItem>
            <ListItem>
              {"Cookies: We employ cookies and similar technologies to keep track of your local computer’s settings such as which account you have logged into and notification settings. Cookies are pieces of data that sites and services can set on your browser or device that can be read on future visits. We may expand our use of cookies to save additional data as new features are added to the Service."}
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Where Information is Processed"}
      </Typography>
      <List>
        <ListItem>
        {"The Company is based in the United States. No matter where you are located, you consent to the processing and transferring of your information in and to the U.S.. The laws of the U.S. governing data collection and use may not be as comprehensive or protective as the laws of the country where you live."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Our Use of Your Information"}
      </Typography>
      <List>
        <ListItem>
          {'We use the information you provide in a manner that is consistent with this Privacy Policy. If you provide information for a certain reason, we may use the information in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the information you provide to answer your question or resolve your problem. Also, if you provide information in order to obtain access to the Services, we will use your information to provide you with access to such Services and to monitor your use of such Services. The Company and its subsidiaries and affiliates (the “Related Companies”) may also use your information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. The Company and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each marketing communication we send you will contain instructions permitting you to "opt-out" of receiving future marketing communications. In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us at support@digitax.io.'}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Our legal bases for handling of your personal data"}
      </Typography>
      <List>
        <ListItem>
          {"The laws in some jurisdictions require companies to tell you about the legal ground they rely on to use or disclose your personal data. To the extent those laws apply, our legal grounds are as follows:"}
        </ListItem>
        <ListItem>
          <List>
            <ListItem>
              {"To honor our contractual commitments to you: Much of our processing of personal data is to meet our contractual obligations to our users, or to take steps at users’ request in anticipation of entering into a contract with them. For example, we handle personal data on this basis to create your account and provide our Services."}
            </ListItem>
            <ListItem>
              {"Legitimate interests: In many cases, we handle personal data on the ground that it furthers our legitimate interests in ways that are not overridden by the interests or fundamental rights and freedoms of the affected individuals: This includes:"}
            </ListItem>
            <ListItem>
              <List>
                <ListItem>
                  {"Providing a safe and enjoyable user experience;"}
                </ListItem>
                <ListItem>
                  {"Marketing, e.g. sending emails or other communications to let you know about new features;"}
                </ListItem>
                <ListItem>
                  {"Protecting our users, personnel, and property;"}
                </ListItem>
                <ListItem>
                  {"Managing legal issues."}
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              {"Consent: Where required by law, and in some other cases, we handle personal data on the basis of your implied or express consent."}
            </ListItem>
          </List>
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Our Disclosure of your Information"}
      </Typography>
      <List>
        <ListItem>
          {"The Company is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your information with certain third parties, as set forth below:"}
        </ListItem>
        <ListItem>
          {"Business Transfers: As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, bankruptcy, dissolution or similar event, your information may be part of the transferred assets."}
        </ListItem>
        <ListItem>
          {"Consent: We may transfer your information with your consent."}
        </ListItem>
        <ListItem>
          {"Legal Requirements: We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of the Company or Related Companies, (iii) protect the personal safety of users of the Services or the public, or (iv) protect against legal liability."}
        </ListItem>
        <ListItem>
          {"Aggregated or Non-identifiable Data: We may also share aggregated or non-personally identifiable information with our partners or others for business purposes."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Children"}
      </Typography>
      <List>
        <ListItem>
          {"Our Services are for users age 18 and over and we do not knowingly collect personal information from people under the age of 18."}
        </ListItem>
        <ListItem>
          {"If we learn that we are engaged in that processing with such users, we will halt such processing and will take reasonable measures to promptly remove applicable information from our records."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Links to other web site"}
      </Typography>
      <List>
        <ListItem>
          {"This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by us (the “Third Party Sites”). The policies and procedures we described here do not apply to the Third Party Sites. The links from the Services do not imply that we endorse or have reviewed the Third Party Sites. We suggest contacting those sites directly for information on their privacy policies."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Security"}
      </Typography>
      <List>
        <ListItem>
          {"We take reasonable steps to protect the information provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any information via the Internet."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Your Data Rights and Choices"}
      </Typography>
      <List>
        <ListItem>
          {"If you choose to not provide certain personal data to us, you will thereafter be not allowed to use our Services. We collect the information we do to maintain the Digitax's ability to provide services. If you do not agree to any of the sections or subsections within this Privacy Policy, you must cease your use of Digitax."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Erasing, Modifying, and Accessing Personal Data"}
      </Typography>
      <List>
        <ListItem>
          {"You may at any time review or change the personal data associated with your account or terminate your account by logging into Ditigax and updating your personal data. Upon your request to terminate your account, we will delete your account and information from our active databases."}
        </ListItem>
        <ListItem>
          {"If you would like to access, modify, or delete your personal data that are processed by Digitax, please send us an email to privacy@digitax.io."}
        </ListItem>
      </List>

      <Typography variant="h5">
        {"Contacting Us"}
      </Typography>
      <List>
        <ListItem>
          {"Please also feel free to contact us if you have any questions about this Privacy Policy or the information practices of the Services. You may contact us at privacy@digitax.io."}
        </ListItem>
      </List>
    </div>
  );
}

export default PrivacyPolicy;