import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadTradeData } from 'actions';
import ImportGrid from './Grid';
import Typography from '@material-ui/core/Typography';
import validateTradeData from 'modules/utils/tradeDataValidators'; // perhaps this should live on the outside
import SortingTableErrorData from 'modules/components/SortingTableErrorData';
import tradeColumns from 'modules/utils/tradeDataValidators/tradeColumns';
import walletColumns from 'modules/utils/tradeDataValidators/walletColumns';
import { TRADES, WALLET } from 'modules/utils/tradeDataValidators/types';
import logger from 'modules/utils/logger';
import { Button } from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'none'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  marginRight: {
    marginRight: theme.spacing(1)
  }
});

class ImportGridContainer extends React.Component {
  state = {
    pendingSubmitFiles: [],
    // pendingSubmitExchange: '',
    submittedFiles: [],
    dropZoneKey: 1,
    badData: []
  };

  handleDropzoneChange = (file) => {
    let myCurrentFiles = this.state.pendingSubmitFiles;
    myCurrentFiles.push(file);

    // makes this unique in case we have any dupes
    // this also handles this function being called multiple times when someone submits twice
    let set = new Set(myCurrentFiles.map(JSON.stringify));
    myCurrentFiles = Array.from(set).map(JSON.parse);
    logger.info('after handleDropZoneChange, myCurrentFiles', myCurrentFiles, myCurrentFiles.length);
    this.setState({ pendingSubmitFiles: myCurrentFiles });
  };

  // handleSelectChange = (event) => {
  //   logger.info('handleSelectChange called');
  //   this.setState({ pendingSubmitExchange: event.target.value });
  // };

  handleSubmit = (event) => {
    // call action creator to upload to the database
    event.preventDefault();
    const {
      pendingSubmitFiles,
      // pendingSubmitExchange,
      submittedFiles,
      dropZoneKey
    } = this.state;

    this.setState({ dropZoneKey: dropZoneKey + 1 });

    if (pendingSubmitFiles !== []) {
      // validation, needs work...
      logger.info('length of pendingSubmitFiles before validation call', pendingSubmitFiles.length);
      const fileOutput = validateTradeData({
        exchange: 'digitax',
        files: pendingSubmitFiles
      });

      if (fileOutput.length > 0) {
        let mySubmittedFiles;
        fileOutput.forEach(({ type, isValid, tradeData, badTrades }) => {
          logger.info('badDatahere', badTrades);
          logger.info('tradeData', tradeData);
          if (isValid) {
            logger.info(type, 'was valid');
            logger.info(tradeData[0]);
            this.props.uploadTradeData({ exchange: 'digitax', type, transactions: tradeData });
            mySubmittedFiles = submittedFiles;
            mySubmittedFiles.push({
              digitax: pendingSubmitFiles
            });
          } else if (badTrades.length > 0) {
            logger.info('here');
            const { badData } = this.state;
            const newBadData = badTrades.map(({ error, id, trade }) => ({
              error,
              id,
              trade: { ...trade, exchange: 'digitax' }
            }));
            badData.push(newBadData);
            this.setState({ badTrades });
          }
        });

        this.setState({
          submittedFiles: mySubmittedFiles,
          pendingSubmitFiles: [],
          // pendingSubmitExchange: 'None'
        });
      } else {
        logger.info('no length');
      }
    }
  }

  render() {
    const { badData } = this.state;
    const { classes } = this.props;
    logger.info(badData, 'badData')
    return (
      <div className={classes.root}>
        {this.state.pendingSubmitFiles.length > 0 ? (
          <div className={classes.flex}>
            <Button
              variant="contained"
              className={classes.button}
              disabled={this.state.pendingSubmitFiles.length <= 0}
              onClick={this.handleSubmit}
              color="primary"
            >
              <CloudUpload className={classes.marginRight} />
              <Typography variant="h5">
                {'Upload To Digitax'}
              </Typography>
            </Button>
          </div>
        ) : null}
        <ImportGrid
          handleDropzoneChangeCallback={this.handleDropzoneChange}
          // pendingSubmitExchange={this.state.pendingSubmitExchange}
          // handleSelectChange={this.handleSelectChange}
          hasUploaded={this.state.pendingSubmitFiles.length !== 0}
          // handleSubmit={this.handleSubmit}
          dropZoneKey={this.state.dropZoneKey}
          hasUploadedDataToDb={this.props.hasUploadedDataToDb}
        />
        {
          badData.length > 0 ?
            <SortingTableErrorData
              key={0}
              tradeData={badData}
              displayColumns={
                Object.keys(badData[0][0].trade).length > 11 ?
                  tradeColumns :
                  walletColumns
              }
              transactionType={
                Object.keys(badData[0][0].trade).length > 11 ?
                  TRADES :
                  WALLET
              }
            /> :
            null
        }
      </div>
    );
  }
};

ImportGridContainer.propTypes = {
  uploadTradeData: PropTypes.func.isRequired
}

export default compose(
  withStyles(styles),
  connect(null, { uploadTradeData })
)(ImportGridContainer);