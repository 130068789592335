import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { storeHeaderTabChange, fetchCalcStatus } from 'actions';
import requireAuth from 'modules/components/requireAuth';
import NoTransactions from './components/NoTransactions';
import StillFetching from './components/StillFetching';
import NavBar from './components/NavBar';
import Calc from './pages/calc';
import Review from './pages/review';
import GainLoss from './pages/gainLoss';
import Plan from './pages/plan';
import Documents from './pages/documents';


const styles = theme => ({
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    width: '50%'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  }
});

class CalculateTopLevel extends React.Component {
  state = {
    hasUploadedData: null
  }

  componentDidMount() {
    const { exchangeAndTaxData: { tradeDataByExchange } } = this.props;

    if (tradeDataByExchange !== undefined && Object.keys(tradeDataByExchange).length > 0) {
      this.setState({ hasUploadedData: true });
    }

    const { ui: { isCalculatingGainsLosses } } = this.props;
    const { api: { calculatingGainLoss } } = this.props;
    if ((isCalculatingGainsLosses === 'calculating') || (Array.isArray(calculatingGainLoss) &&
        calculatingGainLoss.length > 0)
    ) {
      this.props.fetchCalcStatus();
    }
  }

  renderRoutesComponent = () => {
    const { url } = this.props.match;

    let ComponentToRender;
    if (url === '/calculate/calc') {
      ComponentToRender = Calc;
    } else if (url === '/calculate/review') {
      ComponentToRender = Review;
    } else if (url === '/calculate/gainLoss') {
      ComponentToRender = GainLoss;
    } else if (url === '/calculate/plan') {
      ComponentToRender = Plan;
    } else if (url === '/calculate/documents') {
      ComponentToRender = Documents;
    } else if (url === '/calculate') {
      ComponentToRender = Calc;
    }

    const { apiFetching } = this.props.api;

    if (Array.isArray(apiFetching) && ['/calculate/calc', '/calculate/gainLoss', '/calculate/documents', '/calculate/review'].includes(url)) {
      return (
        <div>
          <NavBar url={this.props.match.url} />
          <StillFetching />
        </div>
      );
    }



    return (
      <div>
        <NavBar url={this.props.match.url} />
        <ComponentToRender />
      </div>
    );
  }


  render() {
    const { hasUploadedData } = this.state;

    if (!hasUploadedData) {
      return (
        <div>
          <NavBar url={this.props.match.url} />
          <NoTransactions />
        </div>
      );
    }
    // put in most recent unmatched run
    // if not reconciled, show the not reconciled yet

    return this.renderRoutesComponent();
  }
}

CalculateTopLevel.propTypes = {
  storeHeaderTabChange: PropTypes.func.isRequired,
  api: PropTypes.shape({
    apiFetching: PropTypes.array
  }),
}

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: state.exchangeAndTaxData,
    api: {
      apiFetching: state.api.apiFetching,
      calculatingGainLoss: state.api.calculatingGainLoss
    },
    ui: {
      isCalculatingGainsLosses: state.ui.isCalculatingGainsLosses
    },
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { storeHeaderTabChange, fetchCalcStatus })
)(requireAuth(CalculateTopLevel));