import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  signOut,
  gotoAccountTab,
  clearUICallbacks,
  storeHeaderTabChange,
  updateTaxYear,
  setMobileMenuState,
  storeCalculateTabChange
} from 'actions';
import history from '../../../history';
// import LogoSvgGradient from './logo_svgs/logo_gradient';
import LogoSvgSolid from './logo_svgs/logo_neon_solid';
// import FiberNewOutlinedIcon from '@material-ui/icons/FiberNewOutlined';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(3),
  },
  homepageButton: {
    marginRight: theme.spacing(2)
  },
  userNameButton: {
    marginRight: theme.spacing(4),
    textTransform: 'none'
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '25.5%',
    },
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      width: '12.5%',
      minWidth: '8%',
      maxWidth: '15%'
    },
    marginRight: theme.spacing(2)
  },
  tabWidth: {
    flexGrow: 1,
    width: '15%'
  },
  flex: {
    display: 'flex',
    margin: 'auto'
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  marginRightOne: {
    marginRight: theme.spacing(1)
  },
  siginMobile: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideUnlessVeryLarge: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  hideWhenVeryLarge: {
    [theme.breakpoints.up('ld')]: {
      display: 'none'
    }
  },
  marginTop: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0.75)
    }
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(0.7)
  },
  toolbarTitle: {
    flex: 1,
  }
});

class ButtonAppBar extends React.Component {
  state = {
    tabIndex: 0,
    taxYear: 2019,
    modalOpen: false,
    mobile: {
      currentSelection: null
    }
  }

  componentDidMount() {
    const year = localStorage.getItem('digitax_tax_year');
    if (year) {
      this.setState({ taxYear: year });
    } else {
      localStorage.setItem('digitax_tax_year', '2020');
    }
    // not sure how to get this working properly
    // if (!this.props.auth.firstName) {
    //   if (this.props.location.pathname === '/whatweoffer') {
    //     this.props.storeHeaderTabChange(0);
    //   } else if (this.props.location.pathname === '/exchanges') {
    //     this.props.storeHeaderTabChange(1);
    //   } else if (this.props.location.pathname === '/pricing') {
    //     this.props.storeHeaderTabChange(2);
    //   } else if (this.props.location.pathname === '/aboutus') {
    //     this.props.storeHeaderTabChange(3);
    //   } else {
    //     this.setState({ tabIndex: false });
    //   }
    // }
    // logger.info('this.props.auth.firstName', this.props.auth.firstName);
    // logger.info('this.props.location.pathname', this.props.location.pathname);
  }

  triggerAccountTab = () => {
    this.props.gotoAccountTab();
  }

  linkOnClick = () => {
    this.props.clearUICallbacks();
  }

  handleModalClose = () => {
    this.props.clearDashboardModal();
    this.setState({ modalOpen: false });
  }

  handleSignoutClick = () => {
    this.props.signOut();
  }

  mobileHamburgerMenu = () => {
    this.props.setMobileMenuState();
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({ tabIndex });
    this.props.storeHeaderTabChange(tabIndex);
    const { auth } = this.props;

    if (auth.email) {
      if (tabIndex === 0) {
        history.push('/dashboard');
      } else if (tabIndex === 1) {
        history.push('/dashboard/import');
      } else if (tabIndex === 2) {
        history.push('/calculate/review');
      } else if (tabIndex === 3) {
        history.push('/pricing');
      }
    } else {
      if (tabIndex === 0) {
        history.push('/whatweoffer');
      } else if (tabIndex === 1) {
        history.push('/exchanges');
      } else if (tabIndex === 2) {
        history.push('/pricing');
      } else if (tabIndex === 3) {
        history.push('/aboutus');
      } else if (tabIndex === 4) {
        history.push('/blog/home');
      }
    }
  }

  handleAuthMobileMenuClick = (name, event) => {
    if (['account', 'dashboard', 'calculate'].includes(name)) {
      if (name === 'calculate') {
        history.push(`/${name}/review`);
      } else {
        history.push(`/${name}`);
      }
      this.props.storeCalculateTabChange(4);
      this.setState({ mobile: { currentSelection: null } });
    } else if (name === 'import') {
      history.push(`/dashboard/${name}`);
      this.setState({ mobile: { currentSelection: null } });
    } else if (name === 'documents') {
      history.push(`/calculate/${name}`);

      this.setState({ mobile: { currentSelection: null } });
    } else if (name === 'signout') {
      this.setState({ mobile: { currentSelection: null } });
      this.props.signOut();
    } else {
      this.setState({ mobile: { currentSelection: event.currentTarget } });
    }
  }

  handleMobileMenuClick = (name, event) => {
    if (['whatweoffer', 'exchanges', 'pricing', 'aboutus', 'signin', 'signup'].includes(name)) {
      history.push(`/${name}`);
      this.setState({ mobile: { currentSelection: null } });
    } else if (name === 'home') {
      history.push('/');
      this.setState({ mobile: { currentSelection: null } });
    } else if (name === 'blog') {
      history.push('/blog/home');
      this.setState({ mobile: { currentSelection: null } });
    } else {
      this.setState({ mobile: { currentSelection: event.currentTarget } });
    }
  }

  handleClose = (event) => {
    this.setState({ mobile: { currentSelection: null } });
  }

  renderActionBar = () => {
    const { classes, headerTabSelected, auth } = this.props;

    let _headerTabSelected = headerTabSelected;
    if (auth.firstName) {
      _headerTabSelected = false;
    }

    if (auth.email) {
      return (
        <div className={classes.hideOnMobile}>
          <Tabs value={_headerTabSelected} onChange={this.handleTabChange}>
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Tax Dashboard"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Import Data"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Calculate Profit/Loss"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Plans & Pricing"
            />
          </Tabs>
        </div>
      );
      // else if (window.location.pathname.includes('/blog')) {
      // return (
      //   <Typography
      //     component="h2"
      //     variant="h5"
      //     color="inherit"
      //     align="center"
      //     noWrap
      //     className={classes.toolbarTitle}
      //   >
      //     {"Our Very Own Tax Blog"}
      //   </Typography>
      // )
    } else {
      return (
        <div className={classes.hideOnMobile}>
          <Tabs value={_headerTabSelected} onChange={this.handleTabChange}>
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="What We Offer"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Exchanges"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Pricing"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="About Us"
            />
            <Tab
              className={clsx(classes.tabWidth, classes.hideOnMobile, classes.removeTextTransform)}
              label="Blog"
              // icon={<FiberNewOutlinedIcon/>}
            />
          </Tabs>
        </div>
      );
    }
  }

  renderLogin() {
    const { classes, auth: { email } } = this.props;
    const { mobile: { currentSelection } } = this.state;
    if (email) {
      // needs styling, well the whole app needs styling...
      return (
        <div>
          <div className={classes.hideUnlessVeryLarge}>
            <Link component={RouterLink} to="/account" color="inherit">
              <Button className={classes.userNameButton} onClick={this.triggerAccountTab}>
                <Typography variant="subtitle2">
                  {email}
                </Typography>
              </Button>
            </Link>
            <Button // this should actually log us out of our account
              variant="contained"
              className={classes.removeTextTransform}
              onClick={this.handleSignoutClick}
            >
              {'Sign Out'}
            </Button>
          </div>
          <div className={classes.hideWhenVeryLarge}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              aria-controls="hamburger-menu"
              onClick={(e) => this.handleAuthMobileMenuClick('open', e)}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="hamburger-menu"
              anchorEl={currentSelection}
              keepMounted
              open={Boolean(currentSelection)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('dashboard', e)}>{"Dashboard"}</MenuItem>
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('account', e)}>{"My Account"}</MenuItem>
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('import', e)}>{"Import Data"}</MenuItem>
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('calculate', e)}>{"Calculate Gains and Losses"}</MenuItem>
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('documents', e)}>{"My Tax Documents"}</MenuItem>
              <MenuItem onClick={(e) => this.handleAuthMobileMenuClick('signout', e)}>{"Sign Out"}</MenuItem>
            </Menu>
          </div>
        </div>
      );
    }

    // i want to return a login button
    return (
      <div>
        <div className={classes.hideOnDesktop}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls="hamburger-menu"
            onClick={(e) => this.handleMobileMenuClick('open', e)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="hamburger-menu"
            anchorEl={currentSelection}
            keepMounted
            open={Boolean(currentSelection)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={(e) => this.handleMobileMenuClick('home', e)}>{"Home"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('whatweoffer', e)}>{"What We Offer"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('exchanges', e)}>{"Exchanges"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('pricing', e)}>{"Pricing"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('aboutus', e)}>{"About Us"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('blog', e)}>{"Blog"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('signin', e)}>{"Sign In"}</MenuItem>
            <MenuItem onClick={(e) => this.handleMobileMenuClick('signup', e)}>{"Sign Up"}</MenuItem>
          </Menu>
        </div>
        <div className={classes.hideOnMobile}>
          <Link component={RouterLink} to="/signin" color="inherit">
            <Button
              color="inherit"
              className={clsx(classes.siginMobile, classes.marginRightOne, classes.removeTextTransform)}
              onClick={this.linkOnClick}
            >
              {'Sign In'}
            </Button>
          </Link>
          <Link component={RouterLink} to="/signup" color="inherit">
            <Button
              variant="contained"
              color="primary"
              className={clsx(classes.siginMobile, classes.removeTextTransform)}
              onClick={this.linkOnClick}
            >
              {'Sign Up'}
            </Button>
          </Link>
        </div>
      </div>
    );
  }


  DigitaxIcon = () => {
    const { classes } = this.props;

    // if (email) {
    //   return (
    //     <SvgIcon className={classes.menuButton}>
    //       <Link component={RouterLink} to="/dashboard">
    //         <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    //       </Link>
    //     </SvgIcon>
    //   );
    // }
    // return (
    //   <SvgIcon className={classes.menuButton}>
    //     <Link component={RouterLink} to="/">
    //       <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    //     </Link>
    //   </SvgIcon>
    // );
    // return (
    //   <div className={classes.minWidth}>
    //     <img src="logo_svgs/logo_gradient2.svg" />
    //   </div>
    // )
    return (
      <div className={classes.logo}>
        <Link
          component={RouterLink}
          to="/dashboard"
          color="inherit"
          className={classes.homepageButton}
        >
          <LogoSvgSolid />
        </Link>
      </div>
    );
  }

  handleTaxYearChange = (event, data) => {
    let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
    const { value } = data.props;
    this.setState({ taxYear: value });
    this.props.updateTaxYear(value, gotoRoute);
  }

  renderYear = () => {
    const { classes } = this.props;
    const { auth: { email } } = this.props;

    if (email) {
      return (
        <div className={clsx(classes.marginTop, classes.marginRight)}>
          <FormControl>
            <Select
              id={'tax-year-select'}
              // labelId={'tax-year-select-label'}
              displayEmpty
              value={this.state.taxYear}
              onChange={this.handleTaxYearChange}
              inputProps={{ 'aria-label': 'Tax Year' }}
            >
              <MenuItem value={2021}>{'2021'}</MenuItem>
              <MenuItem value={2020}>{'2020'}</MenuItem>
              <MenuItem value={2019}>{'2019'}</MenuItem>
              <MenuItem value={2018}>{'2018'}</MenuItem>
              <MenuItem value={2017}>{'2017'}</MenuItem>
              <MenuItem value={2016}>{'2016'}</MenuItem>
              <MenuItem value={2015}>{'2015'}</MenuItem>
              <MenuItem value={2014}>{'2014'}</MenuItem>
              <MenuItem value={2013}>{'2013'}</MenuItem>
              <MenuItem value={2012}>{'2012'}</MenuItem>
            </Select>
            <FormHelperText>{"Tax Year"}</FormHelperText>
          </FormControl>
        </div>
      );
    }

    return null;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            {this.DigitaxIcon()}
            {this.renderActionBar()}
            <div className={classes.grow}></div>
            <div className={clsx(classes.marginBottomSmall, classes.alignTogether)}>
              {this.renderYear()}
              {this.renderLogin()}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

// type forcing the classes prop on this.props
ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    firstName: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    headerTabSelected: state.ui.headerTabSelected,
    taxYear: state.exchangeAndTaxData.taxYear,
  };
}

// https://react-redux.js.org/using-react-redux/connect-mapdispatch
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      signOut,
      gotoAccountTab,
      clearUICallbacks,
      storeHeaderTabChange,
      updateTaxYear,
      setMobileMenuState,
      storeCalculateTabChange
    }, dispatch)
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ButtonAppBar);
