import React from 'react';
import ReactDOM from 'react-dom';
import Wrapped from './Wrapped';
import App from './pages/App';

// remove this line of course when we have a user we can sign into
//localStorage.setItem('token', 1234);
// initialState = {


ReactDOM.render(
  <Wrapped>
    <App />
  </Wrapped>,
  document.querySelector('#root')
);
