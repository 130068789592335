// export const adjustmentTypes = [
//   { value: 'fork', label: 'Received currency from a fork' },
//   { value: 'deposit', label: 'Deposited currency from a wallet or other storage.' },
//   { value: 'withdrawal', label: 'Withdrew currency to a wallet or other storage.' },
//   { value: 'mining', label: 'Currency received from mining.' },
//   { value: 'spent', label: 'Spent currency on something.' },
//   { value: 'income', label: 'Currency received as income.' },
//   { value: 'gift_received', label: 'Currency received as a gift.' },
//   { value: 'gift_sent', label: 'You gave away this currency as a gift' },
//   { value: 'stolen', label: 'This currency was stolen from you.' },
//   { value: 'airdrop', label: 'You received this currency from an Airdrop' },
//   { value: 'lost_access', label: 'You lost lost access to this currency' },
//   { value: 'none', label: 'None' }
// ];
export const DEPOSIT_PAIR = 'deposit_pair';
export const FORK = 'fork';
export const DEPOSIT = 'deposit';
export const MINING = 'mining';
export const INCOME = 'income';
export const GIFT_RECEIVED = 'gift_received';
export const AIRDROP = 'airdrop';
export const WITHDRAWAL_PAIR = 'withdrawal_pair';
export const WITHDRAWAL = 'withdrawal';
export const SPENT = 'spent';
export const GIFT_SENT = 'gift_sent';
export const STOLEN = 'stolen';
export const LOST_ACCESS = 'lost_access';
export const NONE = 'none';

export const depositAdjustmentTypes = [
  { value: DEPOSIT_PAIR, label: 'Pair to another Withdrawal.' },
  { value: FORK, label: 'Received currency from a fork.' },
  { value: DEPOSIT, label: 'Deposited currency from a wallet or other storage.' },
  { value: MINING, label: 'Currency received from mining.' },
  { value: INCOME, label: 'Currency received as income.' },
  { value: GIFT_RECEIVED, label: 'Currency received as a gift.' },
  { value: AIRDROP, label: 'You received this currency from an Airdrop.' },
  { value: NONE, label: 'None' }
];

export const withdrawalAdjustmentTypes = [
  { value: WITHDRAWAL_PAIR, label: 'Pair to another Deposit.' },
  { value: WITHDRAWAL, label: 'Withdrew currency to a wallet or other storage.' },
  { value: SPENT, label: 'Spent currency on something.' },
  { value: GIFT_SENT, label: 'You gave away this currency as a gift.' },
  { value: STOLEN, label: 'This currency was stolen from you.' },
  { value: LOST_ACCESS, label: 'You lost access to this currency.' },
  { value: NONE, label: 'None' }
];