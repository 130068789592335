import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { fetchFromExchangeAPI } from 'actions';


const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-25%, -25%)`,
      top: `25%`,
      left: `35%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 25%)`,
    }
    // midWidth: '60%'
  },
  marginText: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  alignCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1)
  },
  marginLeftFour: {
    marginLeft: theme.spacing(4)
  },
  marginButtons: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  // dialog: {
  //   width: '60%'
  // },
  marginCenter: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid black',
    width: '100%',
    minHeight: '3em'
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  alignLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  instructionsContainer: {
    marginTop: theme.spacing(1)
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  exitToAppIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  instructionsText: {
    marginTop: '2px',
    marginRight: theme.spacing(0.5)
  },
  headerAlign: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  marginBottomText: {
    marginBottom: theme.spacing(1)
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  oauthLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
});


// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

class _Modal extends React.Component {

  sendToServer = () => {
    // const { csrfToken } = this.props;

    const csrfToken = sessionStorage.getItem('digitax-csrf-token');
    // PROD CHANGE
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/coinbase?csrfToken=${csrfToken}`;

    // _self enforces the same tab, though apparently it's ALSO a browser setting :/
    window.open(url, "_self");
  }

  render() {
    const { classes, open, exchange, handleClose } = this.props;

    return (
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          <div className={classes.headerAlign}>
            <div className={classes.alignLeft}>
              <Typography variant="h5">
                {`Connect to ${exchange.display}`}
              </Typography>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                component={RouterLink}
                to={`/integrations/${exchange.name}`}
                className={clsx(classes.instructionsContainer, classes.linkTextColor, classes.alignRight)}
              >
                <Typography variant="subtitle2" className={classes.instructionsText}>
                  {`Instructions`}
                </Typography>
                <ExitToAppIcon className={classes.exitToAppIcon} />
              </Link>
            </div>
            <Tooltip title="Close" aria-label="close-form">
              <Button className={classes.closeIcon} onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider className={classes.marginBottom} />
          <Typography className={classes.marginBottomText}>
            {`By clicking the link below, you will be sent to ${exchange.display}.com where you will be asked to log in and to grant Digitax `}
            <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
              {"read-only"}
            </Box>
            {` access of your account.  We will not have the ability to transact or remove funds.`}
          </Typography>
          <Typography>
            {`Then upon successfully returning to Digitax, we will start to fetch your transaction data from ${exchange.display}.`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={clsx(classes.marginTop, classes.marginBottom, classes.removeTextTransform)}
            onClick={this.sendToServer}
          >
            <Typography variant="subtitle1">
              <Box fontWeight="fontWeightMedium">
                {`Continue to ${exchange.display}`}
              </Box>
            </Typography>
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}


export default compose(
  withStyles(styles),
  connect(null, { fetchFromExchangeAPI })
)(_Modal);