import axios from 'axios';
import FileDownload from 'js-file-download';
import history from '../history';
import { batch } from 'react-redux';
import _ from 'lodash';
import {
  AUTH_USER,
  AUTH_ERROR,
  // STORE_CSRF_TOKEN,
  RECOVER_ACCOUNT,
  RECOVER_ACCOUNT_ERROR,
  FETCH_DOCUMENTS,
  UPLOAD_TRADEDATA,
  UPLOAD_TRADEDATA_ERROR,
  UPLOAD_WALLETDATA,
  FETCH_TRANSACTION_DATA,
  TRIGGER_IN_PROGRESS,
  TRIGGER_RECOVER_IN_PROGRESS,
  TRIGGER_SIGNOUT_IN_PROGRESS,
  TRIGGER_RECONILE_MOVEMENTS,
  PROGRESS_COMPLETE,
  DELETE_RECORD_ERROR,
  // DELETE_EXCHANGE_DATA,
  DELETE_EXCHANGE_DATA_ERROR,
  SIGN_OUT,
  // FETCH_DOCUMENT_ERROR,
  PLAN_AND_BILLING_TAB,
  ACCOUNT_TAB,
  FETCH_ACCOUNT_DETAILS_ON_RECOVER,
  FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR,
  RESET_PASSWORD_FROM_RECOVERY,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  TRIGGER_DISCOUNT_CODE_IN_PROGRESS,
  VALIDATE_DISCOUNT_CODE,
  DASHBOARD_MODAL,
  FETCHING_EXCHANGE_DATA,
  FETCHING_EXCHANGE_DATA_ERROR,
  FETCH_TRANSACTION_DATA_APPEND,
  FETCH_CREATING_DOCUMENTS,
  HEADER_TAB_SELECT,
  CALCULATE_TAB_SELECT,
  SET_SELECTED_EXCHANGE,
  UPDATE_TAX_YEAR,
  CALCULATING_GAINS_AND_LOSSES,
  // CALCULATING_GAINS_AND_LOSSES_ERROR,
  UPDATE_PLAN_FOR_TAX_YEAR,
  UPDATE_FBAR_FOR_TAX_YEAR,
  CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE,
  ALREADY_UPLOADED,
  CLASSIFY_UNMATCHED_TRADE,
  CLASSIFY_UNMATCHED_MOVEMENT,
  UPDATE_COST_BASIS,
  CREATE_NEW_MVMT_FOR_STORAGE,
  CLEAR_ACTIVE_UNMATCHED,
  // UNMATCHED_UPDATED,
  TRIGGER_CALCULATE,
  API_MESSAGE_MODAL,
  APP_ERROR_MESSAGE_MODAL,
  APP_API_MESSAGE_MODAL,
  MOBILE_MENU_OPEN,
  RECONCILE_RUN,
  ZERO_COST_BASIS,
  UPGRADE_ACCOUNT,
  UPDATE_MAX_TRANSACTIONS,
  IS_PLAN_SUFFICIENT,
  API_FETCHING_DATA,
  CLEAN_API_FETCHING_DATA,
  PARTIAL_BASIS_DETAIL,
  WALLET_NAME_UPDATE
  // CREATE_8949_PDF,
  // CREATE_8949_CSV,
  // CREATE_8949_TXT,
  // CREATE_8949_TURBOTAX,
  // CREATE_SCHEDULE_1,
  // CREATE_FBAR,
  // CREATE_INCOME_TAX_SUMMARY
  // UPLOAD_WALLETDATA_ERROR
} from './types';
import planTypesTransLimits from 'modules/statics/planTypesTransLimits';
import { TRADES, WALLET } from 'modules/utils/tradeDataValidators/types';
import {
  responseMessages,
  SUCCESSFUL_FETCH,
  SUCCESSFUL_CALCULATION_TRIGGER,
  DELETED_EXCHANGE_DATA,
  API_MESSAGE_TEST,
  SUCCESSFUL_GENERATE_DOCUMENT_TRIGGER,
  UNMATCHED_TRANSACTIONS_EXIST,
  SUCCESSFUL_RECONCILE_TRIGGER,
  SUCCESSFUL_ZERO_COST_BASIS_UPLOAD
} from './responseMessages';
import exchanges from 'modules/statics/exchangesMapInverse';
import logger from 'modules/utils/logger';
import { getCSRFToken, displayApiModal, displayErrorModal } from './helpers';
import fetchUserWithCookieErrorHandler from './errorHandlers/fetchUserWithCookieHandler';
import signUpHandler from './errorHandlers/signUpHandler';
import dispatchUserData from './sharedActionDispatches/userData';
// import ledgerWorker from 'workerize-loader!./workers/ledgerWorker'; // eslint-disable-line import/no-webpack-loader-syntax

axios.defaults.withCredentials = true;

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}


// export const fetchUser = () => {
//   return function(dispatch) {
//     axios
//       .get('/api/current_user')
//       .then(res => dispatch({ type: FETCH_USER, payload: res}));
//   };
// }

// note this pulls our user model.
export const fetchUserWithCookie = (previousRoute, taxYear, statusType) => async dispatch => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  try {
    logger.info('fetchUserWithCookie called');
    logger.info('previousRoute', previousRoute);
    let _taxYear;
    if (taxYear == null) {
      _taxYear = 2020;
    } else {
      _taxYear = parseInt(taxYear);
    }
    logger.info('_taxYear', _taxYear);
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/currentUser`, { params: { _taxYear } });
    logger.info('fetchUser response:');
    logger.info(response);

    if ('data' in response && JSON.stringify(response.data) === "{}") {
      dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
      return;
    }

    dispatchUserData(response, dispatch, statusType);

    if ('responseUser' in response.data) {
      // logger.info('not redirecting');
      if (previousRoute && response.data.responseUser.isEmailConfirmed) {
        logger.info('redirecting');
        history.replace(previousRoute);
      }
    }
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    dispatch({
      type: AUTH_ERROR,
      payload: "either don't have a cookie or didn't pull the user"
    });
    displayErrorModal(fetchUserWithCookieErrorHandler(e), dispatch);
    dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
    // maybe good idea to just import history rather than pass it in? imo will prevent these dumb errors
    // history.push('/signin');
  }
};

export const signUp = (formValues) => async dispatch => {
  // formValues = { email: 'lawl@lawlz.com', password: 'xdww', firstName: 'CPCT', lastName: 'Ravis', confirmPassword: 'xdww' }
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/signup`, formValues);
    logger.info(`the response object from signup is`);
    logger.info(response);
    dispatch({ type: AUTH_USER, payload: response.data.responseUser });
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      // dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: response.headers['csrf-token'] } });
    }
    history.push('/dashboard');

    displayApiModal({ header: 'details', message: "We've sent an confirmation email to the email address you provided.  Please confirm your email address." }, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
      // dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: e.response.headers['csrf-token'] } });
    }

    console.log('e.response', e.response);

    displayErrorModal(signUpHandler(e.response.data), dispatch);
    //  displayErrorModal({ header: 'details', message: 'Problem Getting User Data' }, dispatch);
    //displayErrorModal({ header: 'details', message: 'xdww' }, dispatch);

  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
};

export const signIn = (formValues) => async (dispatch) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  try {
    // TNT NOTE/TODO: get the _taxYear from the localStorage, and if its not there, then default to 2019 imo
    logger.info('calling signIN actions, process.env.REACT_APP_BACKEND_URL:  ', process.env.REACT_APP_BACKEND_URL);
    let _taxYear = localStorage.getItem('digitax_tax_year');
    if (!_taxYear) {
      _taxYear = 2020;
    }
    // TNT NOTE: _taxYear MUST BE A NUMBER!
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/signin`, { ...formValues, _taxYear });
    logger.info('sign in server response');
    logger.info(response);
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      // dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: response.headers['csrf-token'] } });
    }

    dispatchUserData(response, dispatch);

    history.push('/dashboard')
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
      // dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: e.response.headers['csrf-token'] } });
    }

    if ((e.response && e.response.data && e.response.data.name === 'PassportLoginError') || (e.response && e.response.status === 401)) {
      displayErrorModal({ header: 'details', message: 'Incorrect email or password' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'CSRFError') {
      displayErrorModal({ header: 'details', message: 'Please refresh the page and then attempt to sign in.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'DuplicateAccountError') {
      displayErrorModal({ header: 'details', message: "That's an error, this email has an issue." }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem Getting User Data' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
};

export const signOut = () => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_SIGNOUT_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('signout called');
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/signout`, {}, { headers });
    logger.info('sign out server response');
    logger.info(response);
    dispatch({ type: AUTH_USER, payload: response });
    dispatch({ type: SIGN_OUT, payload: "You've been signed out." });
    history.push('/');
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    if (e.response && e.response.data && e.response.data.name === 'CSRFError') {
      displayErrorModal({ header: 'details', message: 'Please refresh page and retry your action.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'SignInRequiredError') {
      displayErrorModal({ header: 'details', message: 'You must be signed in before trying to logout' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem Signing Out.  Please Retry.' }, dispatch);
    }
    history.push('/');
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const recoverAccount = (email) => async dispatch => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  // email will be an object like this { email: 'xdww@haxor.com' }
  try {
    logger.info('request to /api/emailPasswordReset', email);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/emailPasswordReset`, email);
    if (response.data === 'ok') {
      const { enteredEmail } = email;
      dispatch({ type: RECOVER_ACCOUNT, payload: { 'recoverEmail': enteredEmail } }); // sends to reducers
    } else {
      dispatch({ type: RECOVER_ACCOUNT_ERROR, payload: { 'errorMessage': 'Problem Sending Recovery Email.  Please Re-try and contact us at TODO: insert customer support email here' } });
    }

    history.push('/signin/recover/emailsent');

  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    if (e.response && e.response.data && e.response.data.name === 'EmailSentError') {
      //   dispatch({ type: RECOVER_ACCOUNT_ERROR, payload: { 'errorMessage': e.response.data.message } });
      displayErrorModal({ header: 'details', message: 'Our Email Service Provider is Experiencing issues.  Please re-try later when the service is back and functioning.' }, dispatch);
      history.push('/signin/recover/emailsent');

    } else if (e.response && e.response.data && e.response.data.name === 'RateLimitError') {
      //  dispatch({ type: RECOVER_ACCOUNT_ERROR, payload: { 'errorMessage': 'You have tried to reset your password too soon after previously resetting it.  Please wait a minute, or check your email inbox to see if you have received a password reset email.' } });
      displayErrorModal({ header: 'details', message: 'You have tried to reset your password too soon after previously resetting it.  Please wait a minute, or check your email inbox to see if you have received a password reset email.' }, dispatch);
      history.push('/signin/recover/emailsent');

    } else if (e.response && e.response.data && e.response.data.name === 'NotUniqueError') {
      displayErrorModal({ header: 'details', message: 'Problem with generating a Password Reset Token.  Please Click the link below to reset your password again.' }, dispatch);
      history.push('/signin/recover/emailsent');

    } else if (e.response && e.response.data && e.response.data.name === 'CriticalTokenError') {
      displayErrorModal({ header: 'details', message: 'Problem with your generated Password Reset Token.  Please Click the link below to reset your password again.' }, dispatch);
      history.push('/signin/recover/emailsent');
    } else {
      //    displayErrorModal(e, dispatch);
      //   dispatch({ type: RECOVER_ACCOUNT_ERROR, payload: { 'errorMessage': 'Problem Sending Recovery Email.  Please Re-try and contact us at TODO: insert customer support email here' } });
      displayErrorModal({ header: 'details', message: 'There is an issue with your account.  Please contact our support at support@digitax.io for more information.' }, dispatch);
      history.push('/signin/recover/emailsent');
    }
    logger.info('recoverAccount error response', e.response);
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
};

// i think this is unused
export const fetchDocumentsForUser = () => async dispatch => {
  // try {
  //   const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/documents`, token);
  //
  // if (response.headers['csrf-token']) {
  //   dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: response.headers['csrf-token'] } });
  // }
  //   dispatch({ type: FETCH_DOCUMENTS, payload: response.data.token }); // sends to reducers
  //
  //   history.push('/dashboard')
  // } catch (e) {
  //   dispatch({ type: FETCH_DOCUMENTS_ERROR, payload: 'We failed to receive documents for this user, perhaps an invalid user?' });
  // }

  // note maybe i should be splitting this into a separate route for actually querying the database
  dispatch({
    type: FETCH_DOCUMENTS,
    payload: {
      documents: {},
      hasUploadedData: false
    }
  });
}

export const uploadTradeData = ({ exchange, type, transactions }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('sending uploadTradeData');
    logger.info(type, exchange);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/uploadTradeData`,
      {
        transactions,
        type,
        exchange
      },
      { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info('response from uploadTradeData');
    logger.info(type);
    logger.info(response);

    let thisResponse;
    if (type === TRADES) {
      thisResponse = {
        tradeDataByExchange: {
          [exchange]: {
            otherProps: 'pnl!',
            trades: transactions,
            error: null
          }
        },
        hasUploadedData: true,
        exchange
      };
      dispatch({ type: UPLOAD_TRADEDATA, payload: thisResponse }); // sends to reducers
    } else if (type === WALLET) {
      thisResponse = {
        walletDataByExchange: {
          [exchange]: {
            otherProps: 'pnl!',
            trades: transactions,
            error: null
          }
        },
        hasUploadedData: true,
        exchange
      };
      dispatch({ type: UPLOAD_WALLETDATA, payload: thisResponse }); // sends to reducers
    }
    displayApiModal({ header: 'details', message: 'Successfully uploaded.' }, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }

    dispatch({ type: UPLOAD_TRADEDATA_ERROR, payload: null });
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
  // dispatch({
  //   type: UPLOAD_TRADEDATA,
  //   payload: {
  //     tradeDataByExchange: {
  //       [exchange]: {  // i need to make better
  //         otherProps: 'pnl!',
  //         trades: tradeData,
  //         error: null
  //       },
  //     },
  //     hasUploadedData: true,
  //     exchange
  //   }
  // });
}

export const uploadManualTradeData = ({ transactions, type }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('sending uploadManualTradeData');
    logger.info(transactions);
    // tradeData has exchange for each specific one in it
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/uploadTradeData`, { transactions, type }, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info('response from uploadManualTradeData');
    logger.info(response);

    let thisResponse;
    const exchanges = [...new Set(transactions.map(({ exchange }) => exchange))];
    const exchObj = {};
    const tradeDataFromServer = response.data.data;
    logger.info('data from server', response.data.data);
    let trades;
    exchanges.forEach(exchange => {
      trades = tradeDataFromServer.filter(trade => trade.exchange === exchange);
      exchObj[exchange] = {
        otherProps: 'pnl!',
        trades,
        error: null
      };
    });
    if (type === TRADES) {
      for (const exchange of Object.keys(exchObj)) {
        thisResponse = {
          tradeDataByExchange: { [exchange]: exchObj[exchange] },
          hasUploadedData: true,
          exchange
        };
        dispatch({ type: UPLOAD_TRADEDATA, payload: thisResponse }); // sends to reducers
      };
    } else if (type === WALLET) {
      for (const exchange of Object.keys(exchObj)) {
        thisResponse = {
          walletDataByExchange: { [exchange]: exchObj[exchange] },
          hasUploadedData: true,
          exchange
        };
        dispatch({ type: UPLOAD_WALLETDATA, payload: thisResponse }); // sends to reducers
      };
    }

    displayApiModal({ header: 'details', message: 'Successfully uploaded.' }, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }
    dispatch({ type: UPLOAD_TRADEDATA_ERROR, payload: null });
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const displayModal = ({ header, message }) => async (dispatch, getState) => {
  try {
    displayApiModal({ header, message }, dispatch);
  } catch (e) {
    if (e.message) {
      displayErrorModal({ header: 'details', message: e.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unpected' }, dispatch);
    }
  }
}

export const updatePassword = (newAndOldPasswords) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/accountChangePassword`, newAndOldPasswords, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of new password details`);
    logger.info(response);
    logger.info(response.data);
    dispatch({ type: AUTH_USER, payload: response.data });
  } catch (e) {
    logger.info('we got a failure back from change password');
    logger.info(e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.name === 'CSRFError') {
      displayErrorModal({ header: 'details', message: 'Please refresh page and then try updating your password.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'SignInRequiredError') {
      displayErrorModal({ header: 'details', message: 'Must be signed in before updating your password.  Please sign in and try again.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'CriticalError') {
      displayErrorModal({ header: 'details', message: 'Problem with your account, please contact our support at support@gmail.com' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem updating your password.' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
};

export const changePasswordFromRecovery = (
 { newPassword, token, users_id }
) => async (dispatch) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/changePasswordFromRecovery`, { newPassword, token, users_id }
    );
    logger.info(`the response object of new password details`);
    logger.info(response);
    logger.info(response.data);

    if ('status' in response.data && response.data.status === 'complete') {
      dispatch({
        type: RESET_PASSWORD_FROM_RECOVERY,
        payload: { passwordHasBeenReset: true }
      });
    }
  } catch (e) {
    logger.info(e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.name === 'ExpirationError') {
      displayErrorModal({ header: 'details', message: 'Your password reset token has expired.  Please request another password reset token below.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'CriticalError') {
      displayErrorModal({ header: 'details', message: 'Problem with your account, please contact our support at support@gmail.com' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem resetting your password, please try again.' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
};

export const updateAccountDetail = (newAccountDetails) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info(newAccountDetails);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/accountChangeDetails`, newAccountDetails, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of new account details`);
    logger.info(response);

    displayApiModal({ header: 'Details', message: 'Successfully changed account details.' }, dispatch);

    dispatch({ type: AUTH_USER, payload: response.data });
  } catch (e) {
    logger.info("we errored on new account details", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    if (e.response && e.response.status && e.response.status === 409) {
      displayErrorModal({ header: 'details', message: 'Problem Editing Account Details' }, dispatch);
      dispatch({ type: AUTH_ERROR, payload: 'Email already in use' });
    } else {
      displayErrorModal({ header: 'details', message: 'Problem Editing Account Details' }, dispatch);
      dispatch({ type: AUTH_ERROR, payload: { errorMessage: { email: 'unknown error' } } });
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const deleteRecords = (data) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('data to delete', data);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/deleteTransactions`, data, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }

    dispatchUserData(response, dispatch);
  } catch (e) {
    logger.info("we errored on deleting the record", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    if (e.response && e.response.status && e.response.status === 409) {
      dispatch({ type: DELETE_RECORD_ERROR, payload: 'Failed to delete this transaction.  Try refreshing the page.' });
    } else {
      if (e.response && e.response.data && e.response.data.message) {
        displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
      } else {
        displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
      }

      dispatch({ type: DELETE_RECORD_ERROR, payload: 'unknown error' });
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}


export const deleteExchangeData = ({ _exchanges }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('exchange data to delete', _exchanges);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/deleteExchangeData`, { exchanges: _exchanges }, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of deleteExchangeData`);
    logger.info(response);
    dispatchUserData(response, dispatch);
    // trigger modal
    const message = _.cloneDeep(responseMessages[DELETED_EXCHANGE_DATA]);

    let exchString = '';
    if (_exchanges.length > 1) {
      _exchanges.forEach(exch => {
        exchString += exchanges[exch] + ', ';
      });
    } else if (_exchanges.length === 1) {
      exchString = exchanges[_exchanges[0]];
    } else {
      exchString = '';
    }

    message.header = replaceAll(message.header, 'replace_exchange', exchString);
    response.message = message;

    displayApiModal(response, dispatch);
    // dispatch({
    //   type: DELETED_EXCHANGE_DATA,
    //   payload: { deletedExchangeDataMessage: message, deleteSelectedExchange: exchange }
    // });
  } catch (e) {
    logger.info("we errored on deleting the exchange data", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    // we can change this..
    if (e.response && e.response.status && e.response.status === 409) {
      dispatch({ type: DELETE_EXCHANGE_DATA_ERROR, payload: 'Failed to delete transactions for this exchange.  Try refreshing the page.' });
    } else {
      if (e.response && e.response.data && e.response.data.message) {
        displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
      } else {
        displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
      }
      dispatch({ type: DELETE_EXCHANGE_DATA_ERROR, payload: 'unknown error' });
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

// perhaps add createdAt handling here?
// not sure if we want to even store historical copies for them
export const fetchDocumentForUser = ({ taxYear, documentType, documentCreationId }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('taxYear fetching for', taxYear, documentType, documentCreationId);
    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/getDocument`,
      method: 'GET',
      params: {
        taxYear,
        documentType,
        documentCreationId
      },
      headers,
      responseType: 'blob'
    });
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info('the response object of fetching the file is:');
    logger.info(response);

    const PDF_8949 = '8949_pdf';
    const CSV_8949 = '8949_csv';
    const TXT_8949 = '8949_txt';
    const TURBOTAX_8949 = '8949_turbotax';
    const SCHEDULE_1 = 'schedule1';
    const FBAR = 'fbar';
    const INCOME_TAX_SUMMARY = 'income_tax_summary';
    let filename = undefined;
    if (documentType === PDF_8949) {
      filename = `8949_${taxYear}.pdf`;
    } else if (documentType === TURBOTAX_8949) {
      filename = `turbotax_8949_${taxYear}.pdf`;
    } else if (documentType === SCHEDULE_1) {
      filename = `schedule_1_${taxYear}.pdf`;
    } else if (filename === FBAR) {
      filename = `fbar_${taxYear}.csv`;
    } else if (documentType === INCOME_TAX_SUMMARY) {
      filename = `income_tax_summary_${taxYear}.csv`;
    } else if (documentType === CSV_8949) {
      filename = `8949_${taxYear}.csv`;
    } else if (documentType === TXT_8949) {
      filename = `8949_${taxYear}.txt`;
    }
    // kicks off the file download in the browser
    FileDownload(response.data, filename);
  } catch (e) {
    logger.info("we errored on fetching the data", e);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const fetchAccountDataWithToken = ({ token, users_id }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    // token is { token: token }
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/accountDetailsFromToken`, { token, users_id }, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of the fetch account details with token`);
    logger.info(response);

    dispatch({ type: FETCH_ACCOUNT_DETAILS_ON_RECOVER, payload: response.data });
  } catch (e) {
    logger.info("the response object of the errored fetch account details with token", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    if (e.response && e.response.data && e.response.data.name === 'CSRFError') {
      dispatch({ type: FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR, payload: { errorMessage: 'csrf-error' } });
      displayErrorModal({ header: 'details', message: 'Please refresh page and retry your action.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'ExpirationError') {
      dispatch({ type: FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR, payload: { errorMessage: 'expiration-error' } });
      displayErrorModal({ header: 'details', message: 'Token has expired, you will need to reset your password again.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'CriticalError') {
      dispatch({ type: FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR, payload: { errorMessage: 'critical-error' } });
      displayErrorModal({ header: 'details', message: 'Problem with your account, please contact our support at TNT TODO_INSERT_EMAIL_HERE' }, dispatch);
    } else {
      dispatch({ type: FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR, payload: { errorMessage: 'unknown-error' } });
      displayErrorModal({ header: 'details', message: 'Problem Retrieving Account Information.  Please Refresh Page or login again.' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const confirmEmail = ({ token }) => async dispatch => {
  dispatch({ type: TRIGGER_RECOVER_IN_PROGRESS, payload: { isInProgress: true } });
  try {
    logger.info('calling post to /api/emailVerfication', { token });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/emailVerification`, { token }
    );
    logger.info(`the response object of confirmEmail`);
    logger.info(response);

    dispatch({ type: CONFIRM_EMAIL, payload: { isEmailConfirmed: true } });
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
  } catch (e) {
    logger.info("the response object of the confirmEmail errored", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    if (e.response && e.response.data && e.response.data.message) {
      dispatch({ type: CONFIRM_EMAIL_ERROR, payload: { errorMessage: 'There was a problem verifying your email.  Please navigate to our homepage.' } });
    }

    if (e.response && e.response.data && e.response.data.name === 'ExpirationError') {
      displayErrorModal({ header: 'details', message: 'Email Verification Token has expired, please click the link below to resend the email confirmation.' }, dispatch);
    }
    else if (e.response && e.response.data && e.response.data.name === 'CriticalError') {
      displayErrorModal({ header: 'details', message: 'Something is wrong with your account, please contact customer support at support@gmail.com' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem verifying Email, please re-try.' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const validateDiscountCode = (discountCode) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_DISCOUNT_CODE_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/checkDiscountCode`, { discountCode }, { headers }
    );
    logger.info(`the response object of validateDiscountCode`);
    logger.info(response);

    dispatch({
      type: VALIDATE_DISCOUNT_CODE,
      payload: {
        reducePriceBy: response.data.reducePriceBy,
        discountMessage: response.data.discountMessage
      }
    });
    displayApiModal({ header: 'details', message: 'Successfully applied code.' }, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    logger.info("the response object of the validateDiscountCode errored", e.response);
    logger.info('error object %O', e);
    logger.info('data: %O', e.response.data);
    logger.info('name: %O', e.response.data.name);
    if (e.response && e.response.data && e.response.data.name === 'SignInRequiredError') {
      displayErrorModal({ header: 'details', message: 'Must be signed in before updating your password.  Please sign in and try again.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'CSRFError') {
      displayErrorModal({ header: 'details', message: 'Please refresh page and retry your action.' }, dispatch);
    } else if (e.response && e.response.data && e.response.data.name === 'InvalidDiscountCodeError') {
      if (e.response.headers['csrf-token']) {
        sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
      }
      displayErrorModal({ header: 'details', message: 'This discount code is invalid.' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem with applying the discount code, it may no longer be viable.' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

// TNT NOTE/TODO: need to rework this based on potential error responses.  Also why do we want tohe exchange back that we already send?
export const fetchFromExchangeAPI = ({ exchange, apiKeys, csrfToken }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  let { headers } = getCSRFToken(getState);
  try {
    // handling for coinbase
    if (csrfToken) {
      headers = { 'csrf-token': csrfToken };
    }
    logger.info('calling fetchFromExchangeAPI', { exchange, apiKeys }, { headers });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/fetchClientData`, { exchange, apiKeys }, { headers }
    );
    logger.info(`the response object of fetchFromExchangeAPI`);
    logger.info(response);
    // so this is necessary because on redirect back to the page
    // we call fetchUserWithCookie -> and the FetchExchangeDataFromAPI hasn't been called yet
    if (exchange === 'coinbase') {
      sessionStorage.setItem('digitax-did-just-fetch-coinbase', 'yes');
    }
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }

    if ('error' in response.data && response.data.error === ALREADY_UPLOADED) {
      logger.info('should be called');
      const message = responseMessages[ALREADY_UPLOADED];
      message.header = replaceAll(message.header, 'replace_exchange', exchanges[exchange]);
      dispatch({
        type: ALREADY_UPLOADED,
        payload: { fetchExchangeMessage: responseMessages[ALREADY_UPLOADED] }
      });
    } else {
      response.message = _.cloneDeep(responseMessages[SUCCESSFUL_FETCH]);
      displayApiModal(response, dispatch);
      // dispatch({
      //   type: FETCHING_EXCHANGE_DATA,
      //   payload: {
      //     loading: true,
      //     fetchExchangeMessage: responseMessages[SUCCESSFUL_FETCH],
      //     importSelectedExchange: exchange
      //   }
      // });

      let doesUpdateApiFetching = false;
      if (response.data && response.data.apiFetching) {
        doesUpdateApiFetching = true;
      }

      let doesUpdateUserImports = false;
      if (response.data.userImports) {
        doesUpdateUserImports = true;
      }

      batch(() => {
        if (doesUpdateApiFetching) {
          dispatch({ type: API_FETCHING_DATA, payload: { apiFetching: response.data.apiFetching } });
        }

        if (doesUpdateUserImports) {
          dispatch({
            type: FETCH_TRANSACTION_DATA,
            payload: {
              userImports: response.data.userImports,
              hasUploadedData: true,
            }
          });
        }
      });
    }
  } catch (e) {
    logger.info("the response object of the fetchFromExchangeAPI errored");
    logger.info(e);
    logger.info(e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }

    // we can change this..
    if (e.response && e.response.status === 409) {
      // this means we are still fetching, no error
      // jboxxx note - i don't see this on the backend
      displayErrorModal({ header: 'Detail', message: "We're still fetching your data for this exchange." }, dispatch);
      // dispatch({ type: FETCHING_EXCHANGE_DATA, payload: { loading: false } });
      // dispatch({ type: FETCHING_EXCHANGE_DATA_ERROR, payload: { errorMessage: null } });
    } else if (e.response && e.response.status === 422) {
      displayErrorModal({ header: 'Error', message: 'Unexpected Error' }, dispatch);
      // dispatch({
      //   type: FETCHING_EXCHANGE_DATA_ERROR,
      //   payload: { fetchExchangeDataError: e.response.data.errorMessage }
      // });
    } else if (e.response && e.response.status === 403 && e.response.data && e.response.data.errorMessage) {
      displayErrorModal({ header: 'Error', message: e.response.data.errorMessage }, dispatch);
    } else {
      displayErrorModal({ header: 'Error', message: 'Unexpected Error' }, dispatch);

      // dispatch({
      //   type: FETCHING_EXCHANGE_DATA_ERROR,
      //   payload: { errorMessage: 'Unexpected Error' }
      // });
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

// need to add the setInterval fetch
// ez but refresh for the time being
export const triggerCalculateGainLoss = (csrfToken) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  // might need to handle this headers
  let headers;
  if (csrfToken) {
    headers = { 'csrf-token': csrfToken };
  } else {
    headers = getCSRFToken(getState).headers;
  }
  try {
    dispatch({ type: TRIGGER_CALCULATE, payload: null });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calculateGainLosses`, {}, { headers }
    );
    logger.info(`the response object of triggerCalculateGainLoss`);
    logger.info(response);

    // dispatch({ type: FETCHING_EXCHANGE_DATA, payload: { loading: true } });
    response.message = _.cloneDeep(responseMessages[SUCCESSFUL_CALCULATION_TRIGGER]);
    displayApiModal(response, dispatch);
    dispatch({
      type: CALCULATING_GAINS_AND_LOSSES,
      payload: {
        calculatingGainLoss: [response.data], // [{ status: CALCULATING }]
        isCalculatingGainsLosses: response.data.status // CALCULATING
      }
    });
    // dispatch({
    //   type: CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE,
    //   payload: { calcGainLossMessage: responseMessages[SUCCESSFUL_CALCULATION_TRIGGER] }
    // });
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
  } catch (e) {
    logger.info("the response object of the triggerCalculateGainLoss errored", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }

    // // we can change this..
    // if ('response' in e && 'status' in e.response && e.response.status === 409) {
    //   // this means we are still fetching, no error
    //   dispatch({ type: CALCULATING_GAINS_AND_LOSSES, payload: { loading: false } });
    //   dispatch({ type: CALCULATING_GAINS_AND_LOSSES_ERROR, payload: { errorMessage: null } });
    // } else if ('response' in e && 'status' in e.response && e.response.status === 422) {
    //   dispatch({
    //     type: CALCULATING_GAINS_AND_LOSSES_ERROR,
    //     payload: { calculatingError: e.response.data.errorMessage }
    //   });
    // } else {
    //   dispatch({
    //     type: CALCULATING_GAINS_AND_LOSSES_ERROR,
    //     payload: { calculatingError: 'Unexpected Error' }
    //   });
    // }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const triggerReconcileMovements = ({ reset }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  // might need to handle this headers
  let headers = getCSRFToken(getState).headers;

  try {
    dispatch({ type: TRIGGER_RECONILE_MOVEMENTS, payload: null });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/reconcileMovements`, { reset }, { headers }
    );
    logger.info(`the response object of triggerReconcileMovements`);
    logger.info(response);

    const { mostRecentReconcileRun } = response.data;
    let doesUpdateMostRecentReconcileRun = false;
    if (mostRecentReconcileRun) {
      doesUpdateMostRecentReconcileRun = true;
    }

    const { unmatched } = response.data;
    let doesUpdateUnmatchedTrades = false;
    let doesUpdateUnmatchedMovements = false;
    let hasUnmatched = false;
    if (unmatched) {
      if (unmatched.unmatchedTrades && Array.isArray(unmatched.unmatchedTrades)) {
        if (unmatched.unmatchedTrades.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTrades = true;
      }
      if (unmatched.unmatchedMovements && Array.isArray(unmatched.unmatchedMovements)) {
        if (unmatched.unmatchedMovements.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovements = true;
      }
    }

    let doesUpdateCSRFToken = false;
    if (response.headers['csrf-token']) {
      doesUpdateCSRFToken = true;
    }

    if (hasUnmatched) {
      response.message = _.cloneDeep(responseMessages[UNMATCHED_TRANSACTIONS_EXIST]);
      displayApiModal(response, dispatch)
    } else {
      response.message = _.cloneDeep(responseMessages[SUCCESSFUL_RECONCILE_TRIGGER]);
      displayApiModal(response, dispatch)
      history.push('/calculate/calc');
      dispatch(storeCalculateTabChange(2));
    }

    batch(() => {
      if (doesUpdateMostRecentReconcileRun) {
        dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun } });
      }
      if (doesUpdateUnmatchedTrades) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedTrades: unmatched.unmatchedTrades }
        });
      }
      if (doesUpdateUnmatchedMovements) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedMovements: unmatched.unmatchedMovements }
        });
      }
      if (doesUpdateCSRFToken) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
    });
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // default handling
    logger.info("the response object of the triggerReconcileMovements errored", e.response);
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const clearAPIAuthErrors = () => {
  return {
    type: AUTH_USER,
    payload: { errorMessage: {} }
  };
}

export const yearChangeModal = ({ taxYear }) => {
  return {
    type: DASHBOARD_MODAL,
    payload: { displayMessage: `Year changed successfully to the tax year ${taxYear} (01 Jan ${taxYear} to 31 Dec ${taxYear}).` }
  };
}

export const upgradeAccountAfterPurchase = ({ planName }) => async (dispatch, getState) => {
  const currentState = getState();
  let maxTransactions = planTypesTransLimits[planName] ? planTypesTransLimits[planName].maxTransactions : null;
  if (currentState.auth && currentState.auth.planName && currentState.auth.maxTransactions &&
    currentState.auth.planName === 'professional' && planName === 'professional'
  ) {
    // they added 25k transactions, already purchased professional
    maxTransactions += (currentState.auth.maxTransactions + 25000);
  }

  let isPlanSufficient = false;
  if (currentState.exchangeAndTaxData && typeof currentState.exchangeAndTaxData.totalTransactions === 'number') {
    if (maxTransactions >= currentState.exchangeAndTaxData.totalTransactions) {
      isPlanSufficient = true;
    }
  }

  batch(() => {
    dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient } });
    dispatch({ type: UPGRADE_ACCOUNT, payload: { planName, maxTransactions } });
  });
}

export const clearDashboardModal = () => {
  return {
    type: DASHBOARD_MODAL,
    payload: { displayMessage: null }
  };
}

export const gotoPlanAndBillingTab = () => {
  return {
    type: PLAN_AND_BILLING_TAB,
    payload: { tabSelected: 1 }
  };
}

export const gotoAccountTab = () => {
  return {
    type: ACCOUNT_TAB,
    payload: { tabSelected: 0 }
  };
}

export const gotoMyDataTab = () => {
  return {
    type: ACCOUNT_TAB,
    payload: { tabSelected: 2 }
  };
}

export const clearResetPasswordMessage = () => {
  return {
    type: RESET_PASSWORD_FROM_RECOVERY,
    payload: { passwordHasBeenReset: false }
  };
}

export const fetchingExchangeDataState = () => {
  return {
    type: FETCHING_EXCHANGE_DATA,
    payload: { isLoadingExchangeData: true }
  };
}

export const clearFetchingExchangeDataError = () => {
  return {
    type: FETCHING_EXCHANGE_DATA_ERROR,
    payload: { fetchExchangeDataError: null }
  };
}

export const clearFetchingExchangeDataMessages = () => {
  return {
    type: FETCHING_EXCHANGE_DATA,
    payload: { fetchExchangeMessage: null }
  };
}

export const clearDeletedExchangeDataMessages = () => {
  return {
    type: DELETED_EXCHANGE_DATA,
    payload: { deletedExchangeDataMessage: null }
  };
}

export const storeHeaderTabChange = (tabIndex) => {
  return {
    type: HEADER_TAB_SELECT,
    payload: { headerTabSelected: tabIndex }
  };
}

export const updateTradeAdjustmentType = ({ trade_id, adjustmentType }) => {
  return {
    type: CLASSIFY_UNMATCHED_TRADE,
    payload: { trade_id, adjustmentType }
  };
}

export const clearCurrentlyAdjustingMovement = () => {
  return {
    type: CLEAR_ACTIVE_UNMATCHED,
    payload: { currentlyAdjustingMovement: {} }
  };
}

export const updateMovementAdjustmentType = ({ trade_id, adjustmentType }) => {
  return {
    type: CLASSIFY_UNMATCHED_MOVEMENT,
    payload: { trade_id, adjustmentType }
  };
}

export const storeCalculateTabChange = (tabIndex) => {
  return {
    type: CALCULATE_TAB_SELECT,
    payload: { calculateTabSelected: tabIndex }
  };
}

export const clearCalcGainLossMessages = () => {
  return {
    type: CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE,
    payload: { calcGainLossMessage: null }
  };
}

export const clearAPIErrorMessage = () => {
  return {
    type: API_MESSAGE_MODAL,
    payload: { apiErrorMessage: null }
  };
}

export const clearAppErrorMessage = () => {
  return {
    type: APP_ERROR_MESSAGE_MODAL,
    payload: { appErrorMessage: null }
  };
}

export const clearAppApiMessage = () => {
  return {
    type: APP_API_MESSAGE_MODAL,
    payload: { appApiMessage: null }
  };
}

export const setAppApiMessage = (messageObject) => {
  return {
    type: APP_API_MESSAGE_MODAL,
    payload: { appApiMessage: messageObject }
  };
}

export const setAPIErrorMessage = () => {
  return {
    type: API_MESSAGE_MODAL,
    payload: { apiErrorMessage: responseMessages[API_MESSAGE_TEST] }
  };
}

export const addDonorCostBasisToMovement = ({ currentlyAdjustingMovement, donorCostBasis, donorDateAcquired }) => {
  // also changes the currentlyAdjustingMovement to {}
  return {
    type: UPDATE_COST_BASIS,
    payload: { currentlyAdjustingMovement, donorCostBasis, donorDateAcquired }
  };
}

export const addStorageAndMovement = (
  { currentlyAdjustingMovement, costBasis, storage, dateAcquired, dateMoved, isDeposit }
) => {
  // also changes the currentlyAdjustingMovement to {}
  return {
    type: CREATE_NEW_MVMT_FOR_STORAGE,
    payload: { currentlyAdjustingMovement, costBasis, storage, dateAcquired, dateMoved, isDeposit }
  };
}

export const setMobileMenuState = () => {
  return {
    type: MOBILE_MENU_OPEN,
    payload: { mobileMenuOpen: true }
  };
}

export const updateTaxYear = (taxYear, previousRoute) => async (dispatch, getState) => {
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('taxYear in updatedTaxYear is : ', taxYear);
    localStorage.setItem('digitax_tax_year', taxYear);
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get-purchases-for-tax-year/${taxYear}`, { headers });
    logger.info('The response from get-purchases-for-tax-year are : %O');

    logger.info('response.data', response.data);

    let doesStoreCSRFToken = false;
    if (response.headers['csrf-token']) {
      doesStoreCSRFToken = true;
    }

    const { planName, maxTransactions, purchasedFbarForTaxYear } = response.data;
    let doesUpdateTaxYear = false;
    let doesUpdateTaxYearPlan = false;
    if (typeof planName === 'string') {
      // probably could do the same with transactions as well.  ho hum
      if (response.data) {
        doesUpdateTaxYear = true;
        displayApiModal(
          { header: 'details', message: `Year changed successfully to the tax year ${taxYear} (01 Jan ${taxYear} to 31 Dec ${taxYear}).` }, dispatch
        );
      }
      doesUpdateTaxYearPlan = true;
    }

    let doesHaveFbarForTaxYear = false;
    if (purchasedFbarForTaxYear && purchasedFbarForTaxYear === true) {
      doesHaveFbarForTaxYear = true;
    }

    let doesUpdateMaxTransactions = false;
    let isPlanTypeSufficent = false;

    if (typeof maxTransactions === 'number') {
      const state = getState();
      if (state.exchangeAndTaxData && typeof state.exchangeAndTaxData.totalTransactions === 'number') {
        if (maxTransactions >= state.exchangeAndTaxData.totalTransactions) {
          isPlanTypeSufficent = true;
        }
      }
      doesUpdateMaxTransactions = true;
    }

    batch(() => {
      if (doesHaveFbarForTaxYear) {
        dispatch({ type: UPDATE_FBAR_FOR_TAX_YEAR, payload: { hasPurchasedFbarForTaxYear: true } });
      } else {
        dispatch({ type: UPDATE_FBAR_FOR_TAX_YEAR, payload: { hasPurchasedFbarForTaxYear: false } });
      }

      if (doesUpdateTaxYear) {
        dispatch({ type: UPDATE_TAX_YEAR, payload: { taxYear } });
      }
      if (doesUpdateTaxYearPlan) {
        dispatch({ type: UPDATE_PLAN_FOR_TAX_YEAR, payload: { planName } });
      }

      if (doesUpdateMaxTransactions) {
        dispatch({ type: UPDATE_MAX_TRANSACTIONS, payload: { maxTransactions } });
      }

      if (isPlanTypeSufficent) {
        dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: true } });
      } else {
        dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: false } });
      }

      if (doesStoreCSRFToken) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
    });
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    logger.info(e.response);
    if (e.response && e.response.data && e.response.data.name === 'SignInRequiredError') {
      displayErrorModal({ header: 'details', message: 'Must be signed in to get your relevant account plan for the selected tax year.' }, dispatch);
    } /*else if (err.response.data.name === 'CSRFError') {
      displayErrorModal({ header: 'details', message: 'Please refresh page in order for our servers to check your account plan for the selected tax year.' }, dispatch);
    }*/ else {
      displayErrorModal({ header: 'details', message: `Problem getting any account plans purchased for the selected tax year.` }, dispatch);
    }
  }
}


export const setSelectedExchange = (exchange) => {
  return {
    type: SET_SELECTED_EXCHANGE,
    payload: { exchange }
  };
}

export const clearUICallbacks = () => async dispatch => {
  batch(() => {
    dispatch({ type: AUTH_USER, payload: { errorMessage: {}, callbackMessage: '' } });
    dispatch({
      type: RESET_PASSWORD_FROM_RECOVERY,
      payload: { passwordHasBeenReset: false }
    });
    dispatch({ type: ACCOUNT_TAB, payload: { tabSelected: 0 } });
  });
}

// type is like wallet or trade
// this triggers a calculate
export const uploadUpdatedUnmatched = ({ recordsToUpdate, type }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('calling uploadUpdatedUnmatched', { recordsToUpdate, type });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/updateUnmatchedTransactions`, { recordsToUpdate, type }, { headers }
    );

    logger.info(`the response object of uploadUpdatedUnmatched`);
    logger.info(response);

    let doesUpdateCSRFToken = false;
    if (response.headers['csrf-token']) {
      doesUpdateCSRFToken = true;
    }

    let doesUpdateMostRecentReconcileRun = false;
    const { mostRecentReconcileRun } = response.data;
    if (mostRecentReconcileRun) {
      doesUpdateMostRecentReconcileRun = true;
    }

    const { importIdRecord } = response.data;
    let doesUpdateImportIdRecord = false;
    if (importIdRecord) {
      doesUpdateImportIdRecord = true;
    }

    const { unmatched } = response.data;
    let hasUnmatched = false;
    let doesUpdateUnmatchedTrades = false;
    let doesUpdateUnmatchedMovements = false;
    let doesUpdateUnmatchedTradeRecords = false;
    let doesUpdateUnmatchedMovementRecords = false;
    if (unmatched) {
      if (unmatched.unmatchedTrades && Array.isArray(unmatched.unmatchedTrades)) {
        if (unmatched.unmatchedTrades.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTrades = true;
      }
      if (unmatched.unmatchedMovements && Array.isArray(unmatched.unmatchedMovements)) {
        if (unmatched.unmatchedMovements.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovements = true;
      }
      if (unmatched.unmatchedTrades && Array.isArray(unmatched.unmatchedTrades)) {
        if (unmatched.unmatchedTrades.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTrades = true;
      }
      if (unmatched.unmatchedMovements && Array.isArray(unmatched.unmatchedMovements)) {
        if (unmatched.unmatchedMovements.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovements = true;
      }
      if (unmatched.unmatchedTradeRecords && Array.isArray(unmatched.unmatchedTradeRecords)) {
        if (unmatched.unmatchedTradeRecords.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTradeRecords = true;
      }
      if (unmatched.unmatchedMovementRecords && Array.isArray(unmatched.unmatchedMovementRecords)) {
        if (unmatched.unmatchedMovementRecords.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovementRecords = true;
      }
    }

    batch(() => {
      if (doesUpdateCSRFToken) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      if (doesUpdateMostRecentReconcileRun) {
        dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun } });
      }
      if (doesUpdateImportIdRecord) {
        dispatch({ type: FETCH_TRANSACTION_DATA_APPEND, payload: { userImports: importIdRecord } });
      }
      if (doesUpdateUnmatchedTrades) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedTrades: unmatched.unmatchedTrades }
        });
      }
      if (doesUpdateUnmatchedMovements) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedMovements: unmatched.unmatchedMovements }
        });
      }
      if (doesUpdateUnmatchedTradeRecords) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedTradeRecords: unmatched.unmatchedTradeRecords }
        });
      }
      if (doesUpdateUnmatchedMovementRecords) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedMovementRecords: unmatched.unmatchedMovementRecords }
        });
      }
    });

    if (hasUnmatched) {
      response.message = _.cloneDeep(responseMessages[UNMATCHED_TRANSACTIONS_EXIST]);
      displayApiModal(response, dispatch)
    } else {
      response.message = _.cloneDeep(responseMessages[SUCCESSFUL_RECONCILE_TRIGGER]);
      displayApiModal(response, dispatch);
    }

  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // default handling
    logger.info("the response object of the uploadUpdatedUnmatched errored", e.response);
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const triggerGenerateDocument = ({ documentType, taxYear }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('documentType in triggerCreateDocument', documentType);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/generateDocuments`, { documentType, taxYear }, { headers }
    );
    logger.info(`the response object of triggerCreateDocument`);
    logger.info(response);
    let doesUpdateCSRFToken = false;
    if (response.headers['csrf-token']) {
      doesUpdateCSRFToken = true;
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }

    let doesUpdateDocumentsCreating = false
    if ('data' in response && 'documentsCreating' in response.data) {
      const _documentsCreating = response.data.documentsCreating;
      if (_documentsCreating && _documentsCreating.data && _documentsCreating.data.length > 0) {
        doesUpdateDocumentsCreating = true;
      }
    }

    batch(() => {
      if (doesUpdateCSRFToken) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      const { documentsCreating } = response.data;
      if (doesUpdateDocumentsCreating) {
        dispatch({ type: FETCH_CREATING_DOCUMENTS, payload: { documentsCreating: documentsCreating.data } });
      }
    })

    response.message = _.cloneDeep(responseMessages[SUCCESSFUL_GENERATE_DOCUMENT_TRIGGER]);
    displayApiModal(response, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && typeof e.response.data.message === 'string') {
      displayErrorModal({ header: 'Details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'Details', message: 'Unexpected problem with generating document' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

// export const createLedgerWithWorker = (
//   { trade_id, tradeDataByExchange, walletDataByExchange }
// ) => async (dispatch, getState) => {
//   try {
//     let instance = new ledgerWorker();

//     const ledger = await instance.createLedger({ trade_id, tradeDataByExchange, walletDataByExchange });

//   } catch (err) {
//     displayErrorModal(err, dispatch);
//   }
// }

export const addZeroCostBasis = ({ reset, transactionIDs, mostRecentPnlCalcRunID }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('exchange data to delete', reset, transactionIDs, mostRecentPnlCalcRunID);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/applyZeroCostBasis`, { reset, transactionIDs, mostRecentPnlCalcRunID }, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of addZeroCostBasis`)
    logger.info(response);

    if (transactionIDs) {
      dispatch({ type: ZERO_COST_BASIS, payload: { transactionIDs } });
    }

    let doesUpdateCSRFToken = false;
    if (response.headers['csrf-token']) {
      doesUpdateCSRFToken = true;
    }

    let doesUpdateMostRecentReconcileRun = false;
    const { mostRecentReconcileRun } = response.data;
    if (mostRecentReconcileRun) {
      doesUpdateMostRecentReconcileRun = true;
    }

    const { importIdRecord } = response.data;
    let doesUpdateImportIdRecord = false;
    if (importIdRecord) {
      doesUpdateImportIdRecord = true;
    }

    const { unmatched } = response.data;
    let hasUnmatched = false;
    let doesUpdateUnmatchedTrades = false;
    let doesUpdateUnmatchedMovements = false;
    let doesUpdateUnmatchedTradeRecords = false;
    let doesUpdateUnmatchedMovementRecords = false;
    if (unmatched) {
      if (unmatched.unmatchedTrades && Array.isArray(unmatched.unmatchedTrades)) {
        if (unmatched.unmatchedTrades.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTrades = true;
      }
      if (unmatched.unmatchedMovements && Array.isArray(unmatched.unmatchedMovements)) {
        if (unmatched.unmatchedMovements.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovements = true;
      }
      if (unmatched.unmatchedTrades && Array.isArray(unmatched.unmatchedTrades)) {
        if (unmatched.unmatchedTrades.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTrades = true;
      }
      if (unmatched.unmatchedMovements && Array.isArray(unmatched.unmatchedMovements)) {
        if (unmatched.unmatchedMovements.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovements = true;
      }
      if (unmatched.unmatchedTradeRecords && Array.isArray(unmatched.unmatchedTradeRecords)) {
        if (unmatched.unmatchedTradeRecords.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedTradeRecords = true;
      }
      if (unmatched.unmatchedMovementRecords && Array.isArray(unmatched.unmatchedMovementRecords)) {
        if (unmatched.unmatchedMovementRecords.length > 0) {
          hasUnmatched = true;
        }
        doesUpdateUnmatchedMovementRecords = true;
      }
    }

    let doesUpdatePartialBasisDetail = false;
    if (response.data && response.data.partialBasisDetail && Array.isArray(response.data.partialBasisDetail)) {
      doesUpdatePartialBasisDetail = true;
    }

    batch(() => {
      if (doesUpdateCSRFToken) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      if (doesUpdateMostRecentReconcileRun) {
        dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun } });
      }
      if (doesUpdateImportIdRecord) {
        dispatch({ type: FETCH_TRANSACTION_DATA_APPEND, payload: { userImports: importIdRecord } });
      }
      if (doesUpdateUnmatchedTrades) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedTrades: unmatched.unmatchedTrades }
        });
      }
      if (doesUpdateUnmatchedMovements) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedMovements: unmatched.unmatchedMovements }
        });
      }
      if (doesUpdateUnmatchedTradeRecords) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedTradeRecords: unmatched.unmatchedTradeRecords }
        });
      }
      if (doesUpdateUnmatchedMovementRecords) {
        dispatch({
          type: FETCH_TRANSACTION_DATA,
          payload: { unmatchedMovementRecords: unmatched.unmatchedMovementRecords }
        });
      }

      if (doesUpdatePartialBasisDetail) {
        dispatch({
          type: PARTIAL_BASIS_DETAIL,
          payload: { partialBasisDetail: response.data.partialBasisDetail }
        });
      }
    });

    if (hasUnmatched) {
      response.message = _.cloneDeep(responseMessages[UNMATCHED_TRANSACTIONS_EXIST]);
      displayApiModal(response, dispatch)
    } else {
      response.message = _.cloneDeep(responseMessages[SUCCESSFUL_ZERO_COST_BASIS_UPLOAD]);
      displayApiModal(response, dispatch);
    }

    // if (Array.isArray(response.data.unmatchedTrades) && Array.isArray(response.data.movementsWithMissingBasis) &&
    //   Array.isArray(response.data.partialBasisDetail)
    // ) {
    //   batch(() => {
    //     dispatch({
    //       type: ZERO_COST_BASIS,
    //       payload: {
    //         unmatchedTrades: response.data.unmatchedTrades,
    //         movementsWithMissingBasis: response.data.movementsWithMissingBasis,
    //       }
    //     });
        
    //     dispatch({
    //       type: PARTIAL_BASIS_DETAIL,
    //       payload: {
    //         partialBasisDetail: response.data.partialBasisDetail
    //       }
    //     });
    //   })
    // }

    // response.message = _.cloneDeep(responseMessages[SUCCESSFUL_ZERO_COST_BASIS_UPLOAD]);
    // displayApiModal(response, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    logger.info("we errored on allocating zero cost basis", e.response);
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const resendVerificationEmail = ({ email }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    logger.info('new verification email');
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/email/confirmation/resend`, { email }, { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of resendVerificationEmail`)
    logger.info(response);
    // trigger modal

    displayApiModal({ header: 'details', message: "We've resent your verification email.  Please confirm your email address." }, dispatch);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    logger.info("we errored on allocating zero cost basis", e.response);
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

export const fetchCheckoutDetails = ({ checkoutSessionId }) => async (dispatch, getState) => {
  // pretty sure no csrf here
  try {
    logger.info('fetchCheckoutDetails', checkoutSessionId);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/payments/order-success`, { params: { session_id: checkoutSessionId } }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of fetchCheckoutDetails`)
    logger.info(response);
    // trigger modal

    dispatchUserData(response, dispatch);
    const { purchasedData } = response.data;
    if (purchasedData && purchasedData.maxPurchase && purchasedData.maxPurchase.planName) {
      displayApiModal(
        { header: 'details', message: `Your purchase has been successful.` }, dispatch
        // { header: 'details', message: `You account has been upgraded to ${capitalize(purchasedData.maxPurchase.planName)}` }, dispatch
      );
    }

    history.push('/dashboard');

  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    logger.info("we errored retrieving the successful payment", e.response);
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
}


export const cancelPaymentSession = ({ checkoutSessionId }) => async (dispatch, getState) => {
  // pretty sure no csrf here
  try {
    logger.info('cancelPaymentSession', checkoutSessionId);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/order-cancel`, { session_id: checkoutSessionId }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of cancelPaymentSession`)
    logger.info(response);
    // trigger modal
    if ('data' in response && JSON.stringify(response.data) === "{}") {
      dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
      return;
    }

    dispatchUserData(response, dispatch);

    // i'd like a better solution for this
    logger.info('redirecting');
    history.replace('/pricing');
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    logger.info("we errored trying to cancel a payment session", e.response);
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
}

export const passWalletName = ({ walletName }) => {
  return {
    type: WALLET_NAME_UPDATE,
    payload: { walletName }
  };
}


let lastCount = 0;
let isFetchingFetchUploadStatus = false;
export const fetchUploadStatus = () => async (dispatch, getState) => {
  try {
    if (isFetchingFetchUploadStatus) {
      return;
    }
    const intervalID = setInterval(async () => {
      isFetchingFetchUploadStatus = true;
      const { headers } = getCSRFToken(getState);
      logger.info('fetchUploadStatus called', intervalID);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/upload-status`, { headers }
      );
      if (response.headers['csrf-token']) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      logger.info(`the response object of fetchUploadStatus`)
      logger.info(response);

      if (response.data && response.data.status && response.data.status === 'not_fetching') {
        lastCount = 0;
        clearInterval(intervalID);
        isFetchingFetchUploadStatus = false;
        let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
        const year = localStorage.getItem('digitax_tax_year');
        if (year) {
          dispatch(fetchUserWithCookie(gotoRoute, year, { type: 'fetch' }));
        } else {
          dispatch(fetchUserWithCookie(gotoRoute, 2020, { type: 'fetch' }));
        }
      } else if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        // handle for multiple fetches happening
        // if the response.data.length is LESS than lastCount
        // then one API has finished... so we need to fetch the fresh user data
        // but NOT clear the interval... i own
        // console.log('lastCount', lastCount);
        // console.log('response.data', response.data);
        // console.log('response.data.length', response.data.length);
        if (lastCount !== 0 && lastCount > response.data.length) {
          let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
          const year = localStorage.getItem('digitax_tax_year');
          if (year) {
            dispatch(fetchUserWithCookie(gotoRoute, year, { type: 'fetch' }));
          } else {
            dispatch(fetchUserWithCookie(gotoRoute, 2020, { type: 'fetch' }));
          }
        }
        lastCount = response.data.length;
        const currentState = getState();
        if (Array.isArray(currentState.api.apiFetching) && currentState.api.apiFetching.length > 0) {
          // attempting to reduce the number of re-renders happening
          // ONLY re-rendering when necessary
          const newExchangesBeingPulled = response.data.map(({ exchange }) => exchange);
          const exchangesBeingPulled = currentState.api.apiFetching.map(({ exchange }) => exchange);
          if (!exchangesBeingPulled.every(exchange => newExchangesBeingPulled.includes(exchange))) {
            dispatch({ type: CLEAN_API_FETCHING_DATA, payload: { apiFetching: response.data } });
          }
        }
      } else {
        clearInterval(intervalID);
        isFetchingFetchUploadStatus = false;
      }
    }, 10000);
  } catch (e) {
    // todo add the inteerval to the session storage and clear it
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
}

let isFetchingCalcStatus = false;
export const fetchCalcStatus = () => async (dispatch, getState) => {
  try {
    if (isFetchingCalcStatus) {
      return;
    }
    const intervalID = setInterval(async () => {
      isFetchingCalcStatus = true;
      const { headers } = getCSRFToken(getState);
      logger.info('fetchCalcStatus called', intervalID);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/calc-status`, { headers }
      );
      if (response.headers['csrf-token']) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      logger.info(`the response object of fetchCalcStatus`)
      logger.info(response);

      if (response.data && response.data.status && response.data.status === 'not_calculating') {
        clearInterval(intervalID);
        isFetchingCalcStatus = false;
        let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
        const year = localStorage.getItem('digitax_tax_year');
        // display modal.... complete!
        if (year) {
          dispatch(fetchUserWithCookie(gotoRoute, year, { type: 'calc' }));
        } else {
          dispatch(fetchUserWithCookie(gotoRoute, 2020, { type: 'calc' }));
        }
      // } else if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        // dispatch({ type: CLEAN_API_FETCHING_DATA, payload: { apiFetching: response.data } });
      } else if (response.data && response.data.status && response.data.status === 'calculating') {
        // ok
      } else {
        // unknown response
        clearInterval(intervalID);
        isFetchingCalcStatus = false;
      }
    }, 10000);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
}

let lastDocumentCount = 0;
let isFetchingDocumentCreationStatus = false;
export const fetchDocumentCreationStatus = () => async (dispatch, getState) => {
  try {
    if (isFetchingDocumentCreationStatus) {
      return;
    }
    const intervalID = setInterval(async () => {
      isFetchingDocumentCreationStatus = true;
      const { headers } = getCSRFToken(getState);
      logger.info('fetchDocumentCreationStatus called', intervalID);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/document-creation-status`, { headers }
      );
      if (response.headers['csrf-token']) {
        sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      }
      logger.info(`the response object of fetchDocumentCreationStatus`)
      logger.info(response);

      if (response.data && response.data.status && response.data.status === 'not_creating') {
        lastDocumentCount = 0;
        clearInterval(intervalID);
        isFetchingDocumentCreationStatus = false;
        let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
        const year = localStorage.getItem('digitax_tax_year');
        if (year) {
          dispatch(fetchUserWithCookie(gotoRoute, year));
        } else {
          dispatch(fetchUserWithCookie(gotoRoute, 2020));
        }
      } else if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        // re-render ONLY when necessary... god i own
        if (lastDocumentCount !== 0 && lastDocumentCount > response.data.length) {
          let gotoRoute = localStorage.getItem('jboxxx_digitax_route');
          const year = localStorage.getItem('digitax_tax_year');
          if (year) {
            dispatch(fetchUserWithCookie(gotoRoute, year));
          } else {
            dispatch(fetchUserWithCookie(gotoRoute, 2020));
          }
        }
        lastDocumentCount = response.data.length;
        const currentState = getState();
        if (Array.isArray(currentState.api.documentsCreating) && currentState.api.documentsCreating.length > 0) {
          // attempting to reduce the number of re-renders happening
          // ONLY re-rendering when necessary
          const newDocsBeingGenerated = response.data.map(({ documentType, taxYear }) => `${documentType}-${taxYear}`);
          const docsBeingGenerated = currentState.api.documentsCreating.map(({ documentType, taxYear }) => `${documentType}-${taxYear}`);
          if (!docsBeingGenerated.every(docYearPair => newDocsBeingGenerated.includes(docYearPair))) {
            dispatch({ type: FETCH_CREATING_DOCUMENTS, payload: { documentsCreating: response.data } });
          }
        }
      } else {
        clearInterval(intervalID);
        isFetchingDocumentCreationStatus = false;
      }
    }, 10000);
  } catch (e) {
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    // displayErrorModal({ header: 'Details', message: 'Problem with generating document' }, dispatch);
    displayErrorModal(e, dispatch);
  }
}

export const updateAccountProps = ({ exchangeData, pnlData, yourAccount }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    let _taxYear = localStorage.getItem('digitax_tax_year');
    if (!_taxYear) {
      _taxYear = 2020;
    }
    logger.info('calling updateAccountProps with ', { exchangeData, pnlData, yourAccount });
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/account-data`,
      { exchangeData, pnlData, yourAccount, _taxYear },
      { headers }
    );
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }
    logger.info(`the response object of updateAccountProps`);
    logger.info(response);

    
    if (!yourAccount) {
      dispatchUserData(response, dispatch);
      displayApiModal({ header: 'Details', message: 'Successfully changed account details.' }, dispatch);
    } else {
      dispatch({ type: '@@INTI' });
      displayApiModal({ message: { header: 'Details', bodyMessageUL: ['Your account has been successfully deleted.','You will need to create a new account at Digitax to use our services again.'] } }, dispatch);
      // dispatches our initial state
      history.push('/');
    }
  } catch (e) {
    logger.info("we errored on new account details", e.response);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    // we can change this..
    if (e.response && e.response.status && e.response.status === 409) {
      displayErrorModal({ header: 'details', message: 'Problem Editing Account Details' }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Problem Editing Account Details' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}

// perhaps add createdAt handling here?
// not sure if we want to even store historical copies for them
export const downloadTemplateCSV = ({ type }) => async (dispatch, getState) => {
  dispatch({ type: TRIGGER_IN_PROGRESS, payload: { isInProgress: true } });
  const { headers } = getCSRFToken(getState);
  try {
    let filename;
    if (type === 'trades') {
      filename = `digitax-trade-template.csv`;
    } else if (type === 'wallet') {
      filename = `digitax-wallet-movement-template.csv`;
    } else {
      throw new Error('Unrecognized type');
    }

    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/getTemplateCSV`,
      method: 'GET',
      params: {
        type
      },
      headers,
      responseType: 'blob'
    });
    if (response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
    }

    logger.info('the response object of fetching the file is:');
    logger.info(response);

    // kicks off the file download in the browser
    FileDownload(response.data, filename);
  } catch (e) {
    logger.info("we errored on fetching the data", e);
    if (e.response && e.response.headers && e.response.headers['csrf-token']) {
      sessionStorage.setItem('digitax-csrf-token', e.response.headers['csrf-token']);
    }
    if (e.response && e.response.data && e.response.data.message) {
      displayErrorModal({ header: 'details', message: e.response.data.message }, dispatch);
    } else {
      displayErrorModal({ header: 'details', message: 'Unexpected error, please try again later' }, dispatch);
    }
  }
  dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
}
