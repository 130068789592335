import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Dot from 'modules/components/Dot';


const styles = theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  outerDiv: {
    top: `25%`,
    left: `45%`,
    transform: `translate(-25%, -25%)`,
  }
});

class _Modal extends React.Component {
  render() {
    const { classes, open, handleClose, apiMessage } = this.props;
    if (typeof apiMessage === 'object' && apiMessage !== null) {
      return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div className={clsx(classes.paper, classes.outerDiv)}>
            <Card variant="outlined">
              <div>
                {
                  'header' in apiMessage ?
                    (
                      <div>
                        <Card variant="outlined">
                          <Typography variant="h5">
                            {apiMessage['header']}
                          </Typography>
                        </Card>
                        <Divider />
                      </div>
                    ) :
                    null
                }
              </div>
              <div>
                {
                  'bodyMessageUL' in apiMessage ?
                    (
                      <Card variant="outlined">
                        <List>
                          {apiMessage['bodyMessageUL'].map((message, index) => {
                            return (
                              <ListItem key={index}>
                                <Dot />
                                <Typography variant="body1">
                                  {message}
                                </Typography>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Card>
                    ) :
                    null
                }
              </div>
            </Card>
          </div>
        </Modal>
      );
    }
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div className={clsx(classes.paper, classes.outerDiv)}>
          <h2 id="simple-modal-title">Response:</h2>
          <p id="simple-modal-description">
            {apiMessage}
          </p>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(_Modal);