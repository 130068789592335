import binanceTrades from './binance_trades';
import binanceWallet from './binance_wallet';

export default ({ exchange, files }) => {
  let dataToUpload = [];

  files.forEach(data => {
    if (data.length > 0) {
      // might need look at the filename
      // we are basing our withdrawal / deposit on the transaction fee
      // 0.01 for withdrawals and 0 for deposits
      if (data[0][0] === 'Date(UTC)') {
        dataToUpload.push(binanceTrades({ exchange, tradeData: data }));
      } else if (data[0][0] === 'Date') {
        // i need to break this up... for withdrawals and deposits
        dataToUpload.push(binanceWallet({ exchange, walletMovements: data }));
      } else {
        dataToUpload.push({
          isValid: false,
          comment: "We don't recongize this file type",
          tradeData: [],
          type: 'unknown',
          badTrades: []
        });
      }
    }
  });
  return dataToUpload;
}
