import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import VerticalLinearStepper from './components/Stepper';
import Watermark from 'modules/components/Watermark';
import requireAuth from 'modules/components/requireAuth';
import { CALCULATING } from 'actions/uiTypes';


const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2), // not sure i want this margin
    },
    [theme.breakpoints.down('sm')]: {
      // margin: theme.spacing(1)
    }
    // width: '100%',
  },
  container: {
    // width: '90%'
  },
});

let key = 0;
class Dashboard extends React.Component {
  render() {
    const { classes } = this.props;
    const {
      hasUploadedData,
      unmatchedMovements,
      unmatchedTrades,
      movementsWithMissingBasis,
      tradeCount
    } = this.props.exchangeAndTaxData;
    const { mostRecentPnlCalcRun, mostRecentReconcileRun } = this.props.pnl;
    const { maxTransactions } = this.props.auth;
    const { apiFetching, calculatingGainLoss } = this.props.api;
    const { isCalculatingGainsLosses } = this.props.ui;
    
    let nextStepIndex;

    if (!hasUploadedData || (Array.isArray(apiFetching) && apiFetching.length > 0)) {
      nextStepIndex = 0;
    } else if (!mostRecentReconcileRun || (Array.isArray(unmatchedMovements) && unmatchedMovements.length > 0)) {
      nextStepIndex = 1;
    } else if (tradeCount > maxTransactions) {
      nextStepIndex = 2;
    } else if ((!mostRecentPnlCalcRun || mostRecentPnlCalcRun.outcome === 'deleted') ||
      (Array.isArray(calculatingGainLoss) && calculatingGainLoss.length > 0 && !mostRecentPnlCalcRun.outcome)
      || (isCalculatingGainsLosses === CALCULATING && !(mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome &&
        mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis'))
    ) {
      nextStepIndex = 3;
    } else if (unmatchedTrades.length > 0 || movementsWithMissingBasis.length > 0) {
      nextStepIndex = 4;
    } else if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome &&
      mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis'
    ) {
      nextStepIndex = 5;  
    } else if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome &&
      mostRecentPnlCalcRun.outcome === 'success'
    ) {
      nextStepIndex = 6;
    }

    let isFetching = false;
    if (apiFetching && Array.isArray(apiFetching) && apiFetching.length > 0) {
      isFetching = true
    }

    // not receiving this from the backend properly
    let isCalculatingBeforeRec = false;
    let isCalculatingAfterRec = false;
    if (calculatingGainLoss && Array.isArray(calculatingGainLoss) && calculatingGainLoss.length > 0) {
      if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome &&
        mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis' &&
        (Array.isArray(unmatchedTrades) && unmatchedTrades.length === 0) &&
        (Array.isArray(movementsWithMissingBasis) && movementsWithMissingBasis.length === 0)
      ) {
        isCalculatingAfterRec = true;
      } else {
        isCalculatingBeforeRec = true;
      }
    }

    key++;
    return (
      <Paper className={classes.root}>
        <VerticalLinearStepper
          key={key}
          nextStepIndex={nextStepIndex}
          isFetching={isFetching}
          isCalculatingAfterRec={isCalculatingAfterRec}
          isCalculatingBeforeRec={isCalculatingBeforeRec}
        />
        <Paper>
          <Watermark />
        </Paper>
      </Paper>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    maxTransactions: PropTypes.number.isRequired
  }),
  documents: PropTypes.shape({
    documentsCreated: PropTypes.array
  }),
  exchangeAndTaxData: PropTypes.shape({
    hasUploadedData: PropTypes.bool,
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
    movementsWithMissingBasis: PropTypes.array,
    tradeCount: PropTypes.number
  }),
  api: PropTypes.shape({
    apiFetching: PropTypes.array,
    calculatingGainLoss: PropTypes.array
  }),
  pnl: PropTypes.shape({
    mostRecentReconcileRun: PropTypes.object,
    mostRecentPnlCalcRun: PropTypes.object,
  }),
  ui: PropTypes.shape({ 
    isCalculatingGainsLosses: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  return {
    auth: {
      firstName: state.auth.firstName,
      maxTransactions: state.auth.maxTransactions
    },
    documents: {
      documentsCreated: state.documents.documentsCreated
    },
    exchangeAndTaxData: {
      hasUploadedData: state.exchangeAndTaxData.hasUploadedData,
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      movementsWithMissingBasis: state.exchangeAndTaxData.movementsWithMissingBasis,
      tradeCount: state.exchangeAndTaxData.tradeCount
    },
    api: {
      apiFetching: state.api.apiFetching,
      calculatingGainLoss: state.api.calculatingGainLoss,
    },
    pnl: {
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun,
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun
    },
    ui: {
      isCalculatingGainsLosses: state.ui.isCalculatingGainsLosses
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(requireAuth(Dashboard));
