import {
  TRIGGER_IN_PROGRESS,
  TRIGGER_SIGNOUT_IN_PROGRESS,
  TRIGGER_RECOVER_IN_PROGRESS,
  PROGRESS_COMPLETE,
  TRIGGER_DISCOUNT_CODE_IN_PROGRESS,
  API_FETCHING_DATA,
  CLEAN_API_FETCHING_DATA,
  CALCULATING_GAINS_AND_LOSSES,
  FETCH_CREATING_DOCUMENTS
} from 'actions/types';


const INITIAL_STATE = {
  isInProgress: false,
  isSignoutInProgress: false,
  isRecoverInProgress: false,
  isValidateDiscountCodeInProgress: false,
  apiFetching: null
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TRIGGER_IN_PROGRESS:
      return { ...state, isInProgress: action.payload.isInProgress };
    case TRIGGER_SIGNOUT_IN_PROGRESS:
      return { ...state, isSignoutInProgress: action.payload.isInProgress };
    case TRIGGER_RECOVER_IN_PROGRESS:
      return { ...state, isRecoverInProgress: action.payload.isInProgress };      
    case TRIGGER_DISCOUNT_CODE_IN_PROGRESS:
      return { ...state, isValidateDiscountCodeInProgress: action.payload.isInProgress };
    case PROGRESS_COMPLETE:
      return { ...state, isInProgress: false, isSignoutInProgress: false };
    case API_FETCHING_DATA:
      if (Array.isArray(state.apiFetching) && Array.isArray(action.payload.apiFetching)) {
        const newApiFetching = [ ...state.apiFetching ];
        action.payload.apiFetching.forEach(exch => {
          newApiFetching.push(exch);
        });
        return { ...state, apiFetching: newApiFetching };
      } else {
        return { ...state, apiFetching: action.payload.apiFetching };
      }
    case CLEAN_API_FETCHING_DATA:
      return { ...state, apiFetching: action.payload.apiFetching };
    case CALCULATING_GAINS_AND_LOSSES:
      return { ...state, calculatingGainLoss: action.payload.calculatingGainLoss };
    case FETCH_CREATING_DOCUMENTS:
      return {
        ...state,
        documentsCreating: action.payload.documentsCreating
      };
    case '@@INTI':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
