import { batch } from 'react-redux';
import _ from 'lodash';
import {
  UPDATE_PLAN_FOR_TAX_YEAR,
  UPDATE_MAX_TRANSACTIONS,
  UPDATE_TAX_YEAR,
  AUTH_USER,
  LOADING_STATUSES,
  FETCH_TRANSACTION_DATA,
  FETCH_CREATING_DOCUMENTS,
  FETCH_DOCUMENTS,
  CALCULATING_GAINS_AND_LOSSES,
  PNL_CALC_RUN,
  RECONCILE_RUN,
  PARTIAL_BASIS_DETAIL,
  PROGRESS_COMPLETE,
  IS_PLAN_SUFFICIENT,
  API_FETCHING_DATA,
  // STORE_CSRF_TOKEN
} from '../types';
import { responseMessages, API_FETCH_ERROR } from '../responseMessages';
import { displayErrorModal, displayApiModal } from '../helpers';
import exchanges from 'modules/statics/exchangesMapInverse';
// import { displayApiModal } from '../helpers';

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export default (response, dispatch, statusType) => {
  let doesUpdateCSRFToken = false;
  if ('headers' in response && response.headers['csrf-token']) {
    doesUpdateCSRFToken = true;
  }

  const { purchasedData } = response.data;

  let doesUpdatePlan = false;
  if (purchasedData && purchasedData.maxPurchase) {
    // const { planName } = purchasedData.maxPurchase;
    doesUpdatePlan = true;
    // const { maxTransactions } = purchasedData;
  }

  let taxYear = localStorage.getItem('digitax_tax_year');
  if (purchasedData && purchasedData.maxPurchase && purchasedData.maxPurchase.taxYear) {
    localStorage.setItem('digitax_tax_year', purchasedData.maxPurchase.taxYear);
    taxYear = purchasedData.maxPurchase.taxYear
  }

  if (!taxYear) {
    taxYear = 2020;
  }

  let doesUpdateTaxYear = false
  if (taxYear !== null && !isNaN(taxYear)) {
    doesUpdateTaxYear = true;
  }

  // if (response.data.responseUser && !response.data.responseUser.isEmailConfirmed) {
  //   displayApiModal({ header: 'details', message: 'Please confirm your email address.' }, dispatch);
  // }

  let doesUpdateTradeDataByExchange = false;
  let doesUpdatePlanTypeSufficientTrue = false;
  let shouldZeroTradeCount = false;
  const { tradeDataByExchange } = response.data;
  let tradeCount = 0;
  if (tradeDataByExchange && Object.keys(tradeDataByExchange).length > 0) {
    doesUpdateTradeDataByExchange = true;
    Object.keys(tradeDataByExchange).forEach(exchange => {
      tradeDataByExchange[exchange].forEach(({ trades }) => {
        tradeCount += trades.length;
      });
    });
    if (response.data.purchasedData && response.data.purchasedData.maxTransactions) {
      if (typeof response.data.purchasedData.maxTransactions === 'number' &&
          response.data.purchasedData.maxTransactions >= tradeCount
      ) {
        doesUpdatePlanTypeSufficientTrue = true;
        // dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: true } });
      }

      // dispatch({
      //   type: FETCH_TRANSACTION_DATA,
      //   payload: {
      //     tradeDataByExchange: tradeDataByExchange,
      //     hasUploadedData: true
      //   }
      // });
    } else {
      // dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: false } });
    }
  } else {
    shouldZeroTradeCount = true;
  }

  let doesUpdateHasPurchasedFbarForTaxYear = false;
  if (response.data.purchasedData && response.data.purchasedData.purchasedFbarForTaxYear === true) {
    doesUpdateHasPurchasedFbarForTaxYear = true;
  }

  let doesUpdateWalletDataByExchange = false;
  let shouldZeroMovementCount = false;
  const { walletDataByExchange } = response.data;
  let movementCount = 0;
  if (walletDataByExchange && Object.keys(walletDataByExchange).length > 0) {
    Object.keys(walletDataByExchange).forEach(exchange => {
      walletDataByExchange[exchange].forEach(({ trades }) => {
        movementCount += trades.length;
      });
    });
    doesUpdateWalletDataByExchange = true;
  } else {
    shouldZeroMovementCount = true;
  }

  let shouldNullHasUploadedData = false;
  if (movementCount === 0 && tradeCount === 0) {
    shouldNullHasUploadedData = true;
  }

  // if (movementCount && tradeCount && movementCount >= 0 && tradeCount >= 0) {
  //   dispatch({
  //     type: FETCH_TRANSACTION_DATA,
  //     payload: {
  //       totalTransactions: tradeCount + movementCount
  //     }
  //   });
  // }

  let doesUpdateUserImports = false;
  let doesThrowAPIFetchErrorModal = false;
  let lastImportedExchange = null;
  const { userImports } = response.data;
  if (userImports && userImports.length > 0) {
    doesUpdateUserImports = true;
    if (statusType && statusType.type && statusType.type === 'fetch') {
      const sortedUserImports = _.sortBy(userImports, 'createdAt');
      const lastImported = sortedUserImports.slice(-1)[0];
      if (lastImported.outcome === 'error' && lastImported.exchange) {
        doesThrowAPIFetchErrorModal = true;
        lastImportedExchange = lastImported.exchange;
      }
    }
  }

  let doesUpdateMostRecentReconcileRun = false;
  let doesNullMostRecentReconcileRun = false;
  const { mostRecentReconcileRun } = response.data;
  if (mostRecentReconcileRun) {
    doesUpdateMostRecentReconcileRun = true;
  } else {
    doesNullMostRecentReconcileRun = true;
  }

  let doesUpdateUnmatchedTrades = false;
  let doesUpdateUnmatchedMovements = false;
  let doesUpdateMovementsWithMissingBasis = false;
  let doesUpdateUnmatchedTradeRecords = false;
  let doesUpdateUnmatchedMovementRecords = false;
  let doesResetUnmatched = false;
  let unmatched = null;
  if (mostRecentReconcileRun !== null) {
    unmatched = response.data.unmatched;
    if (unmatched) {
      if (unmatched.unmatchedTrades && unmatched.unmatchedTrades.length > 0) {
        doesUpdateUnmatchedTrades = true;
      }
  
      if (unmatched.unmatchedMovements && unmatched.unmatchedMovements.length > 0) {
        doesUpdateUnmatchedMovements = true;
      }
  
      if (unmatched.movementsWithMissingBasis && unmatched.movementsWithMissingBasis.length > 0) {
        doesUpdateMovementsWithMissingBasis = true;
      }

      if (unmatched.unmatchedTradeRecords && unmatched.unmatchedTradeRecords.length > 0) {
        doesUpdateUnmatchedTradeRecords = true;
      }

      if (unmatched.unmatchedMovementRecords && unmatched.unmatchedMovementRecords.length > 0) {
        doesUpdateUnmatchedMovementRecords = true;
      }
    }
  } else {
    doesResetUnmatched = true;
  }

  let doesUpdatePnlTransactions = false;
  const { pnlTransactions } = response.data;
  if (Array.isArray(pnlTransactions)) {
    pnlTransactions.forEach(trans => {
      trans.disposedTimestamp = new Date(trans.disposedTime).getTime();
    });
    doesUpdatePnlTransactions = true;
  }

  let doesUpdateApiFetching = false;
  let doesNullApiFetching = false;
  let doesUpdateApiFetchingWithCoinbase = false;
  const { apiFetching } = response.data;
  if (apiFetching && apiFetching.data && apiFetching.data.length > 0) {
    doesUpdateApiFetching = true;
  } else {
    // so this is necessary because on redirect back to the page
    // we call fetchUserWithCookie -> and the FetchExchangeDataFromAPI hasn't been called yet
    const didJustFetchCoinbase = sessionStorage.getItem('digitax-did-just-fetch-coinbase');
    if (didJustFetchCoinbase === 'yes') {
      doesUpdateApiFetchingWithCoinbase = true;
      sessionStorage.setItem('digitax-did-just-fetch-coinbase', 'no');
    } else {
      doesNullApiFetching = true;
    }
  }

  let doesUpdateCalculatingGainsLosses = false;
  let doesNullCalculatingGainsLosses = false;
  const { calculatingGainLoss } = response.data;
  if (calculatingGainLoss && calculatingGainLoss.data && calculatingGainLoss.data.length > 0) {
    doesUpdateCalculatingGainsLosses = true;
  } else {
    doesNullCalculatingGainsLosses = true;
  }

  let doesUpdateDocumentsCreated = false;
  let doesNullDocumentsCreated = false;
  const { documentsCreated } = response.data;
  if (documentsCreated && documentsCreated.length > 0) {
    doesUpdateDocumentsCreated = true;
  } else {
    doesNullDocumentsCreated = true;
  }

  let doesUpdateDocumentsInCreation = false;
  let doesNullDocumentsCreating = false;
  const { documentsCreating } = response.data;
  if (documentsCreating && documentsCreating.data && documentsCreating.data.length > 0) {
    doesUpdateDocumentsInCreation = true;
  } else {
    doesNullDocumentsCreating = true;
  }

  let doesUpdateMostRecentPnlCalcRun = false;
  let doesNullMostRecentPnLCalcRun = false;
  let doesShowCalcSuccessModal = false;
  let doesShowCalcUnmatchedModal = false;
  let doesShowCalcErrorModal = false;
  const { mostRecentPnlCalcRun } = response.data;
  if (mostRecentPnlCalcRun || mostRecentPnlCalcRun === null) {
    doesUpdateMostRecentPnlCalcRun = true;
    if (statusType && statusType.type && statusType.type === 'calc') {
      if (mostRecentPnlCalcRun.outcome && mostRecentPnlCalcRun.outcome === 'success' &&
          mostRecentPnlCalcRun.outcomeDescription === 'unmatched_transactions'
      ) {
        doesShowCalcUnmatchedModal = true;
      }
      if (mostRecentPnlCalcRun.outcome && mostRecentPnlCalcRun.outcome === 'success' &&
        mostRecentPnlCalcRun.outcomeDescription === 'success'
      ) {
        doesShowCalcSuccessModal = true;
      }
      if (mostRecentPnlCalcRun.outcome && mostRecentPnlCalcRun.outcome === 'error') {
        doesShowCalcErrorModal = true;
      }
    }
  } else {
    doesNullMostRecentPnLCalcRun = true;
  }

  let doesUpdatePartialBasisDetail = false;
  const { partialBasisDetail } = response.data;
  if (Array.isArray(partialBasisDetail)) {
    doesUpdatePartialBasisDetail = true;
  }

  let doesUpdateKnownWallets = false;
  const { knownWallets } = response.data;
  if (knownWallets) {
    doesUpdateKnownWallets = true;
  }

  let doesUpdateUnknownCurrencyTrades = false;
  const { unknownCurrencyTrades } = response.data;
  if (Array.isArray(unknownCurrencyTrades)) {
    doesUpdateUnknownCurrencyTrades = true;
  }

  let doesUpdateUnknownCurrencyMovements = false;
  const { unknownCurrencyMovements } = response.data;
  if (Array.isArray(unknownCurrencyMovements)) {
    doesUpdateUnknownCurrencyMovements = true;
  }

  batch(() => {
    if (doesUpdateCSRFToken) {
      sessionStorage.setItem('digitax-csrf-token', response.headers['csrf-token']);
      // dispatch({ type: STORE_CSRF_TOKEN, payload: { csrfToken: response.headers['csrf-token'] } });
    }

    if (doesUpdateHasPurchasedFbarForTaxYear) {
      response.data.responseUser.hasPurchasedFbarForTaxYear = true;
    } else {
      response.data.responseUser.hasPurchasedFbarForTaxYear = false;
    }

    dispatch({ type: AUTH_USER, payload: response.data.responseUser });

    dispatch({
      type: LOADING_STATUSES,
      payload: {
        isLoadingExchangeData: response.data.apiFetching.status,
        isCalculatingGainsLosses: response.data.calculatingGainLoss.status,
        isDocumentCreating: response.data.documentsCreating.status
      }
    });

    if (doesUpdatePlan) {
      dispatch({ type: UPDATE_PLAN_FOR_TAX_YEAR, payload: { planName: purchasedData.maxPurchase.planName } });
      dispatch({ type: UPDATE_MAX_TRANSACTIONS, payload: { maxTransactions: purchasedData.maxTransactions } });
    }

    if (doesUpdateTaxYear) {
      dispatch({ type: UPDATE_TAX_YEAR, payload: { taxYear: parseInt(taxYear) } });
    }

    if (doesUpdateTradeDataByExchange) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: {
          tradeDataByExchange: tradeDataByExchange,
          hasUploadedData: tradeCount > 0,
          tradeCount
        }
      });
    }

    if (shouldZeroMovementCount) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: {
          walletDataByExchange: {},
          hasUploadedData: tradeCount > 0,
          movementCount: 0
        }
      });
    }

    if (shouldZeroTradeCount) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: {
          tradeDataByExchange: {},
          hasUploadedData: movementCount > 0,
          tradeCount: 0
        }
      });
    }

    if (shouldNullHasUploadedData) {
      dispatch({ type: FETCH_TRANSACTION_DATA, payload: { hasUploadedData: false } });
    }

    if (doesUpdatePlanTypeSufficientTrue) {
      dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: true } });
    } else {
      dispatch({ type: IS_PLAN_SUFFICIENT, payload: { isPlanSufficient: false } });
    }

    if (doesUpdateWalletDataByExchange) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: {
          walletDataByExchange: walletDataByExchange,
          hasUploadedData: movementCount > 0,
          movementCount
        }
      });
      doesResetUnmatched = true;
    }

    if (movementCount && tradeCount && movementCount >= 0 && tradeCount >= 0) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: {
          totalTransactions: tradeCount + movementCount
        }
      });
    }

    if (doesUpdateUserImports) {
      dispatch({ type: FETCH_TRANSACTION_DATA, payload: { userImports } });
    } else {
      dispatch({ type: FETCH_TRANSACTION_DATA, payload: { userImports: [] } });
    }

    if (doesResetUnmatched) {
      dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun } });

      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedTrades: [] }
      });
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedMovements: [] }
      });
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { movementsWithMissingBasis: [] }
      });
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedTradeRecords: [] }
      });
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedMovementRecords: [] }
      });
    }

    if (doesUpdateKnownWallets) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { knownWallets: response.data.knownWallets }
      });
    }

    if (doesUpdateUnmatchedTrades) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedTrades: unmatched.unmatchedTrades }
      });
    }

    if (doesUpdateUnmatchedMovements) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedMovements: unmatched.unmatchedMovements }
      });
    }

    if (doesUpdateMovementsWithMissingBasis) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { movementsWithMissingBasis: unmatched.movementsWithMissingBasis }
      });
    }

    if (doesUpdateUnmatchedTradeRecords) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedTradeRecords: unmatched.unmatchedTradeRecords }
      });
    }

    if (doesUpdateUnmatchedMovementRecords) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unmatchedMovementRecords: unmatched.unmatchedMovementRecords }
      });
    }

    if (doesUpdatePnlTransactions) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { pnlTransactions }
      });
    } else {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { pnlTransactions: [] }
      });
    }

    if (doesUpdateUnknownCurrencyTrades) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unknownCurrencyTrades }
      });
    } else {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unknownCurrencyTrades: [] }
      });
    }

    if (doesUpdateUnknownCurrencyMovements) {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unknownCurrencyMovements }
      });
    } else {
      dispatch({
        type: FETCH_TRANSACTION_DATA,
        payload: { unknownCurrencyMovements: [] }
      });
    }

    if (doesUpdateApiFetchingWithCoinbase) {
      dispatch({ type: API_FETCHING_DATA, payload: { apiFetching: [{ exchange: 'coinbase', timeStarted: new Date() }] } });
    } else if (doesUpdateApiFetching) {
      dispatch({ type: API_FETCHING_DATA, payload: { apiFetching: apiFetching.data } });
    }

    if (doesNullApiFetching) {
      dispatch({ type: API_FETCHING_DATA, payload: { apiFetching: null } });
    }

    if (doesUpdateCalculatingGainsLosses) {
      dispatch({
        type: CALCULATING_GAINS_AND_LOSSES,
        payload: {
          calculatingGainLoss: calculatingGainLoss.data,
          isCalculatingGainsLosses: calculatingGainLoss.status
        }
      });
    }

    if (doesNullCalculatingGainsLosses) {
      dispatch({
        type: CALCULATING_GAINS_AND_LOSSES,
        payload: {
          calculatingGainLoss: null,
          isCalculatingGainsLosses: 'not_calculating'
        }
      });
    }

    if (doesUpdateDocumentsCreated) {
      dispatch({ type: FETCH_DOCUMENTS, payload: { documentsCreated } });
    } else if (doesNullDocumentsCreated) {
      dispatch({ type: FETCH_DOCUMENTS, payload: { documentsCreated: [] } });
    }

    if (doesUpdateDocumentsInCreation) {
      dispatch({ type: FETCH_CREATING_DOCUMENTS, payload: { documentsCreating: documentsCreating.data } });
    }

    if (doesNullDocumentsCreating) {
      dispatch({ type: FETCH_CREATING_DOCUMENTS, payload: { documentsCreating: null } });
    }

    if (doesUpdateMostRecentPnlCalcRun) {
      dispatch({ type: PNL_CALC_RUN, payload: { mostRecentPnlCalcRun } });
    } else if (doesNullMostRecentPnLCalcRun) {
      dispatch({ type: PNL_CALC_RUN, payload: { mostRecentPnlCalcRun: null } });
    }

    if (doesUpdateMostRecentReconcileRun) {
      dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun } });
    } else if (doesNullMostRecentReconcileRun) {
      dispatch({ type: RECONCILE_RUN, payload: { mostRecentReconcileRun: null } });
    }

    if (doesUpdatePartialBasisDetail) {
      dispatch({ type: PARTIAL_BASIS_DETAIL, payload: { partialBasisDetail } });
    } else {
      dispatch({ type: PARTIAL_BASIS_DETAIL, payload: { partialBasisDetail: [] } });
    }

    dispatch({ type: PROGRESS_COMPLETE, payload: { isInProgress: false } });
  });

  // put this OUTSIDE of the batch() as i think this is accurate
  if (doesThrowAPIFetchErrorModal) {
    const message = _.cloneDeep(responseMessages[API_FETCH_ERROR]);
    if (lastImportedExchange in exchanges) {
      message.bodyMessageUL[0] = replaceAll(message.bodyMessageUL[0], 'replace_exchange', exchanges[lastImportedExchange]);
    } else {
      message.bodyMessageUL[0] = replaceAll(message.bodyMessageUL[0], 'replace_exchange', lastImportedExchange);
    }
    displayErrorModal({ message }, dispatch);
  }

  if (doesShowCalcSuccessModal) {
    displayApiModal({
      message: {
        header: 'Details',
        bodyMessageUL: [
          "We have successfully calculated your gains and losses!",
          "You may browse your transactions and proceed to creating your tax documents."
        ],
      }
    }, dispatch);
  }
  if (doesShowCalcUnmatchedModal) {
    displayApiModal({
      message: {
        header: 'Details',
        bodyMessageUL: [
          "Calculating gains and losses has finished.",
          "It appears that we are missing some transactions.",
          "Please navigate to the Reconcile tab for more details.",
        ],
      }
    }, dispatch);
  }
  if (doesShowCalcErrorModal) {
    displayApiModal({
      message: {
        header: 'Details',
        bodyMessageUL: [
          "Calculating gains and losses has errored.",
          "Please confirm you have uploaded all of your transactions",
          "Also please check that your wallet movements are accurate and consistent, especially your wallet names",
          "If the error persists, please email support@digitax.io for more information."
        ],
      }
    }, dispatch);
  }
}