export const FETCH_USER = 'fetch_user';

export const AUTH_USER = 'auth_user';
export const AUTH_ERROR = 'auth_error';
export const AUTH_USER_SIGNOUT = 'auth_user_signout';
export const STORE_CSRF_TOKEN = 'store_csrf_token';

export const SIGN_UP = 'sign_up';
export const SIGN_IN = 'sign_in';
export const SIGN_OUT = 'sign_out';

export const UPDATE_TAX_YEAR = 'update_tax_year';
export const UPDATE_MAX_TRANSACTIONS = 'update_max_transactions';

export const RECOVER_ACCOUNT = 'recover_account';
export const RECOVER_ACCOUNT_ERROR = 'recover_account_error';
export const FETCH_ACCOUNT_DETAILS_ON_RECOVER = 'fetch_account_details_on_recover';
export const FETCH_ACCOUNT_DETAILS_ON_RECOVER_ERROR = 'fetch_account_details_on_recover_error';
export const RESET_PASSWORD_FROM_RECOVERY = 'reset_password_from_recovery';

export const FETCH_DOCUMENTS = 'fetch_documents';
export const FETCH_DOCUMENTS_ERROR = 'fetch_documents_error';
export const FETCH_CREATING_DOCUMENTS = 'fetch_creating_documents';

export const UPLOAD_TRADEDATA = 'upload_tradedata';
export const UPLOAD_TRADEDATA_ERROR = 'upload_tradedata_error';

export const UPLOAD_WALLETDATA = 'upload_walletdata';
export const UPLOAD_WALLETDATA_ERROR = 'upload_walletdata_error';

export const FETCH_TRANSACTION_DATA = 'fetch_transaction_data';
export const FETCH_TRANSACTION_DATA_APPEND = 'fetch_transaction_data_append';

export const TRIGGER_IN_PROGRESS = 'trigger_in_progress';
export const TRIGGER_SIGNOUT_IN_PROGRESS = 'trigger_signout_in_progress';
export const TRIGGER_RECOVER_IN_PROGRESS = 'trigger_recover_in_progress';
export const PROGRESS_COMPLETE = 'progress_complete';

export const DELETE_RECORD = 'delete_record';
export const DELETE_RECORD_ERROR = 'delete_record_error';
export const DELETE_EXCHANGE_DATA = 'delete_exchange_data';
export const DELETE_EXCHANGE_DATA_ERROR = 'delete_exchange_data_error';

export const FETCH_DOCUMENT_ERROR = 'fetch_8949_error';

export const PLAN_AND_BILLING_TAB = 'plan_and_billing_tab';
export const ACCOUNT_TAB = 'account_tab';

export const CREATE_8949_PDF = 'create_8949_pdf';
export const CREATE_8949_CSV = 'create_8949_csv';
export const CREATE_8949_TXT = 'create_8949_txt';
export const CREATE_8949_TURBOTAX = 'create_8949_turbotax';
export const CREATE_SCHEDULE_1 = 'create_schedule_1';
export const CREATE_FBAR = 'create_fbar';
export const CREATE_INCOME_TAX_SUMMARY = 'create_income_tax_summary';

export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_ERROR = 'confirm_email_error';

export const TRIGGER_DISCOUNT_CODE_IN_PROGRESS = 'trigger_discount_code_in_progresss';
export const VALIDATE_DISCOUNT_CODE = 'validate_discount_code';

export const DASHBOARD_MODAL = 'dashboard_modal';
export const CLEAR_DASHBOARD_MODAL = 'clear_dashboard_modal';

export const FETCHING_EXCHANGE_DATA = 'fetching_exchange_data';
export const FETCHING_EXCHANGE_DATA_ERROR = 'fetching_exchange_data_error';

export const HEADER_TAB_SELECT = 'header_tab_select';
export const SET_SELECTED_EXCHANGE = 'set_selected_exchange';
export const CALCULATE_TAB_SELECT = 'calculate_tab_select';

export const CALCULATING_GAINS_AND_LOSSES = 'calculating_gains_and_losses';
export const CALCULATING_GAINS_AND_LOSSES_ERROR = 'calculating_gains_and_losses_error';
export const CALCULATING_GAINS_AND_LOSSES_MODAL_MESSAGE = 'calculating_gains_and_losses_modal_message';

export const UPDATE_PLAN_FOR_TAX_YEAR = 'update_plan_for_tax_year';
export const UPDATE_FBAR_FOR_TAX_YEAR = 'update_fbar_for_tax_year';
//export const GET_PURCHASES_FOR_TAX_YEAR = 'get_purchases_for_tax_year';

export const LOADING_STATUSES = 'loading_statuses';
export const PNL_CALC_RUN = 'pnl_calc_run';
export const ALREADY_UPLOADED = 'already_uploaded';
export const RECONCILE_RUN = 'reconcile_run';

export const CLASSIFY_UNMATCHED_TRADE = 'classify_unmatched_trade';
export const CLASSIFY_UNMATCHED_MOVEMENT = 'classify_unmatched_movement';
export const UNMATCHED_UPDATED = 'unmatched_updated';

export const UPDATE_COST_BASIS = 'update_cost_basis';
export const CREATE_NEW_MVMT_FOR_STORAGE = 'create_new_mvmt_for_storage';
export const CLEAR_ACTIVE_UNMATCHED = 'clear_active_unmatched';

export const TRIGGER_CALCULATE = 'trigger_calculate';
export const TRIGGER_RECONILE_MOVEMENTS = 'trigger_reconcile_movements';

export const API_MESSAGE_MODAL = 'api_message_modal';
export const APP_ERROR_MESSAGE_MODAL = 'app_error_message_modal';
export const APP_API_MESSAGE_MODAL = 'app_api_message_modal';

export const MOBILE_MENU_OPEN = 'mobile_menu_open';

export const PARTIAL_BASIS_DETAIL = 'partial_basis_detail';
export const ZERO_COST_BASIS = 'zero_cost_basis';

export const UPGRADE_ACCOUNT = 'upgrade_account';

export const IS_PLAN_SUFFICIENT = 'is_plan_sufficient';
export const API_FETCHING_DATA = 'api_fetching_data';
export const CLEAN_API_FETCHING_DATA = 'clean_api_fetching_data';

export const WALLET_NAME_UPDATE = 'wallet_name_update';