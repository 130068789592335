import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { clearCurrentlyAdjustingMovement, triggerCalculateGainLoss, triggerReconcileMovements } from 'actions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SortingTableUmWallet from 'modules/components/SortingTableUmWallet';
import walletColumns from 'modules/components/SortingTableUmWallet/headers';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import GiftBasisModal from './Modals/GiftBasis';
import StorageDeposit from './Modals/StorageDeposit';
import StorageWithdrawal from './Modals/StorageWithdrawal';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  DEPOSIT,
  WITHDRAWAL,
  GIFT_RECEIVED
} from 'modules/components/SortingTableUmWallet/adjustmentTypes';

const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
    width: '95%'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    }
  },
  iconSize: {
    width: '50px',
    height: 'auto'
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  info: {
    height: '4em',
    backgroundColor: theme.palette.primary.main
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginLeft: theme.spacing(1)
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
});


class CenterBox extends React.Component {
  state = {
    giftModalOpen: false,
    storageModalOpen: false
  }
  
  componentDidMount() {
    if (this.props.pnl.triggerCalculate) {
      this.props.triggerCalculateGainLoss();
    }
  }

  handleModalClose = () => {
    this.setState({ giftModalOpen: false, storageModalOpen: false });
    this.props.clearCurrentlyAdjustingMovement();
  }

  handleUndoClick = () => {
    this.props.triggerReconcileMovements({ reset: true });
  }
  
  render() {
    let { giftModalOpen, storageModalOpen } = this.state;
    const { classes, count } = this.props;
    const { currentlyAdjustingMovement, unmatchedMovements } = this.props.exchangeAndTaxData;

    console.log('unmatchedMovements', unmatchedMovements);

    if (Array.isArray(unmatchedMovements) && unmatchedMovements.length > 0) {
      let isDeposit = null;
      if (JSON.stringify(currentlyAdjustingMovement) !== "{}" && 'adjustmentType' in currentlyAdjustingMovement) {
        if ([DEPOSIT, WITHDRAWAL].includes(currentlyAdjustingMovement.adjustmentType)) {
          storageModalOpen = true;
          if (currentlyAdjustingMovement.adjustmentType === DEPOSIT) {
            isDeposit = true;
          } else {
            isDeposit = false;
          }
        } else if (currentlyAdjustingMovement.adjustmentType === GIFT_RECEIVED) {
          giftModalOpen = true;
        }
      }

      return (
        <React.Fragment>
          <div className={classes.margin}>
            <List className={classes.marginBottom}>
              <ListItem alignItems="flex-start">
                <KeyboardArrowRightIcon className={classes.marginRight} />
                <Typography variant="h5">
                  {"Please try to "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"classify"}
                  </Box>
                  {" each wallet movement using the drop-down menu labelled "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"Choose"}
                  </Box>
                  {" below."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <KeyboardArrowRightIcon className={classes.marginRight} />
                <Typography variant="h5">
                  {"If you see a "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"deposit"}
                  </Box>
                  {" and "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                    {"withdrawal"}
                  </Box>
                  {" that should be matched, select each of two transactions and select "}
                  <Box component="span" className={classes.linkTextColor} display="inline" fontWeight="fontWeightMedium">
                      {"Pair to another Deposit/Withdrawal"}
                  </Box>
                  {" on the drop-down menu."}
                </Typography>
              </ListItem>   
              <ListItem alignItems="flex-start">
                <KeyboardArrowRightIcon className={classes.marginRight} />
                <Typography variant="h5">
                  {"Once you upload, we'll try to reconcile again.  It may help us to go one by one."}
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Card variant="outlined" className={clsx(classes.info, classes.alignTogether)}>
                  <InfoOutlinedIcon className={classes.marginInfoIcon} />
                  <Typography variant="caption" className={classes.marginRight}>
                    <Box component="span" fontWeight="fontWeightLight">
                      {"If you think you've made a mistake, you can undo your classifications so far."}
                    </Box>
                  </Typography>
                  <Button
                    onClick={this.handleUndoClick}
                    className={clsx(classes.marginRight, classes.removeTextTransform)}
                  >
                    {"Undo"}
                  </Button>
                </Card>
              </ListItem>         
            </List>
          </div>
          <div className={classes.root}>
            <div className={classes.paper}>
              <div className={classes.alignCenter}>
                <SortingTableUmWallet
                  title={'Unmatched Wallet Movements'}
                  key={count}
                  unmatchedMovements={unmatchedMovements}
                  displayColumns={walletColumns}
                  currentlyAdjustingMovement={currentlyAdjustingMovement}
                />
              </div>
            </div>
          </div>
          <GiftBasisModal
            open={giftModalOpen}
            handleClose={this.handleModalClose}
            currentlyAdjustingMovement={currentlyAdjustingMovement}
          />
          {
            storageModalOpen ? (
              isDeposit ? (
                <StorageDeposit
                  open={storageModalOpen}
                  handleClose={this.handleModalClose}
                  currentlyAdjustingMovement={currentlyAdjustingMovement}
                />   
              ) : (
                <StorageWithdrawal
                  open={storageModalOpen}
                  handleClose={this.handleModalClose}
                  currentlyAdjustingMovement={currentlyAdjustingMovement}
                /> 
              )
            ) : null
          }         
        </React.Fragment>
      );
    }
    // add background color to this like a button
    // add a check green checkmark to jazz this up
    return (
      <div className={classes.root}>
        <div className={classes.alignCenter}>
          <List>
            <ListItem>
              <CheckIcon className={clsx(classes.iconSize, classes.marginRight)} />
              <Typography variant="h4" component="h1" className={clsx(classes.textAlignCenter)}>
                {"Nothing to review"}
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    );
  }
}

CenterBox.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    unmatchedMovements: PropTypes.array,
    currentlyAdjustingMovement: PropTypes.object,
  }),
  ui: PropTypes.shape({
    calcGainLossMessage: PropTypes.object,
    isCalculatingGainsLosses: PropTypes.string
  }),
  pnl: PropTypes.shape({
    triggerCalculate: PropTypes.object
  }),
  triggerCalculateGainLoss: PropTypes.func.isRequired
}

// used to force a re-render...
let count = 0
function mapStateToProps(state) {
  count += 1;
  return {
    exchangeAndTaxData: {
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      currentlyAdjustingMovement: state.exchangeAndTaxData.currentlyAdjustingMovement
    },
    pnl: {
      triggerCalculate: state.pnl.triggerCalculate
    },
    count
  };
}

export default compose(
  connect(mapStateToProps, { clearCurrentlyAdjustingMovement, triggerCalculateGainLoss, triggerReconcileMovements }),
  withStyles(styles)
)(CenterBox);