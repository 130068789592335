import { APP_ERROR_MESSAGE_MODAL, APP_API_MESSAGE_MODAL } from './types';
import { responseMessages, APP_ERROR_MESSAGE, APP_API_MESSAGE } from './responseMessages';
import _ from 'lodash';
import logger from 'modules/utils/logger';


export function getCSRFToken(getState) {
  const csrfToken = sessionStorage.getItem('digitax-csrf-token');
  return {
    headers: {
      'csrf-token': csrfToken
    }
  };

  // const myState = getState();
  // let csrfToken = null;
  // if ('auth' in myState) {
  //   // scoping is weird with the try below
  //   // ... not sure why it has to be written this way for the linter to be happy
  //   csrfToken = myState.auth.csrfToken;
  // }
  // return {
  //   csrfToken,
  //   headers: {
  //     'csrf-token': csrfToken
  //   }
  // };
}

export function displayErrorModal(err, dispatch) {
  const thisResponseMessage = _.cloneDeep(responseMessages);
  if ('message' in err) {
    logger.info('err', err);
    logger.info('err.message', err.message);
    if (typeof err.message === 'string' || err.message instanceof String) {
      thisResponseMessage[APP_ERROR_MESSAGE].bodyMessageUL[0] = thisResponseMessage[APP_ERROR_MESSAGE].bodyMessageUL[0].replace(
        'replace_message', err.message
      );
    } else {
      // it was adjusted by the error handler
      thisResponseMessage[APP_ERROR_MESSAGE] = err.message;
    }
    dispatch({
      type: APP_ERROR_MESSAGE_MODAL,
      payload: { appErrorMessage: thisResponseMessage[APP_ERROR_MESSAGE] }
    });
  } else {
    logger.info('no data in error');
  }
}

export function displayApiModal(response, dispatch) {
  const thisResponseMessage = _.cloneDeep(responseMessages);
  if ('message' in response) {
    logger.info('response', response);
    logger.info('response.message', response.message);
    if (typeof response.message === 'string' || response.message instanceof String) {
      thisResponseMessage[APP_API_MESSAGE].bodyMessageUL[0] = thisResponseMessage[APP_API_MESSAGE].bodyMessageUL[0].replace(
        'replace_message', response.message
      );
    } else {
      // it was adjusted by the error handler
      thisResponseMessage[APP_API_MESSAGE] = response.message;
    }
    dispatch({
      type: APP_API_MESSAGE_MODAL,
      payload: { appApiMessage: thisResponseMessage[APP_API_MESSAGE] }
    });
  } else {
    logger.info('no data in response');
  }
}