import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import ToolTip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom';
import {
  gotoPlanAndBillingTab,
  triggerGenerateDocument,
  setAppApiMessage,
  fetchDocumentCreationStatus
} from 'actions';
import requireAuth from 'modules/components/requireAuth';
import {
  PDF_8949,
  CSV_8949,
  TXT_8949,
  TURBOTAX_8949,
  SCHEDULE_1,
  FBAR,
  INCOME_TAX_SUMMARY
} from 'modules/statics/documentTypes';
import planTypesTransLimits from 'modules/statics/planTypesTransLimits';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(4)
    }
  },
  flex: {
    display: 'flex',
    wrap: 'flex-wrap'
  },
  bodyMargin: {
    margin: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  inline: {
    display: 'inline'
  },
  warning: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(5)
  },
  marginRight: {
    margin: theme.spacing(2)
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  unlockMargin: {
    marginLeft: theme.spacing(10.2)
  },
  marginLeft: {
    marginLeft: theme.spacing(6)
  },
  buttonWidth: {
    [theme.breakpoints.up('md')]: {
      minWidth: 150
    }
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

const header = [
  { label: 'Create Reports' }
];

const reports = [
  {
    isSelected: false,
    label: PDF_8949.label,
    subtext: 'Cryptocurrency gain/loss report formatted as a PDF',
    documentType: PDF_8949.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: CSV_8949.label,
    subtext: 'Cryptocurrency gain/loss report formatted as a CSV',
    documentType: CSV_8949.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: TXT_8949.label,
    subtext: 'Cryptocurrency gain/loss report formatted as a TXT file',
    documentType: TXT_8949.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: TURBOTAX_8949.label,
    subtext: 'Cryptocurrency gain/loss report formatted for Turbo Tax',
    documentType: TURBOTAX_8949.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: SCHEDULE_1.label,
    subtext: 'A mostly blank Schedule 1 tax form with your fork income filled',
    documentType: SCHEDULE_1.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: FBAR.label,
    subtext: 'A report your cryptocurrency holdings outside the United States',
    documentType: FBAR.type,
    generate: 'Produce Report'
  },
  {
    isSelected: false,
    label: INCOME_TAX_SUMMARY.label,
    subtext: 'An overview of your cryptocurrency transactions as an addendum to your taxes',
    documentType: INCOME_TAX_SUMMARY.type,
    generate: 'Produce Report'
  }
];

const fbarMessage = "If you have $10,000 of cryptocurrency outside the US, you must submit an FBAR to be compliant with US Tax Law";
const FBAR_TOOLTIP_KEY = 'fbar_tooltip_key';
const INSUFFICIENT_PLAN_TYPE_TOOLTIP_KEY = 'insufficient_plan_type_tooltip_key';

class CreateReports extends React.Component {
  state = {
    tooltipOpen: { [FBAR_TOOLTIP_KEY]: false, [INSUFFICIENT_PLAN_TYPE_TOOLTIP_KEY]: false },
    tradeCount: 0
  };

  componentDidMount() {
    const {
      exchangeAndTaxData: { tradeDataByExchange },
      auth: { maxTransactions, planName }
    } = this.props;
    let transCount = 0;

    Object.keys(tradeDataByExchange).forEach(exchange => {
      tradeDataByExchange[exchange].forEach(upload => {
        if ('trades' in upload && Array.isArray(upload['trades'])) {
          transCount += upload['trades'].length;
        }
      });
    });

    this.setState({ transCount });

    let isPlanTypeSufficient = false;
    if (maxTransactions >= transCount) {
      isPlanTypeSufficient = true;
    }
    
    const { appApiMessage } = this.props.ui;

    if (!appApiMessage && transCount > 0 && !isPlanTypeSufficient) {
      this.props.setAppApiMessage({ 'header': 'Insufficient Plan', 'message': `You have ${transCount} transactions and your plan only supports ${planTypesTransLimits[planName].maxTransactions} transactions.` })
    }

    const { api: { documentsCreating } } = this.props;
    if (Array.isArray(documentsCreating) && documentsCreating.length > 0) {
      this.props.fetchDocumentCreationStatus();
    }
  }

  triggerPlanAndBilling = () => {
    this.props.gotoPlanAndBillingTab();
  }

  clickGenerateDocument = (documentType) => {
    const { taxYear } = this.props.auth;
    this.props.triggerGenerateDocument({ documentType, taxYear });
  }

  handleToolTipClose = (type) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[type] = false;
    this.setState({ tooltipOpen });
  }

  handleToolTipOpenClose = (type) => {
    const { tooltipOpen } = this.state;
    tooltipOpen[type] = !tooltipOpen[type];
    this.setState({ tooltipOpen });
  }

  render() {
    const { transCount } = this.state;
    const {
      classes,
      exchangeAndTaxData: { isFBARNecessary },
      auth: { planName, maxTransactions }
    } = this.props;

    let isPlanTypeSufficient = false;
    if (maxTransactions >= transCount) {
      isPlanTypeSufficient = true;
    }

    return (
      <div className={classes.root}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h4" component="h1" display="inline">
                    {header[0].label}
                  </Typography>
                </TableCell>
                {
                  !isPlanTypeSufficient ? (
                    <TableCell>
                      <Typography>
                        {"Is Eligible?"}
                      </Typography>
                    </TableCell>
                  ) : null
                }
                <TableCell align="center">
                  <Link component={RouterLink} to="/calculate/documents">
                    <Button
                      variant="contained"
                      className={clsx(classes.removeTextTransform, classes.buttonWidth)}
                      color="secondary"
                    >
                      {"View Created Forms"}
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.bodyMargin}>
              {
                reports.map(({ label, subtext, documentType, generate }) => {
                  if (documentType === 'fbar' && isFBARNecessary) {
                    return (
                      <TableRow key={label}>
                        <TableCell>
                          <div>
                            <Typography variant="h5" display="inline">
                              {label}
                            </Typography>
                            <ClickAwayListener onClickAway={() => this.handleToolTipClose(FBAR_TOOLTIP_KEY)}>
                              <div className={clsx(classes.inline, classes.warning)}>
                                <ToolTip
                                  placement="top-start"
                                  title={fbarMessage}
                                  onClose={() => this.handleToolTipClose(FBAR_TOOLTIP_KEY)}
                                  open={this.state.tooltipOpen[FBAR_TOOLTIP_KEY]}
                                  disableFocusListener
                                  disableTouchListener
                                  disableHoverListener
                                >
                                  <WarningIcon
                                    onClick={() => this.handleToolTipOpenClose(FBAR_TOOLTIP_KEY)}
                                    error="true"
                                    color="primary"
                                  />
                                </ToolTip>
                              </div>
                            </ClickAwayListener>
                            <Typography variant="subtitle2">
                              {subtext}
                            </Typography>
                          </div>
                        </TableCell>
                        {
                          !isPlanTypeSufficient ? (
                            <TableCell>
                              <Link
                                component={RouterLink}
                                to="/account"
                                color="inherit"
                                onClick={this.triggerPlanAndBilling}
                              >
                                <Typography>
                                  {"Unlock"}
                                </Typography>
                              </Link>
                            </TableCell>
                          ) : null
                        }
                        <TableCell align="center">
                          <Button
                            className={classes.removeTextTransform}
                            variant="contained"
                            disabled={planName === 'free' || !isPlanTypeSufficient}
                            onClick={() => this.clickGenerateDocument(documentType)}
                          >
                            {generate}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  if (planName === 'free') {
                    return (
                      <TableRow key={label}>
                        <TableCell>
                          <div>
                            <Typography variant="h5">
                              {label}
                            </Typography>
                            <Typography variant="subtitle2">
                              {subtext}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Link
                            component={RouterLink}
                            to="/account"
                            color="inherit"
                            onClick={this.triggerPlanAndBilling}
                          >
                            <Typography>
                              {"Unlock"}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            className={classes.removeTextTransform}
                            variant="contained"
                            disabled
                            onClick={() => this.clickGenerateDocument(documentType)}
                          >
                            {generate}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={label}>
                        <TableCell className={classes.marginRightAuto}>
                          <div>
                            <Typography variant="h5">
                              {label}
                            </Typography>
                            <Typography variant="subtitle2">
                              {subtext}
                            </Typography>
                          </div>
                        </TableCell>
                        {
                          !isPlanTypeSufficient ? (
                            <TableCell>
                              <Link
                                component={RouterLink}
                                to="/account"
                                color="inherit"
                                onClick={this.triggerPlanAndBilling}
                              >
                                <Typography>
                                  {"Unlock"}
                                </Typography>
                              </Link>
                            </TableCell>
                          ) : null
                        }
                        <TableCell align="center">
                          <Button
                            className={classes.removeTextTransform}
                            variant="contained"
                            disabled={!isPlanTypeSufficient}
                            onClick={() => this.clickGenerateDocument(documentType)}
                          >
                            {generate}
                          </Button>
                          {/* {
                            isPlanTypeSufficient ?
                              null :
                              (
                                <div className={clsx(classes.inline, classes.warning)}>
                                  <ToolTip placement="top-start" title={`You have ${transCount} transactions and your plan only supports ${planTypesTransLimits[planName].maxTransactions} transactions.`}>
                                    <WarningIcon error="true" color="primary" />
                                  </ToolTip>
                                </div>
                              )
                          } */}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })
              }
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}


CreateReports.propTypes = {
  exchangeAndTaxData: PropTypes.shape({
    isFBARNecessary: PropTypes.bool.isRequired,
    tradeDataByExchange: PropTypes.object
  }),
  auth: PropTypes.shape({
    taxYear: PropTypes.number,
    planName: PropTypes.string.isRequired,
    maxTransactions: PropTypes.number
  }),
  api: PropTypes.shape({
    documentsCreating: PropTypes.array
  }),
  gotoPlanAndBillingTab: PropTypes.func.isRequired,
  triggerGenerateDocument: PropTypes.func.isRequired,
  setAppApiMessage: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    exchangeAndTaxData: {
      isFBARNecessary: state.exchangeAndTaxData.isFBARNecessary,
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange
    },
    auth: {
      taxYear: state.exchangeAndTaxData.taxYear,
      planName: state.auth.planName,
      maxTransactions: state.auth.maxTransactions
    },
    ui: {
      appApiMessage: state.auth.appApiMessage
    },
    api: {
      documentsCreating: state.api.documentsCreating
    }
  };
}


export default compose(
  connect(mapStateToProps, { gotoPlanAndBillingTab, triggerGenerateDocument, setAppApiMessage, fetchDocumentCreationStatus }),
  withStyles(styles)
)(requireAuth(CreateReports));
