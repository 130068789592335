export default [
  { ticker: 'BTC', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Bitcoin', isCryptoCurrency: true },
  { ticker: 'LTC', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Litecoin', isCryptoCurrency: true },
  { ticker: 'ETH', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Ethereum', isCryptoCurrency: true },
  { ticker: 'BCH', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Bitcoin Cash', isCryptoCurrency: true },
  { ticker: 'XLM', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Stellar', isCryptoCurrency: true },
  { ticker: 'XRP', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Ripple', isCryptoCurrency: true },
  { ticker: 'USDT', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Tether', isCryptoCurrency: true },
  { ticker: 'BNB', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Binance Coin', isCryptoCurrency: true },
  { ticker: 'EOS', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'EOS', isCryptoCurrency: true },
  { ticker: 'XMR', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Monero', isCryptoCurrency: true },
  { ticker: 'ADA', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Cardano', isCryptoCurrency: true },
  { ticker: 'LEO', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'UNUS SED LEO', isCryptoCurrency: true },
  { ticker: 'TRX', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Tron', isCryptoCurrency: true },
  { ticker: 'LINK', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Chainlink', isCryptoCurrency: true },
  { ticker: 'DASH', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Dash', isCryptoCurrency: true },
  { ticker: 'XTZ', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Tezos', isCryptoCurrency: true },
  { ticker: 'Neo', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'NEO', isCryptoCurrency: true },
  { ticker: 'MIOTA', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'IOTA', isCryptoCurrency: true },
  { ticker: 'ETC', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Ethereum Classic', isCryptoCurrency: true },
  { ticker: 'ATOM', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Cosmos', isCryptoCurrency: true },
  { ticker: 'MKR', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Maker', isCryptoCurrency: true },
  { ticker: 'XEM', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'NEM', isCryptoCurrency: true },
  { ticker: 'ONT', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Ontology', isCryptoCurrency: true },
  { ticker: 'USDC', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'USD Coin', isCryptoCurrency: true },
  { ticker: 'CRO', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Crypto.com Chain', isCryptoCurrency: true },
  { ticker: 'ZEC', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Zcash', isCryptoCurrency: true },
  { ticker: 'VSYS', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'V Systems', isCryptoCurrency: true },
  { ticker: 'DOGE', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Dogecoin', isCryptoCurrency: true },
  { ticker: 'DCR', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Decred', isCryptoCurrency: true },
  { ticker: 'VET', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'VeChain', isCryptoCurrency: true },
  { ticker: 'QTM', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Qtum', isCryptoCurrency: true },
  { ticker: 'BTG', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Bitcoin Gold', isCryptoCurrency: true },
  { ticker: 'BSV', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Bitcoin SV', isCryptoCurrency: true },
  { ticker: 'ICX', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Icon', isCryptoCurrency: true },
  { ticker: 'USD', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'United States Dollar', isCryptoCurrency: false },
  { ticker: 'CAD', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'Canadian Dollar', isCryptoCurrency: false },
  { ticker: 'EUR', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'European Union Currency', isCryptoCurrency: false },
  { ticker: 'GBP', createdAt: new Date(), dateStart: new Date('2017-01-01'), dateEnd: null, description: 'British Pound', isCryptoCurrency: false }
];
