import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import history from '../../../../history';
import { storeCalculateTabChange } from 'actions';


const styles = theme => ({
  importButtonWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  importButton: {
    textTransform: 'none',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    width: '50%'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  tabWidth: {
    [theme.breakpoints.up('md')]: {
      width: '10%'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class GainLossNavBar extends React.Component {
  state = {
    tabSelected: false
  }

  componentDidMount() {
    const { url } = this.props;
    if (url === '/calculate/review') {
      this.props.storeCalculateTabChange(0);
      this.setState({ tabSelected: 0 });
    } else if (url === '/calculate/plan') {
      this.props.storeCalculateTabChange(1);
      this.setState({ tabSelected: 3 });
    } else if (url === '/calculate/calc') {
      this.props.storeCalculateTabChange(2);
      this.setState({ tabSelected: 1 });
    } else if (url === '/calculate/gainLoss') {
      this.props.storeCalculateTabChange(3);
      this.setState({ tabSelected: 2 });
    } else if (url === '/calculate/documents') {
      this.props.storeCalculateTabChange(4);
      this.setState({ tabSelected: 4 });
    }
  }

  handleTabChange = (event, tabSelected) => {
    this.setState({ tabSelected });
    this.props.storeCalculateTabChange(tabSelected);
    if (tabSelected === 0) {
      history.push('/calculate/review');
    } else if (tabSelected === 1) {
      history.push('/calculate/plan');
    } else if (tabSelected === 2) {
      history.push('/calculate/calc');
    } else if (tabSelected === 3) {
      history.push('/calculate/gainLoss');
    } else if (tabSelected === 4) {
      history.push('/calculate/documents');
    }
  }

  render() {
    const { classes } = this.props;
    // const { tabSelected } = this.state; // unused but i'll keep it around in case i change my ways
    const { ui: { calculateTabSelected } } = this.props;

    return (
      <div>
        <div className={clsx(classes.hideOnDesktop, classes.root)}>
          <AppBar position="static" color="primary">
            <Tabs
              value={calculateTabSelected}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons="on"
            >
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="1) Review Transactions"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="2) Choose Plan"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="3) Calculate Gain / Loss"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="4) Gain / Loss Data"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="5) Download Tax Forms"
              />
            </Tabs>
          </AppBar>
        </div>
        <div className={clsx(classes.hideOnMobile, classes.root)}>
          <AppBar position="static" color="primary">
            <Tabs
              value={calculateTabSelected}
              onChange={this.handleTabChange}
              variant="fullWidth"
            >
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="1) Review Transactions"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="2) Choose Plan"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="3) Calculate Gain / Loss"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="4) Gain / Loss Data"
              />
              <Tab
                className={clsx(classes.tabWidth, classes.removeTextTransform)}
                label="5) Download Tax Forms"
              />
            </Tabs>
          </AppBar>
        </div>
      </div>
    );
  }
}

GainLossNavBar.propTypes = {
  storeCalculateTabChange: PropTypes.func.isRequired,
  // calculateTabSelected: PropTypes.number  can be a boolean too...
}

function mapStateToProps(state) {
  return {
    ui: {
      calculateTabSelected: state.ui.calculateTabSelected
    }
  };
} 

export default compose(
  connect(mapStateToProps, { storeCalculateTabChange }),
  withStyles(styles)
)(GainLossNavBar);