import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PnlTransactionsTable from '../PnlTransactionsTableWhatWeOffer';
import pnlHeaders from '../PnlTransactionsTableWhatWeOffer/headers';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  height: {
    height: '8em'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imgSize: {
    width: '7em',
    height: 'auto'
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      // marginTop: theme.spacing(5),
      textAlign: 'center',
      // marginRight: theme.spacing(0.5),
      // marginLeft: theme.spacing(0.5)
    },
    marginBottom: theme.spacing(5)
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
      width: '23%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  marginContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    paddingBottom: theme.spacing(10)
  },
  marginSubHeader: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  subHeader: {
    [theme.breakpoints.down('sm')]: {
      // marginLeft: '2.5%',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '20%',
      width: '60%',
    },
    marginBottom: theme.spacing(5)
    // textAlign: 'center'
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5%',
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '10%',
      width: '80%',
    },
    marginBottom: theme.spacing(5)
    // textAlign: 'center'
  },
  marginBottomFirstDescription: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2)
  },
  marginBottomSecondDescription: {
    marginBottom: theme.spacing(8),
    marginRight: theme.spacing(2)
  },
  marginDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  alignStart: {
    alignItems: 'start'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  underline: {
    textDecoration: 'underline'
  },
});

let count = 0;

const sampleData = [
  {
    acquiredTime: new Date('2018-04-13 16:00:00'),
    disposedTime: new Date('2019-05-31 20:00:00'),
    buyCCY: 'BTC',
    term: 'LT',
    quantity: 0.5,
    proceeds: 4279.475,
    costBasis: 4001.545,
    pnl: 277.93
  },
  {
    acquiredTime: new Date('2019-03-14 12:00:00'),
    disposedTime: new Date('2019-06-14 13:00:00'),
    buyCCY: 'BTC',
    term: 'ST',
    quantity: 1.0,
    proceeds: 10668.75,
    costBasis: 7200.25,
    pnl: 3468.50
  },
  {
    acquiredTime: new Date('2019-07-10 17:00:00'),
    disposedTime: new Date('2019-11-22 16:00:00'),
    buyCCY: 'BTC',
    term: 'ST',
    quantity: 0.75,
    proceeds: 5498.723,
    costBasis: 6360.548,
    pnl: -861.825
  }
];

class TaxDocuments extends React.Component {
  // At the top of the 2020 US tax return, you need to specify whether or not you traded cryptocurrency during the 2020 tax year."}

  render() {
    const { classes } = this.props;

    count += 1;
    return (
      <div className={classes.marginContainer}>
        <Typography
          variant="h1"
          className={clsx(classes.header, classes.alignCenter, classes.textAlignCenter)}
        >
          {"Calculating Capital Gains"}
        </Typography>
        <div className={clsx(classes.flex, classes.subHeader)}>
          <List>
            <ListItem className={classes.alignStart} key="text1">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                <Box>
                  {"Since the IRS has "}
                  <Link
                    target="_blank"
                    href="https://www.irs.gov/businesses/small-businesses-self-employed/virtual-currencies"
                    color="inherit"
                    className={classes.underline}
                  >
                    {'classified'}
                  </Link>
                  {" cryptocurrency as an individual's property, it falls under the same tax laws as a sale any other kind of property."}
                </Box>
              </Typography>
            </ListItem>
            <ListItem className={classes.alignStart} key="text2">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                {"For example, say you traded Bitcoin with US Dollars between Jan 1 2019 and Dec 31 2019."}
              </Typography>
            </ListItem>
            <List component="div" disablePadding>
              <ListItem className={classes.nested} key="nested-text1">
                <ArrowRightAltIcon className={classes.marginRight} />
                <Typography variant="subtitle2">
                  {"You bought 1 BTC for $10,000."}
                </Typography>
              </ListItem>
              <ListItem className={classes.nested} key="nested-text2">
                <ArrowRightAltIcon className={classes.marginRight} />
                <Typography variant="subtitle2">
                  {"You sold 1 BTC for $12,000."}
                </Typography>
              </ListItem>
              <ListItem className={clsx(classes.alignStart, classes.nested)} key="nested-text3">
                <ArrowRightAltIcon className={classes.marginRight} />
                <Typography variant="subtitle2">
                  {"You made a short term capital gain of $2,000, and therefore the gain will be taxed as ordinary income."}
                </Typography>
              </ListItem>
            </List>
            <ListItem className={clsx(classes.marginTop, classes.alignStart)} key="text3">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle2">
                {"Note: Trading one cryptocurrency for another (for example, BTC for ETH) is a taxable transactions in US Dollars.  This requires one to know the US Dollar price of each cryptocurrency at the time of the trade."}
              </Typography>
            </ListItem>
          </List>
        </div>
        <div className={classes.table}>
          <Typography variant="h4">
            {"Digitax makes this easy."}
          </Typography>
          <PnlTransactionsTable
            title={`Sample Bitcoin Trades`}
            key={count}
            pnlTransactions={sampleData}
            displayColumns={pnlHeaders}
          />
        </div>
      </div>
    );
  }
}

// { "For example, say you purchased Bitcoin, you have a cost basis of the price you paid for the Bitcoin.  When you sell that Bitcoin, the difference between what you paid for it, and what you sold it for constitutes either a gain or a loss.  In the case of a gain, you owe either long term and short term capital gains.  It's our job to make all of this easy." }


export default withStyles(styles)(TaxDocuments);