import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import exchanges from 'modules/statics/exchangesMapInverse';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
});

const commaize = (a) => [a.slice(0, -1).join(', '), a.pop()].filter(w => w !== '').join(' and ');

class CenterBox extends React.Component {
  render() {
    const { classes, apiFetching } = this.props;

    let exchangeList = null; 
    exchangeList = commaize(apiFetching.map(({ exchange }) => exchanges[exchange]));

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <Typography variant="h5" className={clsx(classes.textAlignCenter, classes.margin)}>
              {`We can't calculate yet because we're still fetching data from ${exchangeList}.`}
            </Typography>
          </div>
          <div className={classes.alignCenter}>
            <Typography
              variant="subtitle1"
              className={clsx(classes.textAlignCenter, classes.margin)}
            >
              {"Please refresh the page in a few minutes."}
            </Typography>
          </div>
          <div className={clsx(classes.alignCenter, classes.marginBottom)}>
            <CircularProgress color="primary" />
          </div>
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(CenterBox);