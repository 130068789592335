import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import exchangeAndTaxDataReducer from './exchangeAndTaxDataReducer';
import apiReducer from './apiReducer';
import uiReducer from './uiReducer';
import paymentReducer from './paymentReducer';
import pnlReducer from './pnlReducer';
import documentsReducer from './documentsReducer';
import { SIGN_OUT } from '../actions/types';

const appReducer = combineReducers({
  auth: authReducer,
  exchangeAndTaxData: exchangeAndTaxDataReducer,
  payment: paymentReducer,
  pnl: pnlReducer,
  documents: documentsReducer,
  // form: formReducer,
  api: apiReducer,
  ui: uiReducer
});

export default (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
}