// export default [
//   id: counter,
//   dateTime: new Date(movement[0]), // time
//   type: movement[1], // type
//   exchange,
//   coin: movement[2], // coin
//   amount: parseFloat(movement[3]), // amount
//   exchangeFee: parseFloat(0.0), // exchangeFee
//   addressTo , // addressTo
//   addressFrom: '', // addressFrom
//   exchangeTradeId: '' // exchangeTradeId
// }]

export default [
  { id: 'dateTime', numeric: false, disablePadding: true, label: 'Date' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
  { id: 'coin', numeric: false, disablePadding: false, label: 'Asset' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'exchangeFee', numeric: true, disablePadding: false, label: 'Exchange Fee' },
  { id: 'addressTo', numeric: false, disablePadding: false, label: 'Address To' },
  { id: 'addressFrom', numeric: false, disablePadding: false, label: 'Address From' }
];
