import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { fetchFromExchangeAPI } from 'actions';
import Box from '@material-ui/core/Box';


const styles = theme => ({
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      minWidth: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1, 1),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      minWidth: '60%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    }
  },
  outerDiv: {
    [theme.breakpoints.up('md')]: {
      transform: `translate(-25%, -25%)`,
      top: `25%`,
      left: `35%`,
    },
    [theme.breakpoints.down('sm')]: {
      transform: `translate(0%, 25%)`,
    }
    // midWidth: '60%'
  },
  marginText: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  alignCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  marginLeftOne: {
    marginLeft: theme.spacing(1)
  },
  marginLeftFour: {
    marginLeft: theme.spacing(4)
  },
  marginButtons: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  grey: {
    backgroundColor: '#e0f2f1'
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  flex: {
    display: 'flex'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  // dialog: {
  //   width: '60%'
  // },
  marginCenter: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid black',
    width: '100%',
    minHeight: '3em'
  },
  marginRightAuto: {
    marginRight: 'auto'
  },
  alignLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  instructionsContainer: {
    marginTop: theme.spacing(1)
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  exitToAppIcon: {
    width: theme.spacing(2),
    height: 'auto'
  },
  instructionsText: {
    marginTop: '2px',
    marginRight: theme.spacing(0.5)
  },
  headerAlign: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  marginTop: {
    marginTop: theme.spacing(3)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  }
});

const FIELDS = [
  { name: 'apiKey', label: 'API Key' },
  { name: 'secretKey', label: 'Secret Key' },
  { name: 'clientID', label: 'Client ID ' }
];

const REQUIRED_FIELDS = [
  { name: 'apiKey' },
  { name: 'secretKey' },
  { name: 'clientID' }
];

const INITIAL_STATE = {
  fields: {
    apiKey: '',
    secretKey: '',
    clientID: ''
  },
  disabled: {
    submit: false,
    clear: false
  },
  errors: {
    apiKey: null,
    secretKey: null,
    clientID: null
  }
};


// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

class _Modal extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  // componentDidMount() {
  //   this.setState(_.cloneDeep(INITIAL_STATE));
  // }

  reset = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  clickFetchWithKeys = () => {
    const { exchange } = this.props;
    this.props.handleClose();
    this.props.fetchFromExchangeAPI({ exchange: exchange.name, apiKeys: this.state.fields });
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  handleChange = name => ({ target: { value } }) => {
    const { fields, disabled } = this.state;
    fields[name] = value;

    disabled.submit = REQUIRED_FIELDS.every(({ name }) => fields[name] !== '');
    disabled.clear = REQUIRED_FIELDS.some(({ name }) => fields[name] !== '');

    this.setState({ fields, disabled });
  }

  handleCloseWrapped = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
    this.props.handleClose();
  }

  renderTextField = ({ name, label }) => {
    const { fields } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.marginText} key={name}>
        <TextField
          id={name}
          name={name}
          variant="outlined"
          placeholder={label}
          label={label}
          value={fields[name]}
          onChange={this.handleChange(name)}
          autoComplete="off"
          fullWidth
        />
      </div>
    );
  }

  render() {
    const { classes, open, exchange } = this.props;
    const { disabled } = this.state;

    return (
      <Dialog className={classes.dialog} open={open} onClose={this.handleCloseWrapped}>
        <DialogTitle id="form-dialog-title">
          <div className={classes.headerAlign}>
            <div className={classes.alignLeft}>
              <Typography variant="h5">
                {`Connect to ${exchange.display}`}
              </Typography>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                component={RouterLink}
                to={`/integrations/${exchange.name}`}
                className={clsx(classes.instructionsContainer, classes.linkTextColor, classes.alignRight)}
              >
                <Typography variant="subtitle2" className={classes.instructionsText}>
                  {`Instructions`}
                </Typography>
                <ExitToAppIcon className={classes.exitToAppIcon} />
              </Link>
            </div>
            <Tooltip title="Close" aria-label="close-form">
              <Button className={classes.closeIcon} onClick={this.handleCloseWrapped}>
                <CloseIcon />
              </Button>
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>
          <Divider className={classes.marginBottom} />
          {
            FIELDS.map(({ name, label }) => (
              this.renderTextField({ name, label })
            ))
          }
          <Button
            variant="contained"
            color="primary"
            disabled={!disabled.submit}
            fullWidth
            className={clsx(classes.marginTop, classes.removeTextTransform)}
            onClick={this.clickFetchWithKeys}
          >
            <Typography variant="body1">
              <Box component="span" fontWeight="fontWeightMedium">
                {"Fetch Transactions"}
              </Box>
            </Typography>
          </Button>
          <div className={clsx(classes.marginText, classes.headerAlign)}>
            <LockOutlinedIcon className={classes.marginRight} />
            <Typography
              variant="caption"
              display="block"
            >
              {"Remember, we only need read-access; for safety please block trading permissions.  Digitax will never store your api keys.  You will need to enter them every time we fetch your transactions."}
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(null, { fetchFromExchangeAPI })
)(_Modal);