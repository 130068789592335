import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Equalizer from '@material-ui/icons/Equalizer';
import Autorenew from '@material-ui/icons/Autorenew';
import Toc from '@material-ui/icons/Toc';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HowItWorks from 'modules/components/HowItWorks';
import GetStarted from 'modules/components/GetStarted';
import Watermark from 'modules/components/Watermark';


// margin guidelines
// theme.spacing(10) for header on desktop
// theme.spacing(7) for header on mobile

const styles = theme => ({
  rootFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
      textAlign: 'center'
    }
  },
  subHeader: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'center'
  },
  subTitle2Margin: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  },
  marginTop: {
    marginTop: theme.spacing(5)
  },
  paddingTopHowTo: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  removeTransform: {
    textTransform: 'none'
  },
  marginRightButton: {
    marginRight: theme.spacing(7)
  },
  fiftyPctWidth: {
    width: '35%',
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  marginRightIcon: {
    marginRight: theme.spacing(1)
  },
  underline: {
    textDecoration: 'underline'
  },
  marginTopSubtitle: {
    marginTop: '3px'
  },
  marginBottom: {
    marginBottom: theme.spacing(10)
  },
  howToColor: {
    backgroundColor: theme.palette.primary.main
  },
  extraMobileMarginTop: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2)
    }
  }
});

class Home extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper>
        <div className={classes.rootFlex}>
          <Typography variant="h1" className={classes.header}>
            {'Cryptocurrency Taxes. Simplified.'}
          </Typography>
          {/* I want to make this larger, somehow */}
          <Typography variant="subtitle2" className={clsx(classes.marginTop, classes.subHeader)}>
            <Box fontWeight="fontWeightMedium">
              {"Digitax was created by a CPA to save CPA fees.  Cryptocurrency taxes can get complicated quickly, so we are here to protect your pocketbook."}
            </Box>
          </Typography>
        </div>
        <div className={clsx(classes.marginTop, classes.rootFlex)}>
          <Link component={RouterLink} to="/signin" color="inherit">
            <Button
              variant="contained"
              className={clsx(classes.marginRightButton, classes.removeTransform)}
              color="primary"
              size="large"
            >
              {'Try For Free'}
            </Button>
          </Link>
          <Link component={RouterLink} to="/pricing" color="inherit">
            <Button variant="contained" className={classes.removeTransform} size="large">
              {'Pricing Plans'}
            </Button>
          </Link>
        </div>
        <div className={clsx(classes.marginTop, classes.rootFlex, classes.marginBottom)}>
          <div className={clsx(classes.marginRightButton, classes.fiftyPctWidth)}>
            <div className={clsx(classes.flex, classes.subTitle2Margin)}>
              <Equalizer className={classes.marginRightIcon} />
              <Typography
                variant="subtitle1"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightBold">
                  {'CPA Created Solution'}
                </Box>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightLight">
                  {'Our software was written by a CPA, enabling our product to being fully complaint with US tax laws.'}
                </Box>
              </Typography>
            </div>
            <div className={clsx(classes.flex, classes.subTitle2Margin)}>
              <Autorenew className={classes.marginRightIcon} />
              <Typography variant="subtitle1">
                <Box fontWeight="fontWeightBold">
                  {'All Tax Years and Forms'}
                </Box>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightLight">
                  {"We're set up to calculate taxes for previous years, so we can create all of the forms if an ammendment is necessary."}
                </Box>
              </Typography>
            </div>
          </div>
          <div className={classes.fiftyPctWidth}>
            <div className={clsx(classes.flex, classes.subTitle2Margin)}>
              <Toc className={classes.marginRightIcon} />
              <Typography
                variant="subtitle1"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightBold">
                  {'Exchange Coverage'}
                </Box>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightLight">
                  {'We have coverage on all of the major liquid US exchanges. '}
                  <Link component={RouterLink} to="/exchanges" color="inherit" className={classes.underline}>
                    {'See our exchanges here.'}
                  </Link>
                </Box>
              </Typography>
            </div>
            <div className={clsx(classes.flex, classes.subTitle2Margin, classes.extraMobileMarginTop)}>
              <ContactSupportIcon className={classes.marginRightIcon} />
              <Typography variant="subtitle1">
                <Box fontWeight="fontWeightBold">
                  {'Dedicated Support Team'}
                </Box>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.marginTopSubtitle}
              >
                <Box fontWeight="fontWeightLight">
                  {'We have a dedicated support team that is ready to assist you with issues you may encounter.'}
                </Box>
              </Typography>
            </div>
          </div>
        </div>
        <HowItWorks />
        <GetStarted />
        <Watermark />
      </Paper>
    );
  }
}

export default withStyles(styles)(Home);
