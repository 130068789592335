import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
// import orange from '@material-ui/core/colors/orange';

// Notes
// in typography
//      we can change how our characters look, font size thickness etc.
//      the commented section is some boilerplate.  I'm living to the defauult rn because it looks nice
//      We can control the font header this way for example

//      Also notice that i'm not defining typography.h4 for example.
//      This is still setting the h4 class on the Typography component

const rawTheme = createTheme({
  palette: {
    primary: {
      main: '#b5e5c8', // light pale green b5e5c8
      dark: '#414042', // dark grey
      light: '#ffffff' // white
    },
    secondary: {
      main: '#2cdb72', // neon green#f5f5f5  2b7b4c
      dark: '#355149', // dark green
      light: '#f5f5f5' // light grey
    },
    // textPrimary: {
    //   main: '#414042' 
    // },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: red,
    success: green,
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    useNextVariants: true,
  },
  button: {
    textTransform: "none",
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightLight,
  fontFamily: rawTheme.typography.fontFamily,
  // textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[400],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography, //      ...rawTheme.typography.h1,, and h2h3h4 subtitle body etc and so on
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 52,
    },
    h2: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 42,
    },
    h4: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 36,
    },
    h5: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 20,
      // fontWeight: rawTheme.typography.fontWeightLight,
    },
    h6: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography,
      ...fontHeader,
      fontSize: 18,
    },
    body1: {
      ...rawTheme.typography,
      fontWeight: rawTheme.typography.fontWeightLight,
      fontSize: 16,
    },
    body2: {
      ...rawTheme.typography,
      fontSize: 14,
    },
  },
};

export default theme;
