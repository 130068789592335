import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { deleteRecords } from 'actions';
import { TRADES } from 'modules/utils/tradeDataValidators/types';
import logger from 'modules/utils/logger';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      displayColumns,
      classes
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && numSelected !== 0}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableCell}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const tableHeadStyles = theme => ({
  tableCell: {
    whiteSpace: 'nowrap'
  }
});

const EnhancedTableHead = withStyles(tableHeadStyles)(_EnhancedTableHead);


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, deleteClick } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography variant="h6" id="tableTitle">
              {"We've found some errors, please take a look at what's highlighted below."}
            </Typography>
            <Typography variant="subtitle1" id="tableSubtitle">
              {"Update your file, and re-upload."}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={deleteClick}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) :
            null
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  }
});

const alignLeftTradeTypes = [
  'exchange', 'side', 'sizeUnit', 'totalUnit', 'feeUnit', 'priceUnit'
];
const alignLeftRoundTradeTypes = ['size', 'price', 'exchangeFee', 'total'];
const alignLeftWalletTypes = ['exchange', 'type', 'addressTo', 'addressFrom'];
const alignLeftRoundWalletTypes = ['amount', 'exchangeFee']

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleDeleteRecordsClick = event => {
    event.preventDefault()

    const { selected, data } = this.state;
    const newData = data.filter((v, i) => !(selected.includes(i)));

    this.setState({ data: newData, selected: [] });
  }

  // have the client update the file on their side...
  // may want to implement this ourselves

  // handleChange = (rowIndex, id) => event => {
  //   const { data } = this.state;
  //   data[rowIndex][id] = event.target.value;
  //   if (data[rowIndex][id] === '') {
  //     data[rowIndex]['error'][id] = undefined;
  //   }
  //   this.setState({ data });
  // }

  componentDidMount() {
    const { tradeData } = this.props;
    const data = [];
    tradeData.forEach(fileArray => {
      fileArray.forEach(({ trade, id, error }) => {
        data.push({ ...trade, id, error });
      });
    });

    if (JSON.stringify(tradeData) !== "{}") {
      this.setState({ data });
    }
  }

  render() {
    const {
      classes,
      displayColumns,
      title,
      transactionType
    } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

    logger.info(data, 'data');
    // onChange={this.handleChange(index, type, TRADES)}
    // onBlur={this.handleOnBlur(index, type, TRADES)}
    // onClick={event => this.handleClick(event, n.id)}
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          deleteClick={this.handleDeleteRecordsClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n, index) => {
                  console.log('n', n);
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => this.handleClick(event, n.id)}
                      >
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      {
                        transactionType === TRADES ?
                          displayColumns.map(({ id }) => {
                            // we have an error, so we'll nest a tooltip around it
                            // not sure if there is a cleaner way to do this..
                            if (n['error'][id]) {
                              if (id === 'product') {
                                return (
                                  <TableCell
                                    scope="row"
                                    padding="none"
                                    key={id}
                                  >
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n[id]}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else if (alignLeftTradeTypes.includes(id)) {
                                return (
                                  <TableCell align="left" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n[id]}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else if (alignLeftRoundTradeTypes.includes(id)) {
                                return (
                                  <TableCell align="right" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={_.round(n[id], 6)}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else if (n[id] && 'toLocaleString' in n[id]) { // (id === 'tradeDateTime')
                                return (
                                  <TableCell align="right" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n[id].toLocaleString()}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              }
                            }

                            // no errors

                            if (id === 'product') {
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  key={id}
                                >
                                  {n[id]}
                                </TableCell>
                              );
                            } else if (alignLeftTradeTypes.includes(id)) {
                              return (
                                <TableCell align="left" key={id}>
                                  {n[id]}
                                </TableCell>
                              );
                            } else if (alignLeftRoundTradeTypes.includes(id)) {
                              return (
                                <TableCell align="right" key={id}>
                                  {_.round(n[id], 6)}
                                </TableCell>
                              );
                            } else {  // if (id === 'tradeDateTime')
                              return (
                                <TableCell align="right" key={id}>
                                  {n[id].toLocaleString()}
                                </TableCell>
                              );
                            }
                          }) :
                          // wallet transactions
                          displayColumns.map(({ id }) => {
                            logger.info('n[id], id', n[id], id);
                            if (n['error'][id]) {
                              if (id === 'coin') {
                                return (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                    key={id}
                                  >
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n.coin}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else if (alignLeftWalletTypes.includes(id)) {
                                return (
                                  <TableCell align="left" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n[id]}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else if (alignLeftRoundWalletTypes.includes(id)) {
                                return (
                                  <TableCell align="right" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={_.round(n[id], 6)}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              } else { // if (id === 'tradeDateTime')
                                return (
                                  <TableCell align="right" key={id}>
                                    <Tooltip placement="top-start" title={n['error'][id]}>
                                      <TextField
                                        variant="outlined"
                                        value={n[id].toLocaleString()}
                                        error
                                      />
                                    </Tooltip>
                                  </TableCell>
                                );
                              }
                            }

                            // no errors

                            if (id === 'coin') {
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  key={id}
                                >
                                  {n.coin}
                                </TableCell>
                              );
                            } else if (alignLeftWalletTypes.includes(id)) {
                              return (
                                <TableCell align="left" key={id}>
                                  {n[id]}
                                </TableCell>
                              );
                            } else if (alignLeftRoundWalletTypes.includes(id)) {
                              return (
                                <TableCell align="right" key={id}>
                                  {_.round(n[id], 6)}
                                </TableCell>
                              );
                            } else { // if (id === 'tradeDateTime')
                              return (
                                <TableCell align="right" key={id}>
                                  {n[id].toLocaleString()}
                                </TableCell>
                              );
                            }
                          })
                      }
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  tradeData: PropTypes.array.isRequired,
  deleteRecords: PropTypes.func.isRequired
};

export default compose(
  connect(null, { deleteRecords }),
  withStyles(styles)
)(EnhancedTable);
