import {
  TYPE,
  BUY_CURRENCY,
  BUY_AMOUNT,
  SELL_CURRENCY,
  SELL_AMOUNT,
  FEE_CURRENCY,
  FEE_AMOUNT,
  DATE,
  EXCHANGE
} from 'modules/components/ManualEntryGrid/tradeTypes';
import {
  WALLET_DATE,
  WALLET_EXCHANGE,
  CURRENCY,
  AMOUNT,
  WALLET_FEE_AMOUNT,
  ADDRESS_TO,
  ADDRESS_FROM,
  EXCHANGE_TRADE_ID
} from 'modules/components/ManualEntryGrid/walletTypes';
import { TRADES, WALLET } from '../types';
import logger from 'modules/utils/logger';

export default (data) => {
  try {
    logger.info('manualEntry validation start');
    let newTrades = [];
    let newMovements = [];
    let badTrades = [];
    let counter = 0;
    data.tradeData.forEach(trans => {
      // TNT QUESTION: can't we just return an empty object?  what is purpose of this specific validate function?  Should we even return anything at all and have a badTrades?
      const errors = validate(trans);
      logger.info('should just be an empty object, errors: ', errors);
      if (JSON.stringify(errors) !== "{}") {
        badTrades.push({ errors, trans, id: counter });
        logger.info('pushed entry into badTrades, badtrades is now: ', badTrades);
        counter++;
        return;
      }
      // ETH - BTC
      // sizeUnit for a Sell should be currency being sold... price, fee, and total unit should be currency being purchased
      // sizeUnit for a Sell should be currency being sold... price, fee, and total unit should be currency being purchased

      if (trans[TYPE] === 'TRADE') {
        if (trans[BUY_CURRENCY] !== 'USD') {
          newTrades.push({
            id: counter,
            product: trans[BUY_CURRENCY] + '-' + trans[SELL_CURRENCY],
            exchange: trans[EXCHANGE].toLowerCase(),
            side: 'BUY',  // TNT NOTE: this is so that when they sell a currency, and sell into USD, it switches the product
            tradeDateTime: new Date(trans[DATE]),
            size: parseFloat(trans[BUY_AMOUNT]),
            sizeUnit: trans[BUY_CURRENCY],
            price: parseFloat(trans[SELL_AMOUNT]) / parseFloat(trans[BUY_AMOUNT]),
            exchangeFee: parseFloat(trans[FEE_AMOUNT]),
            total: Math.abs(parseFloat(trans[BUY_AMOUNT])),
            totalUnit: trans[BUY_CURRENCY],
            feeUnit: trans[FEE_CURRENCY],
            priceUnit: trans[SELL_CURRENCY],
            source: 'manual'
          });
          logger.info('just pushed to newTrades, it is now: ', newTrades);
        } else {
          newTrades.push({
            id: counter,
            product: trans[SELL_CURRENCY] + '-' + trans[BUY_CURRENCY],
            exchange: trans[EXCHANGE].toLowerCase(),
            side: 'SELL',  // TNT NOTE: this is so that when they sell a currency, and sell into USD, it switches the product
            tradeDateTime: new Date(trans[DATE]),
            size: parseFloat(trans[SELL_AMOUNT]),
            sizeUnit: trans[BUY_CURRENCY],
            price: parseFloat(trans[BUY_AMOUNT]) / parseFloat(trans[SELL_AMOUNT]),
            exchangeFee: parseFloat(trans[FEE_AMOUNT]),
            total: Math.abs(parseFloat(trans[BUY_AMOUNT])),
            totalUnit: trans[BUY_CURRENCY],
            feeUnit: trans[FEE_CURRENCY],
            priceUnit: trans[SELL_CURRENCY],
            source: 'manual'
          });
          logger.info('just pushed to newTrades, it is now: ', newTrades);
        }
      } else {
        newMovements.push({
          id: counter,
          dateTime: new Date(trans[WALLET_DATE]), // time
          exchange: trans[WALLET_EXCHANGE].toLowerCase(), // TNT QUESTION: why is this one diff than the regular EXCHANGE? i'm gonna guess its for the property so it doesnt have same value
          type: trans[TYPE], // type of wallet movement
          coin: trans[CURRENCY], // coin
          amount: Math.abs(parseFloat(trans[AMOUNT])), // amount
          exchangeFee: parseFloat(trans[WALLET_FEE_AMOUNT]), // exchangeFee
          addressTo: trans[ADDRESS_TO], // addressTo
          addressFrom: trans[ADDRESS_FROM], // addressFrom
          exchangeTradeId: trans[EXCHANGE_TRADE_ID], // exchangeTradeId
          source: 'manual'
        });
        logger.info('just pushed to newMovements, it is now: ', newMovements);
      }
    });

    if (badTrades.length === 0) {
      logger.info('badTrades length is 0, returning wallet and trade info, new trades: ', newTrades);
      logger.info('new trades: ', newMovements);
      return {
        [TRADES]:
        {
          isValid: true,
          comment: "Look's good!",
          tradeData: newTrades,
          type: TRADES,
          badTrades
        },
        [WALLET]:
        {
          isValid: true,
          comment: "Look's good!",
          tradeData: newMovements,
          type: WALLET,
          badTrades
        }
      };
    } else if (newTrades.length > 0 || newMovements.length > 0) {
      return {
        [TRADES]:
        {
          isValid: false,
          comment: "We have some trades that look right, check some of them tho",
          tradeData: newTrades,
          type: TRADES,
          badTrades
        },
        [WALLET]:
        {
          isValid: false,
          comment: "We have some trades that look right, check some of them tho",
          tradeData: newMovements,
          type: WALLET,
          badTrades
        }
      };
    } else {
      return {
        [TRADES]:
        {
          isValid: false,
          comment: "All uploaded data was bad",
          tradeData: newTrades,
          type: TRADES,
          badTrades
        },
        [WALLET]:
        {
          isValid: false,
          comment: "All uploaded data was bad",
          tradeData: newMovements,
          type: WALLET,
          badTrades
        }
      };
    }
  } catch (err) {
    logger.error('manual entry validate error: ', err.message);
    throw err;
  }
}
// TNT QUESITON: why have this at all?  doesn't seem like we need this or errors in general in this file at all lol
function validate(trans) {
  // so i think i've already validated on the component.
  // This function exists to serve.
  const errors = {};

  return errors;
}
