export default {
  'Coinbase': 'coinbase',
  'Coinbase Pro': 'coinbase_pro',
  'Binance': 'binance',
  'Binance US': 'binance_us',
  // 'Bitfinex': 'bitfinex',
  'Bittrex': 'bittrex',
  'Kraken': 'kraken',
  'Gemini': 'gemini',
  'Bitstamp': 'bitstamp',
  'Gateio': 'gateio'
};
