import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GetAppIcon from '@material-ui/icons/GetApp';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import collapseHeaders from './collapseHeaders';
import { addZeroCostBasis } from 'actions';
import exchanges from 'modules/statics/exchangesMapInverse';
import exportTrades from 'modules/utils/exportCSVs/exportTrades';
import logger from 'modules/utils/logger';


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headerStyles = theme => ({
  headerText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // width: "300px",
    overflow: "hidden"
  }
}
);

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      order,
      orderBy,
      displayColumns
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell />
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.headerText}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

_EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableHead = withStyles(headerStyles)(_EnhancedTableHead);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  removeTransform: {
    textTransform: 'none'
  },
  buttonWidth: {
    [theme.breakpoints.up('md')]: {
      width: '200px'
    }
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, title, uploadClick, csvDownloadClick } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <ListItem>
            <Typography variant="h6" id="tableTitle">
              {title}
            </Typography>
            <IconButton onClick={csvDownloadClick}>
              <GetAppIcon />
            </IconButton>
          </ListItem>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 ?
            (
              <Tooltip title="Upload $0 Cost Basis to Digitax for the selected transaction.">
                <Button
                  variant="contained"
                  className={
                    clsx(classes.removeTransform, classes.buttonWidth)
                  }
                  color="primary"
                  onClick={uploadClick}
                >
                  {"Use $ 0.00 Cost Basis"}
                </Button>
              </Tooltip>
            ) :
            null
        }
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  },
  menuTextField: {
    width: theme.spacing(13),
    borderColor: '#2cdb72',
    // borderColor: '2cdb72'
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3)
    }
  },
  marginText: {
    margin: theme.spacing(2)
  },
  headerText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // width: "300px",
    overflow: "hidden"
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    }
  },
  rowSize: {
    width: '200px'
  },
  rowRoot: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'dummy',
    selected: [],
    data: [],
    rowData: {},
    page: 0,
    rowsPerPage: 5
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleUploadRecordsClick = event => {
    const { selected, data } = this.state;
    const { mostRecentPnlCalcRun } = this.props;

    let transactionIDs = [];
    let record;
    selected.forEach(rowNumber => {
      record = data.slice(rowNumber, rowNumber + 1)[0];
      logger.info('record', record);
      transactionIDs.push(record.unmatched_trade_id);
    });

    // logger.info('selectedRows to add zero cost basis', transactionIDs);
    this.props.addZeroCostBasis({ transactionIDs, mostRecentPnlCalcRunID: mostRecentPnlCalcRun.id });
  }

  handleSelectChange = (row) => event => {
    // i dont think this is necessary anymore
    this.props.updateTradeAdjustmentType({
      trade_id: row.dbId, adjustmentType: event.target.value
    });
    this.handleClick('dummy', row.id);
  }

  buildTableData = ({ movementsWithMissingBasis, partialBasisDetail, unmatchedTradeRecords }) => {
    console.log('movementsWithMissingBasis', movementsWithMissingBasis);
    console.log('unmatchedTradeRecords', unmatchedTradeRecords);
    let tempData = [];
    let id = 0; // id for the sortingtable, and therefore selected
    // logger.info('movementsWithMissingBasis', movementsWithMissingBasis);
    // logger.info('partialBasisDetail', partialBasisDetail);
    movementsWithMissingBasis.forEach(mvmt => {
      // annoyingly mvmt.exchangeFee & mvmt.amount are coming out of the database as string
      if (partialBasisDetail[mvmt.id] && Array.isArray(partialBasisDetail[mvmt.id]) && partialBasisDetail[mvmt.id].length > 0 && partialBasisDetail[mvmt.id][0].unmatched_trade_id) {
        mvmt.unmatched_trade_id = partialBasisDetail[mvmt.id][0].unmatched_trade_id;
      }

      if (!mvmt.unmatched_trade_id && Array.isArray(unmatchedTradeRecords)) {
        unmatchedTradeRecords.forEach(unMvmt => {
          console.log('unMvmt', unMvmt);
          if (unMvmt.wallet_movement_id === mvmt.id) {
            console.log('got it', mvmt.id);
            // console.log('unmatched movement', unMvmt);
            mvmt.unmatched_trade_id = unMvmt.id;
          }
        });
      }

      tempData.push({
        ...mvmt,
        id,
        dbId: mvmt.id,
        mvmtDateTime: new Date(mvmt.dateTime),
        exchange: exchanges[mvmt.exchange] ? exchanges[mvmt.exchange] : mvmt.exchange,
        adjustmentType: mvmt.adjustmentType ? mvmt.adjustmentType : '',
        donorCostBasis: mvmt.donorCostBasis,
        exchangeFee: parseFloat(mvmt.exchangeFee) !== 0.0 ? _.round(mvmt.exchangeFee, 8).toFixed(10) : '0.00000000',
        amount: parseFloat(mvmt.amount) !== 0.0 ? _.round(mvmt.amount, 8).toFixed(10) : '0.00000000'
      });
      id++;
    });

    return tempData;
  }

  csvDownloadClick = event => {
    exportTrades('movements_missing_basis.csv', this.state.data);
  }

  // tablehead
  renderMissingBasis = (row) => {
    const { classes, partialBasisDetail, maxIncomeOverStatementByTrans } = this.props;
    const rowData = [];
    if (Array.isArray(partialBasisDetail[row.dbId]) && partialBasisDetail[row.dbId].length > 0) {
      partialBasisDetail[row.dbId].forEach(basisRow => {
        rowData.push({
          ...basisRow,
          exchange: exchanges[basisRow.exchange] ? exchanges[basisRow.exchange] : basisRow.exchange,
          tradeDateTime: new Date(basisRow.tradeDateTime),
          quantityWithBasis: parseFloat(basisRow.quantityWithBasis) !== 0.0 ? _.round(basisRow.quantityWithBasis, 8).toFixed(10) : '0.00000000',
          costBasisPerCoin: parseFloat(basisRow.costBasisPerCoin) !== 0.0 ? '$ ' + _.round(basisRow.costBasisPerCoin, 8).toFixed(10) : '0.00000000',
        });
      });
      let quantityWithMissingBasis = null;
      if (parseFloat(rowData[0].quantityWithMissingBasis) > 0) {
        quantityWithMissingBasis = _.round(rowData[0].quantityWithMissingBasis, 8).toFixed(10);
      }
      return (
        <Box margin={1} className={classes.marginLeft}>
          <List>
            <ListItem alignItems="flex-start">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle1" gutterBottom component="div">
                {`Missing eligible transactions for ${quantityWithMissingBasis} of ${rowData[0].coin}.`}
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start">
              <KeyboardArrowRightIcon className={classes.marginRight} />
              <Typography variant="subtitle1" gutterBottom component="div">
                {"Applying a $0.00 cost basis for this missing currency will result in an overstatement of income by "}
                <Box component="span" display="inline" fontWeight="fontWeightBold">
                  {`$${_.round(maxIncomeOverStatementByTrans[row.dbId], 4)}`}
                </Box>
                {"."}
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <Table size="medium" aria-label={`transaction-id-${row.id}-detail-table`}>
            <TableHead>
              <TableRow>
                {
                  collapseHeaders.map(row => (
                    <TableCell
                      key={row.id}
                      align={row.numeric ? 'right' : 'left'}
                      padding={row.disablePadding ? 'none' : 'default'}
                      className={classes.headerText}
                    >
                      {row.label}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map(thisRow => (
                <TableRow key={thisRow.buy_trade_id}>
                  <TableCell align="left">{thisRow.coin}</TableCell>
                  <TableCell align="right">{thisRow.tradeDateTime.toLocaleString()}</TableCell>
                  <TableCell align="left">{thisRow.exchange}</TableCell>
                  <TableCell align="right">{thisRow.quantityWithBasis}</TableCell>
                  <TableCell align="right">{thisRow.costBasisPerCoin}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      );
    }
    return (
      <Typography className={classes.marginText}>
        {"We don't know any transactions which would contribute to the cost basis for this movement."}
      </Typography>
    );
  }

  componentDidMount() {
    const { movementsWithMissingBasis, unmatchedTradeRecords, partialBasisDetail } = this.props;

    if (JSON.stringify(movementsWithMissingBasis) !== "{}") {
      const tableData = this.buildTableData(
        { movementsWithMissingBasis, partialBasisDetail, unmatchedTradeRecords }
      );
      this.setState({ data: tableData });

      const updatedByUser = [];
      tableData.forEach(({ id, adjustmentType }) => {
        if (adjustmentType && adjustmentType !== 'choose_one') {
          updatedByUser.push(id);
        }
      });

      this.setState({ selected: updatedByUser });

    }
  }

  render() {
    const { classes, displayColumns, title } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    let timeToDisplay;
    data.forEach(row => {
      row.isSelected = this.isSelected(row.id);
    });

    return (
      <Paper className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          uploadClick={this.handleUploadRecordsClick}
          csvDownloadClick={this.csvDownloadClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  if (n.mvmtDateTime !== null) {
                    timeToDisplay = new Date(new Date(n.mvmtDateTime).getTime()).toLocaleString();
                  }
                  return (
                    <React.Fragment key={n.id}>
                      <TableRow
                        hover
                        aria-checked={n.isSelected}
                        tabIndex={-1}
                        selected={n.isSelected}
                        className={classes.rowRoot}
                      >
                        <TableCell padding="checkbox">
                          <IconButton aria-label="expand row" onClick={event => this.handleClick(event, n.id)}>
                            {n.isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">{n.coin}</TableCell>
                        <TableCell align="left">{n.exchange}</TableCell>
                        <TableCell align="left">{n.type}</TableCell>
                        <TableCell align="right">{timeToDisplay}</TableCell>
                        <TableCell align="right">{n.amount}</TableCell>
                        <TableCell align="right">{n.exchangeFee}</TableCell>
                        <TableCell align="left">{n.addressTo}</TableCell>
                        <TableCell align="left">{n.addressFrom}</TableCell>
                        <TableCell align="left">{n.exchangeTradeId}</TableCell>
                        <TableCell align="left">{n.hash}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={n.isSelected} timeout="auto" unmountOnExit>
                            {this.renderMissingBasis(n)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows-per-page' },
            native: true,
          }}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  movementsWithMissingBasis: PropTypes.array.isRequired,
  addZeroCostBasis: PropTypes.func.isRequired,
};

export default compose(
  connect(null, { addZeroCostBasis }),
  withStyles(styles)
)(EnhancedTable);