import React from 'react';
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
  // or could use their idea for it... return null below
  // https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
  // https://reactrouter.com/web/guides/scroll-restoration

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)