import { WALLET } from '../../types';
import logger from 'modules/utils/logger';

// // coinbase data comes in like the following from the tax reports
// let array = [
//   0: Timestamp, "1/18/2019"
//   1: Transaction Type, "Buy, Sell, Send, Receive",
//   2: Asset, "ETH",
//   3: Quantity Transacted, "3.5"
//   4: USD Spot Price at Transaction, "231.17"
//   5: USD Amount Transacted (Inclusive of Coinbase Fees), "1000",
//   6: Address, "location being sent / received"
//   7: Note, ""Bought 3.3400 BTC for $1,000.00 USD. Paid for with Capital One 360 - 360 ... *****1995. Your bitcoin will arrive by the end of day on Friday Jul 17, 2015.""
// ]

export default ({ exchange, walletMovements }) => {
  let newData = [];
  let badTrades = [];
  let addressTo;
  let type;

  let counter = 0;
  logger.info('first wallet movement');
  logger.info(walletMovements[0]);
  walletMovements.forEach(movement => {
    try {
      const error = validate(movement);
      // logger.info('error', error);
      // logger.info('movement', movement);

      if (movement[1] === 'Send') {
        type = 'withdrawal';
      } else if (movement[7].includes('Fork')) {
        type = 'fork';
      } else {
        type = 'deposit';
      }
      addressTo = movement[1] === 'Send' ? movement[6] : '';

      const thisRecord = createTableData(
        error,
        counter,
        movement[0], // datetime
        type,
        exchange,
        movement[2], // coin
        movement[3], // amount
        0.0, // exchangeFee
        addressTo,
        '', // addressFrom
        '' // exchangeTradeId
      );

      if (JSON.stringify(error) !== "{}") {
        badTrades.push({ error, movement: thisRecord, id: counter });
        return;
      }

      newData.push(thisRecord);
      counter++;
    } catch (e) {
      logger.info(`errored on coinbase wallet upload on id: ${counter}`, e);
      return {
        isValid: false,
        comment: `errored on id:${counter}`,
        tradeData: newData,
        type: WALLET,
        badTrades
      };
    }
  });

  if (badTrades.length === 0) {
    logger.info('coinbase wallet upload valid, first movement below');
    logger.info(newData[0]);
    return {
      isValid: true,
      comment: "Look's good!",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else if (newData.length > 0) {
    logger.info('coinbase wallet upload had some errors, they are below');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "We have some trades that look right, check some of them tho",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  } else {
    logger.info('coinbase wallet upload was only errors, they are below');
    logger.info(badTrades);
    return {
      isValid: false,
      comment: "All uploaded data was bad",
      tradeData: newData,
      type: WALLET,
      badTrades
    };
  }
}

// // coinbase data comes in like the following from the tax reports
// let array = [
//   0: Timestamp, "1/18/2019"
//   1: Transaction Type, "Buy, Sell, Send, Receive",
//   2: Asset, "ETH",
//   3: Quantity Transacted, "3.5"
//   4: USD Spot Price at Transaction, "231.17"
//   5: USD Amount Transacted (Inclusive of Coinbase Fees), "1000",
//   6: Address, "location being sent / received"
//   7: Note, ""Bought 3.3400 BTC for $1,000.00 USD. Paid for with Capital One 360 - 360 ... *****1995. Your bitcoin will arrive by the end of day on Friday Jul 17, 2015.""
// ]

function validate(trade) {
  const datetime = trade[0]; // note not in UTC time
  const transactionType = trade[1];
  const asset = trade[2];
  const size = trade[3]; // don't think we want this. We should be summing across in our database
  // const spotUSD = trade[4]; // don't need these
  // const amountUSD = trade[5];

  const error = {};
  if (!transactionType) {
    error.type = "We need either a withdrawal or desposit";
  }

  try {
    let testDate;
    if (Number.isInteger(datetime)) {
      // test for unix date
      testDate = new Date(datetime * 1000);
    } else {
      testDate = new Date(datetime);
    }
    if (Object.prototype.toString.call(testDate) === "[object Date]") {
      // it is a date
      if (isNaN(testDate.getTime())) {  // d.valueOf() could also work
        // date is not valid
        error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
      } else {
        // date is valid
      }
    } else {
      // not a date
      error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
    }
  } catch (err) {
    error.dateTime = 'Date should be in either unix, mm/dd/yyyy, or yyyy-mm-dd hh:mm:ss format';
  }

  if (!asset) {
    error.amountUnit = "We need an amount unit";
  }
  if (isNaN(size)) {
    error.amount = "amount needs to be a number";
  }

  return error;
}

function createTableData(
  error,
  counter,
  datetime,
  type,
  exchange,
  coin,
  amount,
  exchangeFee,
  addressTo,
  addressFrom,
  exchangeTradeId
) {
  let newDateTime = 'N/A'
  if (!error.datetime) {
    if (Number.isInteger(datetime)) {
      newDateTime = new Date(datetime * 1000)
    } else {
      newDateTime = new Date(datetime);
    }
  }

  return {
    id: counter,
    dateTime: newDateTime,
    type,
    exchange,
    coin,
    amount: Math.abs(parseFloat(amount)),
    exchangeFee: parseFloat(0.0),
    addressTo,
    addressFrom: '',
    exchangeTradeId: '',
    source: 'csv'
  };
}
