// TODOs handle API errors on login Fail
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { changePasswordFromRecovery, fetchAccountDataWithToken } from 'actions';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import SuccessfullyChanged from './components/SuccessfullyChanged';
import NoLongerValid from './components/NoLongerValid';


const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    // marginLeft: theme.spacing(1) ,
    flexGrow: 1
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  paperLogin: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: 650
  },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },
  // paper: {
  //   [theme.breakpoints.up('md')]: {
  //     padding: theme.spacing(2),
  //     color: theme.palette.text.secondary,
  //     textAlign: 'center',
  //     maxWidth: 650
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     padding: theme.spacing(2),
  //     color: theme.palette.text.secondary,
  //     textAlign: 'center',
  //     maxWidth: 650
  //   }
  // },
  flexgrow: {
    flexGrow: 1
  },
  // alignCenter: {
  //   display: 'flex',
  //   justifyContent: 'center'
  // },
  textAlignCenter: {
    textAlign: 'center'
  },
  paperMobile: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: 650
  },
  rootMobile: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    width: 500
  },
  mobileMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    }
  },
  marginBottomOne: {
    marginBottom: theme.spacing(1)
  },
  marginBottomTwo: {
    marginBottom: theme.spacing(2)
  },
  marginBottomCopyRight: {
    paddingBottom: theme.spacing(12)
  },
  emailInput: {
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6)
    }
  },
  passwordRecoveryCaption: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(15),
      textAlign: 'right',
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right'
    }
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      flexBasis: 200,
      marginLeft: theme.spacing(15),
      marginRight: theme.spacing(15),
      width: 300
    }
  },
  createAccountText: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3)
    }
  }, //6eeea1, 3.66
  removeTextTransform: {
    textTransform: 'none'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  signUpWaterMark: {
    paddingTop: theme.spacing(5),
    backgroundColor: '#dedede',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(5)
  },
  signUpFont: {
    color: '#355149'
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  fullViewPort: {
    width: '100%',
    height: '100vh'
  },
  btnContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  btnSubmit: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    textTransform: 'none'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
});

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
  disabled: {
    submit: true
  },
  errors: {
    password: null,
    confirmPassword: null
  }
};

const REQUIRED_FIELDS = [
  { name: 'password', label: 'New Password' },
  { name: 'confirmPassword', label: 'Confirm New Password' }
];

let count = 0;
class ChangePassword extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  componentDidMount() {
    const { firstName, errorMessage } = this.props;
    if (count === 0 && firstName === '' && JSON.stringify(errorMessage) === "{}") {
      count++;
      this.props.fetchAccountDataWithToken({
        token: this.props.match.params.token,
        users_id: this.props.match.params.users_id
      });
    }
  }

  validate = values => {
    const errors = {};
    REQUIRED_FIELDS.forEach(({ name }) => {
      if (!values[name]) {
        errors[name] = 'Required'
      }
    })

    // validate passwords are the same and are long enough
    const { password, confirmPassword } = values;
    if (password && confirmPassword && password !== confirmPassword) {
      errors.password = 'Passwords do not match'
    }
    if (password && password.length < 6) {
      errors.password = 'Please enter a password of at least 6 characters'
    }
    return errors;
  }

  handleChange = name => ({ target: { value } }) => {
    const { password, confirmPassword, errors: stateErrors } = this.state;

    if (
      password !== '' &&
      confirmPassword !== '' &&
      this.state.disabled.submit
    ) {
      const disabled = { submit: false, clear: false };
      if (stateErrors[name] !== null && stateErrors[name] !== undefined) {
        const errors = stateErrors;
        errors[name] = null;
        this.setState({ [name]: value, disabled, errors });
      } else {
        this.setState({ [name]: value, disabled });
      }
      return;
    } else if (value !== '') {
      const { disabled } = this.state;
      disabled.clear = false;
      if (stateErrors[name] !== null && stateErrors[name] !== undefined) {
        const errors = stateErrors;
        errors[name] = null;
        this.setState({ [name]: value, errors, disabled });
      } else {
        this.setState({ [name]: value, disabled });
      }
      return;
    } else if (value === '') {
      const disabled = { submit: true, clear: true };
      this.setState({ [name]: value, disabled });
      return;
    }
    this.setState({ [name]: value });
  }

  reset = () => {
    this.setState(_.cloneDeep(INITIAL_STATE));
  }

  changePasswordClick = (event) => {
    // ** We need to handle errors from the API **
    event.preventDefault()
    const { password, confirmPassword } = this.state;
    const errors = this.validate({ password, confirmPassword });
    if (JSON.stringify(errors) === "{}") {
      this.props.changePasswordFromRecovery(
        {
          newPassword: password,
          token: this.props.match.params.token,
          users_id: this.props.match.params.users_id
        }
      );
    } else {
      this.setState({ errors, confirmPassword: '' });
    }
  }

  render() {
    const {
      classes,
      firstName,
      errorMessage,
      passwordHasBeenReset
    } = this.props;
    const { errors, disabled } = this.state;
    // this.props.match.params.token
    if (JSON.stringify(errorMessage) !== "{}") {
      return <NoLongerValid />
    }

    if (passwordHasBeenReset) {
      return <SuccessfullyChanged />;
    }

    // JD comment - made accessibility change in mobile12
    return (
      <Paper className={classes.fullViewPort}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h6" className={classes.title}>
              {
                firstName ?
                  `Hello ${firstName} - Please change your password below:` :
                  `Hello - Please change your password below:`
              }
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                color="secondary"
                id="password"
                type="password"
                label="New Password"
                name="password"
                autoFocus
                onChange={this.handleChange('password')}
                value={this.state['password']}
                error={this.state.errors['password'] !== null && this.state.errors['password'] !== undefined}
              />
              {errors['password'] ? (
                <Typography color="error">
                  {errors['password']}
                </Typography>
              ) : null}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                color="secondary"
                name="confirm-password"
                label="Confirm New Password"
                type="password"
                id="confirm-password"
                onChange={this.handleChange('confirmPassword')}
                value={this.state['confirmPassword']}
                error={this.state.errors['confirmPassword'] !== null && this.state.errors['confirmPassword'] !== undefined}
              />
              {errors['confirmPassword'] ? (
                <Typography color="error">
                  {errors['confirmPassword']}
                </Typography>
              ) : null}
            </form>
          </div>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            disabled={disabled.submit}
            className={classes.btnSubmit}
            onClick={this.changePasswordClick}
          >
            {"Submit"}
          </Button>
        </Container>
      </Paper>
    );
  }
}


function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
    firstName: state.auth.firstName,
    passwordHasBeenReset: state.ui.passwordHasBeenReset
  };
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  firstName: PropTypes.string,
  passwordHasBeenReset: PropTypes.bool,
  changePasswordFromRecovery: PropTypes.func.isRequired,
  fetchAccountDataWithToken: PropTypes.func.isRequired
}

export default compose(
  connect(mapStateToProps,
    { changePasswordFromRecovery, fetchAccountDataWithToken }
  ),
  withStyles(styles)
)(ChangePassword);
