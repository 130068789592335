import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ExchangePaper from './components/ExchangePaper';
import GetStarted from 'modules/components/GetStarted';
import Watermark from 'modules/components/Watermark';
import exchangesMapInverse from 'modules/statics/exchangesRealMapInverse';


const styles = theme => ({
  container: {
    paddingBottom: theme.spacing(2)
    // margin: theme.spacing(2)
  },
  flexGrow: {
    flexGrow: 1
  },
  rootFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerWidth: {
    [theme.breakpoints.up('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%'
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7),
      textAlign: 'center',
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5)
    }
  },
  subHeader: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center'
    },
  },
  subTitle2Margin: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5)
    }
  },
  marginTop: {
    marginTop: theme.spacing(5)
  },
  marginRightButton: {
    marginRight: theme.spacing(7)
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  exchangeWidth: {
    [theme.breakpoints.up('md')]: {
      width: '20%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '30%'
    },
  },
  marginTopExchangeList: {
    marginTop: theme.spacing(8)
  },
  marginExchange: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5)
    }
  },
  marginTopSubHeader: {
    marginTop: theme.spacing(2)
  },
  linkColor: {
    color: '#355149'
  },
  // h1Size: {
  //   fontSize: '3.5rem' 
  // }
});

class ExchangesWeCover extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Paper>
          <div className={classes.rootFlex}>
            <Typography variant="h1" className={clsx(classes.header, classes.textAlignCenter)}>
              {'Our Exchange Coverage'}
            </Typography>
          </div>
          <div className={clsx(classes.rootFlex, classes.marginTopSubHeader)}>
            <Typography variant="subtitle2" className={classes.subHeader}>
              {'We want our customers to report their cryptocurrency income accurately.  Making sure we can support all of your transactions is the first step in this process.'}
            </Typography>
          </div>
          <div className={classes.rootFlex}>
            <div className={clsx(classes.marginTopExchangeList, classes.rootFlex, classes.containerWidth)}>
              {
                Object.keys(exchangesMapInverse).map(exchange => (
                  <div key={exchange} className={clsx(classes.marginExchange, classes.exchangeWidth)}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      component={RouterLink}
                      to={`/integrations/${exchange}`}
                    >
                      <ExchangePaper exchange={exchangesMapInverse[exchange]} />
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>
          <div className={classes.rootFlex}>
            <Typography variant="h4" className={clsx(classes.header, classes.textAlignCenter)}>
              {"Don't see your exchange?"}
            </Typography>
          </div>
          <div className={classes.rootFlex}>
            <Typography variant="subtitle2" className={clsx(classes.marginTop, classes.subHeader)}>
              {"If you know all of your transaction details, Digitax allows you to insert your exchange transactions manually.  Otherwise, send an email to "}
              <Box component="span" display="inline" fontWeight="fontWeightBold" className={classes.linkColor}>
                {"support@digitax.io"}
              </Box>
               {" with the exchange you would like added to our API integration.  We work around the clock to support our customers."}
            </Typography>
          </div>
          <GetStarted />
          <Watermark />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ExchangesWeCover);