export default [
  { id: 'coin', numeric: false, disablePadding: false, label: 'Asset' },
  { id: 'exchange', numeric: false, disablePadding: false, label: 'Exchange' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'dateTime', numeric: true, disablePadding: false, label: 'Date' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Amount' },
  { id: 'exchangeFee', numeric: true, disablePadding: false, label: 'Exchange Fee' },
  { id: 'addressTo', numeric: false, disablePadding: false, label: 'Address To' },
  { id: 'addressFrom', numeric: false, disablePadding: false, label: 'Address From' },
  { id: 'exchangeTradeId', numeric: false, disablePadding: false, label: 'Exchange Trade Id' },
  { id: 'hash', numeric: false, disablePadding: false, label: 'Hash' }
];