export default {
  'coinbase': 'Coinbase',
  'coinbase_pro': 'Coinbase Pro',
  'binance': 'Binance',
  'binance_us': 'Binance US',
  // 'bitfinex': 'Bitfinex',
  'bittrex': 'Bittrex',
  'kraken': 'Kraken',
  'gemini': 'Gemini',
  'bitstamp': 'Bitstamp',
  'gateio': 'Gateio'
};
