import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import GainLossDetailTab from './components/GainLossDetailTab';
import NotCalculatedYet from '../../components/NotCalculatedYet';
import NotReconciledYet from '../../components/NotReconciledYet';
import StillHaveUnmatched from '../../components/StillHaveUnmatched';
import NeedToReCalculateZeroCB from '../../components/NeedToReCalculateZeroCB';
import NeedToReCalculateError from '../../components/NeedToReCalculateError';
import StillCalculating from '../../components/StillCalculating';
import { CALCULATING } from 'actions/uiTypes';
import NotHighEnoughPlan from '../../components/NotHighEnoughPlan';
import NotHighEnoughPlanButCalculated from '../../components/NotHighEnoughPlanButCalculated';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
    width: '95%'
  },
  root: {
    // flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  tabWidth: {
    [theme.breakpoints.up('md')]: {
      width: '10%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  padding: {
    padding: theme.spacing(2)
  },
  marginTwo: {
    margin: theme.spacing(2)
  },
  disableMargin: {
    marginRight: 0,
    marginLeft: 0
  }
});


class GainLoss extends React.Component {
  state = {
    tabSelected: 0,
    taxYearStartTime: null,
    taxYearEndTime: null,
    allYearsChecked: false
  }

  // componentDidMount() {

  // }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    const {
      exchangeAndTaxData: {
        pnlTransactions,
        unmatchedMovements,
        unmatchedTrades,
        movementsWithMissingBasis,
        taxYear
      }
    } = this.props;
    const { pnl: { mostRecentPnlCalcRun, mostRecentReconcileRun } } = this.props;
    const { ui: { isCalculatingGainsLosses } } = this.props;
    const { api: { apiFetching } } = this.props;
    const { auth: { isPlanSufficient } } = this.props;

    const { tabSelected, allYearsChecked } = this.state;

    const taxYearStartTime = new Date(`${taxYear.toString()}-01-01 00:00:00`);
    const taxYearEndTime = new Date(`${taxYear.toString()}-12-31 24:00:00`);

    if (!isPlanSufficient && mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome === 'success') {
      return <NotHighEnoughPlanButCalculated />;
    }

    if (!isPlanSufficient) {
      return <NotHighEnoughPlan />;
    }

    let totalGainLoss = 0;
    let taxYearGainLoss = 0;
    let pnlTransactionsTaxYear = [];
    if (Array.isArray(pnlTransactions)) {
      pnlTransactions.forEach(({ pnl }) => {
        totalGainLoss += parseFloat(pnl);
      });

      pnlTransactionsTaxYear = pnlTransactions.filter(({ disposedTimestamp }) => {
        return disposedTimestamp < taxYearEndTime && disposedTimestamp >= taxYearStartTime;
      });

      pnlTransactionsTaxYear.forEach(({ pnl }) => {
        taxYearGainLoss += parseFloat(pnl);
      });
    }

    if (totalGainLoss > 0) {
      totalGainLoss = String(_.round(totalGainLoss, 2).toLocaleString());
    } else {
      totalGainLoss = '(' + String(_.round(totalGainLoss, 2).toLocaleString()).replace('-','') + ')';
    }

    if (taxYearGainLoss > 0) {
      taxYearGainLoss = String(_.round(taxYearGainLoss, 2).toLocaleString());
    } else {
      taxYearGainLoss = '(' + String(_.round(taxYearGainLoss, 2).toLocaleString()).replace('-', '') + ')';
    }

    if (isCalculatingGainsLosses === CALCULATING) {
      return <StillCalculating apiFetching={apiFetching} />;
    }

    if (!mostRecentReconcileRun) {
      return <NotReconciledYet />;
    }

    if (unmatchedTrades.length > 0 || unmatchedMovements.length > 0 || movementsWithMissingBasis.length > 0) {
      return <StillHaveUnmatched />;
    }

    if (!mostRecentPnlCalcRun) {
      return <NotCalculatedYet />;
    }

    // there should be no unmatched but
    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun &&
      mostRecentPnlCalcRun.outcome === 'error'
    ) {
      return <NeedToReCalculateError />;
    }

    // there should be no unmatched but
    if (mostRecentPnlCalcRun && 'outcome' in mostRecentPnlCalcRun && (
      mostRecentPnlCalcRun.outcome === 'added_zero_cost_basis'
    )) {
      return <NeedToReCalculateZeroCB />;
    }

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={allYearsChecked}
                    onChange={this.handleChange}
                    name="allYearsChecked"
                    color="secondary"
                  />
                }
                className={classes.disableMargin}
                label="View All Tax Years"
              />
            </FormControl>
          </div>
          <div className={classes.alignCenter}>
            <AppBar position="static" color="primary">
              <Tabs value={tabSelected} onChange={this.handleTabChange}>
                <Tab
                  className={clsx(classes.tabWidth, classes.removeTextTransform)}
                  label="Gain / Loss Trade Detail"
                />
              </Tabs>
            </AppBar>
          </div>
          <div className={classes.padding}>
            <GainLossDetailTab
              pnlTransactions={allYearsChecked ? pnlTransactions : pnlTransactionsTaxYear}
              summedGainLoss={allYearsChecked ? totalGainLoss : taxYearGainLoss}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

GainLoss.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    pnlTransactions: PropTypes.array,
    unmatchedTrades: PropTypes.array,
    unmatchedMovements: PropTypes.array,
    movementsWithMissingBasis: PropTypes.array,
    tradeDataByExchange: PropTypes.object
  }),
  ui: PropTypes.shape({
    isCalculatingGainsLosses: PropTypes.string
  }),
  pnl: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.object
  }),
  apiFetching: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.array
  }),
  auth: PropTypes.shape({
    isPlanSufficient: PropTypes.bool
  })
};

function mapStateToProps(state) {
  return {
    exchangeAndTaxData: {
      pnlTransactions: state.exchangeAndTaxData.pnlTransactions,
      unmatchedTrades: state.exchangeAndTaxData.unmatchedTrades,
      unmatchedMovements: state.exchangeAndTaxData.unmatchedMovements,
      movementsWithMissingBasis: state.exchangeAndTaxData.movementsWithMissingBasis,
      tradeDataByExchange: state.exchangeAndTaxData.tradeDataByExchange,
      taxYear: state.exchangeAndTaxData.taxYear
    },
    pnl: {
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun,
      mostRecentReconcileRun: state.pnl.mostRecentReconcileRun
    },
    ui: {
      isCalculatingGainsLosses: state.ui.isCalculatingGainsLosses
    },
    api: {
      apiFetching: state.ui.apiFetching
    },
    auth: {
      isPlanSufficient: state.auth.isPlanSufficient
    }
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(GainLoss);