import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import headers from 'modules/components/SortingTableMvmtsMissingBasis/headers';
import SortingTableMvmtsMissingBasis from 'modules/components/SortingTableMvmtsMissingBasis';
import { addZeroCostBasis } from 'actions';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  marginOne: {
    margin: theme.spacing(1)
  },
  paper: {
    // margin: theme.spacing(2),
    width: '95%'
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  marginLeft: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2)
    }
  },
  removeTextTransform: {
    textTransform: 'none'
  },
  paddingTop: {
    paddingTop: theme.spacing(2)
  },
  headerMargin: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2)
    }
  },
  marginRight: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    }
  },
  underline: {
    textDecoration: 'underline'
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  info: {
    height: '4em',
    backgroundColor: theme.palette.primary.main
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginLeft: theme.spacing(1)
  },
  alignTogether: {
    // width: '40%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
});

class CenterBox extends React.Component {
  state = {
    maxincomeOverStatement: null,
    maxIncomeOverStatementByTrans: {}
  }
  componentDidMount() {
    const { movementsWithMissingBasis, partialBasisDetail } = this.props.exchangeAndTaxData;

    if (Array.isArray(movementsWithMissingBasis) && movementsWithMissingBasis.length > 0) {
      let maxIncomeOverStatement = 0;
      let maxIncomeOverStatementByTrans = {};
      // logger.info('movementsWithMissingBasis', movementsWithMissingBasis);
      Object.keys(partialBasisDetail).forEach(dbId => {
        if (partialBasisDetail[dbId][0].totalQuantityMoved) {
          let maxPurchaseCostBasis = 0;
          let totalQuantityMoved = null;
          partialBasisDetail[dbId].forEach(purchase => {
            if (!maxIncomeOverStatementByTrans[dbId]) {
              maxIncomeOverStatementByTrans[dbId] = 0;
            }
            if (purchase.totalQuantityMoved > 0 && totalQuantityMoved === null) {
              totalQuantityMoved = purchase.totalQuantityMoved;
            }
            if (purchase.costBasisPerCoin > maxPurchaseCostBasis) {
              maxPurchaseCostBasis = purchase.costBasisPerCoin;
            }
            // basically building a weighted average cost basis using the trades we have
            // will subtract the max cost basis from this at the end, and multiply by the missing currency
            maxIncomeOverStatementByTrans[dbId] += (purchase.costBasisPerCoin * purchase.quantityWithBasis / purchase.totalQuantityMoved);
          });
          maxIncomeOverStatementByTrans[dbId] = (maxPurchaseCostBasis - maxIncomeOverStatementByTrans[dbId]) * totalQuantityMoved;
          maxIncomeOverStatement += maxIncomeOverStatementByTrans[dbId];
        }
      });
      this.setState({ maxIncomeOverStatement: _.round(maxIncomeOverStatement, 4), maxIncomeOverStatementByTrans });
    }
  }

  handleUndoClick = () => {
    const { mostRecentPnlCalcRun } = this.props.pnl;
    this.props.addZeroCostBasis({ reset: true, mostRecentPnlCalcRunID: mostRecentPnlCalcRun.id });
  }

  render() {
    const { classes, count } = this.props;
    const { movementsWithMissingBasis, partialBasisDetail, unmatchedTradeRecords } = this.props.exchangeAndTaxData;

    if (Array.isArray(movementsWithMissingBasis) && movementsWithMissingBasis.length > 0) {
      return (
        <div>
          <div className={classes.margin}>
            <Typography variant="h4" component="h1" className={clsx(classes.paddingTop, classes.headerMargin)}>
              {"Missing Transaction History"}
            </Typography>
            <List>
              <ListItem alignItems="flex-start">
                <KeyboardArrowRightIcon className={classes.marginRight} />
                <Typography variant="h5">
                  {"We have transactions where you are moving more currency from an exchange than we are aware of existing in that exchange. "}
                  <Link component={RouterLink} to="/help/missing-transaction-history" color="inherit" className={clsx(classes.linkTextColor, classes.underline)}>
                    {'  Why is this happening?'}
                  </Link>
                </Typography>
              </ListItem>
              <ListItem alignItems="flex-start">
                <KeyboardArrowRightIcon className={classes.marginRight} />
                <Typography variant="h5">
                  {"Either do one of the following:"}
                </Typography>
              </ListItem>
              <ListItem>
                <List>
                  <ListItem alignItems="flex-start">
                    <KeyboardArrowRightIcon className={classes.marginRight} />
                    <Typography variant="h6">
                      {"Investigate the transactions we already know and upload any missing trades or wallet movements."}
                    </Typography>
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <KeyboardArrowRightIcon className={classes.marginRight} />
                    <Typography variant="h6">
                      {"If you don't know what is missing, we will assume a cost basis of the missing currency as $0.00 per coin.  This the most conservative approach for your taxes."}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <List>
                      <ListItem alignItems="flex-start">
                        <KeyboardArrowRightIcon className={classes.marginRight} />
                        <Typography variant="h6">
                          {"Given the amount of currency we will need to assign $0.00 cost basis to, this could overstate your income by "}
                          <Box component="span" display="inline" fontWeight="fontWeightBold">
                            {`$${this.state.maxIncomeOverStatement}`}
                          </Box>
                          {"."}
                        </Typography>
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <KeyboardArrowRightIcon className={classes.marginRight} />
                        <Typography variant="h6">
                          {'If you choose this, drill into each transaction below to view any partial basises we may know and click "Use $0.00 Cost Basis".'}
                        </Typography>
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Card variant="outlined" className={clsx(classes.info, classes.alignTogether)}>
                  <InfoOutlinedIcon className={classes.marginInfoIcon} />
                  <Typography variant="caption" className={classes.marginRight}>
                    <Box component="span" fontWeight="fontWeightLight">
                      {"If you think you've made a mistake, you can undo your classifications so far."}
                    </Box>
                  </Typography>
                  <Button
                    onClick={this.handleUndoClick}
                    className={clsx(classes.marginRight, classes.removeTextTransform)}
                  >
                    {"Undo"}
                  </Button>
                </Card>
              </ListItem>
            </List>
          </div>
          <div className={classes.root}>
            <div className={classes.paper}>
              <div className={classes.alignCenter}>
                <SortingTableMvmtsMissingBasis
                  title={'Movements With Missing Basis'}
                  key={count}
                  movementsWithMissingBasis={movementsWithMissingBasis}
                  partialBasisDetail={partialBasisDetail}
                  unmatchedTradeRecords={unmatchedTradeRecords}
                  displayColumns={headers}
                  maxIncomeOverStatementByTrans={this.state.maxIncomeOverStatementByTrans}
                  mostRecentPnlCalcRun={this.props.pnl.mostRecentPnlCalcRun}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}


CenterBox.propTypes = {
  exchangeAndTaxData: PropTypes.shape({
    movementsWithMissingBasis: PropTypes.array,
    partialBasisDetail: PropTypes.object,
    unmatchedTradeRecords: PropTypes.array
  }),
  pnl: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.object
  }),
}

// count used to force a re-render...
let count = 0
function mapStateToProps(state) {
  count += 1;
  return {
    exchangeAndTaxData: {
      movementsWithMissingBasis: state.exchangeAndTaxData.movementsWithMissingBasis,
      partialBasisDetail: state.exchangeAndTaxData.partialBasisDetail,
      unmatchedTradeRecords: state.exchangeAndTaxData.unmatchedTradeRecords
    },
    pnl: {
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun
    },
    count
  };
}


export default compose(
  connect(mapStateToProps, { addZeroCostBasis }),
  withStyles(styles)
)(CenterBox);