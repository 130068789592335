import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = theme => ({
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  margin: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  marginProgress: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
});

// add gay little bear icon
class CenterBox extends React.Component {
  render () {
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.alignCenter}>
            <Typography variant="subtitle1" className={clsx(classes.textAlignCenter, classes.margin)}>
              {"We're still calculating your gains and losses.  Please refresh the page in a few minutes."}
            </Typography>
          </div>
          <div className={clsx(classes.alignCenter, classes.marginProgress)}>
            <CircularProgress color="primary" />
          </div>
        </Paper>
      </div>
    );
  }
}


export default withStyles(styles)(CenterBox);