// TODOs handle API errors on login Fail
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { signIn, clearAPIAuthErrors } from 'actions';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    // marginLeft: theme.spacing(1) ,
    flexGrow: 1
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  paperLogin: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: 650
  },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },
  // paper: {
  //   [theme.breakpoints.up('md')]: {
  //     padding: theme.spacing(2),
  //     color: theme.palette.text.secondary,
  //     textAlign: 'center',
  //     maxWidth: 650
  //   },
  //   [theme.breakpoints.down('sm')]: {
  //     padding: theme.spacing(2),
  //     color: theme.palette.text.secondary,
  //     textAlign: 'center',
  //     maxWidth: 650
  //   }
  // },
  flexgrow: {
    flexGrow: 1
  },
  // alignCenter: {
  //   display: 'flex',
  //   justifyContent: 'center'
  // },
  textAlignCenter: {
    textAlign: 'center'
  },
  paperMobile: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: 650
  },
  rootMobile: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    width: 500
  },
  mobileMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    }
  },
  marginBottomOne: {
    marginBottom: theme.spacing(1)
  },
  marginBottomTwo: {
    marginBottom: theme.spacing(2)
  },
  marginBottomCopyRight: {
    paddingBottom: theme.spacing(12)
  },
  emailInput: {
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6)
    }
  },
  passwordRecoveryCaption: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(15),
      textAlign: 'right',
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right'
    }
  },
  btnClear: {
    marginLeft: theme.spacing(2)
  },
  margin: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2)
    }
  },
  textField: {
    [theme.breakpoints.up('md')]: {
      flexBasis: 200,
      marginLeft: theme.spacing(15),
      marginRight: theme.spacing(15),
      width: 300
    }
  },
  createAccountText: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3)
    }
  }, //6eeea1, 3.66
  removeTextTransform: {
    textTransform: 'none'
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  signUpWaterMark: {
    paddingTop: theme.spacing(5),
    backgroundColor: '#dedede',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(5)
  },
  signUpFont: {
    color: '#355149'
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  fullViewPort: {
    width: '100%',
    height: '100vh'
  }
});

const INITIAL_STATE = {
  email: '',
  password: '',
  disabled: {
    submit: true,
    clear: true
  },
  errors: {
    email: null,
    password: null
  }
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://digitax.io/">
        Digitax
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// see signup form
// const CLONED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

class SignInForm extends React.Component {
  state = _.cloneDeep(INITIAL_STATE);

  validate = values => {
    const errors = {};
    const requiredFields = ['email', 'password'];
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  // reset = () => {
  //   this.props.clearAPIAuthErrors();
  //   this.setState(_.cloneDeep(INITIAL_STATE));
  // }

  handleChange = name => ({ target: { value } }) => {
    const { errors: stateErrors } = this.state;

    if (value !== '' && this.state.disabled.submit) {
      let disabled;
      let nameToTest;
      if (name === 'email') {
        nameToTest = 'password';
      } else {
        nameToTest = 'email';
      }
      if (value === '' && this.state[nameToTest] === '') {
        disabled = { submit: true, clear: true };
      } else if (value !== '' && this.state[nameToTest] === '') {
        disabled = { submit: true, clear: false };
      } else {
        disabled = { submit: false, clear: false };
      }
      if (stateErrors[name] !== null && stateErrors[name] !== undefined) {
        const errors = stateErrors;
        errors[name] = null;
        this.setState({ [name]: value, disabled, errors });
      } else {
        this.setState({ [name]: value, disabled });
      }
      return;
    } else if (value !== '') {
      const { disabled } = this.state;
      disabled.clear = false;
      if (stateErrors[name] !== null && stateErrors[name] !== undefined) {
        const errors = stateErrors;
        errors[name] = null;
        this.setState({ [name]: value, errors, disabled });
      } else {
        this.setState({ [name]: value, disabled });
      }
      return;
    } else if (value === '') {
      const disabled = { submit: true, clear: true };
      this.setState({ [name]: value, disabled });
      return;
    }
    this.setState({ [name]: value });
  }

  signInClick = (event) => {
    // ** We need to handle errors from the API **
    event.preventDefault()
    const { email, password } = this.state;
    const errors = this.validate({ email, password });
    if (JSON.stringify(errors) === "{}") {
      this.props.signIn({ email: email.toLowerCase(), password });
    } else {
      this.setState({ errors });
    }
  }

  linkOnClick = (event) => {
    // event.preventDefault();
    this.props.clearAPIAuthErrors();
  }

  render() {
    const { classes } = this.props;

    // JD comment - made accessibility change in mobile12
    return (
      <Paper className={classes.fullViewPort}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {"Sign in"}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                color="secondary"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange('email')}
                value={this.state['email']}
                error={this.state.errors['email'] !== null && this.state.errors['email'] !== undefined}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                color="secondary"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange('password')}
                value={this.state['password']}
                error={this.state.errors['password'] !== null && this.state.errors['password'] !== undefined}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.signInClick}
              >
                {"Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    component={RouterLink}
                    to="/signin/recover"
                    color="inherit"
                    onClick={this.linkOnClick}
                    variant="body2"
                    className={classes.signUpFont}
                  >
                    {"Forgot password?"}
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/signup"
                    variant="body2"
                    className={classes.signUpFont}
                  >
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8} className={classes.marginBottomCopyRight}>
            <Copyright />
          </Box>
        </Container>
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.errorMessage };
}

SignInForm.propTypes = {
  classes: PropTypes.object.isRequired,
  apiMessage: PropTypes.object,
  signIn: PropTypes.func.isRequired,
  clearAPIAuthErrors: PropTypes.func.isRequired
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { signIn, clearAPIAuthErrors })
)(SignInForm);
