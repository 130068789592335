import tradeValidator from './trades';
import walletValidator from './wallet';

export default ({ exchange, files }) => {
  let dataToUpload = [];

  files.forEach(data => {
    if (data.length > 0) {
      // trades
      if (data[0].length > 9) {
        dataToUpload.push(
          tradeValidator({
            exchange,
            tradeData: data
          })
        );
      } else {
        // wallet
        dataToUpload.push(
          walletValidator({
            exchange,
            walletMovements: data
          })
        );
      }
    }
  })
  return dataToUpload;
}
