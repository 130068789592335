import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ToolTip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { deleteRecords, updateMovementAdjustmentType, uploadUpdatedUnmatched } from 'actions';
import { WALLET } from 'modules/utils/tradeDataValidators/types';
import exchanges from 'modules/statics/exchangesMapInverse';
import exchangesDb from 'modules/statics/exchangesMap';
import exportMovements from 'modules/utils/exportCSVs/exportMovements';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  depositAdjustmentTypes,
  GIFT_RECEIVED,
  withdrawalAdjustmentTypes,
  DEPOSIT,
  WITHDRAWAL,
  DEPOSIT_PAIR,
  WITHDRAWAL_PAIR
} from './adjustmentTypes';
import Tooltip from '@material-ui/core/Tooltip';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class _EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      displayColumns,
      classes,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && numSelected !== 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'header-checkbox-label' }}
            />
          </TableCell>
          {displayColumns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableCell}
              >
                <ToolTip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </ToolTip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

const tableHeadStyles = theme => ({
  tableCell: {
    whiteSpace: 'nowrap'
  }
});

const EnhancedTableHead = withStyles(tableHeadStyles)(_EnhancedTableHead);

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  title: {
    flex: '0 0 auto',
  },
  selectedText: {
    marginRight: theme.spacing(2)
  },
  arrowColor: {
    color: '#2b7b4c'
  },
  linkTextColor: {
    color: '#2b7b4c'
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  removeTextTransform: {
    textTransform: 'none'
  }
});

let EnhancedTableToolbar = props => {
  const {
    classes,
    title,
    uploadClick,
    rowData,
    currentlyAdjustingMovement,
    csvDownloadClick
  } = props;
  let { numSelected } = props;

  if (numSelected > 0 && JSON.stringify(currentlyAdjustingMovement) !== "{}") {
    numSelected -= 1;
  }
  let isUploadOkay = false;
  let adjustmentError = false;
  let lackingWalletName = false;
  // let lackingBasis = false;
  let pairsSelected = 0;
  let depoPairSelected = false;
  let withdrawalPairSelected = false;
  // this allows us to NOT throw the error when the modal is present
  // when the modal is present, currentlyAdjustingMovement is the movement we are adjusting in the modal
  let numSelectedWithErrors = 0;
  if (JSON.stringify(currentlyAdjustingMovement) === "{}") {
    rowData.forEach(row => {
      if (row.adjustmentType !== '') {
        // if (row.adjustmentType === 'gift_received' && row.donorCostBasis) {
        //   isUploadOkay = true;
        // } else if (row.adjustmentType === 'gift_received' && !row.donorCostBasis) {
        //   adjustmentError = true;
        //   if (row.isSelected) {
        //     numSelectedWithErrors++;
        //   }
        if ([DEPOSIT, WITHDRAWAL].includes(row.adjustmentType) && !row.subTypeDescription) {
          adjustmentError = true;
          lackingWalletName = true;
          if (row.isSelected) {
            numSelectedWithErrors++;
          }
          // if (!row.costBasis) {
          //   lackingBasis = true;
          // }
        //} else if ([DEPOSIT, WITHDRAWAL].includes(row.adjustmentType) && !row.costBasis) {
          // adjustmentError = true;
          // lackingBasis = true;
          // if (row.isSelected) {
          //   numSelectedWithErrors++;
          // }
          // if (!row.subTypeDescription) {
          //   lackingWalletName = true;
          // }
        } else if (row.adjustmentType === DEPOSIT_PAIR) {
          pairsSelected++;
          depoPairSelected = true;
        } else if (row.adjustmentType === WITHDRAWAL_PAIR) {
          pairsSelected++;
          withdrawalPairSelected = true;
        } else if (row.adjustmentType === GIFT_RECEIVED && !row.donorCostBasis) {
          adjustmentError = true;
          // lackingBasis = true;
        } else {
          isUploadOkay = true;
        }
        // } else if (row.adjustmentType !== 'gift_received' && row.adjustmentType !== '') { //|| (['deposit', 'withdrawal'].includes(row.adjustmentType) && row.adjustmentType !== '')) {
        //   isUploadOkay = true;
        // }
      }
    });
  } else {
    isUploadOkay = true;
  }

  let tooManyPairsSelected = false;
  if (pairsSelected > 2) {
    isUploadOkay = false;
    tooManyPairsSelected = true;
    adjustmentError = true;
  } else if (pairsSelected === 2 && withdrawalPairSelected && depoPairSelected) {
    isUploadOkay = true;
  }

  // <ToolTip title={uploadTooltip}>
  //   <IconButton aria-label="ClassificationUpload" className={classes.marginRight}>
  //     <CloudUpload />
  //   </IconButton>
  // </ToolTip>
  let UploadButton = () => null;
  let NextStepHeader = () => null;
  let PairTransactionsButton = () => null;
  if (isUploadOkay && numSelected > 0) {
    const uploadTooltip = pairsSelected === 2 ? "Upload Pair Transactions" : "Upload Classification to Digitax";
    UploadButton = () => (
      <Tooltip title={uploadTooltip}>
        <Button aria-label="ClassificationUpload" variant="contained" onClick={uploadClick} color="primary">
          <CloudUpload className={classes.marginRight} />
          <Typography variant="body1" className={classes.removeTextTransform}>
            <Box component="span" fontWeight="fontWeightMedium">
              {"Upload"}
            </Box>
          </Typography>
        </Button>
      </Tooltip>
    );

    const movementString = numSelected > 1 ? 'movements' : 'movement';
    const readyMovements = numSelected - numSelectedWithErrors;
    const isOrAre = readyMovements > 1 ? 'are' : 'is';
    let boxText = `${numSelected} ${movementString} selected for upload. (${readyMovements} ${isOrAre} ready)`;
    // when in doubt you want items to align on the same line.  use wrap in a <ListItem>
    if (pairsSelected === 2) {
      boxText = "Both movements are selected and ready to be paired.";
    }
    NextStepHeader = () => (
      <ListItem>
        <Typography
          variant="subtitle1"
          display="inline"
          className={clsx(classes.linkTextColor, classes.selectedText)}
        >
          <Box display="inline" fontWeight="fontWeightMedium">
            {boxText}
          </Box>
        </Typography>
        <ArrowForward className={classes.arrowColor} />
      </ListItem>
    );
  } else if (pairsSelected === 1) {
    NextStepHeader = () => (
      <ListItem>
        <Typography
          variant="subtitle1"
          display="inline"
          className={clsx(classes.linkTextColor, classes.selectedText)}
        >
          <Box display="inline" fontWeight="fontWeightMedium">
            {"Please select the other movement to pair."}
          </Box>
        </Typography>
      </ListItem>
    );
  } else if (!isUploadOkay && numSelected > 0) {
    if (adjustmentError) {
      let errorText = '';
      if (lackingWalletName) {
        errorText = "The movement you're classifying has an error.  You must enter a storage name for the movement for us to accurately calculate gains and losses.";
      } else if (tooManyPairsSelected) {
        errorText = "Please fix the issue below.  You can only selected two movements to match as a pair.";
      }
      UploadButton = () => (
        <ToolTip title={errorText}>
          <IconButton aria-label="WarningIcon">
            <WarningIcon error="true" color="error" />
          </IconButton>
        </ToolTip>
      );
    } else {
      UploadButton = () => (
        <ToolTip title="You haven't classified the movement yet.">
          <IconButton aria-label="WarningIcon">
            <WarningIcon error="true" color="error" />
          </IconButton>
        </ToolTip>
      );
    }
    NextStepHeader = () => (
      <div>
        <Typography color="error" variant="subtitle1" display="inline" className={classes.selectedText}>
          <Box fontWeight="fontWeightBold">
            {"Please fix the issue below:"}
          </Box>
        </Typography>
      </div>
    )
  } else {
    UploadButton = () => null;
    NextStepHeader = () => (
      <ListItem>
        <Typography variant="h6" id="UnmatchedMovements">
          {title}
        </Typography>
        <IconButton onClick={csvDownloadClick}>
          <GetAppIcon />
        </IconButton>
      </ListItem>
    );
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <NextStepHeader />
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <PairTransactionsButton />
        <UploadButton />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  colorPaper: {
    backgroundColor: theme.palette.secondary
  },
  menuTextField: {
    width: theme.spacing(12),
    borderColor: '#2cdb72',
    // borderColor: '2cdb72'
  },
});

let currentPage = 0;

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'mvmtDateTime',
    selected: [],
    data: [],
    page: currentPage,
    rowsPerPage: 5,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    currentPage = page;
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleUploadRecordsClick = event => {
    const { selected, data } = this.state;

    let selectedRows = [];
    let record;
    selected.forEach(rowNumber => {
      record = data.slice(rowNumber, rowNumber + 1)[0];
      // adding the exchange name back on it
      selectedRows.push({ ...record, id: record.dbId, exchange: exchangesDb[record.exchange] });
    });

    // logger.info('selectedRows', selectedRows);
    this.props.uploadUpdatedUnmatched({ recordsToUpdate: selectedRows, type: WALLET });
  }

  handleSelectChange = (row) => event => {
    // i dont think this is necessary anymore
    this.props.updateMovementAdjustmentType({
      trade_id: row.dbId, adjustmentType: event.target.value
    });
    if (event.target.value !== 'none') {
      this.handleClick('dummy', row.id);
    }
  }

  renderSelect = (row, rowCount) => {
    const { classes } = this.props;

    return (
      <TableCell
        padding="none"
        // align="right"
        key="select"
        component="th"
        scope="row"
        className={classes.tableCell}
      >
        {
          // this allows us to NOT throw the error when the modal is present
          // when the modal is present, currentlyAdjustingMovement is the movement we are adjusting in the modal
          // errorTitle !== null && JSON.stringify(currentlyAdjustingMovement) === "{}" ? (
          //   <ToolTip placement="top-start" title={errorTitle}>
          //     <IconButton aria-label={`error-${rowCount}`}>
          //       <WarningIcon error="true" color="error" className={classes.marginLeft} />
          //     </IconButton>
          //   </ToolTip>
          // ) : null
        }
        <TextField
          select
          align="right"
          className={classes.menuTextField}
          value={row.adjustmentType === 'none' ? '' : row.adjustmentType}
          placeholder={"Choose"}
          id={`Choose-${rowCount}-basic`}
          label={"Choose"}
          onChange={this.handleSelectChange(row)}
          color={row.adjustmentType ? "primary" : "secondary"}
          SelectProps={{
            MenuProps: {
              className: classes.menu
            }
          }}
          variant="outlined"
        >
          {
            row.type === DEPOSIT ? (
              depositAdjustmentTypes.map(_type => (
                <MenuItem key={_type.value} value={_type.value}>
                  {_type.label}
                </MenuItem>
              ))
            ) : (
              withdrawalAdjustmentTypes.map(_type => (
                <MenuItem key={_type.value} value={_type.value}>
                  {_type.label}
                </MenuItem>
              ))
            )
          }
        </TextField>
      </TableCell>
    );
  }

  buildTableData = (unmatchedMovements) => {
    let tempData = [];
    let id = 0; // id for the sortingtable, and therefore selected
    unmatchedMovements.forEach(mvmt => {
      // annoyingly mvmt.exchangeFee & mvmt.amount are coming out of the database as string
      tempData.push({
        ...mvmt,
        id,
        dbId: mvmt.id,
        mvmtDateTime: new Date(mvmt.dateTime),
        exchange: exchanges[mvmt.exchange] ? exchanges[mvmt.exchange] : mvmt.exchange,
        adjustmentType: mvmt.adjustmentType ? mvmt.adjustmentType : '',
        donorCostBasis: mvmt.donorCostBasis,
        exchangeFee: parseFloat(mvmt.exchangeFee) !== 0.0 ? _.round(mvmt.exchangeFee, 8).toFixed(10) : '0.00000000',
        amount: parseFloat(mvmt.amount) !== 0.0 ? _.round(mvmt.amount, 8).toFixed(10) : '0.00000000'
      });
      id++;
    });

    return tempData;
  }

  csvDownloadClick = event => {
    exportMovements('unmatched_wallet_movements.csv', this.state.data);
  }

  componentDidMount() {
    const { unmatchedMovements } = this.props;

    if (JSON.stringify(unmatchedMovements) !== "{}") {
      const tableData = this.buildTableData(unmatchedMovements);
      this.setState({ data: tableData });

      const updatedByUser = [];
      tableData.forEach(({ id, adjustmentType }) => {
        if (adjustmentType && !['choose_one', 'none'].includes(adjustmentType)) {
          updatedByUser.push(id);
        }
      });
      this.setState({ selected: updatedByUser });
    }
  }

  render() {
    const { classes, displayColumns, title, currentlyAdjustingMovement } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, data } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let timeToDisplay;

    data.forEach(row => {
      row.isSelected = this.isSelected(row.id);
    });
    let rowCount = 0;
    return (
      <Paper className={clsx(classes.root, classes.colorPaper)}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          uploadClick={this.handleUploadRecordsClick}
          rowData={data}
          currentlyAdjustingMovement={currentlyAdjustingMovement}
          csvDownloadClick={this.csvDownloadClick}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} size="small">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              displayColumns={displayColumns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  // const isSelected = this.isSelected(n.id);
                  rowCount += 1;
                  if (n.mvmtDateTime !== null) {
                    timeToDisplay = new Date(new Date(n.mvmtDateTime).getTime()).toLocaleString();
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={n.isSelected}
                      tabIndex={-1}
                      key={n.dbId}
                      selected={n.isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => this.handleClick(event, n.id)}
                      >
                        <Checkbox checked={n.isSelected} inputProps={{ 'aria-label': `${rowCount}-checkbox-label` }} />
                      </TableCell>
                      {this.renderSelect(n, rowCount)}
                      <TableCell align="left">{n.coin}</TableCell>
                      <TableCell align="left">{n.exchange}</TableCell>
                      <TableCell align="left">{n.type}</TableCell>
                      <TableCell align="right">{timeToDisplay}</TableCell>
                      <TableCell align="right">{n.amount}</TableCell>
                      <TableCell align="right">{n.exchangeFee}</TableCell>
                      <TableCell align="left">{n.addressTo}</TableCell>
                      <TableCell align="left">{n.addressFrom}</TableCell>
                      <TableCell align="left">{n.hash}</TableCell>
                      <TableCell align="left">{n.exchangeTradeId}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { 'aria-label': 'rows-per-page' },
            native: true,
          }}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  displayColumns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  unmatchedMovements: PropTypes.array.isRequired,
  deleteRecords: PropTypes.func.isRequired,
  updateMovementAdjustmentType: PropTypes.func.isRequired,
  uploadUpdatedUnmatched: PropTypes.func.isRequired
};

export default compose(
  connect(null, { deleteRecords, updateMovementAdjustmentType, uploadUpdatedUnmatched }),
  withStyles(styles)
)(EnhancedTable);
