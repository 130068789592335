import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AppBar from '@material-ui/core/AppBar';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {
  fetchFromExchangeAPI,
  storeHeaderTabChange,
  setAppApiMessage,
  clearAppApiMessage,
  fetchUploadStatus,
  downloadTemplateCSV
} from 'actions';
import clsx from 'clsx';
import ImportGridContainer from 'modules/components/ImportGridContainer';
import ManualEntryGrid from 'modules/components/ManualEntryGrid';
import requireAuth from 'modules/components/requireAuth';
import SortingTableTradeData from 'modules/components/SortingTableTradeData';
import SortingTableWalletData from 'modules/components/SortingTableWalletData';
import SortingTableUserImports from 'modules/components/SortingTableUserImports';
import tradeColumns from 'modules/utils/tradeDataValidators/tradeColumns';
import walletColumns from 'modules/components/SortingTableWalletData/headers';
import historyColumns from 'modules/components/SortingTableUserImports/statics/historyColumns';
import APIUploadTab from './components/APIUploadTab';
// import CSVInstructionsGrid from './components/CSVInstructionsGrid';
import VerifyEmail from './components/VerifyEmail';
import history from '../../../history'


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // height: '100vh'
  },
  containerPadding: {
    paddingBottom: theme.spacing(2)
  },
  marginTwo: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: theme.spacing(1),
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1),
    //   marginTop: theme.spacing(1)
    // }
  },
  marginLeft: {
    marginLeft: theme.spacing(2)
  },
  caption: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  paddingTop: {
    paddingTop: theme.spacing(4)
  },
  alignCenter: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  titleMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(2)
    }
  },
  marginRight: {
    marginRight: theme.spacing(4)
  },
  margin: {
    margin: theme.spacing(2)
  },
  subtitleMargin: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  marginTopAndBottom: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  removeTransform: {
    textTransform: 'none'
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonHeight: {
    height: '5%',
    marginTop: theme.spacing(5)
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  alignTogether: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  alignTogetherNoWidth: {
    width: '50%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    textAlign: 'left',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  marginInfoIcon: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    marginLeft: theme.spacing(1)
  },
  info: {
    height: '4em',
    backgroundColor: theme.palette.primary.main
  },
});

// function TabContainer(props) {
//   return (
//     <Typography component="div" style={{ padding: 8 * 3 }}>
//       {props.children}
//     </Typography>
//   );
// }

class ImportDashboard extends React.Component {
  state = {
    tabIndex: 0,
    files: [],
    shouldDisplayAlreadyCalculatedWarning: false
  }

  componentDidMount() {
    // so this is one way to catch the success of the api-keys and oauth success
    // i think it's better to update the state of the redis database and pass back via websocket
    const urlQueryParsed = queryString.parse(this.props.location.search);
    if (urlQueryParsed !== undefined && 'status' in urlQueryParsed &&
      urlQueryParsed.status === 'success' && 'exchange' in urlQueryParsed &&
      urlQueryParsed.exchange === 'coinbase' && urlQueryParsed.csrfToken !== ''
    ) {
      // send request to backend to start pulling
      // update state with "loading"

      // only calling this for coinbase at the moment
      this.props.fetchFromExchangeAPI({
        exchange: urlQueryParsed.exchange,
        apiKeys: {},
        csrfToken: urlQueryParsed.csrfToken
      });
    }
    this.props.storeHeaderTabChange(1);
  
    const { appApiMessage } = this.props.ui;
    const { mostRecentPnlCalcRun } = this.props.pnl;

    if (mostRecentPnlCalcRun && mostRecentPnlCalcRun.outcome === 'success' && !appApiMessage) {
      this.props.setAppApiMessage({ header: "We already calculated your Gains And Losses", bodyMessageUL: ["As always, please import more transaction data if you have it.  This will ensure our reports are accurate.", "If you do import more data, we will need to reconcile and re-calculate your gains and losses again using the updated data."] })
    }

    const { api: { apiFetching } } = this.props;
    if (Array.isArray(apiFetching) && apiFetching.length > 0) {
      this.props.fetchUploadStatus();
    }
  }

  handleTabChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  }

  handleCsvTabChange = (event, csvTabIndex) => {
    this.setState({ csvTabIndex });
  }

  handleFinishedClick = (event) => {
    history.push('/calculate/review');
    this.props.storeHeaderTabChange(2);
  }
  
  handleAlreadyCalculatedModalClose = () => {
    this.setState({ shouldDisplayAlreadyCalculatedWarning: false });
    this.props.clearAppApiMessage();
  }

  renderMyTransactions = () => {
    const {
      classes,
      exchangeAndTaxData: { walletData, tradeData, count, count2 },
    } = this.props;

    return (
      <div className={classes.containerPadding}>
        <Typography variant="subtitle2" className={classes.subtitleMargin}>
          {"View your uploaded exchange trades and wallet movements below:"}
        </Typography>
        {/* <SortingTableTradeData
          title={'Exchange Trades'}
          key={count}
          tradeData={tradeData}
          displayColumns={tradeColumns}
        />
        <SortingTableWalletData
          title={'Wallet Movements'}
          key={count2}
          tradeData={walletData}
          displayColumns={walletColumns}
        /> */}
        <Card variant="outlined" className={classes.marginTwo}>
          <SortingTableTradeData
            title={'Exchange Trades'}
            key={count}
            tradeData={tradeData}
            displayColumns={tradeColumns}
          />
        </Card>
        <Card variant="outlined" className={classes.marginTwo}>
          <SortingTableWalletData
            title={'Wallet Movements'}
            key={count2}
            tradeData={walletData}
            displayColumns={walletColumns}
          />
        </Card>
      </div>
    );
  }

  renderManualTab = () => {
    const { auth: { isEmailConfirmed }, classes } = this.props;
    if (!isEmailConfirmed) {
      return <VerifyEmail />;
    }

    return (
      <div className={classes.containerPadding}>
        <ManualEntryGrid />
      </div>
    );
  }

  downloadClick = (type) => {
    this.props.downloadTemplateCSV({ type });
  }

  renderCsvTab = () => {
    const { classes, exchangeAndTaxData: { hasUploadedData } } = this.props;
    const { auth: { isEmailConfirmed } } = this.props;

    if (!isEmailConfirmed) {
      return <VerifyEmail />;
    }
    // jboxxx todo....
    // move the tables to a new Tab
    // and put some input instructions on the CSVs tab
    return (
      <div>
        <Typography variant="h5" className={clsx(classes.marginLeft, classes.titleMargin)}>
          {"Digitax specific CSVs."}
        </Typography>
        <Typography variant="h6" className={clsx(classes.marginLeft, classes.titleMargin)}>
          {"Please use the templates below to convert your transactions into Digitax's CSVs.  There are two sample transactions on each template that you should use as a reference."}
        </Typography>
        <Card variant="outlined" className={clsx(classes.marginLeft, classes.info, classes.alignTogetherNoWidth)}>
          <InfoOutlinedIcon className={classes.marginInfoIcon} />
          <Typography variant="caption">
            {"When you're ready to upload, make sure you delete the sample transactions."}
          </Typography>
        </Card>
        <div className={clsx(classes.margin)}>
          <div className={classes.alignTogether}>
            <KeyboardArrowRightIcon />
            <Typography variant="h6">
              {"Exchange Trades Template"}
            </Typography>
            <IconButton onClick={() => this.downloadClick('trades')} className={classes.marginLeft}>
              <GetAppIcon />
            </IconButton>
          </div>
          <div className={classes.alignTogether}>
            <KeyboardArrowRightIcon />
            <Typography variant="h6">
              {"Wallet Movements Template"}
            </Typography>
            <IconButton onClick={() => this.downloadClick('wallet')} className={classes.marginLeft}>
              <GetAppIcon />
            </IconButton>
          </div>
        </div>
        <ImportGridContainer hasUploadedDataToDb={hasUploadedData} />
        {/* <Divider className={classes.subtitleMargin} />
        <CSVInstructionsGrid /> */}
      </div>
    );
  }

  renderAPITab = () => {
    const { auth: { isEmailConfirmed } } = this.props;
    if (!isEmailConfirmed) {
      return <VerifyEmail />;
    }

    return (
      <APIUploadTab />
    );
  }

  renderHistoryTab = () => {
    const { exchangeAndTaxData: { userImports, count3 } } = this.props;
    return (
      <div>
        <SortingTableUserImports
          displayColumns={historyColumns}
          title={'History of User Imports'}
          key={count3}
          userImports={userImports}
        />
      </div>
    );
  }

  render() {
    const { tabIndex } = this.state;
    const { classes } = this.props;
    const { mostRecentPnlCalcRun } = this.props.pnl;
    const { apiFetching } = this.props.api;
    const { hasUploadedData } = this.props.exchangeAndTaxData;

    return (
      <Paper className={clsx(classes.marginTwo, classes.root)}>
        <div>
          <div className={classes.hideOnMobile}>
            <div className={clsx(classes.marginTwo, classes.flexBox)}>
              <Typography display="inline" variant="h1" className={classes.paddingTop}>
                {"Import Your Data:"}
              </Typography>
              <Button
                variant="contained"
                className={
                  clsx(
                    classes.removeTransform, classes.buttonHeight
                  )
                }
                color="primary"
                onClick={this.handleFinishedClick}
                disabled={Array.isArray(apiFetching) || !hasUploadedData}
              >
                {
                  <Typography variant="subtitle1">
                    {
                      mostRecentPnlCalcRun && ['success', 'added_zero_cost_basis'].includes(mostRecentPnlCalcRun.outcome) ? "View your gains and losses" : (
                        Array.isArray(apiFetching) ? 
                        "Please wait for the API download to complete" : 
                        !hasUploadedData ? "You need to upload transactions" : "Are you all finished?"
                      )
                    }
                  </Typography>
                }
              </Button>
            </div>
          </div>
          <div className={classes.hideOnDesktop}>
            <div className={classes.marginTwo}>
              <Typography display="inline" variant="h2" className={classes.paddingTop}>
                {"Import Your Data:"}
              </Typography>
              <div className={classes.alignCenter}>
                <Button
                  variant="contained"
                  className={clsx(classes.marginTopAndBottom, classes.removeTransform)}
                  color="primary"
                  onClick={this.handleFinishedClick}
                  disabled={Array.isArray(apiFetching) || !hasUploadedData}
                >
                  {
                    mostRecentPnlCalcRun ? "View your gains and losses" : (
                      Array.isArray(apiFetching) ?
                        "Please wait for the API download to complete" :
                        !hasUploadedData ? "You need to upload transactions" : "Are you all finished?"
                    )
                  }
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.caption}>
          <Typography className={classes.marginTop} variant="body2">
            {"Find your exchange(s) below and follow the steps to connect Digitax to their API."}
          </Typography>
        </div>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab className={classes.removeTransform} wrapped label="API Import" />
            <Tab className={classes.removeTransform} wrapped label="CSVs" />
            <Tab className={classes.removeTransform} wrapped label="Manual Entry" />
            <Tab className={classes.removeTransform} wrapped label="Upload History" />
            <Tab className={classes.removeTransform} wrapped label="My Transactions" />
          </Tabs>
        </AppBar>
        {tabIndex === 0 ? this.renderAPITab() : null}
        {tabIndex === 1 ? this.renderCsvTab() : null}
        {tabIndex === 2 ? this.renderManualTab() : null}
        {tabIndex === 3 ? this.renderHistoryTab() : null}
        {tabIndex === 4 ? this.renderMyTransactions() : null}
      </Paper>
    );
  }
}

// used to force a re-render on SortingTable whenever we get new data...
// allows us to setState in componentDidMount, as you can't setState in render()
let count = 0;
let count2 = 1;
let count3 = 2;
function mapStateToProps(state) {
  count += 1;
  count2 += 1;
  count3 += 1;
  return {
    auth: {
      isEmailConfirmed: state.auth.isEmailConfirmed
    },
    exchangeAndTaxData: {
      hasUploadedData: state.exchangeAndTaxData.hasUploadedData,
      tradeData: state.exchangeAndTaxData.tradeDataByExchange,
      walletData: state.exchangeAndTaxData.walletDataByExchange,
      userImports: state.exchangeAndTaxData.userImports,
      count,
      count2,
      count3
    },
    pnl: {
      mostRecentPnlCalcRun: state.pnl.mostRecentPnlCalcRun
    },
    api: {
      apiFetching: state.api.apiFetching
    },
    ui: {
      appApiMessage: state.ui.appApiMessage
    }
  };
}

ImportDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  exchangeAndTaxData: PropTypes.shape({
    tradeData: PropTypes.object.isRequired,
    walletData: PropTypes.object.isRequired,
    hasUploadedData: PropTypes.bool,
    count: PropTypes.number.isRequired,
    userImports: PropTypes.array.isRequired
  }),
  ui: PropTypes.shape({
    appApiMessage: PropTypes.object
  }),
  pnl: PropTypes.shape({
    mostRecentPnlCalcRun: PropTypes.object
  }),
  api: PropTypes.shape({
    apiFetching: PropTypes.array
  }),
  auth: PropTypes.shape({
    isEmailConfirmed: PropTypes.bool
  }),
  fetchFromExchangeAPI: PropTypes.func.isRequired,
  storeHeaderTabChange: PropTypes.func.isRequired,
  fetchUploadStatus: PropTypes.func.isRequired,
  downloadTemplateCSV: PropTypes.func.isRequired
}

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      fetchFromExchangeAPI,
      storeHeaderTabChange,
      setAppApiMessage,
      clearAppApiMessage,
      fetchUploadStatus,
      downloadTemplateCSV
    }
  )
)(requireAuth(ImportDashboard));