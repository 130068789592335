import React from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  alignTextCenter: {
    textAlign: 'center'
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(4)
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  marginLi: {
    [theme.breakpoints.down('sm')]: {
      // paddingTop: theme.spacing(2)
    }
  },
  forceHeight: {
    [theme.breakpoints.down('sm')]: {
      height: '48px'
    }
  },
  fullHeight: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2.2)
    }
  }
}));

const footers = [
  {
    title: 'Company',
    links: [
      { description: 'About Us', link: '/aboutus' },
      { description: 'Contact Us', link: '/contactus' },
    ]
  },
  {
    title: 'Resources',
    links: [
      { description: 'What We Offer', link: '/whatweoffer' },
      { description: 'Exchanges', link: '/exchanges' },
      { description: 'Pricing', link: '/pricing' },
    ]
  },
  {
    title: 'Legal',
    links: [
      { description: 'Privacy Policy', link: '/privacypolicy' },
      { description: 'Terms of Service', link: '/termsofservice' },
    ]
  },
];


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        {"Digitax"}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Watermark() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" component="footer" className={classes.footer} >
      <Grid container justifyContent="center">
        {footers.map((footer) => (
          <Grid item xs={6} sm={3} key={footer.title} className={classes.alignTextCenter}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.links.map(({ description, link }) => (
                <li key={description} className={classes.forceHeight}>
                  <Link className={classes.fullHeight} component={RouterLink} to={link} variant="subtitle1" color="textSecondary">
                    {description}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))}
      </Grid>
      <Box mt={10} className={classes.marginBottom}>
        <Copyright />
      </Box>
    </Container >
  );
}